import React, { useEffect, useState } from 'react'
import SideDropdown from '../../../../components/analysisComponents.js/SideDropdown';
import Issues from '../../../../components/analysisComponents.js/Issues';
import ContactUsModal from '../../../../components/modals/ContactUsModal';
import ErrorsQuotation from '../../../../components/analysisComponents.js/ErrorsQuotation';
import KeywordsRanking from './KeywordsRanking';
import KeywordTracking from './KeywordTracking';
import Suggestedkeywords from './Suggestedkeywords';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ContentAnalysisPageKeywords } from '../../../../store/actions';
import FeedbackModal from '../../../../components/common/FeedbackModal';
import { checkIfLoading } from '../../../../store/functions';
import { CONTENT_ANALYSIS_PAGE_KEYWORDS } from '../../../../store/contentAnalysis/actionTypes';
import { Spinner } from 'reactstrap';
import Tabs from './Tabs';
import KeywordsTable from './KeywordsTable';
import { useTranslation } from 'react-i18next';

function CopiedContent({ page }) {
    // ** states
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const history = useNavigate()
    const dispatch = useDispatch()
    const [selectedContent, setSelectedContent] = useState([])
    const [tab, setTab] = useState(1)
    const [t] = useTranslation()

    const { loading, contentPage } = useSelector(state => ({
        loading: checkIfLoading(state, CONTENT_ANALYSIS_PAGE_KEYWORDS),
        contentPage: state?.contentAnalysis?.page
    }))

    useEffect(() => {
        if (!page) history('/content-analysis')
        dispatch(ContentAnalysisPageKeywords(page?.url))
    }, [page])

    useEffect(() => {
        if (contentPage) handleSelectContent('ranking', contentPage?.rankedKeywords)
    }, [contentPage])

    console.log(contentPage)

    // ** function to select content
    const handleSelectContent = (value) => {
        switch (value) {
            case "ranking":
                setTab(1)
                setSelectedContent(<KeywordsTable data={contentPage?.rankedKeywords} />)
                break;
            case "related":
                setTab(2)
                setSelectedContent(<KeywordsTable data={contentPage?.relatedKeywords} />)
                break;
            case "density":
                setTab(3)
                setSelectedContent(<KeywordsTable data={contentPage?.densityKeywords} />)
                break;
            case "organic":
                setTab(4)
                setSelectedContent(<KeywordsTable data={contentPage?.audienceKeywords} />)
                break;
            case "paid":
                setTab(5)
                setSelectedContent(<KeywordsTable data={contentPage?.costperclickKeywords} />)
                break;
            case 'tracking':
                setTab(6)
                setSelectedContent(<KeywordTracking data={contentPage?.rankedKeywords} />)
                break;
        }
    }

    console.log(tab)

    return (
        <div className='analysis'>
            <div className='container-fluid'>
                <div className='row justify-content-center'>
                    {
                        loading ? <div className='col-md-12 text-center'>
                            <Spinner color='success' size='md' />
                        </div> : <>
                            <div className='col-xl-6'>
                                <Issues data={page} />
                            </div>
                            <div className='col-xl-6'>
                                <div className='errors-quotation-chart'>
                                    <div className='card'>
                                        <h5>{t('Copied content within your website')}</h5>
                                        <ErrorsQuotation data={contentPage?.copiedContent} />
                                    </div>
                                    <p className='fix-hint'>{t('Fixed your issues? Analyze again to see if everything is fixed.')}</p>
                                    <div className='buttons'>
                                        <button type='button' className='btn click-btn orange-btn' onClick={() => dispatch(ContentAnalysisPageKeywords(page?.url, true))}>{t('Analyze page')}</button>
                                        <button type='button' className='btn click-btn green-btn' onClick={toggle}>{t('Contact us if you need assistance')}</button>
                                    </div>
                                    <FeedbackModal text="tell-us-how-we-can-help" button="Submit Request" modal={modal} toggle={toggle} />
                                </div>
                            </div>
                            {/* {
                                contentPage?.rankedKeywords?.length > 0 ?
                                    <div className='col-12'>
                                        <KeywordTracking data={contentPage?.rankedKeywords} />
                                    </div> : ""
                            } */}
                            <div className='col-md-12 my-3'>
                                <div className='card'>
                                    <Tabs handleSelectContent={handleSelectContent} tab={tab} />
                                    {selectedContent}
                                </div>
                            </div>
                            {/* {
                                contentPage?.rankedKeywords?.length > 0 ?
                                    <div className='col-12'>
                                        <KeywordsRanking data={contentPage?.rankedKeywords} />
                                    </div> : ""
                            }
                            <div className='col-12'>
                                <Suggestedkeywords costperclickKeywords={contentPage?.costperclickKeywords}
                                    audienceKeywords={contentPage?.audienceKeywords}
                                    relatedKeywords={contentPage?.relatedKeywords}
                                    densityKeywords={contentPage?.densityKeywords} />
                            </div> */}
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default CopiedContent
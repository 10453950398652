import {
  ADD_PROJECT,
  ADD_PROJECT_SUCCESS,
  ADD_SUBTASK,
  ADD_SUBTASK_SUCCESS,
  ADD_TASK,
  ADD_TASK_SUCCESS,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  EDIT_TASK,
  EDIT_TASK_SUCCESS,
  GET_PROJECT,
  GET_PROJECT_SUCCESS,
  LIST_PROJECTS,
  LIST_PROJECTS_SUCCESS,
  LIST_TASKS,
  LIST_TASKS_SUCCESS,
  SET_SUBTASK_DONE,
  SET_SUBTASK_DONE_SUCCESS,
  SET_TASK_COMPLETE,
  SET_TASK_COMPLETE_SUCCESS,
  DELETE_SUBTASK,
  DELETE_SUBTASK_SUCCESS,
  DELETE_TASK,
  DELETE_TASK_SUCCESS,
  UPDATE_SUBTASK,
  UPDATE_SUBTASK_SUCCESS,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  ADD_USER_TO_PROJECT,
  ADD_USER_TO_PROJECT_SUCCESS,
  REMOVE_USER_FROM_PROJECT,
  REMOVE_USER_FROM_PROJECT_SUCCESS
} from "./actionTypes"

export const addProject = (data, func) => {
  return {
    type: ADD_PROJECT,
    payload: { data, func },
  }
}

export const addProjectSuccess = (data) => {
  return {
    type: ADD_PROJECT_SUCCESS,
    payload: data
  }
}

export const addUserToProject = (data, func) => {
  return {
    type: ADD_USER_TO_PROJECT,
    payload: { data, func },
  }
}

export const addUserToProjectSuccess = (data) => {
  return {
    type: ADD_USER_TO_PROJECT_SUCCESS,
    payload: data
  }
}

export const removeUserFromProject = (data, func) => {
  return {
    type: REMOVE_USER_FROM_PROJECT,
    payload: { data, func },
  }
}

export const removeUserFromProjectSuccess = (data) => {
  return {
    type: REMOVE_USER_FROM_PROJECT_SUCCESS,
    payload: data
  }
}

export const listProjects = (search) => {
  return {
    type: LIST_PROJECTS,
    payload: { search },
  }
}

export const listProjectsSuccess = (data) => {
  return {
    type: LIST_PROJECTS_SUCCESS,
    payload: data
  }
}

export const getProject = (user, history) => {
  return {
    type: GET_PROJECT,
    payload: { user, history },
  }
}

export const getProjectSuccess = (data) => {
  return {
    type: GET_PROJECT_SUCCESS,
    payload: data
  }
}

export const deleteProject = (user, history) => {
  return {
    type: DELETE_PROJECT,
    payload: { user, history },
  }
}

export const deleteProjectSuccess = (data) => {
  return {
    type: DELETE_PROJECT_SUCCESS,
    payload: data
  }
}

export const updateProject = (user, history) => {
  return {
    type: UPDATE_PROJECT,
    payload: { user, history },
  }
}

export const updateProjectSuccess = (data) => {
  return {
    type: UPDATE_PROJECT_SUCCESS,
    payload: data
  }
}

export const addTask = (data, project, toggle) => {
  return {
    type: ADD_TASK,
    payload: { data, project, toggle },
  }
}

export const addTaskSuccess = (data) => {
  return {
    type: ADD_TASK_SUCCESS,
    payload: data
  }
}

export const listTasks = (user, history) => {
  return {
    type: LIST_TASKS,
    payload: { user, history },
  }
}

export const listTasksSuccess = (data) => {
  return {
    type: LIST_TASKS_SUCCESS,
    payload: data
  }
}

export const editTask = (data, id, toggle) => {
  return {
    type: EDIT_TASK,
    payload: { data, id, toggle },
  }
}

export const editTaskSuccess = (data) => {
  return {
    type: EDIT_TASK_SUCCESS,
    payload: data
  }
}

export const deleteTask = (id, func) => {
  return {
    type: DELETE_TASK,
    payload: { id, func },
  }
}

export const deleteTaskSuccess = (data) => {
  return {
    type: DELETE_TASK_SUCCESS,
    payload: data
  }
}

export const setTaskComplete = (user, history) => {
  return {
    type: SET_TASK_COMPLETE,
    payload: { user, history },
  }
}

export const setTaskCompleteSuccess = (data) => {
  return {
    type: SET_TASK_COMPLETE_SUCCESS,
    payload: data
  }
}

export const addSubtask = (data, taskId, func) => {
  return {
    type: ADD_SUBTASK,
    payload: { data, taskId, func },
  }
}

export const addSubtaskSuccess = (data) => {
  return {
    type: ADD_SUBTASK_SUCCESS,
    payload: data
  }
}

export const updateSubtask = (user, history) => {
  return {
    type: UPDATE_SUBTASK,
    payload: { user, history },
  }
}

export const updateSubtaskSuccess = (data) => {
  return {
    type: UPDATE_SUBTASK_SUCCESS,
    payload: data
  }
}

export const deleteSubtask = (user, history) => {
  return {
    type: DELETE_SUBTASK,
    payload: { user, history },
  }
}

export const deleteSubtaskSuccess = (data) => {
  return {
    type: DELETE_SUBTASK_SUCCESS,
    payload: data
  }
}

export const setSubtaskDone = (user, history) => {
  return {
    type: SET_SUBTASK_DONE,
    payload: { user, history },
  }
}

export const setSubtaskDoneSuccess = (data) => {
  return {
    type: SET_SUBTASK_DONE_SUCCESS,
    payload: data
  }
}
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import { acceptInvitation } from '../../store/actions'
import { ACCEPT_INVITATION } from '../../store/auth/actionTypes'
import { checkIfLoading } from '../../store/functions'

const Invite = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const history = useNavigate()

    const { inviteLoading } = useSelector(state => ({
        inviteLoading: checkIfLoading(state, ACCEPT_INVITATION)
    }))

    useEffect(() => {
        const code = location?.search?.split('?code=')[1]
        dispatch(acceptInvitation(code, history))
    }, [])

    return (
        <div className='invite-page text-center'>
            <div className='text-center'>
                {
                    inviteLoading ? <Spinner color='success' size={'lg'} /> : ""
                }
            </div>
        </div>
    )
}

export default Invite
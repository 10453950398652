export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'

export const REGISTER_USER = 'REGISTER_USER'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'

export const VERIFY_USER = 'VERIFY_USER'
export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS'

export const GET_USER_DATA = 'GET_USER_DATA'
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS'

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA'
export const UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS'

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'

export const FORGET_PASSWORD = 'FORGET_PASSWORD'
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS'

export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'

export const UPDATE_EMAIL = 'UPDATE_EMAIL'
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS'

export const ACCEPT_INVITATION = 'ACCEPT_INVITATION'
export const ACCEPT_INVITATION_SUCCESS = 'ACCEPT_INVITATION_SUCCESS'

export const LIST_LANGUAGES = 'LIST_LANGUAGES'
export const LIST_LANGUAGES_SUCCESS = 'LIST_LANGUAGES_SUCCESS'

export const AUTH_GOOGLE = 'AUTH_GOOGLE'
export const AUTH_GOOGLE_SUCCESS = 'AUTH_GOOGLE_SUCCESS'

export const REFRESH_GOOGLE_TOKEN = 'REFRESH_GOOGLE_TOKEN'
export const REFRESH_GOOGLE_TOKEN_SUCCESS = 'REFRESH_GOOGLE_TOKEN_SUCCESS'

export const LIST_ANALYTICS = 'LIST_ANALYTICS'
export const LIST_ANALYTICS_SUCCESS = 'LIST_ANALYTICS_SUCCESS'

export const LIST_SEARCH_CONSOLE_ACCOUNTS = 'LIST_SEARCH_CONSOLE_ACCOUNTS'
export const LIST_SEARCH_CONSOLE_ACCOUNTS_SUCCESS = 'LIST_SEARCH_CONSOLE_ACCOUNTS_SUCCESS'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'

export const LOGOUT_USER = 'LOGOUT_USER'
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS'

export const GET_PAYMENT_METHOD = 'GET_PAYMENT_METHOD'
export const GET_PAYMENT_METHOD_SUCCESS = 'GET_PAYMENT_METHOD_SUCCESS'

export const ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD'
export const ADD_PAYMENT_METHOD_SUCCESS = 'ADD_PAYMENT_METHOD_SUCCESS'

export const CHANGE_PAYMENT_METHOD = 'CHANGE_PAYMENT_METHOD'
export const CHANGE_PAYMENT_METHOD_SUCCESS = 'CHANGE_PAYMENT_METHOD_SUCCESS'

export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD'
export const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS'

export const SEND_FEEDBACK = 'SEND_FEEDBACK'
export const SEND_FEEDBACK_SUCCESS = 'SEND_FEEDBACK_SUCCESS'
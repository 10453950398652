import React from "react";
import { useLocation } from "react-router-dom";
import { refreshGoogleTokenAPI } from "./auth/authCalls";
import { tokenConfig } from "./config";

export const checkIfLoading = (store, ...actionsToCheck) =>
  store.ui.loader.actions.some(action => actionsToCheck.includes(action.name));

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function getFirst2chars(name) {
  let namesplit = name?.trim().split(' ')
  let chars = 'UN'
  if (namesplit?.length === 1) {
    chars = namesplit[0][0]
  } else if (namesplit?.length === 2) {
    chars = namesplit[0][0] + namesplit[1][0]
  }
  return chars
}

export function getDayName(date) {
  var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  var d = new Date(date);
  return days[d.getDay()]
}

export const stringToHTML = function (str) {
  var parser = new DOMParser();
  var doc = parser.parseFromString(str, 'text/html');
  console.log(doc)
  return doc.body;
};

export const refreshTokenSetup = async (res, state) => {
  // Timing to renew access token
  let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;
  if (state) await refreshGoogleTokenAPI({ token: res.accessToken }, tokenConfig(state))

  const refreshToken = async () => {
    const newAuthRes = await res.reloadAuthResponse();
    refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
    console.log('newAuthRes:', newAuthRes);
    await refreshGoogleTokenAPI({ token: newAuthRes.access_token }, tokenConfig(state))
    setTimeout(refreshToken, refreshTiming);
  };

  setTimeout(refreshToken, refreshTiming);
};

export const reorder = (list, startIndex, endIndex) => {
  let result = Array.from(list);
  console.log({ result })
  const [removed] = result.splice(startIndex, 1);
  console.log({ removed })
  result.splice(endIndex, 0, removed);
  console.log({ result })

  return result;
};

export const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone?.map((el, i) => {
    el.order = i + 1
    return el
  });
  result[droppableDestination.droppableId] = destClone?.map((el, i) => {
    el.order = i + 1
    return el
  });

  return result;
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
import React from "react";
import { useTranslation } from "react-i18next";

function CompetitorDetails({ competitor }) {
    const [t] = useTranslation()

    return (
        <div className="col-md-6">
            <div className="card">
                <div className="d-flex py-2 border-bottom d-flex align-items-center justify-content-between">
                    <div>{t('Agency')}</div>
                    <h5>{competitor?.data?.company_name}</h5>
                </div>
                <div className="d-flex py-2 border-bottom d-flex align-items-center justify-content-between">
                    <div>{t('Foundation Year')}</div>
                    <h5></h5>
                </div>
                <div className="d-flex py-2 border-bottom d-flex align-items-center justify-content-between">
                    <div>{t('Employees')}</div>
                    <h5>{competitor?.data?.employees?.employees_min || 0} - {competitor?.data?.employees?.employees_max || 0}</h5>
                </div>
                <div className="d-flex py-2 border-bottom d-flex align-items-center justify-content-between">
                    <div>{t('Headquarters')}</div>
                    <h5>{competitor?.data?.headquarters?.country}</h5>
                </div>
                <div className="d-flex py-2 border-bottom d-flex align-items-center justify-content-between">
                    <div>{t('Annual revenues')}</div>
                    <h5>{competitor?.data?.revenue?.revenue_min || 0} - {competitor?.data?.revenue?.revenue_max || 0}</h5>
                </div>
                <div className="d-flex py-2 border-bottom d-flex align-items-center justify-content-between">
                    <div>{t('Sector')}</div>
                    <small>{String(competitor?.data?.category_rank?.category).substring(30)}...</small>
                </div>
            </div>
        </div>
    );
}

export default CompetitorDetails;
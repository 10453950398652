import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap'
import { listTags, addColumn, getPipeline, addTab, deleteTab, listCustomers, updateCustomer } from '../../../store/actions'
import { LIST_TAGS } from '../../../store/crm/actionTypes'
import { checkIfLoading, getDayName, getFirst2chars, move, reorder } from '../../../store/functions'
import { deleteColumn, listPipelines, updateColumn, updateTab, updateTabOrders } from '../../../store/salesManagement/actions'
import { GET_PIPELINE, LIST_PIPELINES } from '../../../store/salesManagement/actionTypes'
import AddPipelineModal from './Components/AddPipeline'
import CrmNav from './Components/Nav'
import DragAndDrop from './DragAndDrop'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Highlighter from "react-highlight-words";

function SalesManagement() {
  const dispatch = useDispatch()
  const [addPipelineModal, setAddPipelineModal] = useState(false);
  const [addTabModal, setAddTabModal] = useState(null)
  const [hovering, setHovering] = useState(null)
  const [selectedTag, setSelectedTag] = useState('')
  const [dropdown, setDropdown] = useState(null)
  const [editMode, setEditMode] = useState(null)
  const [columns, setColumns] = useState([])
  const [nameEdit, setNameEdit] = useState(null)
  const [descEdit, setDescEdit] = useState(null)
  const [searchCustomer, setSearchCustomer] = useState(null)
  const [search, setSearch] = useState(null)
  const [customerDropdown, setCustomerDropdown] = useState(null)

  const [tagOpen, setTagOpen] = useState(false);
  const toggle = () => setTagOpen((prevState) => !prevState);

  const tabNameRef = useRef(null)
  const tabDescriptionRef = useRef(null)

  const toggleAddPipelineModal = () => setAddPipelineModal(!addPipelineModal);

  function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      [ref, handler]
    );
  }

  const updateTabName = (e) => {
    if (nameEdit) dispatch(updateTab(nameEdit, { name: tabNameRef.current.value }, () => setNameEdit(null)))
  }

  const updateTabDesc = (e) => {
    if (descEdit) dispatch(updateTab(descEdit, { description: tabDescriptionRef.current.value }, () => setDescEdit(null)))
  }

  useOnClickOutside(tabNameRef, useCallback(updateTabName))
  useOnClickOutside(tabDescriptionRef, useCallback(updateTabDesc))

  const { pipelines, listPipelinesLoading, pipeline, workspace, tags, tagsLoading, customers } = useSelector(state => ({
    pipelines: state?.sales?.pipelines,
    listPipelinesLoading: checkIfLoading(state, LIST_PIPELINES),
    pipeline: state?.sales?.pipeline,
    workspace: state?.workspace?.workspace,
    tags: state?.crm?.tags?.list,
    tagsLoading: checkIfLoading(state, LIST_TAGS),
    customers: state?.crm?.customers?.list
  }))

  useEffect(() => {
    dispatch(listPipelines())
    dispatch(listTags())
    dispatch(listCustomers())
  }, [workspace])

  useEffect(() => {
    if (pipeline) dispatch(getPipeline(pipeline?._id))
  }, [pipelines])

  useEffect(() => {
    if (search && search !== '') dispatch(getPipeline(pipeline?._id, true, search))
    else if(pipeline) dispatch(getPipeline(pipeline?._id, true))
  }, [search])

  useEffect(() => {
    setColumns(pipeline?.columns)
  }, [pipeline])

  function onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    const firstColumn = columns[sInd]
    const secondColumn = columns[dInd]
    const tab = firstColumn.tabs[source.index]
    const newTabOrder = destination.index + 1

    if (sInd === dInd) {
      const tabs = reorder(firstColumn.tabs, source.index, destination.index);
      firstColumn.tabs = tabs
      columns[sInd] = firstColumn
      setColumns(columns);
      dispatch(updateTab(tab?._id, { order: newTabOrder }, () => dispatch(getPipeline(tab?.pipeline, true))))
    } else {
      const result = move(firstColumn.tabs, secondColumn.tabs, source, destination);
      firstColumn.tabs = result[sInd]
      secondColumn.tabs = result[dInd]
      columns[sInd] = firstColumn
      columns[dInd] = secondColumn

      dispatch(updateTab(tab?._id, { column: secondColumn?._id }))
      dispatch(updateTabOrders(firstColumn.tabs))
      dispatch(updateTabOrders(secondColumn.tabs))
      setTimeout(() => dispatch(getPipeline(tab?.pipeline, true)), 2000)
      setColumns(columns);
    }
  }

  const renderPipelinesSelect = () => {
    if (listPipelinesLoading) return <Spinner size="sm" className="m-auto text-center" color="success" />
    if (pipelines && pipelines?.length > 0) {
      return pipelines?.map(pipe => {
        return <option value={pipe?._id}>{pipe?.name}</option>
      })
    } else {
      return <option>No Pipelines found</option>
    }
  }

  const onAddTab = (e) => {
    e.preventDefault()
    const col = e.target[0].value
    const name = e.target[1].value

    dispatch(addTab(col, { name }, () => setAddTabModal(null)))
  }

  const handleNameChangeSubmit = (e) => {
    e.preventDefault()
    const name = e.target[0].value
    dispatch(updateColumn(editMode?._id, { name }, () => setEditMode(null)))
  }

  const renderCustomers = (tab) => {
    if (customers && customers?.length > 0) {
      return customers?.map(c => {
        return <DropdownItem onClick={() => dispatch(updateCustomer(c?._id, { tab: tab?._id }, null, () => dispatch(getPipeline(tab?.pipeline, true))))}>
          <div className='py-2'>{c?.name}</div>
        </DropdownItem>
      })
    } else {
      return <span>No customer found</span>
    }
  }

  const handleDescriptionEdit = (e, tab) => {
    if (e.charCode === 13 && !e.shiftKey) {
      e.preventDefault();
      dispatch(updateTab(tab?._id, { description: e.target.value }, () => setDescEdit(null)))
    }
  }

  useEffect(() => {
    if (searchCustomer && searchCustomer?.length > 0) {
      dispatch(listCustomers({
        name: searchCustomer
      }))
    }
  }, [searchCustomer])

  const renderColumnsAndTabs = () => {
    // if (selectPipLoading) return <div className='col-md-3'><Spinner size="sm" className="m-auto text-center" color="success" /></div>
    if (pipeline && pipeline?.columns?.length > 0) {
      return <DragDropContext onDragEnd={onDragEnd}>
        {columns?.map((col, ind) => (
          <Droppable key={ind} droppableId={`${ind}`}>
            {(provided, snapshot) => (
              <div
                className='col-md-4'
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <div className='header d-flex justify-content-between align-items-center'>
                  <div className='d-flex align-items-center'>
                    <span className='square mr-2'></span>
                    <div className='d-flex align-items-center'>
                      {
                        editMode?._id === col?._id ?
                          <form onSubmit={handleNameChangeSubmit}>
                            <input className='form-control custom-border' defaultValue={editMode.name} placeholder='enter column name' />
                            <button type='submit' hidden></button>
                          </form>
                          :
                          <>
                            <h6 className='m-0 mr-2'>{col?.name}</h6>
                            <span className='text-muted'>({col?.tabs?.length || 0})</span>
                          </>
                      }
                    </div>
                  </div>
                  <Dropdown className='custom-dropdown' isOpen={dropdown === col?._id} toggle={() => setDropdown(dropdown === col?._id ? false : col?._id)}>
                    <DropdownToggle color='transparent'>
                      <span><i class="fas fa-ellipsis-h"></i></span>
                    </DropdownToggle>
                    <DropdownMenu style={{ width: "150px", overflow: 'hidden' }}>
                      <DropdownItem onClick={() => setEditMode(col)}>Rename</DropdownItem>
                      <DropdownItem onClick={() => dispatch(addColumn(pipeline?._id, { name: col?.name + ' copy' }))}>Duplicate</DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem onClick={() => dispatch(deleteColumn(col?._id))}>Delete</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <ul className='list-unstyle tabs'>
                  {col?.tabs?.map((tab, index) => (
                    <Draggable
                      key={tab?._id}
                      draggableId={tab?._id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <li ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps} onMouseOver={() => setHovering(tab?._id)} onMouseLeave={() => setHovering(null)}>
                          <div className='d-flex justify-content-between'>
                            <div className='d-flex align-items-center'>
                              <div className='logo'>{getFirst2chars(tab?.customer?.name || tab?.name)}</div>
                              <div className='name'>
                                {
                                  nameEdit === tab?._id ? <input className='form-control custom-border' style={{ width: "120px" }} ref={tabNameRef} defaultValue={tab?.name} placeholder="Enter tab name" onKeyPress={(e) => e.charCode === 13 ? dispatch(updateTab(tab?._id, { name: e.target.value }, () => setNameEdit(null))) : null} /> :
                                    <h6 className='cursor-pointer' onClick={() => setNameEdit(tab?._id)}>
                                      <Highlighter
                                        highlightClassName="highlight-text"
                                        searchWords={[new RegExp(search, 'i')]}
                                        textToHighlight={tab?.name}
                                      /></h6>
                                }
                                {
                                  tab?.customer ? <Link to={`/crm/customer/${tab?.customer?._id}`}>{tab?.customer?.name}</Link> :
                                    <>
                                      <input className='form-control custom-border' onChange={(e) => {
                                        if(e.target.value?.length > 0) setCustomerDropdown(tab?._id)
                                        else setCustomerDropdown(null)
                                        setSearchCustomer(e.target.value)
                                      }} placeholder="Search customer" />
                                      {
                                        customerDropdown === tab?._id ? <Dropdown className='custom-dropdown' style={{ width: "auto" }} isOpen={searchCustomer ? true : false} toggle={() => setSearchCustomer(null)}>
                                        <DropdownToggle disabled color='transparent'></DropdownToggle>
                                        <DropdownMenu>
                                          {renderCustomers(tab)}
                                        </DropdownMenu>
                                      </Dropdown> : ""
                                      }
                                    </>
                                  // <select onChange={e => dispatch(updateCustomer(e?.target?.value, { tab: tab?._id }, null, () => dispatch(getPipeline(tab?.pipeline, true))))} className='form-control custom p-0' style={{ width: "60px" }}>
                                  //   <option>Select customer</option>
                                  //   {renderCustomers(tab?._id)}
                                  // </select>

                                }
                              </div>
                            </div>
                            {tab?._id === hovering ? <span><span className='tab-x-btn m-0' onClick={() => dispatch(deleteTab(tab?._id))}>x</span></span> : ""}
                          </div>
                          {/* <div className='mt-2'>
                            <ContentEditable
                              innerRef={descriptionContent}
                              html={tab?.description} // innerHTML of the editable div
                              placeholder="Enter description"
                              disabled={false}       // use true to disable editing
                                // handle innerHTML change
                              tagName='article' // Use a custom HTML tag (uses a div by default)
                            />
                          </div> */}
                          {/* <div className='contact text-muted mt-3'>Contact On <span><b>{getDayName(tab?.createdAt)}</b></span></div> */}
                          {
                            descEdit === tab?._id ? <textarea ref={tabDescriptionRef} className='form-control custom-border mt-3' defaultValue={tab?.description} placeholder="Enter tab description" onKeyPress={(e) => handleDescriptionEdit(e, tab)}></textarea> :
                              <p className='cursor-pointer m-0 mt-3' onClick={() => setDescEdit(tab?._id)}>{tab?.description || 'No Description found'}</p>
                          }
                        </li>
                      )}
                    </Draggable>
                  ))}
                  <button onClick={() => {
                    setAddTabModal(col?._id === addTabModal ? null : col?._id)
                  }} className='btn click-btn btn-outline-secondary btn-block py-1 px-2 mt-2'><i className='fas fa-sm mr-1 fa-plus'></i> New tab</button>
                  {
                    addTabModal === col?._id ? <form onSubmit={onAddTab}>
                      <div className='add-tab p-3'>
                        <h6 className='mb-2'>New tab</h6>
                        <input value={col._id} hidden />
                        <input className='form-control' placeholder='add tab name' />
                        <button type='submit' hidden>submit</button>
                      </div>
                    </form> : ""
                  }
                  {provided.placeholder}
                </ul>
              </div>
            )}
          </Droppable>
        ))}
      </DragDropContext>
    } else {
      return 'No columns found'
    }
  }

  const renderTags = () => {
    if (tagsLoading) return <Spinner size="sm" className="m-auto text-center" color="success" />
    if (tags && tags?.length > 0) {
      return tags?.map(tag => {
        return <DropdownItem onClick={() => setSelectedTag(tag)}>
          <button className="btn">{tag?.name}</button>
        </DropdownItem>
      })
    } else {
      return <option>No tags found</option>
    }
  }

  return (
    <div className='container-fluid sales-management page'>
      <CrmNav />
      <div className='row justify-content-between mt-5'>
        <div className='col-md-3'>
          <select className='form-control custom pipelines-select' value={pipeline?._id} onChange={e => dispatch(getPipeline(e.target.value))}>
            {renderPipelinesSelect()}
          </select>
        </div>
        <div className='col-md-3 text-right'>
          <button className='btn click-btn green-btn py-2' onClick={() => setAddPipelineModal(true)}>Add Pipeline</button>
        </div>
      </div>
      <hr />
      {
        listPipelinesLoading ? <Spinner color='success' size='lg' /> : <>
          <div className='row justify-content-between'>
            <div className='col-md-6'>
              <div className='form-inline'>
                <Dropdown className='custom-dropdown sales-tag-select' isOpen={tagOpen} toggle={toggle}>
                  <DropdownToggle caret>
                    {selectedTag?.name || 'List all tags'}
                  </DropdownToggle>
                  <DropdownMenu left={true}>
                    <DropdownItem onClick={() => setSelectedTag('')}>
                      <button className="btn">List all tags</button>
                    </DropdownItem>
                    {renderTags()}
                  </DropdownMenu>
                </Dropdown>
                <input placeholder='search' onInput={(e) => setSearch(e.target.value)} className='form-control custom ml-3' />
              </div>
            </div>
            <div className='col-md-6 text-right'>
              <button className='btn btn-outline-secondary' onClick={() => dispatch(addColumn(pipeline?._id, { name: "new column" }))}><i class="fas fa-sm fa-plus"></i> New Column</button>
            </div>
          </div>
          <div className='columns d-flex mt-3'>
            {renderColumnsAndTabs()}
          </div>
          <AddPipelineModal modal={addPipelineModal} toggle={toggleAddPipelineModal} />
        </>
      }
    </div>
  )
}

export default SalesManagement
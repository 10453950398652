import { put, select, takeEvery } from "redux-saga/effects"
import { startAction, stopAction, setError } from "../actions"
import { listPlansSuccess, selectPlanSuccess } from "./actions"
import { LIST_PLANS, SELECT_PLAN } from "./actionTypes"
import { ListPlansAPI } from "./calls"

function* listPlans({ type }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield ListPlansAPI(state)
    console.log(result)
    yield put(listPlansSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* selectPlan({ type, payload: { id, history } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const plan = state?.subscription?.plans?.find(p => p._id === id)
    yield put(selectPlanSuccess(plan))
    history('/pay-subscription')
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* subscriptionSaga() {
  yield takeEvery(LIST_PLANS, listPlans)
  yield takeEvery(SELECT_PLAN, selectPlan)
}

export default subscriptionSaga
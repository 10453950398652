import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from "reactstrap"

const TablePagination = ({ count, limit, page, setPage, loading }) => {
    const renderPages = (count, limit) => {
        const pagesCount = Math.ceil(count / limit)
        let renderPages = []
        let pages = []
        for (let i = 1; i <= pagesCount; i++) { pages.push(i) }
        if (pages && pages.length > 0) {
            let slicedPages = []
            if (page < 5) {
                slicedPages = pages.splice(0, 9)
            } else {
                slicedPages = pages.splice(page - 5, 9)
            }
            renderPages.push(
                <PaginationItem disabled={loading || page === 1 ? true : false}>
                    <PaginationLink onClick={() => setPage(1)} tabIndex="-1">
                        <i class="fas fa-angle-double-left"></i>
                    </PaginationLink>
                </PaginationItem>
            )
            renderPages.push(
                <PaginationItem disabled={loading || page === 1 ? true : false}>
                    <PaginationLink onClick={() => setPage(page - 1)} tabIndex="-1">
                        <i class="fas fa-angle-left"></i>
                    </PaginationLink>
                </PaginationItem>
            )
            slicedPages.map((v, i) => {
                renderPages.push(<PaginationItem onClick={() => {
                    page === v ? "" : setPage(v)
                }} active={v === page ? true : false} disabled={loading} >
                    <PaginationLink>{v}</PaginationLink>
                </PaginationItem>)
            })
            renderPages.push(
                <PaginationItem disabled={loading || page === pagesCount ? true : false}>
                    <PaginationLink onClick={() => setPage(page + 1)} tabIndex="-1">
                        <i class="fas fa-angle-right"></i>
                    </PaginationLink>
                </PaginationItem>
            )
            renderPages.push(
                <PaginationItem disabled={loading || page === pagesCount ? true : false}>
                    <PaginationLink onClick={() => setPage(pagesCount)}>
                        <i class="fas fa-angle-double-right"></i>
                    </PaginationLink>
                </PaginationItem>
            )
        }
        return renderPages
    }

    return (
        <Pagination
            aria-label="Page navigation example"
            listClassName="justify-content-center table-pagination"
        >
            {renderPages(count, limit)}
        </Pagination>
    )
}

export default TablePagination
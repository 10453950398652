import React, { useState, useEffect } from 'react'
import Chart from "react-apexcharts";

function DonutChart({ data, labels, position = "right", colors, width = 400, height = 200 }) {
  // ** states 
  const [chartOptions, setChartOptions] = useState(null)

  console.log(data)

  // ** use Effect to set chart option if data exist
  useEffect(() => {
    setChartOptions({
      series: data,
      options: {
        labels: labels,
        colors,
        chart: {
          width: width,
          type: 'donut',
        },
        dataLabels: {
          enabled: false
        },
        responsive: [{
          breakpoint: 400,
          options: {
            chart: {
              width: width
            },
            legend: {
              show: false
            }
          }
        }],
        legend: {
          show: false
        }
        // legend: {
        //   position: position,
        //   offsetY: 0,
        //   width: 300,
        //   fontSize: '16px',
        //   fontWeight: 200,
        //   itemMargin: {
        //     horizontal: 0,
        //     vertical: 5
        //   },
        // }
      }
    })
  }, [])

  return (
    <>
      {chartOptions && <Chart height={height} options={chartOptions.options} series={chartOptions.series} type='donut' />}
    </>
  )
}

export default DonutChart
import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { getters, useGlobalContext } from "../GlobalContext/GlobalContext";
import {
   getMonthListBetweenDates,
   getLinePath,
   getTrianglePoints,
   getMousePos,
   getCompleteDateList,
   dateToString,
} from "../utils/helpers";
import "./TaskDetails.css";
import "../global.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, DesktopDatePicker, LocalizationProvider, MuiPickersAdapterContext } from "@mui/x-date-pickers";
import {
   Autocomplete,
   Button,
   Icon,
   IconButton,
   Input,
   Menu,
   MenuItem,
   Popover,
   TextField,
   withStyles,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { PropaneSharp } from "@mui/icons-material";
import { Link } from "react-router-dom";
import ContentEditable from 'react-contenteditable'
import { useDispatch, useSelector } from "react-redux";
import { addTask, editTask } from "../../../../store/actions";
import { checkIfLoading } from "../../../../store/functions";
import { ADD_TASK, EDIT_TASK } from "../../../../store/projectManagement/actionTypes";
import { Spinner } from "reactstrap";

const styles = () => ({
   paper: {
      width: "100%",
   },
});
const TaskDetails = ({ addTaskBtn }) => {
   const [state, dispatch] = useGlobalContext();
   const dispatchRedux = useDispatch()
   const [data, setData] = useState({
      size: 10,
      loading: false,
      options: getters.getActiveProject(state).users.filter((x) => x.id !== "-1"),
      list: getters.getActiveProject(state).users.filter((x) => x.id !== "-1"),
      fileList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
   });
   const contentEditable = useRef(null)
   const [showTo, setShowTo] = useState(false);
   const [showFrom, setShowFrom] = useState(false);
   const [showDeps, setShowDeps] = useState(false);
   const addDepsRef = useRef(null);
   const subtasksWrapperRef = useRef(null);
   const popupAnchorRef = useRef(null);
   const activeProject = getters.getActiveProject(state);
   const taskRef = useRef(null)
   const dateRef = useRef(null)

   useEffect(() => {
      if(activeProject) {
         setData({
            ...data,
            options: activeProject.users.filter((x) => x.id !== "-1"),
            list: activeProject.users.filter((x) => x.id !== "-1")
         })
      }
   }, [activeProject])

   const { addTaskLoading, updateLoading, user } = useSelector(state => ({
      addTaskLoading: checkIfLoading(state, ADD_TASK),
      updateLoading: checkIfLoading(state, EDIT_TASK),
      user: state.auth.user
   }))
   const onStartDateChange = (date) => {
      dispatch({ updateDateOfSelectedTask: { type: "start", date } });
   };
   const onEndDateChange = (date) => {
      dispatch({ updateDateOfSelectedTask: { type: "end", date } });
   };
   const addLink = (id) => {
      const activeProject = getters.getActiveProject(state);
      const taskList = getTaskList();
      const task = taskList.find((x) => x.id === id);
      if (task?.active) {
         dispatch({ removeLinkFromSelectedTask: id });
      } else {
         dispatch({ addLinkToSelectedTask: id });
      }
   };
   const changeDescription = (e) => {
      dispatch({ addDescriptionToSelectionTask: (e.target).value });
   };
   const toggleSelection = (subtask) => {
      dispatch({ updateSubTask: { id: subtask.id, selected: !subtask.selected } });
   };
   const toggleCompleted = (e, subtask) => {
      e.stopPropagation();
      dispatch({ updateSubTask: { id: subtask.id, completed: !subtask.completed } });
   };
   const onSubtaskDateChange = (subtask, date) => {
      dispatch({ updateSubTask: { id: subtask.id, startDate: date } });
   };
   const changeName = (id, name) => {
      dispatch({ updateTaskText: { id, name } });
   };
   const onSubtaskValueChange = (e, subTask) => {
      const newValue = (e.target).value;
      dispatch({ updateSubTask: { id: subTask.id, name: newValue } });
   };
   const addSubtask = () => {
      dispatch({ addSubtaskToSelectedTask: undefined });
   };
   const assignSubTaskToMe = (subTask) => {
      dispatch({ updateSubTask: { id: subTask.id, userId: user?._id } });
   };
   const hideTaskdetails = () => {
      console.log('hii')
      dispatch({ hideTaskDetails: undefined });
   };
   
   // useOnClickOutside(taskRef, useCallback(hideTaskdetails))
   
   const getActiveProject = () => {
      return getters.getActiveProject(state);
   };
   const getUsers = () => {
      const activeProject = getters.getActiveProject(state);
      return activeProject.users;
   };
   const getTaskList = () => {
      const activeProject = getters.getActiveProject(state);

      const selectedTask = activeProject.tasks.find((x) => x.selected);
      if (!selectedTask) return [];
      const links = activeProject.links
         .filter((x) => x.task1 === selectedTask.id || x.task2 === selectedTask.id)
         .flatMap((x) => [x.task1, x.task2]);
      return activeProject.tasks
         .filter((x) => x.id !== selectedTask.id)
         .map((x) => ({ ...x, active: links.includes(x.id) }));
   };
   const getActiveTask = () => {
      const activeProject = getters.getActiveProject(state);
      return (getters.getActiveProject(state).tasks.find((x) => x.selected)) || { subtasks: [] };
   };
   const getTaskDetails = () => {
      const activeProject = getters.getActiveProject(state);
      const activeTask = activeProject.tasks.find((x) => x.selected);
      if (!activeTask) return {};
      else {
         const user = activeProject.users.find((x) => x.id === activeTask.user?.id);
         return { ...activeTask, user, project: activeProject.id, startDate: new Date(activeTask?.startDate),
            endDate: new Date(activeTask?.endDate) };
      }
   };
   const getTaskDetailsStatus = () => {
      return state.showTaskDetails;
   };
   const activeTask = getActiveTask();
   const taskDetails = getTaskDetails();
   const taskList = getTaskList();

   const onSubmit = () => {
      console.log(taskDetails)
      if (Object.entries(taskDetails).length === 0 || taskDetails?.userId === '-1' || taskDetails?.user?.id === '-1') return
      if (taskDetails?._id) {
         dispatchRedux(editTask(taskDetails, taskDetails?._id, hideTaskdetails))
      } else {
         dispatchRedux(addTask(taskDetails, activeProject?.id, hideTaskdetails))
      }
   }

   // useEffect(() => {
   //    console.log({
   //       task: state.showTaskDetails,
   //       open
   //    })
   //    if(!didMount.current) {
   //       return didMount.current = true
   //    }

   //    if(state?.showTaskDetails !== open) {
   //       setOpen(state?.showTaskDetails)
   //    }
   //    if(open === false && state.showTaskDetails === false) onSubmit()
   // }, [state.showTaskDetails, open])

   const toggleCompletedTask = () => {
      if(taskDetails?._id) {
         dispatchRedux(editTask({ completed: !taskDetails?.completed }, taskDetails?._id, () => dispatch({ toggleCompleteTask: undefined })))
      } else {
         dispatch({ toggleCompleteTask: undefined })
      }
   }

   const getAssignedUser = (assignee, userId) => {
      let foundUser
      if(assignee && !userId) {
         foundUser = activeProject.users?.find(u => String(u._id) === String(assignee))
      } else if (userId) {
         foundUser = activeProject.users?.find(u => String(u._id) === String(userId))
      }
      return foundUser
   }

   return (
      <div ref={taskRef} className={classNames("task-details-wrapper", { opened: state.showTaskDetails })}>
         {state.showTaskDetails && (
            <div className="header" v-if="state.showTaskDetails">
               <div
                  className={classNames("btn", { completed: activeTask.completed })}
                  onClick={() => toggleCompletedTask()}
               >
                  <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M1 5.61538L6.25 11L16 1" stroke="#0F393B" stroke-width="1.5" stroke-linejoin="round" />
                  </svg>

                  {activeTask.completed ? (
                     <span v-if="getActiveTask.completed">Mark it as incomplete</span>
                  ) : (
                     <span v-else>Mark it as complete</span>
                  )}
               </div>
               <div className="icons d-flex align-items-center">
                  <svg
                     onClick={hideTaskdetails}
                     width="15"
                     height="15"
                     viewBox="0 0 21 22"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                     className="mt-2"
                  >
                     <path d="M19.5 2L19.5 20" stroke="#0F393B" stroke-width="2.5" stroke-linecap="round" />
                     <path
                        d="M0 11.5H15M15 11.5L10.5 7M15 11.5L10.5 16"
                        stroke="#0F393B"
                        strokeWidth="2.5"
                        strokeLinejoin="round"
                     />
                  </svg>
               </div>
            </div>
         )}

         {state.showTaskDetails && (
            <div className="body" v-if="state.showTaskDetails">
               <div className="title">New Task</div>
               <div className="details-table">
                  <div className="details-label">Assignee</div>
                  <div className="assignee">
                     <img src={taskDetails?.user?.profilePicture || `${process.env.PUBLIC_URL}/assets/images/avatar.png`} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = `${process.env.PUBLIC_URL}/assets/images/avatar.png`;
                     }} />
                     <div>{taskDetails?.user?.fullName || taskDetails?.user?.email || "Not Assigned"}</div>
                  </div>
                  {/* {taskDetails.user?.id !== "-1" && (
                  )} */}
                  <div className="details-label">Date</div>
                  <div className="date-wrapper">
                     <svg width="14" height="17" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                           d="M6.78571 2.875H13.2143V1.07812C13.2143 0.48291 13.692 0 14.2857 0C14.8795 0 15.3571 0.48291 15.3571 1.07812V2.875H17.1429C18.7188 2.875 20 4.16201 20 5.75V20.125C20 21.7107 18.7188 23 17.1429 23H2.85714C1.27902 23 0 21.7107 0 20.125V5.75C0 4.16201 1.27902 2.875 2.85714 2.875H4.64286V1.07812C4.64286 0.48291 5.12054 0 5.71429 0C6.30804 0 6.78571 0.48291 6.78571 1.07812V2.875ZM2.14286 20.125C2.14286 20.5203 2.4625 20.8438 2.85714 20.8438H17.1429C17.5357 20.8438 17.8571 20.5203 17.8571 20.125V8.625H2.14286V20.125Z"
                           fill="#0F393B"
                        />
                     </svg>
                     <div style={{ display: "flex", alignItems: "center" }}>
                        From
                        <LocalizationProvider ref={dateRef} dateAdapter={AdapterDateFns}>
                           <DesktopDatePicker
                              minDate={new Date().setMonth(new Date().getMonth() - 3)}
                              maxDate={new Date().setMonth(new Date().getMonth() + 3)}
                              open={showFrom}
                              // ref={dateRef}
                              onOpen={() => setShowFrom(true)}
                              onClose={() => setShowFrom(false)}
                              renderInput={(params) => (
                                 <>
                                    <TextField
                                       style={{ padding: 0, height: 0, width: 0, opacity: 0 }}
                                       variant="standard"
                                       {...params}
                                       InputProps={{ endAdornment: null }}
                                       onClick={() => {
                                          setShowFrom(!showFrom);
                                       }}
                                    ></TextField>
                                    <b onClick={() => setShowFrom(!showFrom)}>
                                       &nbsp;{dateToString(taskDetails.startDate || (params.value))}&nbsp;
                                    </b>
                                 </>
                              )}
                              value={taskDetails.startDate}
                              onChange={(date) => onStartDateChange(new Date(date))}
                           ></DesktopDatePicker>
                        </LocalizationProvider>
                        to
                        <LocalizationProvider ref={taskRef} dateAdapter={AdapterDateFns}>
                           <DesktopDatePicker
                              minDate={new Date().setMonth(new Date().getMonth() - 3)}
                              maxDate={new Date().setMonth(new Date().getMonth() + 3)}
                              open={showTo}
                              // ref={taskRef}
                              onOpen={() => setShowTo(true)}
                              onClose={() => setShowTo(false)}
                              renderInput={(params) => (
                                 <>
                                    <TextField
                                       style={{ padding: 0, height: 0, width: 0, opacity: 0 }}
                                       variant="standard"
                                       {...params}
                                       InputProps={{ endAdornment: null }}
                                       onClick={() => {
                                          setShowTo(!showTo);
                                       }}
                                    ></TextField>
                                    <b onClick={() => setShowTo(!showTo)}>
                                       &nbsp;{dateToString(taskDetails.endDate || (params.value))}&nbsp;
                                    </b>
                                 </>
                              )}
                              value={taskDetails.endDate}
                              onChange={(date) => onEndDateChange(new Date(date))}
                           ></DesktopDatePicker>
                        </LocalizationProvider>
                        <button onClick={() => onStartDateChange(new Date(Date.now()))} className="btn btn-sm click-btn orange-btn-outline py-1 px-2 ml-2">Today</button>
                     </div>
                  </div>
                  <div className="details-label">Task name</div>
                  <ContentEditable
                     innerRef={contentEditable}
                     className='custom-border py-1 pl-2'
                     html={taskDetails?.name} // innerHTML of the editable div
                     disabled={false}       // use true to disable editing
                     onChange={(e) => changeName(taskDetails?.id, e.target.value)} // handle innerHTML change
                     tagName='article' // Use a custom HTML tag (uses a div by default)
                  />
                  <div className="details-label">Dependencies</div>

                  <div className="add-deps">
                     <div ref={addDepsRef} className="add-deps-heading" onClick={() => setShowDeps(!showDeps)}>
                        Add Dependendcies
                     </div>
                     <Menu
                        className="deps-menu-wrapper"
                        onClose={() => setShowDeps(false)}
                        open={showDeps}
                        anchorEl={addDepsRef.current}
                     >
                        {taskList.map((task) => (
                           <MenuItem
                              onClick={() => {
                                 addLink(task.id);
                                 setShowDeps(false);
                              }}
                              className={classNames({ active: task.active })}
                           >
                              {task.name}
                           </MenuItem>
                        ))}
                     </Menu>
                  </div>
                  {
                     taskDetails?.customer ? <>
                        <div className="details-label">Customer</div>
                        <div className="add-deps">{taskDetails?.customer?.name}</div>
                     </> : ""
                  }
               </div>
               <div className="desc-label">
                  <b>Description</b>
               </div>
               <input
                  value={activeTask.description || ""}
                  onChange={(e) => changeDescription(e)}
                  className="desc"
                  placeholder="Add more details to this task..."
               />
            </div>
         )}

         <div className="subtasks-wrapper">
            <div className="light-text heading">Subtasks</div>
            <div className="subtasks" ref={subtasksWrapperRef}>
               {activeTask.subtasks.map((subtask) => (
                  <div
                     onClick={() => toggleSelection(subtask)}
                     // v-on:click="toggleSelection(subtask)"
                     v-for="subtask in getActiveTask.subtasks"
                     className={classNames("subtask", { completed: subtask.completed, selected: subtask.selected })}
                  >
                     <div className="left align-items-center">
                        {subtask.completed && (
                           <svg
                              onClick={(e) => toggleCompleted(e, subtask)}
                              width="20"
                              height="20"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <circle cx="12.5" cy="12.5" r="12.5" fill="#33B08E" />
                              <path
                                 d="M7 12L10.5 15.5L17 9"
                                 stroke="#F5F3EE"
                                 stroke-width="1.5"
                                 stroke-linejoin="round"
                              />
                           </svg>
                        )}

                        {!subtask.completed && (
                           <svg
                              onClick={(e) => toggleCompleted(e, subtask)}
                              width="20"
                              height="20"
                              viewBox="0 0 25 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <circle cx="12.5" cy="12.5" r="11.75" stroke="#0F393B" stroke-width="1.5" />
                              <path
                                 d="M7 12L10.5 15.5L17 9"
                                 stroke="#0F393B"
                                 stroke-width="1.5"
                                 stroke-linejoin="round"
                              />
                           </svg>
                        )}

                        <input
                           className="subtask-input custom-border py-1 pl-2"
                           onChange={(e) => onSubtaskValueChange(e, subtask)}
                           value={subtask.name}
                        />
                        <i
                           className="fas fa-times text-danger"
                           onClick={() => dispatch({ deleteSubtask: subtask.id })}
                        // @click.stop="store.commit('deleteSubtask', subtask.id)"
                        ></i>
                        {/* <span className="text-danger"><i className="fas fa-times"></i></span> */}
                     </div>
                     <div className="">
                        <LocalizationProvider ref={dateRef} dateAdapter={AdapterDateFns}>
                           <DesktopDatePicker
                              minDate={new Date().setMonth(new Date().getMonth() - 3)}
                              maxDate={new Date().setMonth(new Date().getMonth() + 3)}
                              open={Boolean(subtask.showDatePicker)}
                              onOpen={() => dispatch({ updateSubTask: { id: subtask.id, showDatePicker: true } })}
                              onClose={() => dispatch({ updateSubTask: { id: subtask.id, showDatePicker: false } })}
                              renderInput={(params) => (
                                 <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    <TextField
                                       style={{ padding: 0, height: 0, width: 0, opacity: 0 }}
                                       variant="standard"
                                       {...params}
                                       onClick={() => {
                                          dispatch({
                                             updateSubTask: { id: subtask.id, showDatePicker: !subtask.showDatePicker },
                                          });
                                       }}
                                    ></TextField>
                                    <b
                                       onClick={() =>
                                          dispatch({
                                             updateSubTask: { id: subtask.id, showDatePicker: !subtask.showDatePicker },
                                          })
                                       }
                                    >
                                       &nbsp;
                                       {subtask.startDate ? dateToString(new Date(subtask.startDate)) : ""}
                                       &nbsp;
                                    </b>
                                    <IconButton
                                       style={{ padding: 0,lineHeight: "10px" }}
                                       onClick={() =>
                                          dispatch({
                                             updateSubTask: { id: subtask.id, showDatePicker: !subtask.showDatePicker },
                                          })
                                       }
                                    >
                                       <Icon>
                                          <CalendarTodayIcon></CalendarTodayIcon>
                                       </Icon>
                                    </IconButton>
                                 </div>
                              )}
                              value={subtask.startDate}
                              onChange={(date) => onSubtaskDateChange(subtask, new Date(date))}
                           ></DesktopDatePicker>
                        </LocalizationProvider>
                        {/* <IconButton
                           ref={subtask.userIconEl}
                           onClick={(e) => {
                              if (!subtask.showPopup) {
                                 popupAnchorRef.current = e.target;
                              }
                              dispatch({ updateSubTask: { id: subtask.id, showPopup: !subtask.showPopup } });
                           }}
                        >
                           <PersonOutlineIcon></PersonOutlineIcon>
                        </IconButton>
                        <Popover
                           ref={assignRef}
                           open={Boolean(subtask.showPopup)}
                           onClose={() => dispatch({ updateSubTask: { id: subtask.id, showPopup: false } })}
                           className="subtask-popup"
                           anchorEl={popupAnchorRef.current}
                           PaperProps={{
                              className: "subtask-popup subtask-popper",
                              style: { borderRadius: "10px", width: 600 },
                           }}
                           anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                           }}
                        >
                           <Autocomplete
                              sx={{ width: "100%", border: "none", outline: "none" }}
                              fullWidth
                              classes={{ popper: "test" }}
                              popupIcon={<></>}
                              renderInput={(params) => (
                                 <TextField
                                    {...params}
                                    placeholder="choose user"
                                    inputProps={{ ...params.inputProps }}
                                    style={{ border: "none", outline: "none" }}
                                 ></TextField>
                              )}
                              value={getAssignedUser(subtask?.user?._id, subtask?.userId)}
                              // inputValue={state.allUsers.find((x) => x.id === subtask.userId)?.name || ""}
                              onChange={(_, option) => {
                                 dispatch({ updateSubTask: { id: subtask.id, userId: option?.id } });
                              }}
                              options={data.options}
                              getOptionLabel={(option) => option.fullName || option.email}
                              renderOption={(params, option) => (
                                 <li {...params} ref={listRef} className="subtask-select-item user-select-item">
                                    <img src={option?.profilePicture || `${process.env.PUBLIC_URL}/assets/images/avatar.png`} onError={({ currentTarget }) => {
                                       currentTarget.onerror = null; // prevents looping
                                       currentTarget.src = `${process.env.PUBLIC_URL}/assets/images/avatar.png`;
                                    }}></img>
                                    <div>{option.fullName || option.email}</div>
                                    <div className="email">{option.email}</div>
                                 </li>
                              )}
                           ></Autocomplete>
                           or
                           <div
                              style={{ whiteSpace: "nowrap", marginRight: "0.4rem" }}
                              className="btn"
                              onClick={() => {
                                 assignSubTaskToMe(subtask);
                                 // dispatch({ updateSubTask: { id: subtask.id, showPopup: false } });
                              }}
                           >
                              Assign to Me
                           </div>
                        </Popover> */}
                     </div>
                  </div>
               ))}
            </div>
            <div className="btn" onClick={addSubtask}>
               + Add subtask
            </div>
            <hr />
            <div className="text-right mt-2">
               <button className="btn click-btn green-btn" ref={addTaskBtn} disabled={updateLoading || addTaskLoading} onClick={onSubmit}>
                  {
                     addTaskLoading ? <Spinner color="light" size='sm' /> : taskDetails?._id ? "Update Task" : "Add Task"
                  }
               </button>
            </div>
         </div>
      </div>
   );
};

export default TaskDetails;
import React, { useState, useEffect } from 'react'
import Chart from "react-apexcharts";

function BarChart({ name }) {
  // ** states 
  const [chartOptions, setChartOptions] = useState(null)

  // ** use Effect to set chart option if data exist
  useEffect(() => {
    setChartOptions({
      series: [{
        name: name,
        data: [2, 4.2, 3, 4, 2.5, 1.5, 2, 1, 3.3, 5.1]
      }],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: ['-48 h', '', '', '', '', '', '', '', '', 'Now'],
          labels: {
            rotate: 0
          }
        },
        yaxis: {
          show: false
        },
        colors: ['#EF8451'],
      },
    })
  }, [])

  return (
    <>
      {chartOptions && <Chart height={130} options={chartOptions.options} series={chartOptions.series} type="bar" />}
    </>
  )
}

export default BarChart
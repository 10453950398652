import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom'
import { LOGIN_USER } from "../../store/auth/actionTypes";
import { checkIfLoading, useQuery } from "../../store/functions";
import { loginUser, verifyUser } from "../../store/actions";
import { Spinner } from "reactstrap";
import GoogleAuth from "./GoogleAuth";
import { useTranslation } from "react-i18next";

const Login = () => {
  const history = useNavigate()
  const dispatch = useDispatch()
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const query = useQuery()
  const [t] = useTranslation()

  const { loading, token } = useSelector(state => ({
    loading: checkIfLoading(state, LOGIN_USER),
    token: state?.auth?.token
  }))

  useEffect(() => {
    if (token) history('/')
    const email = query.get('email')
    const code = query.get('verifyCode')
    if (email && code) {
      dispatch(verifyUser({ code, email }, history))
    }
  }, [query])

  const submitLogin = () => {
    console.log({ email, password })
    dispatch(loginUser({ email, password }, history))
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <form className="auth-form">
          <h4>{t('Login')}</h4>
          <GoogleAuth title="Log In With Google" />
          <div className="ordivider">Or</div>
          <div className="form-group">
            <label>{t('email')}</label>
            <input onChange={(e) => setEmail(e.target.value)} type="email" className="form-control" name="email" />
          </div>
          <div className="form-group">
            <label>{t('password')}</label>
            <input type="password" onChange={(e) => setPassword(e.target.value)} className="form-control" name="password" />
            <Link to="/forget-password" className="btn forget-password">Forgot your password?</Link>
          </div>
          <button type="button" className="btn click-btn green-btn btn-block" onClick={submitLogin}>
            {
              loading ? (
                <Spinner
                  size="sm"
                  className="m-auto text-center"
                  color="light"
                />
              ) : t('Login')
            }
          </button>
          <p className="another-choice">{t('Don’t have an account yet?')} <Link to="/signup" className="btn">{t('Sign up')}</Link></p>
        </form>
      </div>
    </div>
  );
}

export default Login;

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { listSearchConsoleAccounts, selectWorkspaceSearchConsole } from '../../../../store/actions';
import { LIST_SEARCH_CONSOLE_ACCOUNTS } from '../../../../store/auth/actionTypes';
import { checkIfLoading } from '../../../../store/functions';
import { SELECT_WORKSPACE_SEARCH_CONSOLE } from '../../../../store/workspace/actionTypes';

function ChooseSearchModal({ modal, toggle }) {
    const dispatch = useDispatch()
    const history = useNavigate()
    const [account, setAccount] = useState(null)
    const [t] = useTranslation()

    const { searchAccounts, searchLoading, chooseLoading } = useSelector(state => ({
        searchAccounts: state.auth.searchAccounts,
        searchLoading: checkIfLoading(state, LIST_SEARCH_CONSOLE_ACCOUNTS),
        chooseLoading: checkIfLoading(state, SELECT_WORKSPACE_SEARCH_CONSOLE)
    }))

    useEffect(() => {
        if(modal) dispatch(listSearchConsoleAccounts())
    }, [modal])

    const renderSearchAccounts = () => {
        if (searchAccounts && searchAccounts?.length) {
            return searchAccounts?.map(a => {
                return <a className={`result btn text-left d-block ${account === a.siteUrl ? 'active' : ""}`} onClick={() => setAccount(a.siteUrl)}>
                    {a.siteUrl}
                </a>
            })
        } else {
            return 'No accounts found'
        }
    }

    const onAccountSubmit = () => {
        dispatch(selectWorkspaceSearchConsole(account, () => {
            toggle()
        }))
    }

    return (
        <Modal className='search-result-modal analytics-modal' isOpen={modal} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle}>
                <h4>{t('Choose Search Console Account')}</h4>
            </ModalHeader>
            <ModalBody>
                {
                    searchLoading ? <div className='text-center my-3'><Spinner size="lg" className="m-auto text-center" color="success" /></div> : <>
                        <div className='results'>
                            {renderSearchAccounts()}
                        </div>
                    </>
                }
                <div className='mt-2'>
                    <button onClick={onAccountSubmit} className='btn btn-block green-btn click-btn'>
                        {
                            chooseLoading ? <Spinner size="sm" className="m-auto text-center" color="light" /> : t('Submit')
                        }
                    </button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ChooseSearchModal
// ** import pages
import React from 'react'
import Dashboard from "../../../pages/admin/dashboard/index";
import TechnicalAnalysis from "../../../pages/admin/technicalAnalysis/index";
import ContentAnalysis from "../../../pages/admin/contentAnalysis/index";
import CopiedContent from '../../../pages/admin/contentAnalysis/copiedContent/index';
import ContentGenerator from "../../../pages/admin/contentGenerator/index";
import LocalSeo from '../../../pages/admin/localSeo/index'
import WebReputation from "../../../pages/admin/WebReputation";
import ProjectManagement from "../../../pages/admin/ProjectManagement";
import Crm from "../../../pages/admin/Crm/Dashboard";
import BlogPost from "../../../pages/admin/contentGenerator/blog-post/index"
import Customers from '../../../pages/admin/Crm/Customers';
import SalesManagement from '../../../pages/admin/Crm/SalesManagement';
import DetailedReport from '../../../pages/admin/localSeo/detailedReport/index'
import BacklinksPage from '../../../pages/admin/WebReputation/backlinks';
import CompetitorsBacklinks from '../../../pages/admin/WebReputation/competitorsBacklinks';
import CompetitorsList from '../../../pages/admin/WebReputation/competitorsList';
import Feedback from '../../../pages/admin/WebReputation/Feedback';
import Reminders from '../../../pages/admin/Crm/Reminders';
import Settings from '../../../pages/admin/settings';
import ChangePlan from '../../../pages/admin/settings/components/changePlan';
import CustomerProfile from '../../../pages/admin/Crm/CustomerProfile';
import Campaigns from '../../../pages/admin/WebReputation/campaigns';
import MarketingAutomation from '../../../pages/admin/MarketingAutomation';
import CompetitorAnalysis from '../../../pages/admin/competitorAnalysis';
import BacklinksGap from '../../../pages/admin/WebReputation/backlinks-gap';
import CampaignDetails from '../../../pages/admin/WebReputation/campaignDetails';
import KeywordsGap from '../../../pages/admin/contentAnalysis/copiedContent/keyword-gap';

const dashboardRoutes = [
    // ** dashboard route
    {
        path: "/",
        component: <Dashboard />,
        layout: "dashboard"
    },
    {
        path: "/settings",
        component: <Settings />,
        layout: "dashboard"
    },
    {
        path: "/settings/change-plan",
        component: <ChangePlan />,
        layout: "dashboard"
    },
    // ** technical analysis route
    {
        path: "/technical-analysis",
        component: <TechnicalAnalysis />,
        layout: "dashboard"
    },
    // ** content analysis route
    {
        path: "/content-analysis",
        component: <ContentAnalysis />,
        layout: "dashboard"
    },
    {
        path: "/content-analysis/copied-content",
        component: <CopiedContent />,
        layout: "dashboard"
    },
    // ** content generator route
    {
        path: "/content-generator",
        component: <ContentGenerator />,
        layout: "dashboard"
    },
    {
        path: "/content-generator/generate",
        component: <BlogPost />,
        layout: "dashboard"
    },
    // ** local seo route
    {
        path: "/local-seo",
        component: <LocalSeo />,
        layout: "dashboard"
    },
    {
        path: "/local-seo/detailed-report",
        component: <DetailedReport />,
        layout: "dashboard"
    },
    // ** web reputation route
    {
        path: "/web-reputation",
        component: <WebReputation />,
        layout: "dashboard"
    },
    {
        path: "/web-reputation/backlinks",
        component: <BacklinksPage />,
        layout: "dashboard"
    },
    {
        path: "/web-reputation/competitor-backlinks",
        component: <CompetitorsBacklinks />,
        layout: "dashboard"
    },
    {
        path: "/web-reputation/competitors",
        component: <CompetitorsList />,
        layout: "dashboard"
    },
    {
        path: "/web-reputation/feedback",
        component: <Feedback />,
        layout: "dashboard"
    },
    {
        path: "/web-reputation/campaigns",
        component: <Campaigns />,
        layout: "dashboard"
    },
    {
        path: "/web-reputation/campaigns/:id",
        component: <CampaignDetails />,
        layout: "dashboard"
    },
    {
        path: "/web-reputation/backlinks-gap",
        component: <BacklinksGap />,
        layout: "dashboard"
    },
    {
        path: "/web-reputation/keywords-gap",
        component: <KeywordsGap />,
        layout: "dashboard"
    },
    // ** project management route
    {
        path: "/project-management",
        component: <ProjectManagement />,
        layout: "dashboard"
    },
    // ** crm route
    {
        path: "/crm/dashboard",
        component: <Crm />,
        layout: "dashboard"
    },
    {
        path: "/crm/current-customers",
        component: <Customers />,
        layout: "dashboard"
    },
    {
        path: "/crm/sales-management",
        component: <SalesManagement />,
        layout: "dashboard"
    },
    {
        path: "/crm/reminders",
        component: <Reminders />,
        layout: "dashboard"
    },
    {
        path: "/crm/customer/:id",
        component: <CustomerProfile />,
        layout: "dashboard"
    },
    // ** Marketing Automation
    {
        path: "/marketing-automation",
        component: <MarketingAutomation />,
        layout: "dashboard"
    },
    // ** Competitor Analysis
    {
        path: "/competitor-analysis",
        component: <CompetitorAnalysis />,
        layout: "dashboard"
    },
]

export default dashboardRoutes
import {
  GET_TECHNICAL_ANALYSIS,
  GET_TECHNICAL_ANALYSIS_SUCCESS,
  UPDATE_TECHNICAL_ANALYSIS,
  UPDATE_TECHNICAL_ANALYSIS_SUCCESS,
  GET_TECHNICAL_ANALYSIS_ERROR
} from "./actionTypes"

const initialState = {
  data: null,
  message: null
}

const technicalAnalysis = (state = initialState, action) => {
  switch (action.type) {
    case GET_TECHNICAL_ANALYSIS:
      return { ...state, message: null, data: null }
    case GET_TECHNICAL_ANALYSIS_SUCCESS:
      return { ...state, data: action.payload }
    case GET_TECHNICAL_ANALYSIS_ERROR:
      return { ...state, message: action.payload }
    default:
      return state
  }
}

export default technicalAnalysis
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import { generateIdea } from '../../../../store/contentGenerator/actions'
import { GENERATE_IDEA } from '../../../../store/contentGenerator/actionTypes'
import { contentGeneratorTabs } from '../../../../store/data'
import { checkIfLoading, useQuery } from '../../../../store/functions'
import ContentInputs from './contentInputs'

const index = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState({})
  const [tab, setTab] = useState(null)
  const [model, setModel] = useState(null)
  const history = useNavigate()
  const query = useQuery()
  const languages = [
    'English (US)',
    'English (UK)',
    'French',
    'Spanish',
    'German',
    "Italian",
    "Dutch",
    'Portuguese',
    'Portuguese (BR)',
    "Swedish",
    "Norwegian",
    "Danish",
    "Finnish",
    "Romanian",
    "Czech",
    "Slovak",
    "Slovenian",
    "Hungarian",
    "Polish",
    "Turkish",
    "Russian",
    "Hindi",
    "Thai",
    "Japanese",
    "Chinese (Simplified)",
    "Korean",
    "Indonesian"
  ]

  const onDataUpdate = (value, type) => {
    setData({
      ...data,
      [type]: value
    })
  }

  useEffect(() => {
    const model = query.get('model')
    setModel(model)
    const tab = contentGeneratorTabs.find(t => t.model === model)
    if (!tab) history('/content-generator')
    setTab(tab)
  }, [query])

  const { ideas, loading } = useSelector(state => ({
    ideas: state.contentGenerator.ideas,
    loading: checkIfLoading(state, GENERATE_IDEA)
  }))

  const submitGenerateIdea = () => {
    dispatch(generateIdea({ model, ...data }))
  }

  const handleModelName = () => {
    let newModel
    if (String(model).includes('-')) {
      newModel = model.split('-').map(m => String(m[0]).toUpperCase() + String(m).substring(1)).join(' ')
    }
    return newModel
  }

  const renderIdeas = () => {
    if (loading) return <Spinner size="xl" className="m-auto text-center" color="success" />
    if (ideas && ideas.length > 0) {
      return ideas.map(idea => (
        <li>{idea}</li>
      ))
    } else {
      return <li>No Ideas found</li>
    }
  }

  return (
    <div className='blog-post container'>
      <div className='row'>
        <div className='col-xl-5'>
          <div className='card form'>
            <div className='d-flex align-items-center'>
              <img src={`${process.env.PUBLIC_URL}/assets/images/icons/Group 459.png`} alt="" />
              <h5 className='m-0 ml-2'>{handleModelName()}</h5>
            </div>
            <p className='description'>Lorem ipsum dolor sit amet, consectetur adipiscing elit..</p>
            <form>
              <div className='row'>
                {tab ? <ContentInputs inputs={tab?.params} languages={languages} onDataUpdate={onDataUpdate} /> : ""}
              </div>
            </form>
            <button type='button' disabled={loading} className='btn click-btn orange-btn' onClick={submitGenerateIdea}>
              {
                loading ? <Spinner size="sm" className="m-auto text-center" color="light" /> : "Create New Texts"
              }
            </button>
          </div>
          <p className='increasing-quality'>Getting low quality results? Increase the creativity or write a better description.</p>
        </div>
        <div className='col-xl-7'>
          <div className='card favourites'>
            {/* <div className='header justify-content-end'>
              <div>
                <button type='button' className='btn select-all-btn'>Select All</button>
              </div>
            </div> */}
            <div className='body'>
              {
                loading || ideas && ideas?.length > 0 ? <ul className='list-unstyled text-left content-generator-list'>
                  {renderIdeas()}
                </ul> : <>
                  <p>Generate new ideas by adding info on the left side. Press “Generate” to generate copy.</p>
                  <img src={`${process.env.PUBLIC_URL}/assets/images/icons/Group 459.png`} alt="" />
                  <h4>Blog Paragraphs</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel quis odio placerat nibh purus luctus. Sagittis id risus amet odio nunc tincidunt dui. At arcu et, dui, quam gravida lacinia imperdiet.</p>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default index
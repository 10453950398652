import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { setError, updateEmail } from '../../../../store/actions';
import { UPDATE_EMAIL } from '../../../../store/auth/actionTypes';
import { checkIfLoading } from '../../../../store/functions';

function UpdateEmailModal({ modal, toggle }) {
    // ** states
    const [email, setEmail] = useState(null)
    const dispatch = useDispatch()
    const history = useNavigate()
    const [t] = useTranslation()

    const { changeLoading } = useSelector(state => ({
        changeLoading: checkIfLoading(state, UPDATE_EMAIL)
    }))

    const onSubmit = () => {
        if (!email) dispatch(setError({ en: "Please provide email" }))
        dispatch(updateEmail({ email }, toggle, history))
    }

    return (
        <>
            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader className='px-4' toggle={toggle}></ModalHeader>
                <ModalBody className='pt-1 pb-3'>
                    <h4 className='mb-3 text-center'>{t('change-your-email')}</h4>
                    <div style={{ width: "80%" }} className="m-auto">
                        <div className='mb-3'>
                            <label>{t('email')}</label>
                            <input type='text' onChange={(e) => setEmail(e.target.value)} className='form-control custom-border' placeholder={t('enter-your-new-email')} />
                        </div>
                        <button type='submit' className='btn click-btn green-btn btn-block mt-2' onClick={onSubmit}>
                            {
                                changeLoading ? <Spinner size="sm" className="m-auto text-center" color="light" /> : t('update-email')
                            }
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default UpdateEmailModal
import {
  ADD_CUSTOMER,
  ADD_CUSTOMER_SUCCESS,
  GET_ANALYTICS,
  GET_ANALYTICS_SUCCESS,
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  LIST_CUSTOMERS,
  LIST_CUSTOMERS_SUCCESS,
  LIST_TAGS,
  LIST_TAGS_SUCCESS,
  LIST_TOP_5_TAGS,
  LIST_TOP_5_TAGS_SUCCESS,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  LIST_EMAIL_TEMPLATES,
  LIST_EMAIL_TEMPLATES_SUCCESS,
  ADD_EMAIL_TEMPLATE,
  ADD_EMAIL_TEMPLATE_SUCCESS,
  GET_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATE_SUCCESS,
  UPDATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
  DELETE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE_SUCCESS,
  ADD_CUSTOMER_TASK,
  ADD_CUSTOMER_TASK_SUCCESS,
  ADD_CUSTOMER_COMMENT,
  ADD_CUSTOMER_COMMENT_SUCCESS,
  REMOVE_CUSTOMER_COMMENT,
  REMOVE_CUSTOMER_COMMENT_SUCCESS,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  GET_EMAILS,
  GET_EMAILS_SUCCESS,
  ADD_TAG,
  ADD_TAG_SUCCESS,
  ADD_MEDIA,
  ADD_MEDIA_SUCCESS,
  LIST_MEDIA,
  LIST_MEDIA_SUCCESS,
  REMOVE_MEDIA,
  REMOVE_MEDIA_SUCCESS
} from "./actionTypes"

const initialState = {
  analytics: {},
  customers: {},
  tags: [],
  top5tags: [],
  customer: null,
  templates: [],
  timeline: [],
  template: null,
  emails: null,
  media: []
}

const crm = (state = initialState, action) => {
  switch (action.type) {
    case GET_ANALYTICS:
      return { ...state, }
    case GET_ANALYTICS_SUCCESS:
      return { ...state, analytics: action.payload }
    case LIST_CUSTOMERS:
      return { ...state, }
    case LIST_CUSTOMERS_SUCCESS:
      return { ...state, customers: action.payload }
    case GET_CUSTOMER:
      return { ...state, customer: null, timeline: null }
    case GET_CUSTOMER_SUCCESS:
      return { ...state, customer: action.payload.customer, timeline: action.payload.timeline }
    case GET_EMAILS:
      return { ...state }
    case GET_EMAILS_SUCCESS:
      return { ...state, emails: { data: action.payload?.data, count: action.payload?.count } }
    case UPDATE_CUSTOMER:
      return { ...state, }
    case UPDATE_CUSTOMER_SUCCESS:
      return { ...state, customer: action.payload.customer, timeline: action.payload.timeline }
    case DELETE_CUSTOMER:
      return { ...state, }
    case DELETE_CUSTOMER_SUCCESS:
      return { ...state, customer: null }
    case ADD_CUSTOMER:
      return { ...state, }
    case ADD_CUSTOMER_SUCCESS:
      return {
        ...state, customers: {
          ...state.customers,
          list: [action.payload, ...state.customers.list],
          page_details: {
            ...state.customers.page_details,
            total_items: state.customers.page_details.total_items + 1
          }
        }
      }
    case LIST_TAGS:
      return { ...state, }
    case LIST_TAGS_SUCCESS:
      return { ...state, tags: action.payload }
    case ADD_TAG:
      return { ...state, }
    case ADD_TAG_SUCCESS:
      return { ...state, tags: { ...state.tags, list: [...state?.tags?.list, action.payload] } }
    case ADD_CUSTOMER_TASK:
      return { ...state, }
    case ADD_CUSTOMER_TASK_SUCCESS:
      return { ...state, timeline: [action.payload, ...state.timeline] }
    case ADD_CUSTOMER_COMMENT:
      return { ...state, }
    case ADD_CUSTOMER_COMMENT_SUCCESS:
      return { ...state, timeline: [action.payload, ...state.timeline] }
    case REMOVE_CUSTOMER_COMMENT:
      return { ...state, }
    case REMOVE_CUSTOMER_COMMENT_SUCCESS:
      return { ...state, timeline: state.timeline?.filter(t => t?._id !== action.payload) }
    case LIST_EMAIL_TEMPLATES:
      return { ...state, templates: [] }
    case LIST_EMAIL_TEMPLATES_SUCCESS:
      return { ...state, templates: action.payload }
    case ADD_EMAIL_TEMPLATE:
      return { ...state, }
    case ADD_EMAIL_TEMPLATE_SUCCESS:
      return { ...state }
    case UPDATE_EMAIL_TEMPLATE:
      return { ...state, }
    case UPDATE_EMAIL_TEMPLATE_SUCCESS:
      return { ...state }
    case DELETE_EMAIL_TEMPLATE:
      return { ...state, }
    case DELETE_EMAIL_TEMPLATE_SUCCESS:
      return { ...state }
    case GET_EMAIL_TEMPLATE:
      return { ...state, }
    case GET_EMAIL_TEMPLATE_SUCCESS:
      return { ...state, template: action.payload }
    case LIST_TOP_5_TAGS:
      return { ...state, }
    case LIST_TOP_5_TAGS_SUCCESS:
      return { ...state, top5tags: action.payload }
    case ADD_MEDIA:
      return { ...state, }
    case ADD_MEDIA_SUCCESS:
      return { ...state, media: [...state.media, action.payload] }
    case LIST_MEDIA:
      return { ...state, media: [] }
    case LIST_MEDIA_SUCCESS:
      return { ...state, media: action.payload }
    case REMOVE_MEDIA:
      return { ...state, }
    case REMOVE_MEDIA_SUCCESS:
      return { ...state, media: state.media?.filter(m => m._id !== action.payload) }
    default:
      return state
  }
}

export default crm
import React from 'react'
import { Context, createContext, useContext, useReducer } from "react";

function buildReducer({
   functions,
}) {
   return (state, action) => {
      let tempState = { ...state };
      Object.keys(action).forEach((key) => {
         let inferredKey = key;
         const func = functions[inferredKey];
         tempState = func(tempState, action[inferredKey]);
      });
      return tempState;
   };
}

/*################################################################################################################################
                                                         Main function
##################################################################################################################################
*/
export function createCustomContext({
   initialState,
   functions,
}) {
   const reducer = buildReducer({ functions });

   const initialContext = [initialState, (() => { })];
   const Context = createContext(initialContext);

   //Create the wrapper function which provied that context
   const Provider = ({ children }) => {
      const [state, dispatch] = useReducer(reducer, initialState);
      return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
   };

   return {
      Context,
      Provider,
      useContextHook: () => useContext(Context),
   };
}

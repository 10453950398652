import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PrivateRoute({ children }) {
    const { token, workspace } = useSelector(state => ({
        token: state.auth.token,
        workspace: state.workspace?.workspace?._id
    }));

    if (!token) {
        return <Navigate to="/login" />
    }

    if (!workspace) {
        return <Navigate to={'/select-workspace'} />
    }

    return children;
}

export default PrivateRoute
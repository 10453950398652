import React, { useEffect, useState } from 'react'
import { Col, FormControl, InputGroup, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import CustomTooltip from '../../../components/common/CustomTooltip'
import { addCompetitor, competitorsBacklinks, listCompetitors, removeCompetitor } from '../../../store/actions'
import { checkIfLoading } from '../../../store/functions'
import { ADD_COMPETITOR, LIST_COMPETITORS } from '../../../store/webreputation/actionTypes'
import WebReputationNav from './components/Nav'

function CompetitorsList() {
    const dispatch = useDispatch()
    const [compUrl, setCompUrl] = useState(null)
    const [t] = useTranslation()
    const [addCompBox, setAddCompBox] = useState(false)

    const { competitors, compLoading, addCompLoading } = useSelector(state => ({
        competitors: state?.webreputation?.competitors?.list,
        compLoading: checkIfLoading(state, LIST_COMPETITORS),
        addCompLoading: checkIfLoading(state, ADD_COMPETITOR)
    }))

    useEffect(() => {
        dispatch(listCompetitors())
    }, [])

    const renderCompetitors = () => {
        if (compLoading) return <Spinner size="sm" className="m-auto text-center" color="success" />
        if (competitors?.length > 0) {
            return competitors?.map(comp => {
                return <li>
                    <span>{comp?.url}</span>
                    <button onClick={() => dispatch(removeCompetitor(comp?._id))} className='btn btn-link m-0 p-0 green float-right'>Remove</button>
                </li>
            })
        } else {
            return <div>No competitor found</div>
        }
    }

    const submitAddCompetitor = () => {
        if (!compUrl) return
        dispatch(addCompetitor(compUrl, () => {
            setAddCompBox(false)
            dispatch(competitorsBacklinks())
        }))
    }

    return (
        <div className='container-fluid web-reputation page'>
            <WebReputationNav />
            <div className='mt-5'>
                <h5 className='m-0'>{t('list-of-your-competitors')} <CustomTooltip header="This is tooltip for" item='competitors_list' /></h5>
                <div className='d-flex align-items-center justify-content-between mb-2'>
                    <span className=''>{competitors?.length} {t('competitors-of-your-domain')}</span>
                    <button className='btn click-btn green-btn py-2 px-4' onClick={() => setAddCompBox(!addCompBox)}>
                        {t('add-new-competitor')}
                    </button>
                </div>
                <div className='d-flex justify-content-end'>
                    <div className='add-competitor mb-2' style={{ display: addCompBox ? 'block' : 'none', width: "400px" }}>
                        <div className='row align-items-end'>
                            <div className='col-md-8'>
                                <input className='form-control' onChange={(e) => setCompUrl(e.target.value)} placeholder={t('enter-competitor-url')} />
                            </div>
                            <div className='col-md-4'>
                                <button className='btn click-btn orange-btn btn-block py-1 px-1' onClick={submitAddCompetitor}>
                                    {
                                        addCompLoading ? <Spinner size="sm" className="m-auto text-center" color="light" /> : t('add-competitor')
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row justify-content-between align-items-end'>
                    <div className='col-md-4'>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1" className='bg-transparent'><i class="fas fa-search"></i></InputGroup.Text>
                            <FormControl
                                placeholder={t('search')}
                                aria-label={t('search')}
                                aria-describedby="basic-addon1"
                            />
                        </InputGroup>
                    </div>
                    <div className='col-md-6 text-right'>
                        <span><i class="fas fa-sm mr-2 text-muted fa-sync"></i> {t('update')}</span>
                    </div>
                </div>
                <ul className='list-unstyled competitors-list mt-3'>
                    {renderCompetitors()}
                </ul>
            </div>
        </div>
    )
}

export default CompetitorsList
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { inviteCollaborator, updateUserData } from '../../../../store/actions';
import { UPDATE_USER_DATA } from '../../../../store/auth/actionTypes';
import { checkIfLoading } from '../../../../store/functions';
import { INVITE_COLLABORATOR } from '../../../../store/workspace/actionTypes';

function InviteModal({ modal, toggle }) {
    const [email, setEmail] = useState(null)
    const dispatch = useDispatch()
    const [t] = useTranslation()

    const { inviteLoading } = useSelector(state => ({
        inviteLoading: checkIfLoading(state, INVITE_COLLABORATOR)
    }))

    const onSubmit = () => {
        dispatch(inviteCollaborator({ email }, toggle))
    }

    return (
        <>
            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader className='px-4' toggle={toggle}></ModalHeader>
                <ModalBody className='pt-1 pb-3'>
                    <h3 className='mb-3 text-center'>{t('invite-collaborator')}</h3>
                    <div style={{ width: "80%" }} className="m-auto">
                        <div className='mb-3'>
                            <label>{t('email')}</label>
                            <input type='text' value={email} onChange={(e) => setEmail(e.target.value)} className='form-control custom-border' placeholder={t('enter-email')} />
                        </div>
                        <button type='submit' className='btn click-btn green-btn btn-block mt-2' onClick={onSubmit}>
                            {
                                inviteLoading ? <Spinner size="sm" className="m-auto text-center" color="light" /> : t('send-invitation')
                            }
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default InviteModal
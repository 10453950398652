import React from 'react';
import Select from 'react-select';

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderRadius: "10px",
        border: "2px solid rgba(15, 57, 59, .34901960784313724)",
        color: "#0f393b",
        backgroundColor: 'transparent'
    }),
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: "#EEEBE3"
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? "#0f393b" : state.isSelected ? "#0f393b" : "inherited",
        color: state.isFocused ? "#EEEBE3" : state.isSelected ? "#EEEBE3" : "inherited",
        "&:hover": {
            backgroundColor: "#347362",
            color: "#EEEBE3"
        },
    }),
}

const CustomSelect = ({ classNames, options, onChange, value, isMulti = false, onInputChange, noOptionsMessage }) => {
    return (
        <Select styles={customStyles} isMulti={isMulti} noOptionsMessage={noOptionsMessage} isSearchable={true} onInputChange={onInputChange} onChange={onChange} defaultValue={value} className={classNames} options={options} />
    );
}

export default CustomSelect;

// ربع عود الومنيا
// و متر ليد بروفايل
// 5 متر سلك سماعات
import React from "react";

function Header() {
  return (
    <header>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <img src={`${process.env.PUBLIC_URL}/assets/images/logos/logo-dark.svg`} alt="logo" />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import SidemenuCloseBtn from "./SidemenuCloseBtn";

function Sidemenu({ open, setOpen }) {
  const [t, i18n] = useTranslation();
  // ** states
  const [layout, setLayout] = useState()
  const location = useLocation()

  // ** useEffect to set side menu layout value
  useEffect(() => {
    if (open && window.innerWidth < 992) {
      setLayout({ transform: 'translateX(0px)' })
    } else if (!open && window.innerWidth < 992) {
      setLayout({ transform: 'translateX(-260px)' })
    }

  }, [open])

  const handleResize = () => {
    if(window.innerWidth > 992 && !open) {
      setOpen(true)
      setLayout({ transform: 'translateX(0px)' })
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [open])

  return (
    <section className="side-menu" style={layout}>
      <SidemenuCloseBtn setOpen={setOpen} />
      <img src={`${process.env.PUBLIC_URL}/assets/images/logos/logo-admin.afb529f9.svg`} alt="logo" />
      <h4>SEO Tools</h4>
      <ul>
        <li className="icon1">
          <NavLink to="/" className="btn">
            {t('dashboard')}
          </NavLink>
        </li>
        <li className="icon2">
          <NavLink to="/technical-analysis" className="btn">
            {t('technical-analysis')}
          </NavLink>
        </li>
        <li className="icon3">
          <NavLink to="/content-analysis" className="btn">
            {t('keywords-and-content-analysis')}
          </NavLink>
        </li>
        <li className="icon4">
          <NavLink to="/content-generator" className="btn">
            {t('content-generator')}
          </NavLink>
        </li>
        <li className="icon5">
          <NavLink to="/local-seo" className="btn">
            {t('localseo')}
          </NavLink>
        </li>
        <li className="icon6">
          <NavLink to="/web-reputation" className="btn">
            {t('web-reputation')}
          </NavLink>
        </li>
        <li className="icon7">
          <NavLink to="/project-management" className="btn">
            {t('project-management')}
          </NavLink>
        </li>
        <li className='icon8'>
          <NavLink to="/crm/sales-management" className={`btn ${location.pathname.includes('crm') ? 'active' : ""}`}>
            {t('crm')}
          </NavLink>
        </li>
        <li className="icon9">
          <NavLink to="/competitor-analysis" className="btn">
            {t('competitor-analysis')}
          </NavLink>
        </li>
        {/* <li className="icon9">
          <NavLink to="/marketing-automation" className="btn">
            {t('marketing-automation')}
          </NavLink>
        </li> */}
      </ul>
    </section>
  );
}

export default Sidemenu;

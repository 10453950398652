import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const ContentInputs = ({ inputs, onDataUpdate, languages }) => {
    const history = useNavigate()
    const [t] = useTranslation()

    const renderLanguages = () => {
        return languages?.map(lang => {
            return <option value={lang}>{lang}</option>
        })
    }
    /**
language
creativity
tone
audience
productname
description
video_description
video_title
quora_question
website_name
website_url
keywords
page_type
business_description
keyword
question
subheading
topic
blog_title
blog_description
business_name
category_name
text_to_rewrite
text_to_summarize
seedwords
company_name
reviewer_name
customer_review
sentence
video_description
video_section_title
following_up_after
confirmation_of
amazon_product_url
   */

    const htmlElement = (label, input) => {
        return <div className='col-md-6'>
            <label>{label}</label>
            <input onChange={e => onDataUpdate(e.target.value, input)} className='form-control select-con' placeholder={input} />
        </div>
    }

    const renderInputs = () => {
        let htmlInputs = []
        if (inputs && inputs?.length > 0) {
            inputs.map(input => {
                switch (input) {
                    case "language":
                        htmlInputs.push(<div className='col-md-6 select-con'>
                            <label>{t('Language')}</label>
                            <select onChange={e => onDataUpdate(e.target.value, input)} className='form-control'>
                                {renderLanguages()}
                            </select>
                            <i className="fas fa-angle-down"></i>
                        </div>)
                        break;
                    case "creativity":
                        htmlInputs.push(<div className='col-md-6 select-con'>
                            <label>{t('Creativity')} <i className="fas fa-question-circle"></i></label>
                            <select onChange={e => onDataUpdate(e.target.value, input)} className='form-control'>
                                <option value='regular'>{t('Regular')}</option>
                                <option value='high'>{t('High')}</option>
                            </select>
                            <i className="fas fa-angle-down"></i>
                        </div>)
                        break;
                    case "tone":
                        htmlInputs.push(<div className='col-md-6 select-con'>
                            <label>{t('Tone')} <i className="fas fa-question-circle"></i></label>
                            <select onChange={e => onDataUpdate(e.target.value, input)} className='form-control'>
                                <option value='professional'>{t('Professional')}</option>
                                <option value='friendly'>{t('Friendly')}</option>
                                <option value='luxury'>{t('Luxury')}</option>
                                <option value='childish'>{t('Childish')}</option>
                                <option value='confident'>{t('Confident')}</option>
                            </select>
                            <i className="fas fa-angle-down"></i>
                        </div>)
                        break;
                    case "audience":
                        htmlInputs.push(<div className='col-md-6'>
                            <label>{t('Audience')}</label>
                            <input onChange={e => onDataUpdate(e.target.value, input)} className='form-control select-con' placeholder={input} />
                        </div>)
                        break;
                    case "productname":
                        htmlInputs.push(htmlElement('Product Name', input))
                        break;
                    case "description":
                        htmlInputs.push(htmlElement('Description', input))
                        break;
                    case "video_description":
                        htmlInputs.push(htmlElement('Video Description', input))
                        break;
                    case "video_title":
                        htmlInputs.push(htmlElement('Video Title', input))
                        break;
                    case "quora_question":
                        htmlInputs.push(htmlElement('Quora Question', input))
                        break;
                    case "website_name":
                        htmlInputs.push(htmlElement('Website Name', input))
                        break;
                    case "website_url":
                        htmlInputs.push(htmlElement('Website Url', input))
                        break;
                    case "keywords":
                        htmlInputs.push(htmlElement('Keywords', input))
                        break;
                    case "page_type":
                        htmlInputs.push(htmlElement('Page Type', input))
                        break;
                    case "business_description":
                        htmlInputs.push(htmlElement('Business Description', input))
                        break;
                    case "keyword":
                        htmlInputs.push(htmlElement('Keyword', input))
                        break;
                    case "question":
                        htmlInputs.push(htmlElement('Question', input))
                        break;
                    case "subheading":
                        htmlInputs.push(htmlElement('Sub Heading', input))
                        break;
                    case "topic":
                        htmlInputs.push(htmlElement('Topic', input))
                        break;
                    case "blog_title":
                        htmlInputs.push(htmlElement('Blog Title', input))
                        break;
                    case "blog_description":
                        htmlInputs.push(htmlElement('Blog Description', input))
                        break;
                    case "business_name":
                        htmlInputs.push(htmlElement('Business Name', input))
                        break;
                    case "category_name":
                        htmlInputs.push(htmlElement('Category Name', input))
                        break;
                    case "text_to_rewrite":
                        htmlInputs.push(htmlElement('Text to Rewrite', input))
                        break;
                    case "text_to_summarize":
                        htmlInputs.push(htmlElement('Text to summarize', input))
                        break;
                    case "seedwords":
                        htmlInputs.push(htmlElement('Seed Words', input))
                        break;
                    case "company_name":
                        htmlInputs.push(htmlElement('Company Name', input))
                        break;
                    case "reviewer_name":
                        htmlInputs.push(htmlElement('Reviewer Name', input))
                        break;
                    case "customer_review":
                        htmlInputs.push(htmlElement('Customer Review', input))
                        break;
                    case "sentence":
                        htmlInputs.push(htmlElement('Sentence', input))
                        break;
                    case "video_description":
                        htmlInputs.push(htmlElement('Video Description', input))
                        break;
                    case "video_section_title":
                        htmlInputs.push(htmlElement('Video Section Title', input))
                        break;
                    case "following_up_after":
                        htmlInputs.push(htmlElement('Following Up After', input))
                        break;
                    case "confirmation_of":
                        htmlInputs.push(htmlElement('Confirmation Of', input))
                        break;
                    case "amazon_product_url":
                        htmlInputs.push(htmlElement('Amazon Product Url', input))
                }
            })
            return htmlInputs
        } else {
            history('/content-generator')
        }
    }

    return renderInputs()
}

export default ContentInputs
import { put, select, takeEvery } from "redux-saga/effects"
import { AddCompetitorAPI, GetBacklinksDataAPI, GetCompetitorBacklinksAPI, GetCompetitorBacklinksNotmeAPI, GetCompetitorKeywordsNotmeAPI, GetFeedbackAPI, ListBacklinksAPI, ListCompetitorsAPI, ListCompetitorsBacklinksAPI, RemoveCompetitorAPI } from "./calls"
import { addCompetitorSuccess, competitorsBacklinksSuccess, getBacklinksDataSuccess, getCompetitorBacklinksNotMeSuccess, getCompetitorBacklinksSuccess, getCompetitorKeywordsNotMeSuccess, getFeedbackSuccess, listBacklinksSuccess, listCompetitorsSuccess, removeCompetitorSuccess } from './actions'
import { ADD_COMPETITOR, COMPETITORS_BACKLINKS, GET_BACKLINKS_DATA, GET_COMPETITOR_BACKLINKS, GET_COMPETITOR_BACKLINKS_NOT_ME, GET_COMPETITOR_KEYWORDS_NOT_ME, GET_FEEDBACK, LIST_BACKLINKS, LIST_COMPETITORS, REMOVE_COMPETITOR } from "./actionTypes"
import { stopAction, startAction, setError } from "../actions"

function* listCompetitors({ type, payload: { query } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const body = { workspace: state?.workspace?.workspace?._id }
    const result = yield ListCompetitorsAPI(query, body, state)
    console.log(result)
    yield put(listCompetitorsSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* removeCompetitor({ type, payload: { id } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield RemoveCompetitorAPI(id, state)
    console.log(result)
    yield put(removeCompetitorSuccess(id))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* getCompetitorsBacklinks({ type, payload: { competitors, query } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const body = { workspace: state?.workspace?.workspace?._id, competitors }
    console.log({ query })
    const result = yield ListCompetitorsBacklinksAPI(query, body, state)
    console.log(result)
    yield put(competitorsBacklinksSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* listBacklinks({ type, payload: { query } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const filters = new URLSearchParams({ workspace: state?.workspace?.workspace?._id, ...query }).toString()
    const result = yield ListBacklinksAPI(filters, state)
    console.log(result)
    yield put(listBacklinksSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* getBacklinksData({ type, payload: { update } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    let query = { workspace: state?.workspace?.workspace?._id }
    if (update) query['update'] = update
    query = new URLSearchParams(query).toString()
    const result = yield GetBacklinksDataAPI(query, state)
    console.log(result)
    yield put(getBacklinksDataSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* getCompetitorBacklinks({ type, payload: { query } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const filters = new URLSearchParams(query).toString()
    const result = yield GetCompetitorBacklinksAPI(filters, state)
    console.log(result)
    yield put(getCompetitorBacklinksSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* getCompetitorBacklinksNotme({ type, payload: { id } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    let query = { workspace: state.workspace.workspace?._id }
    query = new URLSearchParams(query).toString()
    const result = yield GetCompetitorBacklinksNotmeAPI(id, query, state)
    console.log(result)
    yield put(getCompetitorBacklinksNotMeSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* getCompetitorKeywordsNotme({ type, payload: { id } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    let query = { workspace: state.workspace.workspace?._id }
    query = new URLSearchParams(query).toString()
    const result = yield GetCompetitorKeywordsNotmeAPI(id, query, state)
    console.log(result)
    yield put(getCompetitorKeywordsNotMeSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* addCompetitor({ type, payload: { url, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const body = { workspace: state?.workspace?.workspace?._id, url }
    console.log(body)
    const result = yield AddCompetitorAPI(body, state)
    yield put(addCompetitorSuccess(result?.data?.data))
    if (toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* getFeedback({ type, payload }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const query = new URLSearchParams({ workspace: state?.workspace?.workspace?._id }).toString()
    const result = yield GetFeedbackAPI(query, state)
    console.log(result)
    yield put(getFeedbackSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* WebReputationSaga() {
  yield takeEvery(LIST_COMPETITORS, listCompetitors)
  yield takeEvery(REMOVE_COMPETITOR, removeCompetitor)
  yield takeEvery(COMPETITORS_BACKLINKS, getCompetitorsBacklinks)
  yield takeEvery(LIST_BACKLINKS, listBacklinks)
  yield takeEvery(GET_BACKLINKS_DATA, getBacklinksData)
  yield takeEvery(GET_COMPETITOR_BACKLINKS, getCompetitorBacklinks)
  yield takeEvery(GET_COMPETITOR_BACKLINKS_NOT_ME, getCompetitorBacklinksNotme)
  yield takeEvery(GET_COMPETITOR_KEYWORDS_NOT_ME, getCompetitorKeywordsNotme)
  yield takeEvery(ADD_COMPETITOR, addCompetitor)
  yield takeEvery(GET_FEEDBACK, getFeedback)
}

export default WebReputationSaga
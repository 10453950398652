import { Icon, IconButton, TextField } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dateToString } from '../../ProjectManagement/utils/helpers'
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

function CampaignEmail({ name, subject, content, order, from, day, updateEmail, senders, deleteEmail }) {
    const [edit, setEdit] = useState(false)
    const [editNum, setEditNum] = useState(false)
    const [openCalendar, setOpenCalendar] = useState(false)
    const [t] = useTranslation()

    const closeEdits = () => {
        setEdit(false);
        setEditNum(false);
    }

    const onKeyPress = e => e.charCode === 13 ? closeEdits() : null

    const changeNumber = (e) => {
        // if (Number(e.target.value) && Number(e.target.value) > 31) return
        // updateEmail(order, 'day', e.target.value)
        updateEmail(order, 'day', e)
        setOpenCalendar(false)
    }

    const renderSenders = () => {
        if (senders && senders?.length > 0) {
            return senders?.map(s => (
                <option value={s.EmailAddress}>{s.EmailAddress}</option>
            ))
        } else {
            return <option>No sender found</option>
        }
    }

    return (
        <div className='email mb-4'>
            <div className='before text-center'>
                <div className='d-flex flex-column'>
                    <span className='day'>{t('Day')}</span>
                    {/* {
                        editNum ? <div className='text-center'>
                            <input value={day} type="text" max={'30'} className="custom-border text-center" style={{ width: "30px", color: "#fff" }} onKeyPress={onKeyPress} onChange={changeNumber} />
                        </div> : <span className='number'>{day}</span>
                    } */}
                </div>
                <div className='position-relative'>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            minDate={new Date().setMonth(new Date().getMonth() - 10)}
                            maxDate={new Date().setMonth(new Date().getMonth() + 30)}
                            open={openCalendar}
                            renderInput={(params) => (
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <TextField
                                       style={{ padding: 0, height: 0, width: 0, opacity: 0 }}
                                       variant="standard"
                                       {...params}
                                       onClick={() => {
                                          setOpenCalendar(!openCalendar)
                                       }}
                                    ></TextField>
                                    {/* <b
                                       onClick={() =>
                                        setOpenCalendar(!openCalendar)
                                       }
                                    >
                                       &nbsp;
                                       {day ? dateToString(new Date(day)) : ""}
                                       &nbsp;
                                    </b> */}
                                    <span className='edit cursor-pointer' onClick={() => setOpenCalendar(!openCalendar)}><i class="fas fa-calendar"></i></span>
                                 </div>
                            )}
                            value={new Date(day)}
                            onChange={(date) => changeNumber(new Date(date))}
                        ></DesktopDatePicker>
                    </LocalizationProvider>
                    <span className='text-dark' style={{
                        top: "15px",
                        backgroundColor: "#EEEBE3",
                        position: "relative"
                    }}>{day ? dateToString(day) : ""}</span>
                </div>
            </div>
            <div className='card'>
                <div className='text-right'>
                    <span onClick={() => setEdit(!edit)} className='cursor-pointer'><i className='fas fa-sm fa-edit text-green'></i></span>
                    <span onClick={() => deleteEmail(order)} className="cursor-pointer"><i className='fas fa-sm fa-times text-danger ml-3'></i></span>
                </div>
                {/* {
                    edit ? <input className='custom-border d-inline-block px-2 py-1 mb-1' value={name} onKeyPress={onKeyPress} placeholder='enter name' onChange={e => updateEmail(order, 'name', e.target.value)} /> : <h5>{name}</h5>
                } */}
                <h5>{name}</h5>
                <div className='d-flex align-items-center'>
                    <span className='text-muted mr-2'>{t('From')}: </span>
                    {
                        edit ? <select className='form-control custom-border mb-1' onChange={(e) => updateEmail(order, 'from', e.target.value)} style={{ width: "60%" }}>
                            <option value={null}>{t('Choose email address')}</option>
                            {renderSenders()}
                        </select> : <span>{from}</span>
                    }
                </div>
                <div>
                    <span className='text-muted'>{t('Subject')}: </span>
                    {
                        edit ? <input className='custom-border d-inline-block px-2 py-1 mb-1' value={subject} onKeyPress={onKeyPress} placeholder='enter subject' onChange={e => updateEmail(order, 'subject', e.target.value)} /> :
                            <span className='text-green'>{subject}</span>
                    }
                </div>
                <hr />
                <div>
                    {
                        edit ? <textarea className='form-control custom-border d-inline-block' rows={5} value={content} onKeyPress={onKeyPress} placeholder='enter content' onChange={e => updateEmail(order, 'content', e.target.value)}></textarea> : <p>
                            {content || "Enter your content here"}
                        </p>
                    }
                </div>
            </div>
        </div>
    )
}

export default CampaignEmail
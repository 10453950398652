import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom'
import { checkIfLoading, useQuery } from "../../store/functions";
import { ListGroup, ListGroupItem, Spinner } from "reactstrap";
import { GET_WORKSPACE, LIST_WORKSPACES } from "../../store/workspace/actionTypes";
import { listWorkspaces, getWorkspace } from "../../store/workspace/actions";
import { logoutUser } from "../../store/actions";
import { useTranslation } from "react-i18next";

const SelectWorkspace = () => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const [workspace, setWorkspace] = useState(null)
    const query = useQuery()
    const [t] = useTranslation()

    const { loading, getWorkspaceLoading, workspaces, token } = useSelector(state => ({
        loading: checkIfLoading(state, LIST_WORKSPACES),
        getWorkspaceLoading: checkIfLoading(state, GET_WORKSPACE),
        workspaces: state?.workspace?.workspaces,
        token: state?.auth?.token
    }))

    useEffect(() => {
        dispatch(listWorkspaces())
    }, [])

    useEffect(() => {
        if (!token) return history('/login')
        if (workspaces?.length > 0) setWorkspace(workspaces[0]?._id)
    }, [workspaces])

    const renderWorkspaces = () => {
        if (loading) return <Spinner size="sm" className="m-auto text-center" color="success" />
        if (workspaces?.length > 0) {
            return workspaces?.map((w, i) => {
                return <ListGroupItem className={`${workspace === w?._id ? 'active' : ""}`} onClick={() => setWorkspace(w._id)}>
                    <h6><b>{w.name || w.domain}</b></h6>
                    <span>{w?.collaborators?.length || 0} Members</span>
                </ListGroupItem>
            })
        } else {
            return 'No workspaces found'
        }
    }

    console.log({ workspace })

    const selectWorkspace = () => {
        dispatch(getWorkspace(workspace, history))
    }

    return (
        <div className="container my-5">
            <div className="row justify-content-center align-items-center">
                <div className="col-md-6">
                    <div className="select-workspace">
                        <h4>{t('Select a workspace')}</h4>
                        <span className="text-muted">{t('Welcome back! Please choose a workspace that you want to enter.')}</span>
                        <ListGroup className="mt-3">
                            {renderWorkspaces()}
                        </ListGroup>
                        <div className="mt-3">
                            <div className="row justify-content-end">
                                <div className="col-md-8 d-flex">
                                    <button onClick={() => dispatch(logoutUser(history))} className="btn btn-link">{t('Logout')}</button>
                                    <button type="button" disabled={!workspace || loading || getWorkspaceLoading} onClick={selectWorkspace} className="btn click-btn orange-btn btn-block">
                                        {
                                            getWorkspaceLoading ? (
                                                <Spinner
                                                    size="sm"
                                                    className="m-auto text-center"
                                                    color="light"
                                                />
                                            ) : (
                                                t("Select Workspace")
                                            )
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ordivider my-3">Or</div>
                    <div className="text-center mb-5">
                        <Link to={"/add-workspace"}><span className="text-dark text-unstyle"><b>
                            {workspaces?.length === 0 ? t('Add your first workspace now') : t("Create a workspace")}</b></span></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SelectWorkspace;
import { put, select, takeEvery } from "redux-saga/effects"
import { GET_DASHBOARD } from "./actionTypes"
import { getDashboardError, getDashboardSuccess } from "./actions"
import { setError, startAction, stopAction } from '../actions'
import { GetDashboardAPI } from "./calls"

function* getDashboardAnalytics({ type, payload: { id, update } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    let query = {}
    if (update) query['update'] = update
    query = new URLSearchParams(query).toString()
    const result = yield GetDashboardAPI(id, query, state)
    console.log(result)
    yield put(getDashboardSuccess(result?.data?.data))
  } catch (err) {
    yield put(getDashboardError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* dashboardSaga() {
  yield takeEvery(GET_DASHBOARD, getDashboardAnalytics)
}

export default dashboardSaga
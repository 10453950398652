import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { ADD_PIPELINE } from '../../../../store/salesManagement/actionTypes';
import { checkIfLoading } from '../../../../store/functions';
import { addPipeline } from '../../../../store/salesManagement/actions';
import { useTranslation } from 'react-i18next';

function AddPipelineModal({ modal, toggle }) {
    // ** states
    const [name, setName] = useState(null)
    const dispatch = useDispatch()
    const [t] = useTranslation()

    const { pipeloading } = useSelector(state => ({
        pipeloading: checkIfLoading(state, ADD_PIPELINE),
    }))

    // ** function to handle toggle search result modal and hide business modal
    const handleAddPipeline = () => {
        dispatch(addPipeline({ name }, toggle))
    }

    return (
        <Modal isOpen={modal} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle}>{t('Pipeline Details')}</ModalHeader>
            <ModalBody className='text-center px-3 pt-1 pb-3'>
                <input type='text' onChange={(e) => setName(e.target.value)} className='form-control m-auto bg-transparent' placeholder={t('Pipeline Name Example')} required />
                <div className='d-flex float-right align-items-center mt-4'>
                    <button className='btn btn-link'>{t('Cancel')}</button>
                    <button type='button' disabled={pipeloading || !name} onClick={handleAddPipeline} className='btn click-btn green-btn py-2'>
                        {
                            pipeloading ? (
                                <Spinner
                                    size="sm"
                                    className="m-auto text-center"
                                    color="light"
                                />
                            ) : (
                                t("Save")
                            )
                        }
                    </button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default AddPipelineModal
import React, { useState } from 'react'
import { Tooltip } from 'reactstrap'
import { issues } from '../../store/issues'

const CustomTooltip = ({ item, header, position = 'right', text }) => {
    const [tooltipOpen, setTooltipOpen] = useState(null)

    return (
        <>
            <img src={`${process.env.PUBLIC_URL}/assets/images/icons/question.svg`} className="custom-tooltip"
                onMouseOver={({ currentTarget }) => {
                    currentTarget.src = `${process.env.PUBLIC_URL}/assets/images/icons/question2.svg`;
                    setTooltipOpen(item)
                }} onMouseLeave={({ currentTarget }) => {
                    currentTarget.src = `${process.env.PUBLIC_URL}/assets/images/icons/question.svg`;
                    setTooltipOpen(null)
                }} id={item} />
            {tooltipOpen === item ? <Tooltip placement={position} isOpen={true} target={item}>
                <div><b>{header}</b></div>
                <span>{issues[item]}</span>
            </Tooltip> : ""}
        </>
    )
}

export default CustomTooltip
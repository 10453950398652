import {
  LIST_REMINDERS,
  LIST_REMINDERS_SUCCESS,
  UPDATE_REMINDER,
  UPDATE_REMINDER_SUCCESS,
  ADD_REMINDER,
  ADD_REMINDER_SUCCESS,
  DELETE_REMINDER,
  DELETE_REMINDER_SUCCESS
} from "./actionTypes"

const initialState = {
  reminders: []
}

const reminder = (state = initialState, action) => {
  switch (action.type) {
    case LIST_REMINDERS:
      return { ...state, }
    case LIST_REMINDERS_SUCCESS:
      return { ...state, reminders: action.payload }
    case ADD_REMINDER:
      return { ...state, }
    case ADD_REMINDER_SUCCESS:
      return { ...state, reminders: action.payload }
    case UPDATE_REMINDER:
      return { ...state, }
    case UPDATE_REMINDER_SUCCESS:
      return { ...state, reminders: action.payload }
    case DELETE_REMINDER:
      return { ...state, }
    case DELETE_REMINDER_SUCCESS:
      return { ...state, reminders: action.payload }
    default:
      return state
  }
}

export default reminder

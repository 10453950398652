import { put, select, takeEvery } from "redux-saga/effects"
import { stopAction, startAction, setError } from "../actions"
import { getCompetitorDataSuccess } from "./actions"
import { GET_COMPETITOR_DATA } from "./actionTypes"
import { GetCompetitorDataAPI } from "./calls"

function* getCompetitorData({ type, payload: { query } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    query = new URLSearchParams(query).toString()
    const result = yield GetCompetitorDataAPI(query, state)
    console.log(result)
    yield put(getCompetitorDataSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* CompetitorAnalysisSaga() {
  yield takeEvery(GET_COMPETITOR_DATA, getCompetitorData)
}

export default CompetitorAnalysisSaga
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DataTableComponent from "../../../../components/tables/DataTable";

function KeywordsTable({ data }) {
    const [tableData, setTableData] = useState([])
    const [t] = useTranslation()

    useEffect(() => {
        if (data && data?.length > 0) {
            const mappedData = data?.map(e => {
                return {
                    id: e._id,
                    keyword: e.keyword,
                    KeywordVolume: e.searchVolume,
                    rank: e.rank,
                    price: e?.cpc ? Number(e?.cpc).toFixed(2) : "0",
                    density: e.density ? Number(e.density).toFixed(4) : "0",
                    frequency: e.frequency
                }
            })
            setTableData(mappedData)
        } else {
            setTableData([])
        }
    }, [data])

    const columns = [
        {
            name: t('Keyword'),
            selector: "keyword",
            sortable: true,
            cell: e => {
                return <p style={{ fontWeight: 600, fontSize: "13px", color: "#347362", marginBottom: 0 }}>{e.keyword}</p>
            },
        },
        {
            name: t('URL'),
            selector: "URL",
            sortable: true,
            cell: e => {
                return <a style={{ fontWeight: 800, fontSize: "14px", color: "#347362", marginBottom: 0, textDecoration: "underline" }} href={e.URL}>{e.URL}</a>
            },
        },
        {
            name: t('Price'),
            selector: "price",
            sortable: true,
            cell: e => {
                return <p style={{ fontWeight: 800, fontSize: "14px", color: "#347362", marginBottom: 0 }}>{e.price}</p>
            },
        },
        {
            name: t('Frequency'),
            selector: "frequency",
            sortable: true,
            cell: e => {
                return <p style={{ fontWeight: 800, fontSize: "14px", color: "#347362", marginBottom: 0 }}>{e.frequency}</p>
            },
        },
        {
            name: t('Density'),
            selector: "density",
            sortable: true,
            cell: e => {
                return <p style={{ fontWeight: 800, fontSize: "14px", color: "#347362", marginBottom: 0 }}>{e.density}</p>
            },
        },
        {
            name: t('Keyword volume'),
            selector: "KeywordVolume",
            sortable: true,
            cell: e => {
                return <p style={{ fontWeight: 800, fontSize: "14px", color: "#347362", marginBottom: 0 }}>{e.KeywordVolume}</p>
            },
        },
        {
            name: t('Rank'),
            selector: "rank",
            sortable: true,
            cell: e => {
                return <p style={{ fontWeight: 500, fontSize: "18px", color: "#347362", marginBottom: 0 }}>{e.rank}</p>
            },
        },
    ];

    return <DataTableComponent columns={columns} data={tableData} />
}

export default KeywordsTable;


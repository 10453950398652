import api from '../apis/api'
import { tokenConfig } from '../config'

export const ListCampaignsAPI = (query, state) => {
  return new Promise((res, rej) => {
    api.get(`/campaign?${query}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const CreateCampaignAPI = (body, state) => {
  return new Promise((res, rej) => {
    api.post(`/campaign`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const UpdateCampaignAPI = (id, body, state) => {
  return new Promise((res, rej) => {
    api.put(`/campaign/${id}`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const GetCampaignAPI = (id, query, state) => {
  return new Promise((res, rej) => {
    api.get(`/campaign/${id}?${query}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const DeleteCampaignAPI = (id, state) => {
  return new Promise((res, rej) => {
    api.delete(`/campaign/${id}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}
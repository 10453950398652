import React from 'react'
import { Link } from 'react-router-dom'

function Cards({ filterData }) {
    // ** render data
    const renderData = filterData?.length > 0 ? filterData.map(ele =>
        <div key={ele.id} className='col-xl-3'>
            <Link className='card btn' to={`/content-generator/generate?model=${ele?.model}`}>
                <img src={`${process.env.PUBLIC_URL}/assets/images/icons/icon-1.0392a5c4.svg`} alt='' />
                <h4>{ele.title}</h4>
                <p>{ele.description}</p>
                <img src={`${process.env.PUBLIC_URL}/assets/images/icons/top-rightarrow.fc85980a.svg`} className="arrow-icon" alt='' />
            </Link>
        </div>
    ) : null

    return (
        <>
            {renderData}
        </>
    )
}

export default Cards
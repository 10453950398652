import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { checkIfLoading } from '../../../../store/functions';
import { ADD_CUSTOMER } from '../../../../store/crm/actionTypes';
import { addCustomer } from '../../../../store/actions';
import { useTranslation } from 'react-i18next';

function AddContactModal({ modal, toggle }) {
    const [name, setName] = useState(null)
    const [type, setType] = useState('lead')
    const dispatch = useDispatch()
    const [t] = useTranslation()

    const { addLoading, workspace } = useSelector(state => ({
        addLoading: checkIfLoading(state, ADD_CUSTOMER),
        workspace: state.workspace.workspace,
    }))

    const handleAddCustomer = () => {
        dispatch(addCustomer({ name, type, workspace: workspace?._id }, toggle))
    }

    return (
        <Modal isOpen={modal} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle}>{t('Contact Details')}</ModalHeader>
            <ModalBody className='text-center px-3 pt-1 pb-3'>
                <div className='d-flex'>
                    <input type='text' onChange={(e) => setName(e.target.value)} className='form-control col-md-8 m-auto bg-transparent' style={{ borderRadius: '10px' }} placeholder='Contact Name Example' required />
                    <select onChange={(e) => setType(e.target.value)} className='form-control ml-3 custom custom-border'>
                        <option value='lead'>{t('Lead')}</option>
                        <option value='customer'>{t('Customer')}</option>
                        <option value='media'>{t('Media')}</option>
                    </select>
                </div>

                <div className='d-flex float-right align-items-center mt-4'>
                    <button className='btn btn-link' onClick={toggle}>Cancel</button>
                    <button type='button' disabled={addLoading || !name} onClick={handleAddCustomer} className='btn click-btn green-btn py-2'>
                        {
                            addLoading ? (
                                <Spinner
                                    size="sm"
                                    className="m-auto text-center"
                                    color="light"
                                />
                            ) : (
                                t("Submit")
                            )
                        }
                    </button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default AddContactModal
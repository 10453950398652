import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";

function SocialMediaTraffic({ competitor }) {
    const [chartOptions, setChartOptions] = useState(null)
    const [t] = useTranslation()

    useEffect(() => {
        let socials = competitor?.data?.traffic?.sources?.social?.top_socials

        socials = socials?.map(s => {
            return {
                x: s.site,
                y: s.percent
            }
        })
        if (socials?.length > 0) {
            setChartOptions({
                series: [{
                    data: socials
                }],
                options: {
                    plotOptions: {
                        bar: {
                            borderRadius: 4,
                            dataLabels: {
                                position: 'top',
                            },
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                            return val + '%';
                        },
                        offsetY: -20,
                        style: {
                            fontSize: '12px',
                            colors: ["#304758"]
                        }
                    },
                    tooltip: {
                        enabled: false,
                    },
                    // xaxis: {
                    //     categories: ['-48 h', '', '', '', '', '', '', '', '', 'Now'],
                    //     labels: {
                    //         rotate: 0
                    //     }
                    // },
                    yaxis: {
                        show: false
                    },
                    colors: ['#EF8451'],
                },
            })
        }
    }, [competitor])

    return (
        <div className="card mt-3 competitors-marketing">
            <div className="d-flex align-items-center justify-content-between">
                <div>
                    <h4>{t('Social Media Traffic')}</h4>
                    <span className="text-muted">
                        The top social media networks directing traffic to {competitor?.data?.site_url} are Youtube, Facebook, and Instagram. Look for opportunities to
                        engage audiences through Foursquare or other networks.
                        (On Desktop, Worldwide)
                    </span>
                </div>
                {/* <div className="bg-gray rounded-10 p-3"><i class="fas fa-share-alt"></i></div> */}
            </div>
            <div className="bg-gray rounded-10 p-3 mt-3">
                <h5>{t('Social Network Distribution')}</h5>
                {
                    chartOptions ? <Chart height={280} options={chartOptions.options} series={chartOptions.series} type="bar" /> : ""
                }
            </div>
        </div>
    );
}

export default SocialMediaTraffic;
import React, { useEffect, useRef } from 'react'
import Router from './routers/router'
import { ReactNotifications } from 'react-notifications-component'
import { Store } from 'react-notifications-component';
import { useDispatch, useSelector } from 'react-redux'

import 'react-notifications-component/dist/theme.css'
import 'animate.css/animate.min.css';
import { clearError } from './store/actions';
import { useTranslation } from 'react-i18next';

function App() {
  const dispatch = useDispatch()
  const [t, i18n] = useTranslation();
  const { error, success, lang } = useSelector(state => ({
    error: state.ui.error,
    success: state.ui.success,
    lang: state.ui.lang
  }))

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevError = usePrevious(error)

  const notificationValues = {
    container: "top-center",
    animationIn: ['animate__animated animate__fadeIn'],
    animationOut: ['animate__animated animate__fadeOut'],
    dismiss: {
      duration: 3000
    }
  }

  useEffect(() => {
    if (error) {
      Store.addNotification({
        title: error[lang] || 'Error, Please try again later',
        type: "danger",
        ...notificationValues
      })
    }
    if (success) {
      Store.addNotification({
        title: success[lang],
        type: "success",
        ...notificationValues
      })
    }
    if (error || success) dispatch(clearError())
  }, [error, success])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  return (
    <div className="app-container">
      <ReactNotifications />
      <Router />
    </div>
  )
}

export default App
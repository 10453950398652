import React, { useEffect, useState } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Dropdown, DropdownMenu, DropdownToggle, Modal, Spinner } from 'reactstrap'
import DeletePrompt from '../../../../components/common/DeletePrompt'
import ImageCropModal from '../../../../components/common/ImageCropModal'
import { deleteWorkspace, getDomain, registerDomain, removeCollaborator, updateWorkspaceLogo } from '../../../../store/actions'
import { checkIfLoading } from '../../../../store/functions';
import { DELETE_WORKSPACE, REGISTER_DOMAIN, UPDATE_WORKSPACE } from '../../../../store/workspace/actionTypes'
import AddDomainModal from './addDomainModal'
import AddSenderModal from './addSenderModal'
import ChangeWorkspaceName from './changeWorkspaceName'
import InviteModal from './inviteModal'

const WorkspaceTab = () => {
    const [cropModal, setCropModal] = useState(false)
    const [changeNameModal, setChangeNameModal] = useState(false)
    const [img, setImg] = useState(null)
    const [userImage, setUserImage] = useState(null)
    const dispatch = useDispatch()
    const history = useNavigate()
    const [senderDropdown, setSenderDropdown] = useState(false)
    const [senderModal, setSenderModal] = useState(false)
    const [domainModal, setDomainModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(null)
    const [copiedMsg, setCopiedMsg] = useState(false)
    const [inviteModal, setInviteModal] = useState(false)
    const [t] = useTranslation()

    const { uploadLoading, workspace, registerLoading, deleteLoading } = useSelector(state => ({
        uploadLoading: checkIfLoading(state, UPDATE_WORKSPACE),
        workspace: state?.workspace?.workspace,
        registerLoading: checkIfLoading(state, REGISTER_DOMAIN),
        deleteLoading: checkIfLoading(state, DELETE_WORKSPACE)
    }))

    const copyToClipboard = (key) => {
        setCopiedMsg(true)
        navigator.clipboard.writeText(key);
        setTimeout(() => { setCopiedMsg(false) }, 2000)
    };

    const handleCropSubmit = file => {
        setCropModal(false)
        setUserImage(file)
    }

    useEffect(() => {
        dispatch(getDomain())
    }, [])

    useEffect(() => {
        if (userImage) dispatch(updateWorkspaceLogo(userImage))
    }, [userImage])

    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        setImg(URL.createObjectURL(fileUploaded))
        setTimeout(() => {
            setCropModal(true)
        }, 300)
    };

    const renderCollaborators = () => {
        const team = workspace?.collaborators
        if (team && team?.length > 0) {
            return team?.map(t => {
                return <li className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex align-items-center'>
                    {
                        t?.profilPicture ? <img className='img-thumbnail rounded-circle image' src={t.profilPicture} /> :
                            <span className='img-thumbnail rounded-circle font-logo text-center' >{String(t?.fullName).charAt(0).toUpperCase()}</span>
                    }
                    <div className='ml-2'>
                        <div className='h6 m-0'>{t.fullName}</div>
                        <span className='text-muted'>{t.email}</span>
                    </div>
                    </div>
                    <span className='cursor-pointer text-dark' onClick={() => dispatch(removeCollaborator(t?._id))}><i className='fas fa-times'></i></span>
                </li>
            })
        }
    }

    const renderSenders = () => {
        const { senders } = workspace
        if (senders && senders?.length > 0) {
            return senders?.map(s => (
                <div className='d-flex align-items-center justify-content-between mb-2'>
                    <span>{s?.EmailAddress}</span>
                    <span className={`${s?.status === 'Pending' ? 'text-dark' : s?.status === 'Verified' ? 'text-green' : 'text-danger'}`}>{s?.status}</span>
                </div>
            ))
        }
    }

    return (
        <div className='row workspace-tab'>
            <div className='col-md-8'>
                <h4>{t('primary-information')}</h4>
                <div className='workspace-info card'>
                    <div className='d-flex align-items-center'>
                        {
                            uploadLoading ? <Spinner className="ml-2" size="sm" color="success" /> : <>
                                {
                                    workspace?.logo ?
                                        <div className='position-relative'>
                                            <span className='delete-workspace-logo' onClick={() => dispatch(updateWorkspaceLogo(null))}>x</span>
                                            <img className='img-thumbnail rounded-circle avatar cursor-pointer' onClick={() => handleClick()} src={workspace?.logo} alt="workspace logo" />
                                        </div>
                                        : <span className='img-thumbnail rounded-circle avatar cursor-pointer workspace-font-logo text-center' onClick={() => handleClick()}>{String(workspace?.domain).charAt(0).toUpperCase()}</span>
                                }
                                <input type="file" accept="image/*" style={{ display: "none" }} ref={hiddenFileInput} onChange={(e) => handleChange(e)} /></>
                        }
                        <div className='d-inline-block ml-3'>
                            <h5 className='m-0 mb-1'>{workspace?.name || workspace?.domain}</h5>
                            <span onClick={() => setChangeNameModal(true)} className='btn btn-link m-0 p-0' style={{ fontSize: "14px" }}>
                                {
                                    workspace?.name ? "Change workspace name" : "Add workspace name"
                                }
                            </span>
                        </div>
                    </div>
                </div>
                {/* <div className='mt-4'>
                    <h4>{t('seo-suite-api')}</h4>
                    <div className='card'>
                        <p>This is your SEO Suite API access key. Here we explain everything about what it is
                            and why is it important. You can copy and paste it. However, don’t share it with
                            anybody, even your friends and family  :)</p>
                        <div className='row'>
                            <div className='col-md-8'>
                                <input className='form-control custom-border py-3' value={workspace?.apiKey} placeholder='cysFXeCnTZHesHYS' />
                            </div>
                            <div className='col-md-4 pl-3 position-relative'>
                                {
                                    copiedMsg ? <span className='bg-dark text-white rounded px-2 py-1' style={{
                                        position: "absolute",
                                        left: "80px",
                                        bottom: "45px"
                                    }}>Copied!</span> : ""
                                }
                                <button className='btn click-btn orange-btn btn-block py-2' onClick={() => copyToClipboard(workspace?.apiKey)}>Copy API</button>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className='mt-4'>
                    <h4>{t('domain-email-configuration')}</h4>
                    <span className='text-muted'>
                        <small>{t('Please note that changes in this section may take effect between 1-30 minutes, depending on server congestion.')}</small>
                    </span>
                    <div className='card mt-2'>
                        <div className='d-flex mb-3 align-items-center justify-content-between'>
                            <div className='d-flex align-items-center'>
                                <h5>{t('domain-status')}</h5>
                                <div className={`badge ml-3 ${workspace?.status === 'Verified' ? 'badge-success' : "badge-danger"}`}>{workspace?.status}</div>
                            </div>
                            <span className='text-muted'>{workspace?.domain}</span>
                        </div>
                        <div>
                            {
                                workspace?.status === 'Pending' ? <><div className='alert alert-warning m-0'>
                                    {t('Your website registration is processing')} <button className='btn btn-link text-green' onClick={() => dispatch(getDomain(true))}>{t('Refresh again')}</button>
                                </div>
                                    <div><a className='btn btn-link text-left m-0' onClick={() => setDomainModal(true)}>{t('How can I integrate?')}</a></div>
                                    <span><small className='text-muted'>{t('Note: DNS settings changes might take up to 24 hours depending on the server provider.')}</small></span>
                                </> : workspace?.status === 'Verified' ? <div className='alert alert-success'>{t('Your domain is verified')}</div> : <>
                                    <button className='btn btn-outline-secondary col-md-3 btn-block mb-3' onClick={() => dispatch(registerDomain())}>
                                        {registerLoading ? <div><Spinner color='success' size={'sm'} /></div> : t('register-domain')}
                                    </button>
                                    <p>{t('To experience our services at its maximum, please make sure that both your email and domain are registered. To register your domain, paste a piece of code that we send to your primary sender email.')}</p>
                                    <span className='text-muted'>{t('Note: DNS settings changes might take up to 24 hours depending on the server provider.')}</span>
                                </>
                            }
                            <hr />
                            <h5>{t('sender-email')}</h5>
                            <div className='d-flex justify-content-between'>
                                <Dropdown className='custom-dropdown col-md-9' isOpen={senderDropdown} toggle={() => setSenderDropdown(!senderDropdown)}>
                                    <DropdownToggle caret>
                                        {t('List all senders')}
                                    </DropdownToggle>
                                    <DropdownMenu right={true}>
                                        {renderSenders()}
                                    </DropdownMenu>
                                </Dropdown>
                                <button className='btn btn-outline-secondary col-md-3 btn-block ml-2' style={{ fontSize: "13px" }} onClick={() => setSenderModal(true)}><i className='fas fa-plus'></i> {t('add-sender-email')}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-4'>
                    <h4>{t('delete-workspace')}</h4>
                    <span><small>{t('Completely remove your current workspace from SEO Suite')}</small></span>
                    <div className='card d-flex mt-2'>
                        <h5 className='d-inline-block'>{t('delete-workspace')}</h5>
                        <span>{t('If you want to delete your workspace, you can do so from this')} <Link to={'#'} onClick={() => {
                            setDeleteModal({
                                show: true,
                                func: () => {
                                    dispatch(deleteWorkspace(workspace?._id, history, () => setDeleteModal(null)))
                                }
                            })
                        }} className='text-orange'>{t('delete-workspace')}</Link> {t('button')}.</span>
                    </div>
                </div>
            </div>
            <div className='col-md-4'>
                <h4>{t('your-team')}</h4>
                <ul className='list-unstyled collaborators-list'>
                    <li className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                            {
                                workspace?.manager?.profilPicture ?
                                    <img className='img-thumbnail rounded-circle image' src={workspace?.manager?.profilPicture} /> :
                                    <span className='img-thumbnail rounded-circle font-logo text-center'>{String(workspace?.manager?.fullName).charAt(0).toUpperCase()}</span>
                            }
                            <div className='ml-2'>
                                <div className='h6 m-0'>{workspace?.manager?.fullName}</div>
                                <span className='text-muted'>{workspace?.manager?.email}</span>
                            </div>
                        </div>
                        <div className='text-muted'>Owner</div>
                    </li>
                    {renderCollaborators()}
                </ul>
                <button className='btn btn-block green-btn-outline' onClick={() => setInviteModal(true)}>Invite Collaborator</button>
            </div>
            <Modal centered={true} size='lg' isOpen={cropModal} toggle={() => { setCropModal(!cropModal) }}>
                <ImageCropModal handleCropSubmit={handleCropSubmit} img={img} toggle={() => { setCropModal(!cropModal) }} />
            </Modal>
            <ChangeWorkspaceName modal={changeNameModal} toggle={() => setChangeNameModal(false)} workspace={workspace} />
            <AddSenderModal modal={senderModal} toggle={() => setSenderModal(false)} />
            <AddDomainModal modal={domainModal} toggle={() => setDomainModal(false)} />
            <InviteModal modal={inviteModal} toggle={() => setInviteModal(false)} />
            <Modal centered={true} size='md' isOpen={deleteModal?.show} toggle={() => { setDeleteModal(null) }}>
                <DeletePrompt title={'Delete Workspace'} message="Are you sure you want to delete this workspace?" func={deleteModal?.func} loading={deleteLoading} toggle={() => setDeleteModal(null)} />
            </Modal>
        </div>
    )
}

export default WorkspaceTab
import React from 'react'
import ProjectApp from './app'
import { GlobalContextProvider } from './GlobalContext/GlobalContext'

function ProjectManagement() {
    return (
        <GlobalContextProvider>
            <ProjectApp></ProjectApp>
        </GlobalContextProvider>
    )
}

export default ProjectManagement
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { updateWorkspace } from '../../../../store/actions';
import { checkIfLoading } from '../../../../store/functions';
import { UPDATE_WORKSPACE } from '../../../../store/workspace/actionTypes';

function ChangeWorkspaceName({ modal, toggle, workspace }) {
    const [name, setName] = useState(null)
    const dispatch = useDispatch()
    const [t] = useTranslation()

    useEffect(() => {
        if(workspace?.name) setName(workspace?.name)
    }, [workspace.name])

    const { changeLoading } = useSelector(state => ({
        changeLoading: checkIfLoading(state, UPDATE_WORKSPACE)
    }))

    const onSubmit = () => {
        dispatch(updateWorkspace(workspace?._id, { name }, toggle))
    }

    return (
        <>
            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader className='px-4' toggle={toggle}></ModalHeader>
                <ModalBody className='pt-1 pb-3'>
                    <h3 className='mb-3 text-center'>{t('Change Workspace name')}</h3>
                    <div style={{ width: "80%" }} className="m-auto">
                        <div className='mb-3'>
                            <label>Name</label>
                            <input type='text' value={name} onChange={(e) => setName(e.target.value)} className='form-control custom-border' placeholder={t('Enter workspace name')} />
                        </div>
                        <button type='submit' disabled={changeLoading} className='btn click-btn green-btn btn-block mt-2' onClick={onSubmit}>
                            {
                                changeLoading ? <Spinner size="sm" className="m-auto text-center" color="light" /> : t("Update Name")
                            }
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default ChangeWorkspaceName
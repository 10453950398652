import {
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_SUCCESS,
  CHANGE_SUBSCRIPTION,
  CHANGE_SUBSCRIPTION_SUCCESS,
  LIST_BILLINGS,
  LIST_BILLINGS_SUCCESS,
  LIST_PLANS,
  LIST_PLANS_SUCCESS,
  PAY_SUBSCRIPTION,
  PAY_SUBSCRIPTION_SUCCESS,
  SELECT_PLAN,
  SELECT_PLAN_SUCCESS
} from "./actionTypes"

const initialState = {
  plans: [],
  plan: null
}

const subscription = (state = initialState, action) => {
  switch (action.type) {
    case LIST_PLANS:
      return { ...state }
    case LIST_PLANS_SUCCESS:
      return { ...state, plans: action.payload }
    case SELECT_PLAN:
      return { ...state }
    case SELECT_PLAN_SUCCESS:
      return { ...state, plan: action.payload }
    default:
      return state
  }
}

export default subscription

import { Button, Dialog, Input, Menu, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { getters, useGlobalContext } from "../GlobalContext/GlobalContext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./SubHeader.css";
import "../global.css";
import classNames from "classnames";
import { AddCircleOutline, SearchOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { addProject, listProjects } from "../../../../store/actions";
import { compareDates, getCompleteDateList, getEndDate, getStartDate } from "../utils/helpers";
import { checkIfLoading } from "../../../../store/functions";
import { ADD_PROJECT, LIST_PROJECTS } from "../../../../store/projectManagement/actionTypes";
import { Spinner } from "reactstrap";
import { useLocation } from "react-router-dom";

const SubHeader = ({ search, setSearch, gridRef }) => {
   const [state, dispatch] = useGlobalContext();
   const dispatchRedux = useDispatch()
   const [projectName, setProjectName] = useState("");
   const [showMenu, setShowMenu] = useState(false);
   const menuLinkRef = useRef(null);
   const activeProject = getters.getActiveProject(state);
   const location = useLocation()

   const { projects, listLoading, addLoading, collaborators, manager, workspace } = useSelector(state => ({
      projects: state?.project?.projects,
      listLoading: checkIfLoading(state, LIST_PROJECTS),
      addLoading: checkIfLoading(state, ADD_PROJECT),
      collaborators: state.workspace?.workspace?.collaborators,
      manager: state?.workspace?.workspace?.manager,
      workspace: state?.workspace?.workspace?._id
   }))

   const getAllDates = () => {
      const { endDate, startDate } = getters.getActiveProject(state);
      const dateList = getCompleteDateList(startDate, endDate);
      return dateList;
   };

   const allDates = useMemo(() => getAllDates(), [getAllDates]);

   const selectProject = (id) => {
      dispatch({
         setState: {
            allUsers: collaborators
         }
      })
      if (id === "new") {
         dispatch({ openProjectDialog: undefined });
      } else {
         dispatch({ selectProjectById: id });
      }
   };

   const createNewProject = (func) => {
      dispatchRedux(addProject({ name: projectName }, func))
   };

   useEffect(() => {
      dispatchRedux(listProjects(search))
   }, [search, workspace])

   useEffect(() => {
      dispatch({
         setState: {
            allUsers: [
               ...collaborators,
               manager
            ]
         }
      })
   }, [])

   const getColNumber = (date) => {
      const allDates = getCompleteDateList(activeProject.startDate, activeProject.endDate);
      return allDates.findIndex((x) => compareDates(new Date(date), x)) + 1;
   }

   useEffect(() => {
      let projectId = location.search?.split('?project=')[1]
      projectId = projectId && projectId?.length > 10 ? projectId : null

      console.log(projects[0])

      if (projects && projects?.length > 0) {
         let newProjects = projects?.map(p => {
            return {
               ...p,
               startDate: getStartDate(),
               endDate: getEndDate(),
               links: [],
               users: [
                  ...p.users?.map((u, i) => {
                     return {
                        ...u,
                        noOfRows: 1, 
                        startRow: i + 1,
                        endRow: i + 2
                     }
                  }),
                  ...[
                     { id: "-1", img: "", name: "", noOfRows: 8, startRow: 5, endRow: 13, email: "" },
                  ]
               ],
               tasks: [
                  ...p?.tasks?.map((t,i) => {
                     const userIndex = p?.users?.findIndex(u => u.id === t?.user?._id)
                     return {
                        ...t,
                        startCol: getColNumber(t?.startDate),
                        endCol: getColNumber(t?.endDate),
                        row: p.users.slice(0, userIndex).reduce((ac, a) => ac + 1, 0) + 1,
                     }
                  })
               ],
            }
         })

         const users = newProjects[0]?.users?.map(u => u?._id)
         let allUsers = collaborators?.filter(c => !users?.includes(c?._id))
         allUsers = [ ...allUsers, manager ]
         
         dispatch({ setState: { projects: newProjects, allUsers }, selectProjectById: projectId || newProjects[0]?._id })
      } else {
         dispatch({
            setState: {
               projects: [
                  {
                     id: "1",
                     startDate: getStartDate(),
                     endDate: getEndDate(),
                     links: [],
                     name: "No projects found",
                     tasks: [],
                     users: [
                        { id: "-1", img: "", name: "Unassigned", noOfRows: 8, startRow: 5, endRow: 13, email: "" },
                        { id: "-1", img: "", name: "Unassigned", noOfRows: 8, startRow: 5, endRow: 13, email: "" },
                        { id: "-1", img: "", name: "Unassigned", noOfRows: 8, startRow: 5, endRow: 13, email: "" },
                        { id: "-1", img: "", name: "Unassigned", noOfRows: 8, startRow: 5, endRow: 13, email: "" }
                     ],
                  }
               ]
            }, selectProjectById: '1'
         })
      }
   }, [projects])

   return (
      <div className="sub-header">
         <Dialog
            open={state.newProjectDialog}
            onClose={() => dispatch({ setState: { newProjectDialog: false } })}
            maxWidth="md"
         >
            <div className="project-dialog">
               <input
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
                  onKeyUp={(e) => e.key === "Enter" && createNewProject}
                  placeholder="Enter Project Name"
               />
               <div className="footer-wrapper">
                  <span slot="footer" className="dialog-footer">
                     <div className="btn-cancel" onClick={() => dispatch({ setState: { newProjectDialog: false } })}>
                        Cancel
                     </div>
                     <div
                        className="btn-save"
                        onClick={() => {
                           createNewProject(() => dispatch({ setState: { newProjectDialog: false } }));
                        }}
                     >
                        {
                           addLoading ? <Spinner size={'sm'} color="light" /> : "Save"
                        }
                     </div>
                  </span>
               </div>
            </div>
         </Dialog>
         {
            activeProject ? <div className="dropdown-link" onClick={() => setShowMenu(!showMenu)} ref={menuLinkRef}>
               {activeProject.name}
               <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
            </div> : ""
         }
         <div>
            <button className="btn click-btn orange-btn" onClick={() => {
               const col = allDates.findIndex((x) => compareDates(x, new Date()));
               gridRef.current.scrollLeft = col * 38
            }}>Today</button>
         </div>
         <Menu
            PaperProps={{ style: { width: 400 }, className: "project-dropdown-menu" }}
            open={showMenu}
            anchorEl={menuLinkRef.current}
            onClose={() => setShowMenu(false)}
         >
            {listLoading ? 'Loading...' : projects.map((project) => (
               <MenuItem
                  className="dropdown-item"
                  onClick={() => {
                     selectProject(project.id);
                     setShowMenu(false);
                  }}
               >
                  <div className={classNames("item-content", { active: project._id === activeProject.id })}>
                     <div>{project.name}</div>

                     {project.id === activeProject?.id && (
                        <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path
                              d="M17.9578 20.9996H2.95781V5.99959H10.275L13.275 2.99959H2.95781C1.34297 2.99959 0 4.34256 0 5.99959V20.9996C0 22.6566 1.34297 23.9996 2.95781 23.9996H17.9156C19.5722 23.9996 20.8734 22.6566 20.8734 20.9996L20.8733 10.6824L17.9154 13.6819V20.9996H17.9578ZM23.3391 1.97725L22.0205 0.658188C21.1416 -0.220718 19.717 -0.220718 18.8386 0.658188L17.0283 2.46897L21.5287 6.96944L23.3391 5.15866C24.2203 4.28069 24.2203 2.85616 23.3391 1.97725ZM6.90469 12.8621L6.01219 17.3255C5.93353 17.7191 6.28055 18.0662 6.67406 17.9874L11.138 17.0949C11.3558 17.0513 11.5557 16.9443 11.7127 16.7873L20.4689 8.03106L15.9684 3.53059L7.20938 12.2902C7.05469 12.4449 6.94688 12.6465 6.90469 12.8621Z"
                              fill="#0F393B"
                           />
                        </svg>
                     )}
                  </div>
               </MenuItem>
            ))}
            <MenuItem
               className="dropdown-item create-new"
               onClick={() => {
                  selectProject("new");
                  setShowMenu(false);
               }}
            >
               <div className={classNames("item-content")} style={{ justifyContent: "start", gap: "0.5rem" }}>
                  <AddCircleOutline style={{ width: "18px" }}></AddCircleOutline>
                  <div>Create new project</div>
               </div>
            </MenuItem>
         </Menu>
         <div className="right">
            <div className="imgs">
               {
                  activeProject ? activeProject.users.map((user, i) =>
                     user?.id !== "-1" ? (
                        <img
                           src={user?.profilePicture || `${process.env.PUBLIC_URL}/assets/images/avatar.png`} onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = `${process.env.PUBLIC_URL}/assets/images/avatar.png`;
                           }}
                           alt=""
                           style={{
                              zIndex: activeProject.users.length - i,
                           }}
                        />
                     ) : null
                  ) : "No users found"
               }
            </div>
            <Input onInput={(e) => setSearch(e.target.value)} startAdornment={<SearchOutlined></SearchOutlined>} disableUnderline className="search-input"></Input>
         </div>
      </div>
   );
};

export default SubHeader;
import React, { useEffect, useRef, useState } from 'react';
import { render } from 'react-dom'

import EmailEditor from 'react-email-editor';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addEmailTemplate, updateEmailTemplate } from '../../../../store/actions';

const CreateNewEmail = ({ toggle, template }) => {
    const emailEditorRef = useRef(null);
    const [name, setName] = useState(null)
    const dispatch = useDispatch()
    const [t] = useTranslation()

    useEffect(() => {
        if(template) setName(template?.name)
    }, [template])

    const exportHtml = () => {
        if(name) {
            emailEditorRef.current.editor.exportHtml((data) => {
                const { design, html } = data;
                if(!template) dispatch(addEmailTemplate({ html, name, design }, toggle))
                else dispatch(updateEmailTemplate(template?._id, { html, name, design }, toggle))
            });
        }
    };

    console.log(template)

    const onLoad = () => {
        if(template?.design) {
            console.log({ template })
            setTimeout(() => {
                const templateJson = template.design;
            emailEditorRef.current.editor.loadDesign(templateJson);
            }, 2000)
        }
    }

    const onReady = () => {
        // editor is ready
        console.log('onReady');
    };

    return (
        <div>
            <div className='d-flex align-items-center justify-content-between mb-2'>
                <div className='col-md-3'>
                    <input className='form-control custom-border' placeholder={t('Enter name')} defaultValue={template?.name} onChange={e => setName(e.target.value)} />
                </div>
                <div className='col-md-3'>
                    <button className='btn click-btn btn-block green-btn' onClick={exportHtml}>
                        {t('Save Template')}
                    </button>
                </div>
            </div>
            <EmailEditor
                ref={emailEditorRef}
                onLoad={onLoad}
                onReady={onReady}
            />
        </div>
    );
};

export default CreateNewEmail
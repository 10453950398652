import React, { useEffect } from 'react'
import AvatarDropdown from './AvatarDropdown'
import LangDropdown from './LangDropdown'
import Notification from './Notification'
import DomianDropdown from './DomianDropdown'
import { getUserData, getWorkspace, listWorkspaces, verifyUser } from '../../../store/actions'
import { useDispatch, useSelector } from 'react-redux'

function NavbarIndex() {
  const dispatch = useDispatch()

  const { token, workspace } = useSelector(state => ({
    token: state.auth.token,
    workspace: state.workspace.workspace
  }))

  useEffect(() => {
    if (token) {
      dispatch(getUserData()),
        dispatch(listWorkspaces())
      if (workspace) dispatch(getWorkspace())
    }
  }, [])

  return (
    <nav className="navbar d-flex">
      <DomianDropdown />
      <Notification />
      <LangDropdown />
      <AvatarDropdown />
    </nav>
  )
}

export default NavbarIndex
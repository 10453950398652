import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom'
import { checkIfLoading, useQuery } from "../../store/functions";
import { Spinner } from "reactstrap";
import { listPlans, selectPlan } from "../../store/subscription/actions";
import { LIST_PLANS } from "../../store/subscription/actionTypes";
import { useTranslation } from "react-i18next";

const ChoosePlan = () => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const [plan, setPlan] = useState(null)
    const [t] = useTranslation()

    const { plans, plansLoading, workspace, user } = useSelector(state => ({
        plans: state?.subscription?.plans,
        plansLoading: checkIfLoading(state, LIST_PLANS),
        workspace: state?.workspace?.workspace?._id,
        user: state?.auth?.user
    }))

    useEffect(() => {
        if(!user) return history('/login')
        if(!workspace) return history('/select-workspace')
        else dispatch(listPlans())
    }, [])

    const renderPlans = () => {
        if (plansLoading) return <Spinner size="md" className="m-auto text-center" color="success" />
        if (plans && plans?.length > 0) {
            return plans?.map(p => (
                <div className="col-md-4 mb-3">
                    <div className={`card plan-card ${plan === p?._id ? 'active' : ""}`} onClick={() => setPlan(p?._id)}>
                        <h3 className="text-center">{String(p?.type[0]).toUpperCase() + String(p?.type).substring(1)}</h3>
                        <hr />
                        <div className="d-flex align-items-center mb-2 justify-content-between">
                            <h5 className="m-0">{t('Key features')}</h5>
                            <ul className="list-group plan-keys list-group-horizontal-lg">
                                {p.features > 0 ? Array(p.features).fill(p.features).map(p => <li className="bg-orange"></li>) : ""}
                                {p.features > 0 ? Array(5 - p.features).fill(p.features).map(p => <li></li>) : ""}
                            </ul>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <span>{t('SEO Audit')}</span>
                            <span>{p?.seoPages} {t('pages')}</span>
                        </div>
                        <ul className="list-unstyled mt-3">
                            <li className="d-flex align-items-center justify-content-between mb-1">
                                <span>{t('Keyword Research')}</span>
                                {p?.keywordSearch ? <span><i class="fas fa-check-square"></i></span> : ""}
                            </li>
                            <li className="d-flex align-items-center justify-content-between mb-1">
                                <span>{t('Content Marketing')}</span>
                                {p?.contentMarketing ? <span><i class="fas fa-check-square"></i></span> : ""}
                            </li>
                            <li className="d-flex align-items-center justify-content-between mb-1">
                                <span>{t('Competitor Analysis')}</span>
                                {p?.competitorAnalysis ? <span><i class="fas fa-check-square"></i></span> : ""}
                            </li>
                            <li className="d-flex align-items-center justify-content-between mb-1">
                                <span>{t('Backlink')}</span>
                                {p?.backlink ? <span><i class="fas fa-check-square"></i></span> : ""}
                            </li>
                            <li className="d-flex align-items-center justify-content-between mb-1">
                                <span>{t('Marketing Automation')}</span>
                                {p?.marketingAutomation ? <span><i class="fas fa-check-square"></i></span> : ""}
                            </li>
                        </ul>
                        <div className="plan-price">
                            <hr />
                            <span>€{p?.amount}</span>.00 {t('per-month')}
                        </div>
                    </div>
                </div>
            ))
        }
    }

    return (
        <div className="container plans-page text-center">
            <h2>{t('Choose a plan that suits you')}</h2>
            <div className="row mt-3">
                {renderPlans()}
            </div>
            <div className="row justify-content-center mt-5">
                <button onClick={() => dispatch(selectPlan(plan, history))} className="btn click-btn col-md-3 btn-block green-btn px-4">Start now</button>
            </div>
        </div>
    );
}

export default ChoosePlan;
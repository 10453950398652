import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { getDomain, setError, updateEmail } from '../../../../store/actions';
import { UPDATE_EMAIL } from '../../../../store/auth/actionTypes';
import { checkIfLoading } from '../../../../store/functions';
import { GET_DOMAIN } from '../../../../store/workspace/actionTypes';

function AddDomainModal({ modal, toggle }) {
    const dispatch = useDispatch()
    const history = useNavigate()
    const [t] = useTranslation()

    const { getDomainLoading, domain } = useSelector(state => ({
        getDomainLoading: checkIfLoading(state, GET_DOMAIN),
        domain: state?.workspace?.domain
    }))

    // useEffect(() => {
    //     dispatch(getDomain())
    // }, [])

    return (
        <>
            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader className='px-4' toggle={toggle}></ModalHeader>
                <ModalBody className='pt-1 pb-3'>
                    {
                        getDomainLoading ? <Spinner size={'md'} color="success" /> : domain ? <div>
                            <h4>{t('Integrate your domain')}</h4>
                            <ol>
                                <li>{t('Open DNS Records tab in your host dashboard.')}</li>
                                <li>{t('Click the Add to add a new record. We will start with adding the CNAME record.')}</li>
                                <li>{t('We will start by adding the CNAME record. For the Type, select CNAME from the drop-down.')}</li>
                                <li>{t('You can generally leave the TTL as it is.')}</li>
                                <li>{t('In the Name field, enter')} <code>pm-bounces</code>
                                    <br/>
                                    {t('and in the Hostname field enter')} <code>pm.mtasv.net</code>
                                    <br/>{t('and then')} <b>{t('click Create')}</b>.</li>
                                <li>{t('Click Add again to add another record for DKIM. For the Type, select TXT from the drop-down.')}</li>
                                <li>
                                    {t('In the Name field, enter the Hostname value')}
                                    <br/>
                                    <code>{domain?.host}</code>
                                    <br/> {t('Then in the Text Value field, enter the TXT value')}
                                    <br/> 
                                    <code>{domain?.value}</code>.
                                </li>
                                <li><b>{t('Click Create')}</b></li>
                            </ol>
                        </div> : t("No data to be shown")
                    }
                </ModalBody>
            </Modal>
        </>
    )
}

export default AddDomainModal
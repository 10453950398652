import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

function SideDropdown({ pages, setPage, page }) {
  // ** states    
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [t] = useTranslation()

  // ** function to handle toggle of dropdown
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const renderPages = () => {
    if (pages && pages?.length > 0) {
      return pages?.map(p => {
        return <DropdownItem key={p?.url} onClick={() => setPage(p)}>{p?.url}</DropdownItem>
      })
    }
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret>
        {page?.url || t('site-wide-info')}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => setPage(null)}>{t('site-wide')}</DropdownItem>
        {renderPages()}
      </DropdownMenu>
    </Dropdown>
  );
}

export default SideDropdown
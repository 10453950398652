export const issues = {
    "onpage_score": "On Page score",
    "no_description": "On Page score",
    "size_greater_than_3mb": "On Page score",
    "broken_links": "On Page score",
    "duplicate_title": "On Page score",
    "duplicate_description": "On Page score",
    "duplicate_content": "On Page score",
    "broken_resources": "On Page score",
    "canonical": "On Page score",
    "duplicate_meta_tags": "On Page score",
    "frame": "On Page score",
    "large_page_size": "On Page score",
    "irrelevant_description": "On Page score",
    "irrelevant_meta_keywords": "On Page score",
    "is_https": "On Page score",
    "is_http": "On Page score",
    "title_too_long": "On Page score",
    "low_content_rate": "On Page score",
    "small_page_size": "On Page score",
    "no_h1_tag": "On Page score",
    "recursive_canonical": "On Page score",
    "no_favicon": "On Page score",
    "no_image_alt": "On Page score",
    "no_image_title": "On Page score",
    "seo_friendly_url": "On Page score",
    "seo_friendly_url_characters_check": "On Page score",
    "seo_friendly_url_dynamic_check": "On Page score",
    "seo_friendly_url_keywords_check": "On Page score",
    "seo_friendly_url_relative_length_check": "On Page score",
    "title_too_short": "On Page score",
    "no_content_encoding": "On Page score",
    "high_waiting_time": "On Page score",
    "high_loading_time": "On Page score",
    "is_redirect": "On Page score",
    "is_broken": "On Page score",
    "is_4xx_code": "On Page score",
    "is_5xx_code": "On Page score",
    "is_www": "On Page score",
    "no_doctype": "On Page score",
    "no_encoding_meta_tag": "On Page score",
    "high_content_rate": "On Page score",
    "low_character_count": "On Page score",
    "high_character_count": "On Page score",
    "low_readability_rate": "On Page score",
    "irrelevant_title": "On Page score",
    "deprecated_html_tags": "On Page score",
    "duplicate_title_tag": "On Page score",
    "no_title": "On Page score",
    "flash": "On Page score",
    "lorem_ipsum": "On Page score",
    "canonical_to_broken": "On Page score",
    "canonical_to_redirect": "On Page score",
    "has_links_to_redirects": "On Page score",
    "is_orphan_page": "On Page score",
    "has_meta_refresh_redirect": "On Page score",
    "meta_charset_consistency": "On Page score",
    "has_html_doctype": "On Page score",
    "https_to_http_links": "On Page score",
    "has_render_blocking_resources": "On Page score",
    "redirect_chain": "On Page score",
    "canonical_chain": "On Page score",
    "is_link_relation_conflict": "On Page score",
    "links_external": "On Page score",
    "links_internal": "On Page score",
    "links_relation_conflict": "On Page score",
    "redirect_loop": "On Page score",
    "has_misspelling": "On Page score"
}
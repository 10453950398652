import {
  START_UPLOAD,
  FINISH_UPLOAD,
  START_UPLOAD_SUCCESS
} from "./actionTypes"

const initialState = {
  error: null
}

const upload = (state = initialState, action) => {
  switch (action.type) {
    case START_UPLOAD:
      return { ...state }
    case START_UPLOAD_SUCCESS:
      return { ...state, }
    case FINISH_UPLOAD:
      return { ...state, }
    default:
      return state
  }
}

export default upload
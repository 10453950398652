import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { listAnalytics, selectWorkspaceAnalytics } from '../../../../store/actions';
import { LIST_ANALYTICS } from '../../../../store/auth/actionTypes';
import { checkIfLoading } from '../../../../store/functions';
import { SELECT_WORKSPACE_ANALYTICS } from '../../../../store/workspace/actionTypes';

function ChooseAnalyticsModal({ modal, toggle }) {
    const dispatch = useDispatch()
    const history = useNavigate()
    const [account, setAccount] = useState(null)
    const [t] = useTranslation()

    const { analytics, analyticsLoading, chooseLoading } = useSelector(state => ({
        analytics: state.auth.analytics,
        analyticsLoading: checkIfLoading(state, LIST_ANALYTICS),
        chooseLoading: checkIfLoading(state, SELECT_WORKSPACE_ANALYTICS)
    }))

    useEffect(() => {
        if(modal) dispatch(listAnalytics())
    }, [modal])

    const renderAnalytics = () => {
        if (analytics && analytics?.length) {
            return analytics?.map(a => {
                return <a className={`result btn text-left d-block ${account === a.id ? 'active' : ""}`} onClick={() => setAccount(a.id)}>
                    {a.name}
                </a>
            })
        } else {
            return 'No accounts found'
        }
    }

    const onAccountSubmit = () => {
        dispatch(selectWorkspaceAnalytics(account, () => {
            toggle()
        }))
    }

    return (
        <Modal className='search-result-modal analytics-modal' isOpen={modal} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle}>
                <h4>{t('Choose Analytics Account')}</h4>
            </ModalHeader>
            <ModalBody>
                {
                    analyticsLoading ? <div className='text-center my-3'><Spinner size="lg" className="m-auto text-center" color="success" /></div> : <>
                        <div className='results'>
                            {renderAnalytics()}
                        </div>
                    </>
                }
                <div className='mt-2'>
                    <button onClick={onAccountSubmit} className='btn btn-block green-btn click-btn'>
                        {
                            chooseLoading ? <Spinner size="sm" className="m-auto text-center" color="light" /> : t('Submit')
                        }
                    </button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ChooseAnalyticsModal
import api from '../apis/api'
import { tokenConfig } from '../config'

export const GetTechnicalAnalysisAPI = (query, state) => {
  return new Promise((res, rej) => {
    api.get(`/technical-analysis/data?${query ? query : ''}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => {
        if (err?.response?.data?.error?.code === 406) {
          rej(err?.response?.data?.error?.message || 'Error, Please try again later')
        }
      })
  })
}

export const UpdateTechnicalAnalysisAPI = (query, state) => {
  return new Promise((res, rej) => {
    api.get(`/technical-analysis/data?${query ? query : ''}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => {
        if (err?.response?.data?.error?.code === 406) {
          rej(err?.response?.data?.error?.message || 'Error, Please try again later')
        }
      })
  })
}
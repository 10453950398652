export const GET_ANALYTICS = 'GET_ANALYTICS'
export const GET_ANALYTICS_SUCCESS = 'GET_ANALYTICS_SUCCESS'

export const LIST_CUSTOMERS = 'LIST_CUSTOMERS'
export const LIST_CUSTOMERS_SUCCESS = 'LIST_CUSTOMERS_SUCCESS'

export const LIST_TAGS = 'LIST_TAGS'
export const LIST_TAGS_SUCCESS = 'LIST_TAGS_SUCCESS'

export const ADD_TAG = 'ADD_TAG'
export const ADD_TAG_SUCCESS = 'ADD_TAG_SUCCESS'

export const ADD_CUSTOMER = 'ADD_CUSTOMER'
export const ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS'

export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS'

export const DELETE_CUSTOMER = 'DELETE_CUSTOMER'
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS'

export const ADD_TAG_TO_CUSTOMER = 'ADD_TAG_TO_CUSTOMER'
export const ADD_TAG_TO_CUSTOMER_SUCCESS = 'ADD_TAG_TO_CUSTOMER_SUCCESS'

export const REMOVE_TAG_FROM_CUSTOMER = 'REMOVE_TAG_FROM_CUSTOMER'
export const REMOVE_TAG_FROM_CUSTOMER_SUCCESS = 'REMOVE_TAG_FROM_CUSTOMER_SUCCESS'

export const LIST_TOP_5_TAGS = 'LIST_TOP_5_TAGS'
export const LIST_TOP_5_TAGS_SUCCESS = 'LIST_TOP_5_TAGS_SUCCESS'

export const LIST_TAG_CUSTOMERS = 'LIST_TAG_CUSTOMERS'
export const LIST_TAG_CUSTOMERS_SUCCESS = 'LIST_TAG_CUSTOMERS_SUCCESS'

export const GET_CUSTOMER = 'GET_CUSTOMER'
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS'

export const UPLOAD_CUSTOMERS = 'UPLOAD_CUSTOMERS'
export const UPLOAD_CUSTOMERS_SUCCESS = 'UPLOAD_CUSTOMERS_SUCCESS'

export const ADD_CUSTOMER_TASK = 'ADD_CUSTOMER_TASK'
export const ADD_CUSTOMER_TASK_SUCCESS = 'ADD_CUSTOMER_TASK_SUCCESS'

export const ADD_CUSTOMER_COMMENT = 'ADD_CUSTOMER_COMMENT'
export const ADD_CUSTOMER_COMMENT_SUCCESS = 'ADD_CUSTOMER_COMMENT_SUCCESS'

export const REMOVE_CUSTOMER_COMMENT = 'REMOVE_CUSTOMER_COMMENT'
export const REMOVE_CUSTOMER_COMMENT_SUCCESS = 'REMOVE_CUSTOMER_COMMENT_SUCCESS'

export const GET_TIMELINE = 'GET_TIMELINE'
export const GET_TIMELINE_SUCCESS = 'GET_TIMELINE_SUCCESS'

export const GET_EMAILS = 'GET_EMAILS'
export const GET_EMAILS_SUCCESS = 'GET_EMAILS_SUCCESS'

export const ADD_SPENDING = 'ADD_SPENDING'
export const ADD_SPENDING_SUCCESS = 'ADD_SPENDING_SUCCESS'

export const LIST_EMAIL_TEMPLATES = 'LIST_EMAIL_TEMPLATES'
export const LIST_EMAIL_TEMPLATES_SUCCESS = 'LIST_EMAIL_TEMPLATES_SUCCESS'

export const ADD_EMAIL_TEMPLATE = 'ADD_EMAIL_TEMPLATE'
export const ADD_EMAIL_TEMPLATE_SUCCESS = 'ADD_EMAIL_TEMPLATE_SUCCESS'

export const GET_EMAIL_TEMPLATE = 'GET_EMAIL_TEMPLATE'
export const GET_EMAIL_TEMPLATE_SUCCESS = 'GET_EMAIL_TEMPLATE_SUCCESS'

export const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE'
export const UPDATE_EMAIL_TEMPLATE_SUCCESS = 'UPDATE_EMAIL_TEMPLATE_SUCCESS'

export const DELETE_EMAIL_TEMPLATE = 'DELETE_EMAIL_TEMPLATE'
export const DELETE_EMAIL_TEMPLATE_SUCCESS = 'DELETE_EMAIL_TEMPLATE_SUCCESS'

export const ADD_MEDIA = 'ADD_MEDIA'
export const ADD_MEDIA_SUCCESS = 'ADD_MEDIA_SUCCESS'

export const LIST_MEDIA = 'LIST_MEDIA'
export const LIST_MEDIA_SUCCESS = 'LIST_MEDIA_SUCCESS'

export const REMOVE_MEDIA = 'REMOVE_MEDIA'
export const REMOVE_MEDIA_SUCCESS = 'REMOVE_MEDIA_SUCCESS'
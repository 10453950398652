import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import AreaChart from '../../../../components/charts/AreaChart'

function KeywordTracking({ data }) {
  const [keyword, setKeyword] = useState(null)
  const [chart, setChart] = useState([])
  const history = useNavigate()
  const [t] = useTranslation()

  useEffect(() => {
    let keywordData

    if (keyword) {
      keywordData = data?.find(e => e?.keyword === keyword?.keyword && e?.searchVolume === keyword?.volume)
    } else {
      keywordData = data[0]
      setKeyword({
        volume: keywordData?.searchVolume,
        keyword: keywordData?.keyword
      })
    }

    const chart = keywordData?.monthlySearches?.map(e => {
      return {
        label: e.month + '/' + e.year,
        value: e.search_volume
      }
    })

    setChart(chart || [])
  }, [data, keyword])

  const renderTabs = () => {
    if (data && data?.length > 0) {
      return data?.slice(0, 5)?.map(e => {
        return <a onClick={() => setKeyword({
          volume: e.searchVolume,
          keyword: e.keyword
        })} className={`btn ${e.searchVolume === keyword?.volume ? 'active' : ""}`} >{e.keyword}</a>
      })
    }
  }

  return (
    <div className='keywords-tracking row'>
      <div className='col-12'>
        <h2>{t('Keyword Tracking')}</h2>
        <p className='description'>{t('See how each keyword positioned for this page ranked time by time and take action to improve it')}</p>
        {
          data?.length > 0 ? <>
            <div className='tabs'>
              {renderTabs()}
            </div>
            <div className='card'>
              <AreaChart height={280} name="Keyword Tracking" data={chart} curveType="straight" showYaxis={true} />
            </div>
          </> : <span>{t("it seems this page is not ranking on Google now")}</span>
        }
      </div>
    </div>
  )
}

export default KeywordTracking 
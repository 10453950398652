import {
  LIST_WORKSPACES,
  LIST_WORKSPACES_SUCCESS,
  ADD_WORKSPACE,
  ADD_WORKSPACE_SUCCESS,
  GET_WORKSPACE,
  GET_WORKSPACE_SUCCESS,
  UPDATE_WORKSPACE,
  UPDATE_WORKSPACE_SUCCESS,
  DELETE_WORKSPACE,
  DELETE_WORKSPACE_SUCCESS,
  INVITE_COLLABORATOR,
  INVITE_COLLABORATOR_SUCCESS,
  REMOVE_COLLABORATOR,
  REMOVE_COLLABORATOR_SUCCESS,
  REGISTER_DOMAIN,
  REGISTER_DOMAIN_SUCCESS,
  ADD_SENDER_EMAIL,
  ADD_SENDER_EMAIL_SUCCESS,
  LIST_WORKSPACE_LANGUAGES,
  LIST_WORKSPACE_REGIONS,
  LIST_WORKSPACE_LANGUAGES_SUCCESS,
  LIST_WORKSPACE_REGIONS_SUCCESS,
  LIST_WORKSPACE_BILLINGS,
  LIST_WORKSPACE_BILLINGS_SUCCESS,
  UPDATE_WORKSPACE_LOGO,
  UPDATE_WORKSPACE_LOGO_SUCCESS,
  CANCEL_WORKSPACE_SUBSCRIPTION,
  CANCEL_WORKSPACE_SUBSCRIPTION_SUCCESS,
  SELECT_WORKSPACE_ANALYTICS,
  SELECT_WORKSPACE_ANALYTICS_SUCCESS,
  DISCONNECT_GOOGLE,
  DISCONNECT_GOOGLE_SUCCESS,
  UPDATE_WORKSPACE_BUSINESS_SUCCESS,
  GET_DOMAIN,
  GET_DOMAIN_SUCCESS,
  GET_WORKSPACE_CONNECTIONS,
  GET_WORKSPACE_CONNECTIONS_SUCCESS,
  SELECT_WORKSPACE_SEARCH_CONSOLE,
  SELECT_WORKSPACE_SEARCH_CONSOLE_SUCCESS
} from "./actionTypes"

export const listWorkspaces = () => {
  return {
    type: LIST_WORKSPACES,
    payload: {},
  }
}

export const listWorkspacesSuccess = (data) => {
  return {
    type: LIST_WORKSPACES_SUCCESS,
    payload: data
  }
}

export const listWorkspaceBillings = (query) => {
  return {
    type: LIST_WORKSPACE_BILLINGS,
    payload: { query },
  }
}

export const listWorkspaceBillingsSuccess = (data) => {
  return {
    type: LIST_WORKSPACE_BILLINGS_SUCCESS,
    payload: data
  }
}

export const addWorkspace = (data, history) => {
  return {
    type: ADD_WORKSPACE,
    payload: { data, history },
  }
}

export const addWorkspaceSuccess = (data) => {
  return {
    type: ADD_WORKSPACE_SUCCESS,
    payload: data
  }
}

export const getWorkspace = (id, history) => {
  return {
    type: GET_WORKSPACE,
    payload: { id, history }
  }
}

export const getWorkspaceSuccess = (data) => {
  return {
    type: GET_WORKSPACE_SUCCESS,
    payload: data
  }
}

export const deleteWorkspace = (id, history, toggle) => {
  return {
    type: DELETE_WORKSPACE,
    payload: { id, history, toggle }
  }
}

export const deleteWorkspaceSuccess = () => {
  return {
    type: DELETE_WORKSPACE_SUCCESS,
    payload: null
  }
}

export const updateWorkspace = (id, data, toggle) => {
  return {
    type: UPDATE_WORKSPACE,
    payload: { id, data, toggle }
  }
}

export const updateWorkspaceSuccess = (data) => {
  return {
    type: UPDATE_WORKSPACE_SUCCESS,
    payload: data
  }
}

export const updateWorkspaceLogo = (image) => {
  return {
    type: UPDATE_WORKSPACE_LOGO,
    payload: { image }
  }
}

export const updateWorkspaceLogoSuccess = (data) => {
  return {
    type: UPDATE_WORKSPACE_LOGO_SUCCESS,
    payload: data
  }
}

export const inviteCollaborator = (data, toggle) => {
  return {
    type: INVITE_COLLABORATOR,
    payload: { data, toggle }
  }
}

export const inviteCollaboratorSuccess = (data) => {
  return {
    type: INVITE_COLLABORATOR_SUCCESS,
    payload: data
  }
}

export const removeCollaborator = (collaborator) => {
  return {
    type: REMOVE_COLLABORATOR,
    payload: { collaborator }
  }
}

export const removeCollaboratorSuccess = (data) => {
  return {
    type: REMOVE_COLLABORATOR_SUCCESS,
    payload: data
  }
}

export const registerDomain = () => {
  return {
    type: REGISTER_DOMAIN,
    payload: {}
  }
}

export const registerDomainSuccess = (domain) => {
  return {
    type: REGISTER_DOMAIN_SUCCESS,
    payload: domain
  }
}

export const getDomain = (refresh) => {
  return {
    type: GET_DOMAIN,
    payload: { refresh }
  }
}

export const getDomainSuccess = (domain) => {
  return {
    type: GET_DOMAIN_SUCCESS,
    payload: domain
  }
}

export const selectWorkspaceAnalytics = (id, toggle) => {
  return {
    type: SELECT_WORKSPACE_ANALYTICS,
    payload: { id, toggle }
  }
}

export const selectWorkspaceAnalyticsSuccess = (data) => {
  return {
    type: SELECT_WORKSPACE_ANALYTICS_SUCCESS,
    payload: data
  }
}

export const selectWorkspaceSearchConsole = (id, toggle) => {
  return {
    type: SELECT_WORKSPACE_SEARCH_CONSOLE,
    payload: { id, toggle }
  }
}

export const selectWorkspaceSearchConsoleSuccess = (data) => {
  return {
    type: SELECT_WORKSPACE_SEARCH_CONSOLE_SUCCESS,
    payload: data
  }
}

export const disconnectGoogle = (type) => {
  return {
    type: DISCONNECT_GOOGLE,
    payload: { type }
  }
}

export const disconnectGoogleSuccess = (data) => {
  return {
    type: DISCONNECT_GOOGLE_SUCCESS,
    payload: data
  }
}

export const getWorkspaceConnections = () => {
  return {
    type: GET_WORKSPACE_CONNECTIONS,
    payload: {}
  }
}

export const getWorkspaceConnectionsSuccess = (data) => {
  return {
    type: GET_WORKSPACE_CONNECTIONS_SUCCESS,
    payload: data
  }
}

export const cancelSubscription = (id) => {
  return {
    type: CANCEL_WORKSPACE_SUBSCRIPTION,
    payload: { id }
  }
}

export const cancelSubscriptionSuccess = () => {
  return {
    type: CANCEL_WORKSPACE_SUBSCRIPTION_SUCCESS,
    payload: {}
  }
}

export const listWorkspaceLanguages = () => {
  return {
    type: LIST_WORKSPACE_LANGUAGES,
    payload: {}
  }
}

export const listWorkspaceLanguagesSuccess = (data) => {
  return {
    type: LIST_WORKSPACE_LANGUAGES_SUCCESS,
    payload: data
  }
}

export const listWorkspaceRegions = () => {
  return {
    type: LIST_WORKSPACE_REGIONS,
    payload: {}
  }
}

export const listWorkspaceRegionsSuccess = (data) => {
  return {
    type: LIST_WORKSPACE_REGIONS_SUCCESS,
    payload: data
  }
}

export const addSenderEmail = (data, toggle) => {
  return {
    type: ADD_SENDER_EMAIL,
    payload: { data, toggle }
  }
}

export const addSenderEmailSuccess = (data) => {
  return {
    type: ADD_SENDER_EMAIL_SUCCESS,
    payload: data
  }
}

export const updateWorkspaceBusiness = (business) => {
  return {
    type: UPDATE_WORKSPACE_BUSINESS_SUCCESS,
    payload: business
  }
}
import React from "react";
import { useTranslation } from "react-i18next";

function CompetitorAudience({ competitor }) {
    const [t] = useTranslation()

    const renderWebsites = () => {
        const websites = competitor?.data?.audience?.other_visited_websites
        if (websites && websites?.length > 0) {
            return websites?.map(w => {
                return <li className="pl-0 pt-2 border-bottom">
                    {w}
                </li>
            })
        } else {
            return <li>{t('No Websites found')}</li>
        }
    }

    const renderCategories = () => {
        const categories = competitor?.data?.audience?.top_categories
        if (categories && categories?.length > 0) {
            return categories?.map(w => {
                return <li className="pl-0 pt-2 border-bottom">
                    {w.title}
                </li>
            })
        } else {
            return <li>{t('No categories found')}</li>
        }
    }

    const renderTopics = () => {
        const topics = competitor?.data?.audience?.top_topics
        if (topics && topics?.length > 0) {
            return topics?.map(w => {
                return <li className="pl-0 pt-2 border-bottom">
                    {w}
                </li>
            })
        } else {
            return <li>{t('No topics found')}</li>
        }
    }

    return (
        <div className="card mt-3 competitors-audience">
            <div className="d-flex align-items-center justify-content-between">
                <div>
                    <h4>{t('Audience Interests')}</h4>
                    <span className="text-muted">
                        Audience interests reveal key details on the browsing interests of {competitor?.data?.site_url}'s visitors. {competitor?.data?.site_url}'s audience is
                        interested in Computers Electronics and Technology Social Media Networks & google.
                    </span>
                </div>
                {/* <div className="bg-gray rounded-10 p-3"><i class="fas fa-share-alt"></i></div> */}
            </div>
            <div className="row mt-3">
                <div className="col-md-4">
                    <div className="bg-gray rounded-10 p-3">
                        <h4>{t('Top categories')}</h4>
                        <ul className="list-unstyled">
                            {renderCategories()}
                        </ul>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="bg-gray rounded-10 p-3">
                        <h4>{t('Other Visited Websites')}</h4>
                        <ul className="list-unstyled">
                            {renderWebsites()}
                        </ul>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="bg-gray rounded-10 p-3">
                        <h4>{t('Top topics')}</h4>
                        <ul className="list-unstyled">
                            {renderTopics()}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompetitorAudience;
import { put, select, takeEvery } from "redux-saga/effects"
import { ACCEPT_INVITATION, AUTH_GOOGLE, CHANGE_PAYMENT_METHOD, DELETE_PAYMENT_METHOD, DELETE_USER, FORGET_PASSWORD, GET_PAYMENT_METHOD, GET_USER_DATA, LIST_ANALYTICS, LIST_SEARCH_CONSOLE_ACCOUNTS, LOGIN_USER, LOGOUT_USER, REGISTER_USER, RESET_PASSWORD, SEND_FEEDBACK, UPDATE_EMAIL, UPDATE_PASSWORD, UPDATE_USER_DATA, VERIFY_USER } from "./actionTypes"
import {
  forgetPasswordSuccess, getUserDataSuccess, listAnalyticsSuccess, listLanguagesSuccess,
  loginUserSuccess, logoutUserSuccess, resetPasswordSuccess, updateEmailSuccess,
  updatePasswordSuccess, updateUserDataSuccess, verifyUserSuccess, verifyUser, registerUserSuccess, logoutUser, getPyamentMethodSuccess, deletePaymentMethodSuccess, changePaymentMethodSuccess, acceptInvitationSuccess, listSearchConsoleAccountsSuccess
} from "./actions"
import { AcceptInvitationAPI, authGoogleAPI, ChangePaymentMethodAPI, DeletePaymentMethodAPI, deleteUserAPI, forgetPasswordAPI, GetPaymentMethodAPI, getUserDataAPI, listAnalyticsAccountAPI, listLanguagesAPI, listSearchConsoleAccountAPI, LoginAPI, RegisterAPI, resetPasswordAPI, SendFeedbackAPI, updateEmailAPI, updatePasswordAPI, updateProfileAPI, VerifyAPI } from "./authCalls"
import { setError, setSuccess, startAction, stopAction, getWorkspaceConnectionsSuccess, getWorkspace } from "../actions"
import { uploadImageSaga } from "../upload/saga"
import { START_UPLOAD } from "../upload/actionTypes"

function* loginUser({ type, payload: { user, history } }) {
  try {
    yield put(startAction(type))
    const result = yield LoginAPI(user)
    localStorage.setItem('token', result?.data?.data?.token)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(loginUserSuccess(result?.data?.data))
    const workspaces = result?.data?.data?.user?.workspaces
    console.log({ workspaces })
    if (workspaces?.length === 0) {
      history('/add-workspace')
    } else if (workspaces?.length === 1) {
      yield put(getWorkspace(workspaces[0]?._id, history))
    } else {
      history('/select-workspace')
    }
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* registerUser({ type, payload: { user, history } }) {
  try {
    console.log(user)
    yield put(startAction(type))
    const result = yield RegisterAPI(user)
    yield put(setSuccess(result?.data?.message))
    yield put(registerUserSuccess())
    // setTimeout(() => { history('/login') }, 3000)
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* googleAuth({ type, payload: { data, history, func } }) {
  try {
    yield put(startAction(type))
    const query = new URLSearchParams(data).toString()
    const state = yield select()
    const result = yield authGoogleAPI(query, state)
    localStorage.setItem('token', result?.data?.data?.token)
    if (result?.data?.data?.connections) {
      yield put(getWorkspaceConnectionsSuccess(result?.data?.data?.connections))
    }
    const workspaces = result?.data?.data?.user?.workspaces
    if (history) {
      yield put(setSuccess(result?.data?.message))
      yield put(loginUserSuccess(result?.data?.data))
      console.log({ workspaces })
      if (workspaces?.length === 0) {
        history('/add-workspace')
      } else if (workspaces?.length === 1) {
        yield put(getWorkspace(workspaces[0]?._id, history))
      } else {
        history('/select-workspace')
      }
    }
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* verifyUserSaga({ type, payload: { user, history } }) {
  try {
    yield put(startAction(type))
    const result = yield VerifyAPI(user)
    yield put(setSuccess(result.data?.message))
    history('/login')
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* getUserData({ type }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield getUserDataAPI(state)
    console.log(result)
    yield put(getUserDataSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    localStorage.removeItem('token')
    yield put(logoutUserSuccess())
  } finally {
    yield put(stopAction(type))
  }
}

function* updateUserData({ type, payload: { data, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    if (data.image) {
      const result = yield uploadImageSaga({
        type: START_UPLOAD,
        payload: {
          file: data.image,
          uploadType: "user"
        }
      })
      data.image = result
    }
    const result = yield updateProfileAPI(data, state)
    console.log(result)
    if (!data.language) yield put(setSuccess(result?.data?.message))
    yield put(updateUserDataSuccess(result?.data))
    if (toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError())
  } finally {
    yield put(stopAction(type))
  }
}

function* updatePassword({ type, payload: { data, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield updatePasswordAPI(data, state)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* forgetPasswordSaga({ type, payload: { email, history } }) {
  try {
    yield put(startAction(type))
    const result = yield forgetPasswordAPI({ email })
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(forgetPasswordSuccess())
    // history('/reset-password')
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* resetPasswordSaga({ type, payload: { data, history } }) {
  try {
    yield put(startAction(type))
    const result = yield resetPasswordAPI(data)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(resetPasswordSuccess())
    history('/login')
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* listLanguages({ type, payload: { data } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield listLanguagesAPI(data, state)
    console.log(result)
    yield put(listLanguagesSuccess(result?.data?.message))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* listAnalytics({ type, payload }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield listAnalyticsAccountAPI(state)
    console.log(result)
    yield put(listAnalyticsSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* listSearchAccounts({ type, payload }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield listSearchConsoleAccountAPI(state)
    console.log(result)
    yield put(listSearchConsoleAccountsSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* updateEmail({ type, payload: { data, toggle, history } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield updateEmailAPI(data, state)
    console.log(result)
    yield put(updateEmailSuccess(data?.email))
    yield put(setSuccess(result?.data?.message))
    toggle()
    yield put(logoutUser(history))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* deleteUser({ type, payload: { history, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield deleteUserAPI(state)
    yield put(setSuccess(result?.data?.message))
    yield put(logoutUser(history))
    if (toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* logoutUserSaga({ type, payload: { history } }) {
  try {
    yield put(startAction(type))
    localStorage.removeItem('token')
    localStorage.removeItem('workspace')
    yield put(logoutUserSuccess())
    history('/login')
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* getPaymentMethod({ type }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield GetPaymentMethodAPI(state)
    yield put(getPyamentMethodSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* changePaymentMethod({ type, payload: { data, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield ChangePaymentMethodAPI(data, state)
    yield put(changePaymentMethodSuccess(result?.data?.data))
    if (toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* deletePaymentMethod({ type, payload: { id } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield DeletePaymentMethodAPI(id)
    yield put(deletePaymentMethodSuccess(result?.data?.message))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* acceptInvitation({ type, payload: { code, history } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    let query = new URLSearchParams({ code }).toString()
    const result = yield AcceptInvitationAPI(query)
    if (history) history('/login')
    yield put(setSuccess(result?.data?.message))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* sendFeedback({ type, payload: { data, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    if (data?.image) {
      const result = yield uploadImageSaga({
        type: START_UPLOAD,
        payload: {
          file: data?.image,
          uploadType: "feedback",
          query: { type: "pdf" }
        }
      })
      data.image = result
    }
    console.log({ data })
    const result = yield SendFeedbackAPI(data, state)
    console.log(result)
    if (toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* loginSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(REGISTER_USER, registerUser)
  yield takeEvery(AUTH_GOOGLE, googleAuth)
  yield takeEvery(VERIFY_USER, verifyUserSaga)
  yield takeEvery(LIST_ANALYTICS, listAnalytics)
  yield takeEvery(LIST_SEARCH_CONSOLE_ACCOUNTS, listSearchAccounts)
  yield takeEvery(GET_USER_DATA, getUserData)
  yield takeEvery(FORGET_PASSWORD, forgetPasswordSaga)
  yield takeEvery(RESET_PASSWORD, resetPasswordSaga)
  yield takeEvery(UPDATE_USER_DATA, updateUserData)
  yield takeEvery(UPDATE_PASSWORD, updatePassword)
  yield takeEvery(UPDATE_EMAIL, updateEmail)
  yield takeEvery(DELETE_USER, deleteUser)
  yield takeEvery(LOGOUT_USER, logoutUserSaga)
  yield takeEvery(GET_PAYMENT_METHOD, getPaymentMethod)
  yield takeEvery(CHANGE_PAYMENT_METHOD, changePaymentMethod)
  yield takeEvery(DELETE_PAYMENT_METHOD, deletePaymentMethod)
  yield takeEvery(SEND_FEEDBACK, sendFeedback)
  yield takeEvery(ACCEPT_INVITATION, acceptInvitation)
}

export default loginSaga
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PublicRoute({ children }) {
    const { token, workspace } = useSelector(state => ({
        token: state.auth.token,
        workspace: state.workspace?.workspace?._id
    }));

    // if (token) {
    //     return <Navigate to="/" />
    // }

    // if (workspace) {
    //     return <Navigate to="/" />
    // }

    return children;
}

export default PublicRoute
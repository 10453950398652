import React, { useRef } from 'react'
import MessagePage from '../../components/common/MessagePage'

const PaymentDone = () => {
    return (
        <div className='payment-done'>
            <MessagePage url="/" />
        </div>
    )
}

export default PaymentDone
export const contentGeneratorTabs = [
    {
        id: 1,
        icon: "",
        model: 'facebook-primary',
        title: "Facebook Primary Text",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'tone', 'audience', 'productname', 'description'],
        category: "blog-content"
    },
    {
        id: 2,
        icon: "",
        model: 'facebook-headlines',
        title: "Facebook Headlines",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'tone', 'audience', 'productname', 'description'],
        category: "website-copy"
    },
    {
        id: 3,
        icon: "",
        model: 'google-headlines',
        title: "Google Ads Headlines",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'tone', 'audience', 'productname', 'description'],
        category: "social-media"
    },
    {
        id: 4,
        icon: "",
        model: 'google-descriptions',
        title: "Google Ads Descriptions",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'tone', 'audience', 'productname', 'description'],
        category: "marketing"
    },
    {
        id: 5,
        icon: "",
        model: 'youtube-titles',
        title: "Video Titles",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'video_description'],
        category: "more-tools"
    },
    {
        id: 6,
        icon: "",
        model: 'youtube-descriptions',
        title: "Video Descriptions",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'video_title'],
        category: "more-tools"
    },
    {
        id: 7,
        icon: "",
        model: 'quora-answers',
        title: "Quora Answers",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'quora_question'],
        category: "marketing"
    },
    {
        id: 8,
        icon: "",
        model: 'website-headlines',
        title: "Website Headlines",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'tone', 'audience', 'productname', 'description'],
        category: "marketing"
    },
    {
        id: 9,
        icon: "",
        model: 'website-subheadlines',
        title: "Website Subheaders",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'tone', 'audience', 'productname', 'description'],
        category: "website-copy"
    },
    {
        id: 10,
        icon: "",
        model: 'product-descriptions',
        title: "Product Descriptions",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'audience', 'productname', 'description'],
        category: "website-copy"
    },
    {
        id: 11,
        icon: "",
        model: 'value-proposition',
        title: "Value Proposition",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'tone', 'audience', 'productname', 'description'],
        category: "website-copy"
    },
    {
        id: 12,
        icon: "",
        model: 'feature-to-benefit',
        title: "Feature To Benefit",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'tone', 'audience', 'productname', 'description'],
        category: "website-copy"
    },
    {
        id: 13,
        icon: "",
        model: 'meta-titles',
        title: "Meta Titles(URL)",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['creativity', 'website_name', 'website_url', 'keywords'],
        category: "website-copy"
    },
    {
        id: 14,
        icon: "",
        model: 'meta-descriptions',
        title: "Meta Descriptions",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'page_type', 'website_name', 'business_description', 'keyword'],
        category: "website-copy"
    },
    {
        id: 15,
        icon: "",
        model: 'meta-descriptions',
        title: "Meta Descriptions(URL)",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['creativity', 'website_name', 'website_url', 'keywords'],
        category: "website-copy"
    },
    {
        id: 16,
        icon: "",
        model: 'faq',
        title: "FAQs",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'audience', 'productname', 'description'],
        category: "website-copy"
    },
    {
        id: 17,
        icon: "",
        model: 'faq-answers',
        title: "FAQ Answers",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'question', 'audience', 'productname', 'description'],
        category: "website-copy"
    },
    {
        id: 18,
        icon: "",
        model: 'subheading-paragraph',
        title: "Paragraph Writer",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'blog_title', 'subheading'],
        category: "website-copy"
    },
    {
        id: 19,
        icon: "",
        model: 'blog-titles',
        title: "Blog Titles",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'topic'],
        category: "website-copy"
    },
    {
        id: 20,
        icon: "",
        model: 'blog-ideas',
        title: "Blog Ideas",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'topic'],
        category: "website-copy"
    }, {
        id: 21,
        icon: "",
        model: 'blog-titles-listicles',
        title: "Blog Titles(Listicles)",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'topic'],
        category: "website-copy"
    },
    {
        id: 22,
        icon: "",
        model: 'blog-intros',
        title: "Blog Intros",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'blog_title', 'blog_description'],
        category: "website-copy"
    },
    {
        id: 23,
        icon: "",
        model: 'blog-outline',
        title: "Blog Outlines",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'blog_title', 'blog_description'],
        category: "website-copy"
    },
    {
        id: 24,
        icon: "",
        model: 'ecommerce-product-descriptions',
        title: "Ecommerce Product Descriptions",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'tone', 'audience', 'productname', 'description'],
        category: "website-copy"
    },
    {
        id: 25,
        icon: "",
        model: 'ecommerce-product-descriptions',
        title: "Ecommerce Category Descriptions",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'business_name', 'audience', 'business_description', 'category_name', 'keyword'],
        category: "website-copy"
    },
    {
        id: 26,
        icon: "",
        model: 'Ecommerce Product Names',
        title: "ecommerce-product-names",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['creativity', 'tone', 'audience', 'description'],
        category: "website-copy"
    },
    {
        id: 27,
        icon: "",
        model: 'rewrite-with-keyword',
        title: "Rewrite With Keyword",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'keyword', 'text_to_rewrite'],
        category: "website-copy"
    },
    {
        id: 28,
        icon: "",
        model: 'tldr',
        title: "Text Summarizer (TL;DR)",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['creativity', 'text_to_summarize'],
        category: "website-copy"
    },
    {
        id: 29,
        icon: "",
        model: 'startup-name-generator',
        title: "Startup Name Generator",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['creativity', 'seedwords', 'description'],
        category: "website-copy"
    },
    {
        id: 30,
        icon: "",
        model: 'review-responder',
        title: "Review Responder",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'company_name', 'reviewer_name', 'rating', 'tone', 'customer_review'],
        category: "website-copy"
    },
    {
        id: 31,
        icon: "",
        model: 'grammer-rewrite',
        title: "Grammer Rewrite",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['sentence'],
        category: "website-copy"
    },
    {
        id: 32,
        icon: "",
        model: 'essay-intros',
        title: "Essay Intros",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['creativity', 'topic'],
        category: "website-copy"
    },
    {
        id: 33,
        icon: "",
        model: 'essay-outlines',
        title: "Essay Outlines",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['creativity', 'topic'],
        category: "website-copy"
    },
    {
        id: 34,
        icon: "",
        model: 'write-for-me',
        title: "Write for me",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['creativity', 'content'],
        category: "website-copy"
    },
    {
        id: 35,
        icon: "",
        model: 'pain-agitate-solution',
        title: "Pain Agitate Solution",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'audience', 'productname', 'description'],
        category: "website-copy"
    },
    {
        id: 36,
        icon: "",
        model: 'attention-interest-desire-action',
        title: "AIDA Formula",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'audience', 'productname', 'description'],
        category: "website-copy"
    },
    {
        id: 37,
        icon: "",
        model: 'quest',
        title: "QUEST Formula",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'audience', 'productname', 'description'],
        category: "website-copy"
    },
    {
        id: 38,
        icon: "",
        model: 'testimonials',
        title: "Testimonial/Reviews",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'productname', 'description'],
        category: "website-copy"
    },
    {
        id: 39,
        icon: "",
        model: 'about-us',
        title: "About Us",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'tone', 'audience', 'productname', 'description'],
        category: "website-copy"
    },
    {
        id: 40,
        icon: "",
        model: 'cta',
        title: "Call To Action",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'audience', 'productname', 'description'],
        category: "website-copy"
    },
    {
        id: 41,
        icon: "",
        model: 'video-script-intros',
        title: "Video Script Intros",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'tone', 'audience', 'video_description'],
        category: "website-copy"
    },
    {
        id: 42,
        icon: "",
        model: 'video-script-outlines',
        title: "Video Script Outlines",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'tone', 'audience', 'video_description'],
        category: "website-copy"
    },
    {
        id: 43,
        icon: "",
        model: 'video-script-section',
        title: "Video Script Section",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'tone', 'audience', 'video_section_title', 'video_description'],
        category: "website-copy"
    },
    {
        id: 44,
        icon: "",
        model: 'follow-up-email',
        title: "Follow Up Email",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'tone', 'audience', 'following_up_after', 'productname', 'description'],
        category: "website-copy"
    },
    {
        id: 45,
        icon: "",
        model: 'welcome-email',
        title: "Welcome Email",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'tone', 'audience', 'productname', 'description'],
        category: "website-copy"
    },
    {
        id: 46,
        icon: "",
        model: 'cancellation-email',
        title: "Cancellation Email",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'tone', 'audience', 'productname', 'description'],
        category: "website-copy"
    },
    {
        id: 47,
        icon: "",
        model: 'confirmation-email',
        title: "Confirmation Email",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'tone', 'audience', 'productname', 'description', 'confirmation_of'],
        category: "website-copy"
    },
    {
        id: 48,
        icon: "",
        model: 'email-subject-lines',
        title: "Email Subject Lines",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'tone', 'audience', 'productname', 'description'],
        category: "website-copy"
    },
    {
        id: 49,
        icon: "",
        model: 'amazon-product-descriptions',
        title: "Amazon Product Descriptions",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['creativity', 'amazon_product_url'],
        category: "website-copy"
    },
    {
        id: 50,
        icon: "",
        model: 'sentence-rewriter',
        title: "Paragraph Rewriter",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.",
        params: ['language', 'creativity', 'sentence'],
        category: "website-copy"
    }
]
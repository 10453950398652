import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import { listCampaigns, viewCampaign } from '../../../store/actions'
import { LIST_CAMPAIGNS, VIEW_CAMPAIGN } from '../../../store/campaigns/actionTypes'
import { checkIfLoading } from '../../../store/functions'
import WebReputationNav from './components/Nav'

const CampaignDetails = () => {
    const dispatch = useDispatch()
    const { getLoading, campaign } = useSelector(state => ({
        getLoading: checkIfLoading(state, VIEW_CAMPAIGN),
        campaign: state?.campaign?.campaign
    }))

    const history = useNavigate()
    const [t] = useTranslation()

    useEffect(() => {
        const id = location.pathname.split('/web-reputation/campaigns/')[1]
        if (!id || id?.length !== 24) history('web-reputation/campaigns')
        dispatch(viewCampaign(id))
    }, [])

    const renderCampaignEmails = () => {
        if (campaign && campaign?.emails?.length > 0) {
            return campaign?.emails?.map(email => (
                <tr>
                    <td><a href={`/crm/customer/${email?.customer?._id}`} className="text-dark">{email?.email}</a></td>
                    <td>{email?.companyName}</td>
                    <td>{email?.status}</td>
                </tr>
            ))
        } else {
            return <div className='col-md-12'>{t('no-campaign-found')}</div>
        }
    }

    return (
        <div className='container-fluid page mt-5'>
            <WebReputationNav />
            <div className='row mt-3'>
                {
                    getLoading ? <Spinner size={'md'} color="success" /> : <>
                        <div className='col-md-12'>
                            <div>
                                <div>
                                    <i class="fas fa-arrow-left fa-2x text-green" style={{ cursor: "pointer" }} onClick={() => history('/web-reputation/campaigns')}></i>
                                </div>
                            </div>
                            <hr />
                        </div>
                        <div className='col-md-12'>
                            <h4 className='mb-3'>{t('all-campaigns')}</h4>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>{t('email')}</th>
                                        <th>{t('company')}</th>
                                        <th>{t('status')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderCampaignEmails()}
                                </tbody>
                            </table>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default CampaignDetails
import {
  GET_CONTENT_ANALYSIS,
  GET_CONTENT_ANALYSIS_SUCCESS,
  UPDATE_CONTENT_ANALYSIS,
  UPDATE_CONTENT_ANALYSIS_SUCCESS,
  CONTENT_ANALYSIS_PAGE_KEYWORDS,
  CONTENT_ANALYSIS_PAGE_KEYWORDS_SUCCESS,
  GET_CONTENT_ANALYSIS_ERROR
} from "./actionTypes"

const initialState = {
  data: null,
  message: null,
  page: null
}

const contentAnalysis = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTENT_ANALYSIS:
      return { ...state, message: null, data: null }
    case GET_CONTENT_ANALYSIS_SUCCESS:
      return { ...state, data: action.payload }
    case GET_CONTENT_ANALYSIS_ERROR:
      return { ...state, message: action.payload }
    case CONTENT_ANALYSIS_PAGE_KEYWORDS:
      return { ...state, page: null }
    case CONTENT_ANALYSIS_PAGE_KEYWORDS_SUCCESS:
      return { ...state, page: action.payload }
    default:
      return state
  }
}

export default contentAnalysis
import React, { useEffect, useState } from 'react';
import { useGoogleLogin } from 'react-google-login';
import { refreshTokenSetup } from '../../../../store/functions';
import { gapi } from 'gapi-script';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { authGoogle, disconnectGoogle, getWorkspaceConnections, listAnalytics, selectWorkspaceAnalytics, selectWorkspaceSearchConsole } from '../../../../store/actions';
import ChooseAnalyticsModal from './chooseAnalyticsModal';
import { Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ChooseSearchModal from './chooseSearchModal';

const clientId = '816940198937-hvkmu9l2eiso4mjtddp77te5j1lbieo3.apps.googleusercontent.com';

const ConnectGoogle = ({ scope, title, param, icon }) => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const [field, setField] = useState(false)
    const [loading, setLoading] = useState(false)
    const [analyticsModal, setAnalyticsModal] = useState(false);
    const [searchModal, setSearchModal] = useState(false);
    const toggleAnalyticsModal = () => setAnalyticsModal(!analyticsModal);
    const toggleSearchModal = () => setSearchModal(!searchModal);
    const [t] = useTranslation()

    const { workspace } = useSelector(state => ({
        workspace: state.workspace?.workspace?._id
    }))

    const disconnect = () => {
        dispatch(disconnectGoogle(String(scope).split('https://www.googleapis.com/auth/')[1]))
    }

    useEffect(() => {
        setField(param)
    }, [param])

    const onSuccess = (res) => {
        console.log(scope)
        setLoading(false)
        if (res.code) {
            dispatch(authGoogle({ code: res.code, workspace }))
        }
    };

    const onFailure = (res) => {
        setLoading(false)
        console.log(res)
        console.log('Login failed: res:', res);
    };

    const { signIn } = useGoogleLogin({
        onSuccess,
        onFailure,
        clientId,
        isSignedIn: true,
        accessType: 'offline',
        responseType: 'code',
        prompt: 'consent',
        scope: "https://www.googleapis.com/auth/webmasters https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/analytics https://www.googleapis.com/auth/adwords",
        redirectUri: process.env.REACT_APP_CALLBACK_URL
    });

    const connectGoogle = () => {
        setLoading(true)
        console.log(scope)
        signIn()
        // setTimeout(() => {  }, 1000)
    }

    return (
        <div className='card connection-card mb-3'>
            <div className='d-flex'>
                <div className='connection-img d-inline-block'>
                    <img className='img-responsive' src={icon} />
                </div>
                <div>
                    <div className='d-flex align-items-center justify-content-between mb-3'>
                        <div>
                            <h4 className='m-0'>{title}</h4>
                            {
                                field?.connected ?
                                    <span className='text-green'>{t('Connected')} {field?.id ? <span className='text-green'>{field?.id}</span> : ""}
                                        {
                                            scope?.includes('analytics') ? <>
                                                <Link to={'#'} onClick={() => setAnalyticsModal(true)} className="text-green mx-2"><small><b><i class="fas fa-edit"></i></b></small></Link>
                                                <Link to={'#'} onClick={() => dispatch(selectWorkspaceAnalytics())} className="text-danger"><small><b><i class="fas fa-trash"></i></b></small></Link>
                                            </> : scope?.includes('webmasters') ? <>
                                                <Link to={'#'} onClick={() => setSearchModal(true)} className="text-green mx-2"><small><b><i class="fas fa-edit"></i></b></small></Link>
                                                <Link to={'#'} onClick={() => dispatch(selectWorkspaceSearchConsole())} className="text-danger"><small><b><i class="fas fa-trash"></i></b></small></Link>
                                            </> : ""
                                        }
                                    </span> :
                                    <span className='text-muted'>{t('Not connected')}</span>
                            }
                        </div>
                        {
                            field?.connected ?
                                <button className='btn btn-outline-secondary' onClick={disconnect}>{t('Disconnect')}</button> :
                                <button className='btn click-btn orange-btn' disabled={loading} onClick={() => connectGoogle()}>
                                    {loading ? <Spinner color='light' size='sm' /> : 'Connect'}
                                </button>
                        }
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis ut habitant lectus aliquam tortor adipiscing diam adipiscing at. Et consectetur egestas odio nam nunc sed. Massa turpis augue sagittis diam tellus elit feugiat sed. Facilisi pellentesque velit at neque. Nisi ultrices cras in vitae. Quis sagittis, non cursus sem.</p>
                </div>
            </div>
            {
                scope.includes('analytics') ? <ChooseAnalyticsModal modal={analyticsModal} toggle={toggleAnalyticsModal} /> : ""
            }
            {
                scope.includes('webmasters') ? <ChooseSearchModal modal={searchModal} toggle={toggleSearchModal} /> : ""
            }
        </div>
    )
}

export default ConnectGoogle
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom'
import { Spinner } from "reactstrap";
import { resetPassword } from "../../store/actions";
import { RESET_PASSWORD } from "../../store/auth/actionTypes";
import { checkIfLoading, useQuery } from "../../store/functions";

function ResetPassword() {
    const history = useNavigate()
    const dispatch = useDispatch()
    const [password, setPassword] = useState(null)
    const [confirmPassword, setConfirmPassword] = useState(null)
    const [t] = useTranslation()

    const query = useQuery()

    const { loading } = useSelector(state => ({
        loading: checkIfLoading(state, RESET_PASSWORD)
    }))

    const submitReset = () => {
        const code = query.get('code')
        const data = { code, password, confirmPassword }
        dispatch(resetPassword(data, history))
    }

    return (
        <form className="auth-form">
            <h4>{t('Reset your password')}</h4>
            <div className="form-group">
                <label>{t('password')}</label>
                <input type="password" onChange={(e) => setPassword(e.target.value)} className="form-control" name="password" />
            </div>
            <div className="form-group">
                <label>{t('Confirm Password')}</label>
                <input type="password" onChange={(e) => setConfirmPassword(e.target.value)} className="form-control" name="confirmPassword" />
            </div>
            <button type="button" onClick={submitReset} className="btn click-btn orange-btn btn-block">
                {
                    loading ? (
                        <Spinner
                            size="sm"
                            className="m-auto text-center"
                            color="light"
                        />
                    ) : t("Update password")
                }
            </button>
        </form>
    );
}

export default ResetPassword;
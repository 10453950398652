import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import { addComment, getEmails, listProjects, removeComment } from '../../../../store/actions'
import { ADD_CUSTOMER_COMMENT, GET_EMAILS, REMOVE_CUSTOMER_COMMENT } from '../../../../store/crm/actionTypes'
import { checkIfLoading } from '../../../../store/functions'
import AddTaskModal from './AddTask'
import TablePagination from '../../../../components/common/Pagination'
import { useTranslation } from 'react-i18next'

function TimeLine({ customer, timeline, emails }) {
    const dispatch = useDispatch()
    const history = useNavigate()
    const [comm, setComm] = useState(null)
    const [addTaskModal, setAddTaskModal] = useState(false)
    const [t] = useTranslation()

    let [emailsQuery, setEmailsQuery] = useState({
        page: 1
    })

    const { user, addCommLoading, deleteLoading, emailsLoading } = useSelector(state => ({
        user: state?.auth?.user,
        addCommLoading: checkIfLoading(state, ADD_CUSTOMER_COMMENT),
        deleteLoading: checkIfLoading(state, REMOVE_CUSTOMER_COMMENT),
        emailsLoading: checkIfLoading(state, GET_EMAILS)
    }))

    useEffect(() => {
        dispatch(listProjects())
    }, [customer])

    useEffect(() => {
        if (customer) dispatch(getEmails(customer?._id, emailsQuery))
    }, [customer, emailsQuery.page])

    const commentSubmit = (e) => {
        if (e.charCode === 13) {
            dispatch(addComment({
                content: comm,
                customer: customer?._id
            }, () => setComm('')))
        }
    }

    const renderTimeline = () => {
        if (timeline && timeline?.length > 0) {
            return timeline.map(t => {
                if (t?.type === 'comment') {
                    return <div className='comment-box mt-3 d-flex align-items-center'>
                        <img className='img-thumbnail rounded-circle avatar timeline-avatar mr-2' src={t?.user?.profilePicture || `${process.env.PUBLIC_URL}/assets/images/avatar.png`} onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = `${process.env.PUBLIC_URL}/assets/images/avatar.png`;
                        }} alt="user avatar" />
                        <div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className='d-flex'>
                                    <h6 className='m-0 mr-3'>{t?.user?.fullName}</h6>
                                    <span className='text-muted' title={moment(t?.createdAt).format('YYYY-MM-DD hh:mm')}>{moment(t?.createdAt).fromNow()}</span>
                                </div>
                                {
                                    deleteLoading ? <div className='ml-3'><Spinner color='secondary' size={'sm'} /></div> : <div className='ml-3' onClick={() => dispatch(removeComment(t?._id))}><i className='fas fa-times text-danger cursor-pointer'></i></div>
                                }
                            </div>
                            <p className='m-0'>{t?.body}</p>
                        </div>
                    </div>
                }

                if (t?.type === 'activity') {
                    return <div class="card mt-4">
                        <div class="info d-flex justify-content-between">
                            <span className='m-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, </span>
                            <span className='text-muted'>12:13 PM</span>
                        </div>
                    </div>
                }

                if (t.type === 'task') {
                    return <div class="card task-assigned">
                        <div class="info d-flex justify-content-between">
                            <div className='d-flex'>
                                <img className='img-thumbnail rounded-circle avatar timeline-avatar mr-2' src={t?.user?.profilePicture || `${process.env.PUBLIC_URL}/assets/images/avatar.png`} alt="user avatar" />
                                <div>
                                    <span>Task assigned to <b>{t?.assignee?.fullName || t?.assignee?.email}</b> by <b>{t?.user?.fullName}</b></span>
                                    <div className='text-muted'>{t?.description}</div>
                                    <div>When: <b>{moment(t?.start).format('MMM DD, YYYY')}</b></div>
                                    <button onClick={() => history(`/project-management?project=${t?.project}`)} className='btn btn-outline-secondary btn-sm mt-2'>Go to project</button>
                                </div>
                            </div>
                            <div className='text-right' style={{ width: "70px" }}>
                                <span className='text-muted'>{moment(t?.createdAt).format('hh:mm')}</span>
                            </div>
                        </div>
                    </div>
                }
            })
        } else {
            return <div className='ml-3'>No timeline found</div>
        }
    }

    const renderEmails = () => {
        if (emails?.data && emails?.data?.length > 0) {
            return emails?.data?.map(e => {
                return <tr>
                    <td>{e.Subject}</td>
                    <td></td>
                    <td>{moment(e.ReceivedAt).format('MMM DD,YYYY ')}</td>
                    <td></td>
                    <td><b>{e?.Status}</b></td>
                </tr>
            })
        } else {
            return <div>{t('No emails found')}</div>
        }
    }

    return (
        <>
            <div className='timeline-cont'>
                <h5>{t('Timeline')}</h5>
                <hr />
                <div className='position-relative'>
                    <div className='text-right'><button className='btn click-btn orange-btn' onClick={() => setAddTaskModal(!addTaskModal)}>{t('Add Task')}</button></div>
                    {
                        addTaskModal ? <AddTaskModal customer={customer} toggle={() => setAddTaskModal(!addTaskModal)} /> : ""
                    }
                </div>
                <div class="timeline">
                    <div class="outer">
                        <div className='add-comment-box mt-3'>
                            <div className='d-flex align-items-center'>
                                <img className='img-thumbnail rounded-circle avatar timeline-avatar mr-2' src={user?.profilePicture || `${process.env.PUBLIC_URL}/assets/images/avatar.png`} onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = `${process.env.PUBLIC_URL}/assets/images/avatar.png`;
                        }} alt="user avatar" />
                                <input onChange={e => setComm(e.target.value)} value={comm} onKeyPress={commentSubmit} className='form-control custom-border' placeholder='leave a comment' />
                                {
                                    addCommLoading ? <Spinner style={{
                                        position: "absolute",
                                        right: "10px"
                                    }} size='sm' color='secondary' /> : ""
                                }
                            </div>
                            <div className='text-right'>
                                <small className='text-muted'>{t('Only you and other team memebers can see comments')}</small>
                            </div>
                        </div>
                        {renderTimeline()}
                    </div>
                    {/* {
                    timeline?.length > 10 ? <>
                        <hr className='mt-4' />
                        <div className='text-center'>
                            <button className='btn click-btn green-btn'>Load More Timeline <i class="fas fa-redo fa-sm ml-2" style={{ transform: "scaleX(-1)" }}></i></button>
                        </div>
                    </> : ""
                } */}
                </div>
            </div>
            <hr />
            <div className='email-activity'>
                <div>
                    <h6>{t('Email Activity')}</h6>
                    <hr />
                    <table className='table'>
                        <thead>
                            <tr className='text-muted'>
                                <th>{t('Email Received')}</th>
                                <th>{t('Engagement')}</th>
                                <th>{t('Date')}</th>
                                <th>{t('Location')}</th>
                                <th>{t('Status')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                emailsLoading ? <tr>
                                    <td colSpan={5} className="text-center"><Spinner size={'sm'} color="success" /></td>
                                </tr> : renderEmails()
                            }
                        </tbody>
                    </table>
                    <TablePagination count={emails?.count} limit={5} loading={emailsLoading} page={emailsQuery.page} setPage={(p) => setEmailsQuery({ ...emailsQuery, page: p })} />
                </div>
            </div>
        </>
    )
}

export default TimeLine
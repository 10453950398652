import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { getDashboard } from '../../../store/dashboard/actions'

function Header({ workspace }) {
  const dispatch = useDispatch()
  const [t] = useTranslation()

  return (
    <div className='dashboard-header'>
      <div className='logo d-flex align-items-center'>
        <div className='header'>
          {
            workspace?.logo ? <img className='workspace-logo' src={workspace?.logo} alt='' /> : <span className='header-workspace-font-logo'>{String(workspace.domain).charAt(0).toUpperCase()}</span>
          }
        </div>
        <div className='description'>
          <h4>{workspace?.name || workspace?.domain}</h4>
          <p className='m-0'>
            {/* <img src={`${process.env.PUBLIC_URL}/assets/images/flags/canda-flag.png`} className="mr-2" alt='' />  */}
            {workspace?.region} ({workspace?.language})
            <span className='time'>{t('Last updated')}: {new Date(workspace?.updatedAt).toLocaleDateString()}</span>
          </p>
        </div>
      </div>
      <div className='update'>
        <button type='button' className='btn' onClick={() => dispatch(getDashboard(workspace?._id, true))}><i className="fas fa-redo"></i> {t('Update')}</button>
      </div>
    </div>
  )
}

export default Header
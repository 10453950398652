import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { setLanguage, updateUserData } from '../../../store/actions';

function LangDropdown() {
    // ** states
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useDispatch()

    const { lang } = useSelector(state => ({
        lang: state?.ui?.lang
    }))

    // ** function to handle toggle of dropdown
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    // ** function to show selected choice of language
    const showSelected = (value) => {
        if (value === "en") {
            return <>
                EN
            </>
        } else {
            return <>
                IT
            </>
        }
    }

    return (
        <div className="lang-con">
            <Dropdown className='custom-dropdown' isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle caret>
                    {showSelected(lang)}
                </DropdownToggle>
                <DropdownMenu right={true}>
                    <span>Choose Language</span>
                    <DropdownItem>
                        <button className="btn" name='en' onClick={() => {
                            dispatch(setLanguage('en'))
                            dispatch(updateUserData({ language: 'en' }))
                        }}>
                            English
                        </button>
                    </DropdownItem>
                    <DropdownItem>
                        <button className="btn" name='it' onClick={() => {
                            dispatch(setLanguage('it'))
                            dispatch(updateUserData({ language: 'it' }))
                        }}>
                            Italian
                        </button>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    )
}

export default LangDropdown
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'reactstrap'
import AreaChart from '../../../components/charts/AreaChart'

function BounceRate({ total, data, loading }) {

  // ** fake backend database 
  const [chart, setChart] = useState([])
  const [t] = useTranslation()

  useEffect(() => {
    if (data) {
      let chartData = []
      data.forEach(e => {
        chartData.push({
          label: moment().format('MM') + '-' + e.label,
          value: e.value
        })
      })
      console.log(chartData)
      setChart(chartData)
    }
  }, [data])

  return (
    <div className='bounce-rate analytics card'>
      {loading ? <Spinner size="md" className="m-auto text-center" color="success" /> : <>
        <h2 className='number'>{Number(total).toFixed(2)}% </h2>
        <p>{t('Bounce Rate')}</p>
        {
          chart?.length > 0 ? <AreaChart height={130} name="Bounce Rate" data={chart} curveType="straight" showYaxis={false} /> : "No data shown"
        }
      </>}
    </div>
  )
}

export default BounceRate
export const LIST_WORKSPACES = 'LIST_WORKSPACES'
export const LIST_WORKSPACES_SUCCESS = 'LIST_WORKSPACES_SUCCESS'

export const ADD_WORKSPACE = 'ADD_WORKSPACE'
export const ADD_WORKSPACE_SUCCESS = 'ADD_WORKSPACE_SUCCESS'

export const GET_WORKSPACE = 'GET_WORKSPACE'
export const GET_WORKSPACE_SUCCESS = 'GET_WORKSPACE_SUCCESS'

export const GET_WORKSPACE_CONNECTIONS = 'GET_WORKSPACE_CONNECTIONS'
export const GET_WORKSPACE_CONNECTIONS_SUCCESS = 'GET_WORKSPACE_CONNECTIONS_SUCCESS'

export const UPDATE_WORKSPACE = 'UPDATE_WORKSPACE'
export const UPDATE_WORKSPACE_SUCCESS = 'UPDATE_WORKSPACE_SUCCESS'

export const UPDATE_WORKSPACE_LOGO = 'UPDATE_WORKSPACE_LOGO'
export const UPDATE_WORKSPACE_LOGO_SUCCESS = 'UPDATE_WORKSPACE_LOGO_SUCCESS'

export const DELETE_WORKSPACE = 'DELETE_WORKSPACE'
export const DELETE_WORKSPACE_SUCCESS = 'DELETE_WORKSPACE_SUCCESS'

export const INVITE_COLLABORATOR = 'INVITE_COLLABORATOR'
export const INVITE_COLLABORATOR_SUCCESS = 'INVITE_COLLABORATOR_SUCCESS'

export const REMOVE_COLLABORATOR = 'REMOVE_COLLABORATOR'
export const REMOVE_COLLABORATOR_SUCCESS = 'REMOVE_COLLABORATOR_SUCCESS'

export const LIST_WORKSPACE_LANGUAGES = 'LIST_WORKSPACE_LANGUAGES'
export const LIST_WORKSPACE_LANGUAGES_SUCCESS = 'LIST_WORKSPACE_LANGUAGES_SUCCESS'

export const LIST_WORKSPACE_REGIONS = 'LIST_WORKSPACE_REGIONS'
export const LIST_WORKSPACE_REGIONS_SUCCESS = 'LIST_WORKSPACE_REGIONS_SUCCESS'

export const LIST_WORKSPACE_BILLINGS = 'LIST_WORKSPACE_BILLINGS'
export const LIST_WORKSPACE_BILLINGS_SUCCESS = 'LIST_WORKSPACE_BILLINGS_SUCCESS'

export const ADD_SENDER_EMAIL = 'ADD_SENDER_EMAIL'
export const ADD_SENDER_EMAIL_SUCCESS = 'ADD_SENDER_EMAIL_SUCCESS'

export const REGISTER_DOMAIN = 'REGISTER_DOMAIN'
export const REGISTER_DOMAIN_SUCCESS = 'REGISTER_DOMAIN_SUCCESS'

export const GET_DOMAIN = 'GET_DOMAIN'
export const GET_DOMAIN_SUCCESS = 'GET_DOMAIN_SUCCESS'

export const CANCEL_WORKSPACE_SUBSCRIPTION = 'CANCEL_WORKSPACE_SUBSCRIPTION'
export const CANCEL_WORKSPACE_SUBSCRIPTION_SUCCESS = 'CANCEL_WORKSPACE_SUBSCRIPTION_SUCCESS'

export const SELECT_WORKSPACE_ANALYTICS = 'SELECT_WORKSPACE_ANALYTICS'
export const SELECT_WORKSPACE_ANALYTICS_SUCCESS = 'SELECT_WORKSPACE_ANALYTICS_SUCCESS'

export const SELECT_WORKSPACE_SEARCH_CONSOLE = 'SELECT_WORKSPACE_SEARCH_CONSOLE'
export const SELECT_WORKSPACE_SEARCH_CONSOLE_SUCCESS = 'SELECT_WORKSPACE_SEARCH_CONSOLE_SUCCESS'

export const DISCONNECT_GOOGLE = 'DISCONNECT_GOOGLE'
export const DISCONNECT_GOOGLE_SUCCESS = 'DISCONNECT_GOOGLE_SUCCESS'

export const UPDATE_WORKSPACE_BUSINESS_SUCCESS = 'UPDATE_WORKSPACE_BUSINESS_SUCCESS'
import api from '../apis/api'
import { tokenConfig } from '../config'

export const ListBusinessesAPI = (query, state) => {
  return new Promise((res, rej) => {
    api.get(`/business?${query}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const GetBusinessAPI = (id, query, state) => {
  return new Promise((res, rej) => {
    api.get(`/business/${id}?${query}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const DisconnectBusinessAPI = (query, state) => {
  return new Promise((res, rej) => {
    api.get(`/business/disconnect?${query}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

const CrmNav = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [t] = useTranslation()

    return (
        <div className='container crm-nav mt-5'>
            <ul className='list-group m-auto text-center list-group-horizontal-md'>
                <li onClick={() => navigate('/crm/sales-management')} className={`${location.pathname === '/crm/sales-management' ? 'active' : ""} m-auto`}><i class="fas fa-balance-scale"></i><h6>{t('Sales Pipeline')}</h6></li>
                <li onClick={() => navigate('/crm/current-customers')} className={`${location.pathname === '/crm/current-customers' ? 'active' : ""} m-auto`}><i class="fas fa-users"></i><h6>{t('Customers & Leads')}</h6></li>
                <li onClick={() => navigate('/crm/dashboard')} className={`${location.pathname === '/crm/dashboard' ? 'active' : ""} m-auto`}><i class="fas fa-tachometer-alt"></i><h6>{t('Dashboard')}</h6></li>
                {/* <li onClick={() => navigate('/crm/marketing')} className={`${location.pathname === '/crm/marketing' ? 'active' : ""} m-auto`}><i class="fas fa-cogs"></i><h6>Marketing Automation</h6></li> */}
                <li onClick={() => navigate('/crm/reminders')} className={`${location.pathname === '/crm/reminders' ? 'active' : ""} m-auto`}><i class="fas fa-bell"></i><h6>{t('Reminds')}</h6></li>
            </ul>
        </div>
    )
}

export default CrmNav
import React, { useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import { getBusiness, listBusinesses, selectBusiness } from "../../../../../store/actions";
import { checkIfLoading } from "../../../../../store/functions";
import { GET_BUSINESS, LIST_BUSINESSES, SELECT_BUSINESS } from "../../../../../store/localseo/actionTypes";
import DetailedReport from '../index'

function Discovering() {

  const history = useNavigate()
  const [name, setName] = useState(null)
  const dispatch = useDispatch()
  const [t] = useTranslation()

  const { listLoading, businesses, selectLoading, workspace, keyword, business, selectedBusiness } = useSelector(state => ({
    listLoading: checkIfLoading(state, LIST_BUSINESSES),
    businesses: state?.localseo?.businesses,
    selectLoading: checkIfLoading(state, SELECT_BUSINESS),
    workspace: state?.workspace?.workspace?._id,
    keyword: state?.localseo?.keyword,
    business: state?.localseo?.business,
    selectedBusiness: state?.localseo?.selectedBusiness,
  }))

  useEffect(() => {
    if(!keyword) dispatch(listBusinesses(business?.searchName))
    setName(keyword)
  }, [keyword])

  useEffect(() => {
    if(businesses?.length > 0) dispatch(selectBusiness(businesses[0]?._id))
  }, [businesses])

  const renderBusinesses = () => {
    if (listLoading) return <div className="text-center"><Spinner color="success" size={'xl'} /></div>
    if (businesses && businesses?.length > 0) {
      return businesses?.map(b => {
        return (
          <a className={`result btn ${selectedBusiness?._id === b?._id ? 'active' : ""}`} onClick={() => dispatch(selectBusiness(b?._id))}>
            <div className='img-con'>
              {
                b?.image ? <img src={b?.image} onError={({ currentTarget }) => {
                  currentTarget.src = `${process.env.PUBLIC_URL}/assets/images/market.jpg`;
                }} alt='result-img' /> : <span className='img-thumbnail rounded-circle font-logo text-center'>{String(b?.title).charAt(0).toUpperCase()}</span>
              }
            </div>
            <div className='description'>
              <h3 className='title'>
                <span className='name'>{b?.title}</span>
                <span className='review'>
                  <i class="fas fa-star"></i>
                  <b>{b?.rating}</b>
                  ({b?.votes})
                </span>
              </h3>
              <p className='location m-0 mt-2'>
                <i class="fas fa-map-marker-alt"></i>
                {b?.address?.address}, {b?.address?.city}, {b?.address?.country_code}
              </p>
            </div>
          </a>
        )
      })
    } else {
      return 'No businesses Found'
    }
  }
 
  return (
    <div className="container descovering">
      <div className="row">
        <div className="col-md-12 mb-2 search-container">
          <div className="row align-items-center">
            <div className="col-md-5 border-right">
              <label>{t('Which keyword you want to test against your competitors')}</label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1" className='bg-transparent'><i class="fas fa-search"></i></InputGroup.Text>
                <FormControl
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </InputGroup>
            </div>
            <div className="col-md-4">
              <label>{t('Where?')}</label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1" className='bg-transparent'><i class="fas fa-search"></i></InputGroup.Text>
                <FormControl
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </div>
            <div className="col-md-3">
              <button className="btn btn-block click-btn green-btn"
                onClick={() => dispatch(listBusinesses(name))}>
                {
                  listLoading ? <Spinner color="light" size={'sm'} /> : t("Search")
                }
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 mt-5">
          <div>{businesses?.length || 0} {t('Local businesses rank here for this keyword')}</div>
          <div className="business-list mt-3">
            {renderBusinesses()}
          </div>
        </div>
        <div className="col-md-9">
          <DetailedReport selectLoading={selectLoading} selectedBusiness={selectedBusiness} descovering={true} />
        </div>
      </div>
    </div>
  )
}

export default Discovering;
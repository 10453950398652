import React from "react";
import { useTranslation } from "react-i18next";

function Tabs({ handleSelectContent, tab }) {
    const [t] = useTranslation()

    return (
        <div className='tabs'>
            <button type='button' className={tab === 6 ? 'btn active' : 'btn'} value="tracking" onClick={e => handleSelectContent(e.target.value)}>
                {t('Keywords Tracking')}
            </button>
            <button type='button' className={tab === 1 ? 'btn active' : 'btn'} value="ranking" onClick={e => handleSelectContent(e.target.value)}>
                {t('Ranked Keywords')}
            </button>
            <button type='button' className={tab === 2 ? 'btn active' : 'btn'} value="related" onClick={e => handleSelectContent(e.target.value)}>
                {t('Related keywords')}
            </button>
            <button type='button' className={tab === 3 ? 'btn active' : 'btn'} value="density" onClick={e => handleSelectContent(e.target.value)}>
                {t('Density keywords')}
            </button>
            <button type='button' className={tab === 4 ? 'btn active' : 'btn'} value="organic" onClick={e => handleSelectContent(e.target.value)}>
                {t('Suggested keywords (Organic)')}
            </button>
            <button type='button' className={tab === 5 ? 'btn active' : 'btn'} value="paid" onClick={e => handleSelectContent(e.target.value)}>
                {t('Suggested keywords (Pay-Per-Click)')}
            </button>
        </div>
    )
}

export default Tabs;
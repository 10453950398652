import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Spinner, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { checkIfLoading } from '../../../../store/functions';
import { ADD_CUSTOMER_TASK } from '../../../../store/crm/actionTypes';
import { addCustomer, addCustomerTask, listProjects } from '../../../../store/actions';
import DateTimePicker from './DateTimePicker';
import { LIST_PROJECTS } from '../../../../store/projectManagement/actionTypes';
import { useTranslation } from 'react-i18next';

function AddTaskModal({ customer, toggle }) {
    const [newTask, setNewTask] = useState({
        name: null,
        project: null,
        startDate: new Date(),
        assignee: null
    })
    const [projectsDrop, setProjectsDrop] = useState(null)
    const [assigneeDrop, setAssigneeDrop] = useState(null)
    const dispatch = useDispatch()
    const [t] = useTranslation()

    const { projects, getProjectsLoading, addTaskLoading, workspace } = useSelector(state => ({
        projects: state?.project?.projects,
        getProjectsLoading: checkIfLoading(state, LIST_PROJECTS),
        addTaskLoading: checkIfLoading(state, ADD_CUSTOMER_TASK),
        workspace: state?.workspace?.workspace
    }))

    const handleNewTask = (key, value) => {
        setNewTask({
            ...newTask,
            [key]: value
        })
    }

    const renderProjects = () => {
        if (projects && projects?.length > 0) {
            return projects?.map(pr => {
                return <DropdownItem onClick={() => handleNewTask('project', pr)}>{pr?.name}</DropdownItem>
            })
        }
    }

    const renderCollaborators = () => {
        const collaborators = [...workspace?.collaborators, workspace?.manager]
        if (collaborators && collaborators?.length > 0) {
            return collaborators?.map(col => {
                return <DropdownItem onClick={() => handleNewTask('assignee', col)}>{col?.fullName || col?.email}</DropdownItem>
            })
        }
    }

    const submitTask = () => {
        // console.log({
        //     ...newTask,
        //     project: newTask?.project?._id,
        //     assignee: newTask?.assignee?._id
        // })
        dispatch(addCustomerTask(newTask?.project?._id, {
            ...newTask,
            project: newTask?.project?.name,
            assignee: newTask?.assignee?._id,
            customer: customer?._id
        }, toggle))
    }

    return (
        <div className='add-customer-task'>
            <div>
                <label className='m-0'>{t('Name')}</label>
                <input aria-expanded={false} onChange={(e) => handleNewTask('name', e.target.value)} className='form-control custom-border' placeholder='Task name' />
            </div>
            <div className='d-flex justify-content-between mt-2 align-items-center'>
                <label className='m-0'>{t('Assignee')}</label>
                <Dropdown className='custom-dropdown' style={{ width: "250px" }} isOpen={assigneeDrop} toggle={() => setAssigneeDrop(!assigneeDrop)}>
                    <DropdownToggle color='transparent' className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <img src={newTask?.assignee?.profilePicture || `${process.env.PUBLIC_URL}/assets/images/avatar.png`} className="img-thumbnail rounded-circle avatar timeline-avatar mr-3" />
                            <span className='mr-3'>{newTask?.assignee ? newTask?.assignee?.fullName || newTask?.assignee?.email : 'Choose assignee'}</span>
                        </div>
                        <i class="fas fa-angle-down"></i>
                    </DropdownToggle>
                    <DropdownMenu style={{ width: "200px", maxHeight: "300px", overflowX: 'hidden', overflowY: 'scroll' }}>
                        {renderCollaborators()}
                    </DropdownMenu>
                </Dropdown>
                {/* <div className='d-flex align-items-center'>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/avatar.png`} className="img-thumbnail rounded-circle avatar timeline-avatar" />
                    <h6 className='m-0 ml-2'>{customer?.name}</h6>
                </div> */}
            </div>
            <div className='d-flex justify-content-between mt-2 align-items-center'>
                <label className='m-0'>{t('Date')}</label>
                <span className='datetime'><DateTimePicker date={newTask.startDate} setDate={(d) => handleNewTask('startDate', d)} className={'text-right'} /></span>
            </div>
            <div className='d-flex justify-content-between mt-2 align-items-center'>
                <label className='m-0'>{t('Projects')}</label>
                <Dropdown className='custom-dropdown' style={{ width: "200px" }} isOpen={projectsDrop} toggle={() => setProjectsDrop(!projectsDrop)}>
                    {
                        getProjectsLoading ? <Spinner size='sm' color='success' /> : <>
                            <DropdownToggle color='transparent' className='d-flex align-items-center justify-content-between'>
                                <span className='mr-3'>{newTask?.project?.name || t('Choose project')}</span>
                                <i class="fas fa-angle-down"></i>
                            </DropdownToggle>
                            <DropdownMenu style={{ width: "150px", maxHeight: "300px", overflowX: 'hidden', overflowY: 'scroll' }}>
                                {renderProjects()}
                            </DropdownMenu>
                        </>
                    }
                </Dropdown>
            </div>
            <div className='mt-4'>
                <button className='btn click-btn green-btn' disabled={addTaskLoading || !newTask.assignee
                    || !newTask.name || !newTask.project || !newTask.startDate} onClick={submitTask}>
                    {
                        addTaskLoading ? <Spinner color='light' size='sm' /> : t("Add task")
                    }
                </button>
                <button className='btn btn-link' onClick={() => toggle()}>{t("Cancel")}</button>
            </div>
        </div>
    )
}

export default AddTaskModal
import React, { useEffect, useState } from "react";
import { REGISTER_USER } from "../../store/auth/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom'
import { checkIfLoading } from "../../store/functions";
import { Spinner } from "reactstrap";
import { registerUser } from "../../store/actions";
import GoogleAuth from "./GoogleAuth";
import { useTranslation } from "react-i18next";

const Signup = () => {
  const history = useNavigate()
  const dispatch = useDispatch()
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const [firstName, setfirstName] = useState(null)
  const [lastName, setlastName] = useState(null)
  const [t] = useTranslation()

  let { loading, token } = useSelector(state => ({
    loading: checkIfLoading(state, REGISTER_USER),
    token: state?.auth?.token
  }))

  const submitRegister = () => {
    console.log({ email, password, firstName, lastName })
    dispatch(registerUser({ email, password, firstName, lastName }, history))
  }

  useEffect(() => {
    if (token) history('/')
  }, [])

  return (
    <form className="auth-form">
      <h4>{t('sign-up')}</h4>
      <GoogleAuth title="Continue with google" />
      <div className="divider">Or</div>
      <div className="form-group">
        <label>{t('email')}</label>
        <input onChange={(e) => setEmail(e.target.value)} type="email" className="form-control" name="email" />
      </div>
      <div className="form-group">
        <label>{t('password')}</label>
        <input onChange={(e) => setPassword(e.target.value)} type="password" className="form-control" name="password" />
      </div>
      <div className="form-group">
        <label>{t('First Name')}</label>
        <input onChange={(e) => setfirstName(e.target.value)} type="text" className="form-control" name="fname" />
      </div>
      <div className="form-group">
        <label>{t('Last Name')}</label>
        <input onChange={(e) => setlastName(e.target.value)} type="text" className="form-control" name="lname" />
      </div>
      <button type="button" onClick={submitRegister} className="btn click-btn green-btn btn-block">
        {
          loading ? (
            <Spinner
              size="sm"
              className="m-auto text-center"
              color="light"
            />
          ) : (
            "Signup"
          )
        }
      </button>
      <p className="another-choice">{t('Already have an account?')} <Link to="/login" className="btn">{t('Login')}</Link></p>
    </form>
  );
}

export default Signup;

import React, { useEffect, useState } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'
import { useGoogleLogin } from 'react-google-login'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import FeedbackModal from '../../../../components/common/FeedbackModal'
import { authGoogle, listAnalytics } from '../../../../store/actions'
import ConnectGoogle from './ConnectGoogle'

const ConnectionsTab = () => {
    const { workspace } = useSelector(state => ({
        workspace: state?.workspace?.workspace
    }))

    const [feedbackModal, setFeedbackModal] = useState(false);
    const toggle = () => setFeedbackModal(!feedbackModal);
    const [t] = useTranslation()

    return (
        <div className='connections'>
            <h4>{t('recommended-connections')}</h4>
            <span className='text-muted'>{t('Manage your connected accounts and services')}</span>
            <div className='row mt-2'>
                <div className='col-md-8'>
                    <ConnectGoogle icon={`${process.env.PUBLIC_URL}/assets/images/calendar.png`} param={workspace?.connections?.googleCalendar} scope="https://www.googleapis.com/auth/calendar" title='Google Calendar' />
                    <ConnectGoogle icon={`${process.env.PUBLIC_URL}/assets/images/analytics.png`} param={workspace?.connections?.googleAnalytics} scope="https://www.googleapis.com/auth/analytics" title='Google Analytics' />
                    <ConnectGoogle icon={`${process.env.PUBLIC_URL}/assets/images/ads.png`} param={workspace?.connections?.googleAds} scope="https://www.googleapis.com/auth/adwords" title='Google Adwords' />
                    <ConnectGoogle icon={`${process.env.PUBLIC_URL}/assets/images/google-console.png`} param={workspace?.connections?.googleSearchConsole} scope="https://www.googleapis.com/auth/webmasters" title='Google Search Console' />
                </div>
                <div className='col-md-4'>
                    <div className='card text-center'>
                        <div>
                            <h5 className='mb-2'>{t('need-help-to-connect')}</h5>
                            <div className='mb-4'>{t('contact-us-if-any-help')}</div>
                            <button className='btn click-btn green-btn' onClick={() => setFeedbackModal(true)}>{t('contact-us')}</button>
                        </div>
                    </div>
                </div>
            </div>
            <FeedbackModal text="tell-us-how-we-can-help" button="Submit Request" modal={feedbackModal} toggle={toggle} />
        </div>
    )
}

export default ConnectionsTab
import { alpha, Button, Menu, MenuItem, styled } from "@mui/material";
import classNames from "classnames";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { addUserToProject, removeCollaborator, removeUserFromProject } from "../../../../store/actions";
import { getters, useGlobalContext } from "../GlobalContext/GlobalContext";
import "./UserList.css";

const UserList = () => {
   const [state, dispatch] = useGlobalContext();
   const dispatchRedux = useDispatch()
   const anchorRef = useRef(null);
   const [open, setOpen] = useState(false);
   const activeProject = getters.getActiveProject(state);

   const getAllUsers = () => {
      const activeProject = getters.getActiveProject(state);
      return state.allUsers.map((x) => ({
         ...x,
         active: Boolean(activeProject.users.find((u) => u.id === x.id)),
      }));
   };
   const getUsers = () => {
      const users = getters.getActiveProject(state).users;
      return users.map((x, i) => {
         const startRow = users.slice(0, i).reduce((ac, a) => ac + a.noOfRows, 0);
         return { ...x, startRow, endRow: startRow + x.noOfRows };
      });
   };
   const removeUser = () => {
      dispatchRedux(removeCollaborator())
   }
   const users = getUsers();
   const allUsers = getAllUsers();
   const handleClose = () => {
      setOpen(false);
   };
   return (
      <div className="users-wrapper">
         <div className="btn-wrapper" style={{ position: "relative" }}>
            <div className="btn" onClick={() => dispatch({ addNewTask: undefined })}>
               + Add task
            </div>
            <Button className="btn" ref={anchorRef} style={{ textTransform: "none" }} onClick={() => setOpen(!open)}>
               + Add colleague
            </Button>
            <Menu onClose={handleClose} anchorEl={anchorRef.current} open={open}>
               {
                  allUsers?.length > 0 ? allUsers.map((user) => (
                     <MenuItem
                        onClick={() => {
                           dispatchRedux(addUserToProject({
                              project: activeProject._id,
                              collaborator: user?._id
                           }, () => dispatch({ addUserToActiveProject: user._id })))
                        }}
                        className={classNames("subtask-select-item user-select-item", { active: user.active })}
                     >
                        <img src={user?.profilePicture || `${process.env.PUBLIC_URL}/assets/images/avatar.png`} onError={({ currentTarget }) => {
                           currentTarget.onerror = null; // prevents looping
                           currentTarget.src = `${process.env.PUBLIC_URL}/assets/images/avatar.png`;
                        }}></img>
                        <div>{user?.fullName || user?.email}</div>
                        <div className="email">{user.email}</div>
                     </MenuItem>
                  )) : <MenuItem
                     className={classNames("subtask-select-item user-select-item")}>No User found</MenuItem>
               }
            </Menu>
         </div>
         <div className="users">
            {users.map((user) => (
               <div style={{ gridRow: `span ${user.noOfRows}` }} className="user" v-for="user in getUsers">
                  {
                     user?.id !== '-1' ? <div className="user-body">
                        <img src={user?.profilePicture || `${process.env.PUBLIC_URL}/assets/images/avatar.png`} onError={({ currentTarget }) => {
                           currentTarget.onerror = null; // prevents looping
                           currentTarget.src = `${process.env.PUBLIC_URL}/assets/images/avatar.png`;
                        }} style={{ visibility: user._id === "-1" ? "hidden" : "initial" }} />
                        <div style={{ width: '100%' }} className="d-flex justify-content-between">
                           <span>{user.fullName || user?.email}</span>
                           <span className="cursor-pointer" onClick={() => {
                              dispatchRedux(removeUserFromProject({
                                 project: activeProject._id,
                                 collaborator: user?._id
                              }, () => dispatch({ removeUserFromActiveProject: user._id })))
                           }}><i className="fas fa-times mr-1"></i></span>
                        </div>
                     </div> : ""
                  }
               </div>
            ))}
         </div>
      </div>
   );
};

export default UserList;

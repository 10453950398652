import api from '../apis/api'
import { tokenConfig } from '../config'

export const ListProjectsAPI = (query, state) => {
  return new Promise((res, rej) => {
    api.get(`/projects?${query}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const GetProjectAPI = (id, state) => {
  return new Promise((res, rej) => {
    api.get(`/projects/${id}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const UpdateProjectAPI = (id, body, state) => {
  return new Promise((res, rej) => {
    api.put(`/projects/${id}`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const DeleteProjectAPI = (id, state) => {
  return new Promise((res, rej) => {
    api.delete(`/projects/${id}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const AddProjectAPI = (body, state) => {
  return new Promise((res, rej) => {
    api.post(`/projects`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const AddUserToProjectAPI = (body, state) => {
  return new Promise((res, rej) => {
    api.post(`/projects/user`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const RemoveUserFromProjectAPI = (body, state) => {
  return new Promise((res, rej) => {
    api.post(`/projects/user/remove`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const ListTasksAPI = (body, state) => {
  return new Promise((res, rej) => {
    api.post(`/login`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const AddTaskAPI = (id, body, state) => {
  return new Promise((res, rej) => {
    api.post(`/projects/${id}/task`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const EditTaskAPI = (id, body, state) => {
  return new Promise((res, rej) => {
    api.put(`/projects/task/${id}`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const DeleteTaskAPI = (id, state) => {
  return new Promise((res, rej) => {
    api.delete(`/projects/task/${id}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const AddSubtaskAPI = (id, body, state) => {
  return new Promise((res, rej) => {
    api.post(`/projects/task/${id}/subtask`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const UpdateSubtaskAPI = (id, body, state) => {
  return new Promise((res, rej) => {
    api.put(`/projects/subtask/${id}`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const DeleteSubtaskAPI = (id, state) => {
  return new Promise((res, rej) => {
    api.delete(`/projects/subtask/${id}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const SetTaskCompleteAPI = (body, state) => {
  return new Promise((res, rej) => {
    api.post(`/login`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const SetSubtaskDoneAPI = (body, state) => {
  return new Promise((res, rej) => {
    api.post(`/login`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}
import React, { useEffect, useState } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import CustomTooltip from '../../../components/common/CustomTooltip'
import TablePagination from '../../../components/common/Pagination'
import { competitorsBacklinks, listCompetitors, removeCompetitor } from '../../../store/actions'
import { checkIfLoading } from '../../../store/functions'
import { COMPETITORS_BACKLINKS, LIST_BACKLINKS, LIST_COMPETITORS } from '../../../store/webreputation/actionTypes'
import CompetitorsBacklinksTable from './components/CompetitorsBacklinksTable'
import CompetitorsTable from './components/CompetitorsTable'
import CreateCampaignModal from './components/CreateCampaignModal'
import CampaignEmail from './components/Email'
import WebReputationNav from './components/Nav'

function WebReputation() {
  const history = useNavigate()
  const [campaignModal, setCampaignModal] = useState(false)
  const [t] = useTranslation()

  const [campaignDetails, setCampaignDetails] = useState({
    emails: [
      {
        order: 1,
        from: "",
        subject: "new email",
        name: "First Email",
        content: '',
        day: new Date(),
      },
      {
        order: 2,
        from: "",
        subject: "new email",
        name: "Follow up",
        content: '',
        day: new Date()
      },
      {
        order: 3,
        from: "",
        subject: "new email",
        name: "Last Email",
        content: '',
        day: new Date()
      }
    ],
    name: null
  })

  const [selectedEmails, setSelectedEmails] = useState([])

  const handleSelectedEmails = (email) => {
    if (selectedEmails?.includes(email)) {
      let newEmails = selectedEmails
      newEmails = newEmails?.filter(e => e !== email)
      setSelectedEmails(newEmails)
    } else {
      setSelectedEmails([
        ...selectedEmails,
        email
      ])
    }
  }

  const { competitors, compDetails, backlinks, backlinksDetails, compLoading, bkLoading, workspace } = useSelector(state => ({
    competitors: state?.webreputation?.competitors?.list,
    compDetails: state?.webreputation?.competitors?.page_details,
    backlinks: state?.webreputation?.competitorBacklinks?.list,
    backlinksDetails: state?.webreputation?.competitorBacklinks?.page_details,
    compLoading: checkIfLoading(state, LIST_COMPETITORS),
    bkLoading: checkIfLoading(state, COMPETITORS_BACKLINKS),
    workspace: state?.workspace?.workspace
  }))

  const updateEmail = (order, key, value) => {
    let { emails } = campaignDetails
    emails = emails?.map(e => {
      if (e.order === order) {
        return {
          ...e,
          [key]: value
        }
      } else {
        return e
      }
    })
    setCampaignDetails({
      ...campaignDetails,
      emails
    })
  }

  useEffect(() => {
    setCampaignDetails({
      emails: [
        {
          order: 1,
          from: "",
          subject: "new email",
          name: "First Email",
          content: '',
          day: new Date(),
        },
        {
          order: 2,
          from: "",
          subject: "new email",
          name: "Follow up",
          content: '',
          day: new Date()
        },
        {
          order: 3,
          from: "",
          subject: "new email",
          name: "Last Email",
          content: '',
          day: new Date()
        }
      ],
      name: null
    })
    setSelectedEmails([])
  }, [workspace])

  const updateName = (value) => {
    setCampaignDetails({
      ...campaignDetails,
      name: value
    })
  }

  const renderEmails = () => {
    return campaignDetails?.emails?.map(e => (
      <CampaignEmail senders={workspace?.senders || []} day={e.day} deleteEmail={deleteEmail} from={e.from} updateEmail={updateEmail} order={e.order} name={e.name} subject={e.subject} content={e.content} />
    ))
  }

  const createNewEmail = () => {
    const newEmail = {
      order: campaignDetails?.emails?.length + 1,
      from: "",
      subject: "new email again",
      name: "Follow up",
      content: '',
      day: campaignDetails.emails?.length > 1 ? campaignDetails.emails[campaignDetails?.emails?.length - 1].day + 1 : 1
    }
    let emails = campaignDetails?.emails
    emails?.splice(emails?.length - 1, 0, newEmail)

    setCampaignDetails({
      ...campaignDetails,
      emails: emails
    })
  }

  const deleteEmail = (id) => {
    let emails = campaignDetails.emails
    emails = emails.filter(e => e.order !== id)

    setCampaignDetails({
      ...campaignDetails,
      emails
    })
  }

  return (
    <div className='container-fluid web-reputation page mb-2'>
      <WebReputationNav />
      <div className='text-center my-5'>
        <h5>{t('increase-web-reputation')}</h5>
        <span>Analyze your competitors' strategy by finding out who talks about them and get them talked about You</span>
      </div>
      <div className='mt-2 mb-4'>
        <h5><span className='h4'>1.</span> {t('choose-your-competitors')} <CustomTooltip header="This is tooltip for" item='choose_competitors' /></h5>
        <span>We found {competitors?.length} {t('competitors-for-your-domain')}</span>
      </div>
      <CompetitorsTable loading={compLoading} details={compDetails} competitors={competitors} />
      <hr />
      <h5 className='my-4'><span className='h4'>2.</span> {t('see-who-speeks-about-your-competitors')} <CustomTooltip header="This is tooltip for" item='who_speaks' /></h5>
      <CompetitorsBacklinksTable handleSelectedEmails={handleSelectedEmails} selectedEmails={selectedEmails} loading={bkLoading} details={backlinksDetails} competitors={competitors} backlinks={backlinks} />
      <hr />
      <div className='text-center'>
        <h5>3 {t('contact-who-speeks')} <CustomTooltip header="This is tooltip for" item='contact_competitors' /></h5>
        <div>Let us generate emails for you based on your area of expertise</div>
        <button className='btn click-btn orange-btn mt-4' onClick={() => history('/content-generator')}>{t('generate-your-content')}</button>
      </div>
      <div className='row mt-3 justify-content-center'>
        <div className='col-md-6 mt-2'>
          <span className='emails-line' style={{ height: 780 + ((campaignDetails.emails?.length - 3) * 362)}}></span>
          <div className='emails'>
            {renderEmails()}
          </div>
          <button className='btn btn-outline-secondary btn-block mt-3' onClick={createNewEmail}><i className='fas fa-plus'></i> {t('create-new-email')}</button>
        </div>
      </div>
      <hr />
      <div className='row justify-content-end mb-3'>
        <div className='col-md-6 text-right'>
          <button className='btn click-btn orange-btn' onClick={() => {
            setCampaignModal(true)
          }}>{t('launch-your-campaign')}</button>
        </div>
      </div>
      <CreateCampaignModal campaignDetails={campaignDetails} backlinkEmails={selectedEmails} name={campaignDetails.name} setName={updateName} modal={campaignModal} toggle={() => setCampaignModal(!campaignModal)} />
    </div>
  )
}

export default WebReputation
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { getWorkspace } from '../../../store/actions';
import { checkIfLoading } from '../../../store/functions';
import { GET_WORKSPACE } from '../../../store/workspace/actionTypes';

function DomianDropdown() {
    // ** states
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useDispatch()
    const history = useNavigate()

    // ** function to handle toggle of dropdown
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const { workspaces, workspace, workspaceLoading } = useSelector(state => ({
        workspaces: state?.workspace?.workspaces,
        workspace: state?.workspace?.workspace,
        workspaceLoading: checkIfLoading(state, GET_WORKSPACE)
    }))

    const selectWorkspace = (id) => {
        dispatch(getWorkspace(id))
    }

    const renderWorkspaces = () => {
        if (workspaces?.length > 0) {
            return workspaces?.map(w => {
                return <DropdownItem>
                    <button onClick={() => selectWorkspace(w._id)} className="btn">{w.domain}</button>
                </DropdownItem>
            })
        }
    }

    return (
        <div className='domain-con'>
            <Dropdown className='custom-dropdown' isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle caret>
                    {workspaceLoading ? 'Loading...' : workspace?.domain || workspace?.name}
                </DropdownToggle>
                <DropdownMenu right={true}>
                    {/* <div className='pl-4 text-dark'><b>{workspace?.domain || workspace?.name}</b></div> */}
                    {/* <hr /> */}
                    {renderWorkspaces()}
                    <hr className='my-1'/>
                    <DropdownItem>
                        <div className='p-2' onClick={() => history('/add-workspace')}><i className='fas fa-plus-circle mr-2'></i> Add Workspace</div>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    )
}

export default DomianDropdown
import React, { useEffect, useState } from 'react';
import { useGoogleLogin } from 'react-google-login';
import { refreshTokenSetup } from '../../store/functions';
import { gapi } from 'gapi-script';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authGoogle } from '../../store/actions';
import { Spinner } from 'reactstrap';

const clientId = '816940198937-hvkmu9l2eiso4mjtddp77te5j1lbieo3.apps.googleusercontent.com';

const GoogleAuth = ({ title }) => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const { auth, user } = useSelector(state => ({
        auth: state.auth,
        user: state.auth.user
    }))

    // useEffect(() => {
    //     function start() {
    //         gapi.client.init({
    //             clientId: clientId,
    //             scope: 'email',
    //         });
    //     }

    //     gapi.load('client:auth2', start);
    // }, []);

    const onSuccess = (res) => {
        console.log(res)
        setLoading(false)
        if (res.code) {
            dispatch(authGoogle({ code: res.code }, history))
        } else {
            if(auth.token) {
                refreshTokenSetup(res, { auth });
            } else {
                refreshTokenSetup(res, null);
            }
        }
    };

    const onFailure = (res) => {
        setLoading(false)
        console.log('Login failed: res:', res);
    };

    const { signIn } = useGoogleLogin({
        onSuccess,
        onFailure,
        clientId,
        accessType: 'offline',
        responseType: 'code',
        prompt: 'consent',
        scope: "https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar openid https://www.googleapis.com/auth/analytics",
        redirectUri: process.env.REACT_APP_CALLBACK_URL
    });

    return (
        <button type="button" disabled={loading} onClick={() => {
            setLoading(true)
            signIn()
        }} className="btn google-auth d-flex justify-content-center align-items-center">
            {
                loading ? <Spinner
                    size="md"
                    className="m-auto text-center"
                    color="success"
                /> : <>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/googleicon.svg`} className="mr-2" alt='google-icon' />
                    <div>{title}</div>
                </>
            }
        </button>
    );
}

export default GoogleAuth;
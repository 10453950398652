import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FormCheck, Spinner, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import AreaChart from '../../../../components/charts/AreaChart'
import TablePagination from '../../../../components/common/Pagination'
import { LIST_CUSTOMERS } from '../../../../store/crm/actionTypes'
import { checkIfLoading } from '../../../../store/functions'

const BestSellingPeriod = ({ data, loading }) => {
    const [bestSellingData, setBestSellingData] = useState([])
    const [t] = useTranslation()

    useEffect(() => {
        if (data) {
            const traffic = []
            console.log(data)
            data.forEach(e => {
                traffic.push({
                    label: moment(e.date).format('MMMM'),
                    value: e.total
                })
            })
            setBestSellingData(traffic)
        }
    }, [data])

    return (
        <div className='traffic-history'>
            {
                data?.length > 0 ? <AreaChart name="Spending" data={bestSellingData} curveType="smooth" showYaxis={true} /> : 
                <div className='alert alert-info'>{t('No data found')}</div>
            }
        </div>
    )
}

export default BestSellingPeriod
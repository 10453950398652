import React, { useEffect, useState } from 'react'
import BusinessModal from './BusinessModal';
import ContactUsModal from '../../../components/modals/ContactUsModal';
import { useDispatch, useSelector } from 'react-redux';
import { getBusiness } from '../../../store/actions';
import { useNavigate } from 'react-router-dom';
import { checkIfLoading } from '../../../store/functions';
import { GET_BUSINESS } from '../../../store/localseo/actionTypes';
import { Spinner } from 'reactstrap';
import FeedbackModal from '../../../components/common/FeedbackModal';
import { useTranslation } from 'react-i18next';

function index() {
  // ** states
  const [businessModal, setBusinessModal] = useState(false);
  const [contactUsModal, setContactUsModal] = useState(false);
  const dispatch = useDispatch()
  const history = useNavigate()
  const [t] = useTranslation()

  const { workspace, getLoading } = useSelector(state => ({
    workspace: state?.workspace?.workspace,
    getLoading: checkIfLoading(state, GET_BUSINESS)
  }))

  const toggleBusinessModal = () => setBusinessModal(!businessModal);
  const toggleContactUsModal = () => setContactUsModal(!contactUsModal);

  useEffect(() => {
    if (workspace?.business) {
      console.log({
        business: workspace?.business
      })
      dispatch(getBusiness(workspace?.business, null, history))
    }
  }, [workspace?.business])

  return (
    <>
      {
        getLoading ? <div className='text-center'>< Spinner color='success' size='xl' /></div > : <div className='card local-seo'>
          <button type='button' className='btn google-btn' onClick={toggleBusinessModal}>
            <svg data-v-da77f7da="" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                data-v-da77f7da=""
                d="M19.7869 10.2247C19.7869 9.56634 19.7286 8.94134 19.6286 8.33301H10.2119V12.0913H15.6036C15.3619 13.3247 14.6536 14.3663 13.6036 15.0747V17.5747H16.8202C18.7036 15.833 19.7869 13.2663 19.7869 10.2247Z"
                fill="#4285F4">
              </path>
              <path
                data-v-da77f7da=""
                d="M10.2121 19.9999C12.9121 19.9999 15.1704 19.0999 16.8204 17.5749L13.6038 15.0749C12.7038 15.6749 11.5621 16.0415 10.2121 16.0415C7.60378 16.0415 5.39544 14.2832 4.60378 11.9082H1.28711V14.4832C2.92878 17.7499 6.30378 19.9999 10.2121 19.9999Z"
                fill="#34A853"
              ></path>
              <path
                data-v-da77f7da=""
                d="M4.60358 11.9083C4.39525 11.3083 4.28691 10.6666 4.28691 9.99993C4.28691 9.33327 4.40358 8.6916 4.60358 8.0916V5.5166H1.28691C0.60358 6.8666 0.211914 8.38327 0.211914 9.99993C0.211914 11.6166 0.60358 13.1333 1.28691 14.4833L4.60358 11.9083Z"
                fill="#FBBC05">
              </path>
              <path
                data-v-da77f7da=""
                d="M10.2121 3.95833C11.6871 3.95833 13.0038 4.46667 14.0454 5.45834L16.8954 2.60833C15.1704 0.991668 12.9121 0 10.2121 0C6.30378 0 2.92878 2.25 1.28711 5.51667L4.60378 8.09167C5.39544 5.71667 7.60378 3.95833 10.2121 3.95833Z"
                fill="#EA4335">
              </path>
            </svg>
            {t('connect-google-business')}
          </button>
          <h2>{t('want-managed-service')}</h2>
          <p>{t("If you don't have a Google My Business profile you can contact us to create a new one for you")}</p>
          <button type='button' className='btn contact-us-btn' onClick={toggleContactUsModal}>{t('contact-us')}</button>
          <BusinessModal modal={businessModal} toggle={toggleBusinessModal} />
          <FeedbackModal text="tell-us-how-we-can-help" button="submit-request" modal={contactUsModal} toggle={toggleContactUsModal} />
        </div>
      }
    </>
  )
}

export default index
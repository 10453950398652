import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Progress, Spinner } from 'reactstrap'
import { getCrmAnalytics } from '../../../store/actions'
import { GET_ANALYTICS } from '../../../store/crm/actionTypes'
import { checkIfLoading } from '../../../store/functions'
import BestSellingPeriod from './Components/BestSellingPeriod'
import CrmNav from './Components/Nav'

function Crm() {
  const dispatch = useDispatch()
  const colors = ['orange', 'danger', 'primary', 'warning', 'danger', 'info', 'secondary']

  const { workspace, analytics, loading } = useSelector(state => ({
    workspace: state?.workspace?.workspace,
    analytics: state?.crm?.analytics,
    loading: checkIfLoading(state, GET_ANALYTICS)
  }))
  const [t] = useTranslation()

  useEffect(() => {
    dispatch(getCrmAnalytics())
  }, [workspace])

  const renderClients = () => {
    const clients = analytics.newCustomers
    if (loading) return <div className='text-center'><Spinner size="sm" className="m-auto text-center" color="success" /></div>
    if (clients && clients?.length > 0) {
      return clients.map(c => {
        return <li className='d-flex justify-content-between border-bottom mb-2'>
          <span>{c?.name}</span>
          <span>{new Date(c?.createdAt).toLocaleDateString()}</span>
        </li>
      })
    } else {
      return <div className='alert alert-info'>No data found</div>
    }
  }

  const renderMostSpendingProgress = (clients) => {
    if (clients?.length > 0) {
      return clients.map((e, i) => {
        return <Progress bar color={colors[i]} value={Number(e.percent)} />
      })
    }
  }

  const renderMostSpending = (clients) => {
    if (clients?.length > 0) {
      return clients.map((e, i) => {
        return <li className='d-flex justify-content-between mb-2'>
          <span><span className={`list-circle bg-${colors[i]}`}></span>{e.name}</span>
          <span><b>${e.spendings}</b></span>
        </li>
      })
    }
  }

  const renderBestSellingProgress = (clients) => {
    if (clients?.length > 0) {
      return clients.map((e, i) => {
        return <Progress bar color={colors[i]} value={Number(e.percent)} />
      })
    }
  }

  const renderBestSelling = (clients) => {
    if (clients?.length > 0) {
      return clients.map((e, i) => {
        return <li className='d-flex justify-content-between mb-1'>
          <span><span className={`list-circle bg-${colors[i]}`}></span>{e.name}</span>
          <span><b>${e.percent}</b></span>
        </li>
      })
    }
  }

  const renderWeather = (days) => {
    if (days?.length > 0) {
      return days.map((day, i) => {
        return <li className='d-flex align-items-center justify-content-between mb-1'>
          <div className='d-flex align-items-center'>
            <img className='img-responsive' width={30} src={day.icon} />
            <span><b>{day?.text}</b> ({day?.avgTemp})</span>
          </div>
          <span>{moment(day?.date).format('MMM-DD')}</span>
        </li>
      })
    }
  }

  return (
    <div className='container-fluid crm-analytics page'>
      <CrmNav />
      <div className='row mt-4'>
        <div className='col-md-6'>
          <h3 className='mt-2'>{t('CRM Dashboard')}</h3>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='analytics-box'>
                <h6>{t('New Clients')}</h6>
                <ul className='list-unstyled mt-2'>
                  {renderClients()}
                </ul>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='analytics-box'>
                <h6 className='m-0 mb-1'>{t('Most spending clients')}</h6>
                <div className='mb-2'><small>{t('Last 30days')}</small></div>
                {
                  loading ? <div className='text-center'><Spinner size="sm" className="m-auto text-center" color="success" /></div> : 
                  analytics?.mostSpendingInLast30 && analytics?.mostSpendingInLast30?.length > 0 ?
                  <><Progress multi>
                    {renderMostSpendingProgress(analytics?.mostSpendingInLast30)}
                  </Progress>
                    <ul className='mt-3'>
                      {renderMostSpending(analytics?.mostSpendingInLast30)}
                    </ul>
                  </> : <div className='alert alert-info'>{t('No data found')}</div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='analytics-box'>
            <h6>{t('My Best Selling Period')}</h6>
            {loading ? <div className='text-center'><Spinner size="sm" className="m-auto text-center" color="success" /></div> : <BestSellingPeriod data={analytics?.bestSellingPeriod} />}
          </div>
        </div>
      </div>
      <div className='row mt-2'>
        <div className='col-md-6'>

        </div>
        <div className='col-md-6'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='analytics-box'>
                <h6>{t('Best Selling Sellers')}</h6>
                {
                  loading ? <div className='text-center'><Spinner size="sm" className="m-auto text-center" color="success" /></div> : 
                  analytics?.bestSellingClients && analytics?.bestSellingClients?.length > 0 ?
                  <><Progress multi>
                    {renderBestSellingProgress(analytics?.bestSellingClients)}
                  </Progress>
                    <ul>
                      {renderBestSelling(analytics?.bestSellingClients)}
                    </ul>
                  </> : <div className='alert alert-info'>{t('No data found')}</div>
                }
              </div>
            </div>
            {/* <div className='col-md-6'>
              <div className='analytics-box'>
                <h6>Weather in next 15 days</h6>
                <span>{workspace.location}</span>
                <hr />
                {
                  loading ? <div className='text-center'><Spinner size="sm" className="m-auto text-center" color="success" /></div> : <ul>
                    {renderWeather(analytics?.weather)}
                  </ul>
                }
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Crm
import React from "react";

// ** import routes
import routes from "./routes/index";

// ** Error page Not found
import Error from "../pages/error/Error";

// ** home ( Dashboard ) page
import Dashboard from "../pages/admin/dashboard/index";

// ** import constant components
import Sidemenu from "../components/layouts/sidemenu/index";
import Nav from "../components/layouts/navbar/index";
import Feedback from "../components/layouts/Feedback";
import Header from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";

// ** router dom imports
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import PrivateRoute from "./routes/privateRoute";
import PublicRoute from "./routes/publicRoute";

const Router = () => {
  // ** routes of default layout
  const defaultLayoutRoutes = routes.filter((ele) => ele.layout === "dashboard");

  // ** routes of auth layout
  const authLayoutRoutes = routes.filter((ele) => ele.layout === "auth");

  // ** default layout
  function defaultLayout() {
    return (
      <>
        <Nav />
        <Sidemenu />
        <Feedback />
        <div className="content">
          <Outlet />
        </div>
      </>
    );
  }

  // ** auth layout
  function authLayout() {
    return (
      <>
        <Header />
        <Outlet />
        <Footer />
      </>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        {/* default layout */}
        <Route element={defaultLayout()}>
          {defaultLayoutRoutes.map((ele, index) => (
            <Route exact key={index} path={ele.path} element={
              <PrivateRoute>
                {ele.component}
              </PrivateRoute>
            } />
          ))}
        </Route>

        {/* auth layout */}
        <Route path="/" element={authLayout()}>
          {authLayoutRoutes.map((ele, index) => (
            <Route exact key={index} path={ele.path} element={
              <PublicRoute>
                {ele.component}
              </PublicRoute>
            } />
          ))}
        </Route>

        {/* if route is fault redirect to error page */}
        <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

import { put, select, takeEvery } from "redux-saga/effects"
import { DisconnectBusinessAPI, GetBusinessAPI, ListBusinessesAPI } from "./calls"
import { disconnectBusinessSuccess, getBusinessSuccess, listBusinessesSuccess, selectBusinessSuccess } from './actions'
import { DISCONNECT_BUSINESS, GET_BUSINESS, LIST_BUSINESSES, SELECT_BUSINESS } from "./actionTypes"
import { setError, startAction, stopAction, updateWorkspaceBusiness } from "../actions"

function* listBusinesses({ type, payload: { name, toggle1, toggle2 } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const workspace = state.workspace.workspace?._id
    const query = new URLSearchParams({ name, workspace }).toString()
    const result = yield ListBusinessesAPI(query, state)
    console.log(result)
    yield put(listBusinessesSuccess({
      data: result?.data?.data,
      keyword: name
    }))
    if (toggle1) toggle1()
    if (toggle2) toggle2()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* getBusiness({ type, payload: { id, workspace, history } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    let query = {}
    if (workspace) query = { workspace }
    query = new URLSearchParams(query).toString()
    const result = yield GetBusinessAPI(id, query, state)
    console.log(result)
    if(workspace) {
      localStorage.setItem('workspace', JSON.stringify({
        ...state.workspace.workspace,
        business: id
      }))
      yield put(updateWorkspaceBusiness(id))
    }
    yield put(getBusinessSuccess(result?.data?.data))
    if (history) history('/local-seo/detailed-report')
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* selectBusiness({ type, payload: { id } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield GetBusinessAPI(id, '', state)
    console.log(result)
    yield put(selectBusinessSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* disconnectBusiness({ type, payload: { history } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const workspace = state.workspace.workspace?._id
    let query = { workspace }
    query = new URLSearchParams(query).toString()
    const result = yield DisconnectBusinessAPI(query, state)
    console.log(result)
    localStorage.setItem('workspace', JSON.stringify({
      ...state.workspace.workspace,
      business: null
    }))
    yield put(updateWorkspaceBusiness(null))
    yield put(disconnectBusinessSuccess(null))
    history('/local-seo')
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* LocalseoSaga() {
  yield takeEvery(LIST_BUSINESSES, listBusinesses)
  yield takeEvery(GET_BUSINESS, getBusiness)
  yield takeEvery(SELECT_BUSINESS, selectBusiness)
  yield takeEvery(DISCONNECT_BUSINESS, disconnectBusiness)
}

export default LocalseoSaga
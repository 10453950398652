import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'reactstrap'
import DataTableComponent from '../../../components/tables/DataTable'
import SmallTable from '../../../components/tables/SmallTable'

function OrganicSearch({ data, loading }) {
    const [t] = useTranslation()
    const [tableData, setTableData] = useState([])

    useEffect(() => {
        if (data && data?.length > 0) {
            const mappedData = data?.map(e => {
                return {
                    id: e?.keyword,
                    keyword: e?.keyword,
                    ctr: e?.ctr,
                    impressions: e?.impressions,
                    clicks: e?.clicks,
                }
            })
            setTableData(mappedData)
        } else {
            setTableData([])
        }
    }, [data])

    const columns = [
        {
            name: t('Keyword'),
            selector: "keyword",
            sortable: true,
            cell: e => {
                return <p style={{ fontWeight: 600, fontSize: "13px", color: "#000", marginBottom: 0 }}>{e.keyword}</p>
            },
        },
        {
            name: t('Ctr'),
            selector: "ctr",
            sortable: true,
            cell: e => {
                return <p style={{ fontWeight: 800, fontSize: "14px", color: "#000", marginBottom: 0 }}>{e.ctr}</p>
            },
        },
        {
            name: t('Impressions'),
            selector: "impressions",
            sortable: true,
            cell: e => {
                return <p style={{ fontWeight: 800, fontSize: "14px", color: "#000", marginBottom: 0 }}>{e.impressions}</p>
            },
        },
        {
            name: t('Clicks'),
            selector: "clicks",
            sortable: true,
            cell: e => {
                return <p style={{ fontWeight: 800, fontSize: "14px", color: "#000", marginBottom: 0 }}>{e.clicks}</p>
            },
        },
    ];

    console.log({
        data
    })

    return (
        <div className='organic-search card'>
            <h5 className='mb-1'>{t('Organic Search')}</h5>
            <span className='mb-3 text-muted'><small>{t('Traffic by Organic Keywords')}</small></span>
            {
                loading ? <Spinner size="md" className="m-auto text-center" color="success" /> : <div className='data-list'>
                    <DataTableComponent limit={10} paginationPerPage={[10, 20, 40]} columns={columns} data={tableData} />
                </div>
            }
        </div>
    )
}

export default OrganicSearch
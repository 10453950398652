import {
  GET_COMPETITOR_DATA,
  GET_COMPETITOR_DATA_SUCCESS
} from "./actionTypes"

export const getCompetitorData = (query) => {
  return {
    type: GET_COMPETITOR_DATA,
    payload: { query },
  }
}

export const getCompetitorDataSuccess = (data) => {
  return {
    type: GET_COMPETITOR_DATA_SUCCESS,
    payload: data
  }
}
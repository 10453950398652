import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { numberWithCommas } from "../../../../store/functions";

function CompetitorCountry({ competitor }) {
    const [chartOptions, setChartOptions] = useState(null)
    const [t] = useTranslation()

    useEffect(() => {
        let countries = competitor?.data?.traffic?.countries

        countries = countries?.map(s => {
            return {
                x: s.percent,
                y: s.value
            }
        })

        if (countries?.length > 0) {
            setChartOptions({
                series: [{
                    data: countries
                }],
                options: {
                    plotOptions: {
                        bar: {
                            borderRadius: 4,
                            dataLabels: {
                                position: 'top',
                            },
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                            return val + '%';
                        },
                        offsetY: -20,
                        style: {
                            fontSize: '12px',
                            colors: ["#304758"]
                        }
                    },
                    tooltip: {
                        enabled: false,
                    },
                    // xaxis: {
                    //     categories: ['-48 h', '', '', '', '', '', '', '', '', 'Now'],
                    //     labels: {
                    //         rotate: 0
                    //     }
                    // },
                    yaxis: {
                        show: false
                    },
                    colors: ['#EF8451'],
                },
            })
        }
    }, [competitor])


    const renderCountries = () => {
        const countries = competitor?.data?.traffic?.countries
        if (countries && countries?.length > 0) {
            return countries?.map(country => {
                return <li className="p-2 d-flex align-items-center border-bottom">
                    <img className="circled-image" src={`${process.env.PUBLIC_URL}/assets/images/flags/flag_en.png`} />
                    <div className="ml-2">
                        <div>{country?.country}</div>
                        <div className="h6">{country?.percent}%</div>
                    </div>
                </li>
            })
        } else {
            return <li>{t('No Countries found')}</li>
        }
    }

    return (
        <div className="card mt-3 competitors-traffic">
            <div className="d-flex align-items-center justify-content-between">
                <div>
                    <h4>{t('Geography & Country Targeting')}</h4>
                    <span className="text-muted">
                        {t("Identify the geographic location of a site's core audience over the last month. The country sending the most traffic to")}
                        {competitor?.data?.site_url} is USA.
                        ({t('On Desktop')})
                    </span>
                </div>
                {/* <div className="bg-gray rounded-10 p-3"><i class="fas fa-share-alt"></i></div> */}
            </div>
            <div className="row mt-4">
                <div className="col-md-8">
                    {
                        chartOptions ? <Chart height={400} options={chartOptions.options} series={chartOptions.series} type="bar" /> : ""
                    }
                </div>
                {/* <div className="col-md-4 bg-gray rounded-10 py-2">
                    <h5>Top countries</h5>
                    <ul className="list-unstyled">
                        {renderCountries()}
                    </ul>
                </div> */}
            </div>
        </div>
    );
}

export default CompetitorCountry;
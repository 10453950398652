import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Card } from 'reactstrap';
import CustomTooltip from '../common/CustomTooltip';

function Issues({ data, setPage }) {
  // ** states
  const [isOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState([])
  const [t] = useTranslation()
  const [openNeeded, setOpenNeeded] = useState(false)
  const [openNotNeeded, setOpenNotNeeded] = useState(false)

  console.log(data)

  useEffect(() => {
    if (data) {
      let errors = []
      for (let [key, value] of Object.entries(data)) {
        if (key !== 'pages' && key !== 'issuesCount' && key !== 'badUrls' && key !== 'url') {
          errors.push({
            key,
            value
          })
        }
      }
      setErrors(errors)
    }
  }, [data])

  const renderErrors = (errors) => {
    if (errors && errors?.length > 0) {
      return errors.map(err => {
        return <>
          <Button key={err.key} className={isOpen === err?.key ? 'toggled-btn issue-btn' : 'issue-btn'} onClick={() => isOpen === err?.key ? setIsOpen(null) : setIsOpen(err?.key)}>
            <CustomTooltip item={err?.key} header="What is this and why it is important" />
            {t(err.key) || err.key}
            {
              typeof (err?.value) === 'boolean' || !err.value ? <span className='numbered-issue boolean-issue'>{`${err?.value === null ? false : err?.value}`}</span> :
                <span className='numbered-issue'>{Array.isArray(err?.value) ? err?.value?.length : err.value}</span>
            }
          </Button>
          {
            isOpen === err?.key ? Array.isArray(err.value) && err?.value?.length > 0 ? err?.value?.map(e => {
              return <Card className='text-left issue-link mb-1 p-2'>
                <div className='btn text-left' onClick={() => setPage(e)}>{e}</div>
              </Card>
            }) : "" : ""
          }
        </>
      })
    }
  }

  return (
    <div className='page-issues pb-5'>
      <h4>{t('page-issues')}</h4>
      {/* <Tooltip placement="right" isOpen={true} target="mohaned">Mohaned magdy</Tooltip> */}
      <div className='issues'>
        <Button className={`${openNeeded ? 'toggled-btn issue-btn' : 'issue-btn'} mb-2`} onClick={() => setOpenNeeded(!openNeeded)}>
          {t('Needs Action')}
        </Button>
        {
          openNeeded ? <Card>
            {renderErrors(errors)}
          </Card> : ""
        }
        <Button className={`${openNotNeeded ? 'toggled-btn issue-btn' : 'issue-btn'} mt-2`} onClick={() => setOpenNotNeeded(!openNotNeeded)}>
          {t("Doesn't need Action")}
        </Button>
        {
          openNotNeeded ? <Card>
            {renderErrors(errors)}
          </Card> : ""
        }
      </div>
    </div>
  )
}

export default Issues
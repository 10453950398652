import { REMOVE_COMPETITOR, REMOVE_COMPETITOR_SUCCESS } from "../webreputation/actionTypes"
import {
  GET_COMPETITOR_DATA,
  GET_COMPETITOR_DATA_SUCCESS
} from "./actionTypes"

const initialState = {
  competitor: null
}

const CompetitorAnalysis = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPETITOR_DATA:
      return { ...state, competitor: null }
    case GET_COMPETITOR_DATA_SUCCESS:
      return { ...state, competitor: action.payload }
    case REMOVE_COMPETITOR:
      return { ...state }
    case REMOVE_COMPETITOR_SUCCESS:
      return { ...state, competitor: null }
    default:
      return state
  }
}

export default CompetitorAnalysis
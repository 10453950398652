import { put, select, takeEvery } from "redux-saga/effects"
import { ADD_SENDER_EMAIL, ADD_WORKSPACE, CANCEL_WORKSPACE_SUBSCRIPTION, DELETE_WORKSPACE, DISCONNECT_GOOGLE, GET_DOMAIN, GET_WORKSPACE, GET_WORKSPACE_CONNECTIONS, INVITE_COLLABORATOR, LIST_WORKSPACES, LIST_WORKSPACE_BILLINGS, LIST_WORKSPACE_LANGUAGES, LIST_WORKSPACE_REGIONS, REGISTER_DOMAIN, REMOVE_COLLABORATOR, SELECT_WORKSPACE_ANALYTICS, SELECT_WORKSPACE_SEARCH_CONSOLE, UPDATE_WORKSPACE, UPDATE_WORKSPACE_LOGO } from "./actionTypes"
import { addSenderEmailSuccess, addWorkspaceSuccess, cancelSubscriptionSuccess, deleteWorkspaceSuccess, disconnectGoogleSuccess, getDomainSuccess, getWorkspace, getWorkspaceConnectionsSuccess, getWorkspaceSuccess, inviteCollaboratorSuccess, listWorkspaceBillingsSuccess, listWorkspaceLanguagesSuccess, listWorkspaceRegionsSuccess, listWorkspacesSuccess, registerDomainSuccess, removeCollaboratorSuccess, selectWorkspaceAnalyticsSuccess, selectWorkspaceSearchConsoleSuccess, updateWorkspaceLogoSuccess, updateWorkspaceSuccess } from "./actions"
import { AddSenderEmailAPI, AddWorkspaceAPI, CancelWorkspaceSubscriptionAPI, DeleteWorkspaceAPI, GetDomainAPI, GetWorkspaceAPI, GetWorkspaceConnectionsAPI, InviteCollaboratorAPI, ListWorkspaceBillingsAPI, ListWorkspaceLanguagesAPI, ListWorkspaceRegionsAPI, ListWorkspacesAPI, RegisterDomainAPI, RemoveCollaboratorAPI, SelectWorkspaceAnalyticsAPI, SelectWorkspaceSearchAPI, UpdateWorkspaceAPI, WorkspaceDisconnectGoogleAPI } from "./calls"
import { setError, setSuccess, startAction, stopAction, logoutUser } from "../actions"
import { uploadImageSaga } from "../upload/saga"
import { START_UPLOAD } from "../upload/actionTypes"

function* listWorkspaces({ type }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield ListWorkspacesAPI(state)
    console.log(result)
    yield put(listWorkspacesSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* addWorkspace({ type, payload: { data, history } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield AddWorkspaceAPI(data, state)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(addWorkspaceSuccess(result?.data?.data))
    if (history) history('/select-plan')
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* getWorkspaceSaga({ type, payload: { id, history } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    id = id || state.workspace.workspace._id
    const result = yield GetWorkspaceAPI(id, state)
    console.log(result)
    localStorage.setItem('workspace', JSON.stringify(result?.data?.data))
    yield put(getWorkspaceSuccess(result?.data?.data))
    if (history) history('/')
  } catch (err) {
    console.log(err)
    yield put(setError(err))
    yield put(logoutUser())
  } finally {
    yield put(stopAction(type))
  }
}

function* deleteWorkspace({ type, payload: { id, history, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield DeleteWorkspaceAPI(id, state)
    console.log(result)
    localStorage.removeItem('workspace')
    yield put(setSuccess(result?.data?.message))
    yield put(deleteWorkspaceSuccess(result?.data?.data))
    if (history) history('/select-workspace')
    if (toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* updateWorkspace({ type, payload: { id, data, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    id = id || state.workspace.workspace._id
    const result = yield UpdateWorkspaceAPI(id, data, state)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(updateWorkspaceSuccess(result?.data?.data))
    if (toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* updateWorkspaceLogo({ type, payload: { image } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    let imageUploaded = 'delete'
    // console.log({ image })
    if (image) {
      const result = yield uploadImageSaga({
        type: START_UPLOAD,
        payload: {
          file: image,
          uploadType: "workspace"
        }
      })
      imageUploaded = result
    }
    const workspace = state.workspace.workspace?._id
    const result = yield UpdateWorkspaceAPI(workspace, { logo: imageUploaded }, state)
    yield put(updateWorkspaceSuccess(result?.data?.data))
  } catch (error) {
    yield put(setError(error))
  } finally {
    yield put(stopAction(type))
  }
}

function* listWorkspaceLanguages({ type }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield ListWorkspaceLanguagesAPI(state)
    console.log(result)
    yield put(listWorkspaceLanguagesSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* listWorkspaceRegions({ type }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield ListWorkspaceRegionsAPI()
    console.log(result)
    yield put(listWorkspaceRegionsSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* listWorkspaceBillings({ type, payload: { query } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const workspace = state.workspace.workspace?._id
    query = new URLSearchParams(query).toString()
    const result = yield ListWorkspaceBillingsAPI(workspace, query, state)
    console.log(result)
    yield put(listWorkspaceBillingsSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* cancelSubscription({ type, payload: { id } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield CancelWorkspaceSubscriptionAPI(id, state)
    console.log(result)
    yield put(cancelSubscriptionSuccess())
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* selectWorkspaceAnalytics({ type, payload: { id, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const body = { accountId: id }
    const workspace = state.workspace.workspace?._id
    const result = yield SelectWorkspaceAnalyticsAPI(workspace, body, state)
    console.log(result)
    yield put(selectWorkspaceAnalyticsSuccess(result.data.data))
    if (toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* selectWorkspaceSearchConsole({ type, payload: { id, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const body = { accountId: id }
    const workspace = state.workspace.workspace?._id
    const result = yield SelectWorkspaceSearchAPI(workspace, body, state)
    console.log(result)
    yield put(selectWorkspaceSearchConsoleSuccess(result.data.data))
    if (toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* registerDomain({ type, payload }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const workspace = state.workspace.workspace?._id
    const result = yield RegisterDomainAPI(workspace, state)
    console.log(result)
    yield put(registerDomainSuccess(result.data.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* getDomainData({ type, payload: { refresh } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const workspace = state.workspace.workspace?._id
    const result = yield GetDomainAPI(workspace, state)
    console.log(result)
    if (refresh) yield put(setSuccess(result?.data?.message))
    yield put(getDomainSuccess(result.data.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* addSenderEmail({ type, payload: { data, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const workspace = state.workspace.workspace?._id
    const result = yield AddSenderEmailAPI(workspace, data, state)
    console.log(result)
    yield put(addSenderEmailSuccess(result.data.data))
    if (toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* disconnectGoogleService({ type: actionType, payload: { type, toggle } }) {
  try {
    yield put(startAction(actionType))
    const state = yield select()
    const body = { type }
    const workspace = state.workspace.workspace?._id
    const result = yield WorkspaceDisconnectGoogleAPI(workspace, body, state)
    console.log(result)
    yield put(disconnectGoogleSuccess(result.data.data))
    if (toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(actionType))
  }
}

function* getWorkspaceConnections({ type }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const workspace = state.workspace.workspace?._id
    const result = yield GetWorkspaceConnectionsAPI(workspace, state)
    console.log(result)
    yield put(getWorkspaceConnectionsSuccess(result.data.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* inviteCollaborator({ type, payload: { data, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const workspace = state.workspace.workspace?._id
    const result = yield InviteCollaboratorAPI(workspace, data, state)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    if(toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* removeCollaborator({ type, payload: { collaborator, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const workspace = state.workspace.workspace?._id
    const result = yield RemoveCollaboratorAPI(workspace, collaborator, state)
    console.log(result)
    yield put(removeCollaboratorSuccess(collaborator))
    yield put(setSuccess(result?.data?.message))
    if(toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* workspaceSaga() {
  yield takeEvery(LIST_WORKSPACES, listWorkspaces)
  yield takeEvery(ADD_WORKSPACE, addWorkspace)
  yield takeEvery(GET_WORKSPACE, getWorkspaceSaga)
  yield takeEvery(DELETE_WORKSPACE, deleteWorkspace)
  yield takeEvery(UPDATE_WORKSPACE, updateWorkspace)
  yield takeEvery(LIST_WORKSPACE_LANGUAGES, listWorkspaceLanguages)
  yield takeEvery(LIST_WORKSPACE_REGIONS, listWorkspaceRegions)
  yield takeEvery(LIST_WORKSPACE_BILLINGS, listWorkspaceBillings)
  yield takeEvery(INVITE_COLLABORATOR, inviteCollaborator)
  yield takeEvery(REMOVE_COLLABORATOR, removeCollaborator)
  yield takeEvery(UPDATE_WORKSPACE_LOGO, updateWorkspaceLogo)
  yield takeEvery(CANCEL_WORKSPACE_SUBSCRIPTION, cancelSubscription)
  yield takeEvery(SELECT_WORKSPACE_ANALYTICS, selectWorkspaceAnalytics)
  yield takeEvery(SELECT_WORKSPACE_SEARCH_CONSOLE, selectWorkspaceSearchConsole)
  yield takeEvery(REGISTER_DOMAIN, registerDomain)
  yield takeEvery(GET_DOMAIN, getDomainData)
  yield takeEvery(ADD_SENDER_EMAIL, addSenderEmail)
  yield takeEvery(DISCONNECT_GOOGLE, disconnectGoogleService)
  yield takeEvery(GET_WORKSPACE_CONNECTIONS, getWorkspaceConnections)
}

export default workspaceSaga
import "./App.css";
import Calendar from "./Components/Calendar";
import "./global.css";
import "./index.css";
import TaskDetails from "./Components/TaskDetails";
import SubHeader from "./Components/SubHeader";
import { useSelector } from "react-redux";
import { getters, useGlobalContext } from "./GlobalContext/GlobalContext";
import { checkIfLoading } from "../../../store/functions";
import { LIST_PROJECTS } from "../../../store/projectManagement/actionTypes";
import { useRef, useState } from "react";

function App() {
   const { projects, listLoading } = useSelector(state => ({
      projects: state?.project?.projects,
      listLoading: checkIfLoading(state, LIST_PROJECTS)
   }))
   const [search, setSearch] = useState(null)
   const gridRef = useRef(null)
   const addTaskBtn = useRef(null)

   const [state, dispatch] = useGlobalContext();
   const activeProject = getters.getActiveProject(state);

   return (
      <div id="app">
         <SubHeader gridRef={gridRef} search={search} setSearch={setSearch} ></SubHeader>
         {
            !listLoading && projects?.length === 0 ? "No Projects found" : <>
               <Calendar gridRef={gridRef} addTaskBtn={addTaskBtn} search={search}></Calendar>
               <TaskDetails addTaskBtn={addTaskBtn}></TaskDetails>
            </>
         }
      </div>
   );
}

export default App;
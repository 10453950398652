import React, { useEffect, useState } from 'react'
import ErrorsChart from '../../../components/analysisComponents.js/ErrorsChart'
import Issues from '../../../components/analysisComponents.js/Issues'
import SideDropdown from '../../../components/analysisComponents.js/SideDropdown'
import { useDispatch, useSelector } from 'react-redux'
import { getContentAnalysis } from '../../../store/contentAnalysis/actions'
import { GET_CONTENT_ANALYSIS } from '../../../store/contentAnalysis/actionTypes'
import { checkIfLoading } from '../../../store/functions'
import { Spinner } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import CopiedContent from './copiedContent'
import FeedbackModal from '../../../components/common/FeedbackModal'
import { useTranslation } from 'react-i18next'
import KeywordsGap from './copiedContent/keyword-gap'

function ContentAnalysis() {
  // ** states
  const [modal, setModal] = useState(false);
  const [page, setPage] = useState(null)

  const dispatch = useDispatch()
  const history = useNavigate()
  const [t] = useTranslation()
  const [crawl, setCrawl] = useState(false)

  const { loading, workspace, contentAnalysis, message, lang } = useSelector(state => ({
    loading: checkIfLoading(state, GET_CONTENT_ANALYSIS),
    workspace: state?.workspace?.workspace,
    contentAnalysis: state?.contentAnalysis?.data,
    message: state?.contentAnalysis?.message,
    lang: state.ui.lang
  }))

  useEffect(() => {
    dispatch(getContentAnalysis(workspace?._id))
    setTimeout(() => {setCrawl(true)}, 2000)
  }, [workspace])

  // ** function to handle modal toggle
  const toggle = () => setModal(!modal);

  const handleSetPage = (url) => {
    const page = contentAnalysis?.pages?.find(p => p.url === url)
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setPage(page)
  }

  return (
    <div className='analysis'>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          {
            loading ?
              <>
                <div className='text-center col-md-12'>
                  <Spinner className="m-auto big-spinner text-center" color="success" />
                </div>
                { crawl ? <div className='col-md-6 text-center lead mt-3'>{t('we are crawling your website, the time needed for this action might change based on our queue and your website size. Please hold on or return here later')}</div> : ""}
              </> :
              contentAnalysis ? <>
                <div className='col-md-11 text-center'>
                  <SideDropdown pages={contentAnalysis.pages} setPage={setPage} page={page} />
                  <p className='description'>{t('Every good SEO strategy needs to be done page by page. For This reason we indicate deeper analysis in the specific pages')}</p>
                </div>
                {
                  !page ? <div className='col-xl-11'>
                    <div className='row'>
                      <div className='col-xl-6 col-sm-8'>
                        <Issues data={contentAnalysis?.data} setPage={handleSetPage} />
                      </div>
                      <div className='col-xl-6 col-sm-4'>
                        <div className='errors-quotation-chart'>
                          {/* <div className='card'>
                            <ErrorsChart data={contentAnalysis} />
                          </div> */}
                          <p className='fix-hint'>{t('fixed-analyze-again')}</p>
                          <div className='buttons'>
                            <button type='button' className='btn click-btn orange-btn' onClick={() => dispatch(getContentAnalysis(workspace?._id, true))}>{t('analyze-website')}</button>
                            <button type='button' className='btn click-btn green-btn' onClick={toggle}>{t('contact-us-if-any-help')}</button>
                          </div>
                          <FeedbackModal text="tell-us-how-we-can-help" button="Submit Request" modal={modal} toggle={toggle} />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-12'>
                        <KeywordsGap />
                      </div>
                    </div>
                  </div> : <div className='col-md-11'>
                    <CopiedContent page={page} />
                  </div>
                }
              </> : message ? <div className='col-md-11'>
                <div className='alert alert-warning'>{message[lang]}</div>
              </div> :
                <div className='col-md-11'>
                  <div className='alert alert-warning'>{t('Task in progress')}</div>
                </div>
          }
        </div>
      </div>
    </div>
  )
}

export default ContentAnalysis
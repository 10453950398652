import React from "react";
import { useTranslation } from "react-i18next";

function CompetitorSimilarSites({ competitor }) {
    const [t] = useTranslation()

    const renderSites = () => {
        const sites = competitor?.data?.sites?.similar_sites
        if (sites && sites?.length > 0) {
            return sites?.map((site, i) => {
                return <tr>
                    <td>{i + 1}</td>
                    <td>{site?.site}</td>
                    <td>{ }</td>
                    <td>{ }</td>
                    <td>{ }</td>
                    <td>{site?.rank}</td>
                </tr>
            })
        } else {
            return <li>No Sites found</li>
        }
    }

    return (
        <div className="card mt-3 competitors-sites">
            <div className="d-flex align-items-center justify-content-between">
                <div>
                    <h4>{t('Competitors & Similar Sites')}</h4>
                    <span className="text-muted">
                        Reveal your top competitors and find potential or emerging competitors. {competitor?.data?.site_url}'s top competitor is
                        rokarestaurant.com. Analyze competitors
                    </span>
                </div>
                {/* <div className="bg-gray rounded-10 p-3"><i class="fas fa-share-alt"></i></div> */}
            </div>
            <div>
                <table className="table">
                    <thead>
                        <th>#</th>
                        <th>Site</th>
                        <th>Affinity</th>
                        <th>Monthly visits</th>
                        <th>Category</th>
                        <th>Category rank</th>
                    </thead>
                    <tbody>
                        {renderSites()}
                    </tbody>
                </table>
            </div>
            {/* <div className="text-center">
                <button className="btn click-btn orange-btn">See all competitors</button>
            </div> */}
        </div>
    );
}

export default CompetitorSimilarSites;
import React, { useEffect, useState } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'reactstrap'
import CustomTooltip from '../../../../components/common/CustomTooltip'
import TablePagination from '../../../../components/common/Pagination'

const BacklinksTable = ({ loading, backlinks, details, filters, setPage }) => {
    const [t] = useTranslation()
    const [sortedBacklinks, setSortedBacklinks] = useState([])
    const [sort, setSort] = useState({
        field: 'domain',
        sort: 1
    })

    const sortBacklinks = (field, sort) => {
        if (backlinks && backlinks?.length > 0) {
            let sorted
            if (field === 'domain') {
                if (sort === 1) sorted = backlinks.sort((a, b) => Number(a?.domainAuthority) - Number(b?.domainAuthority))
                if (sort === -1) sorted = backlinks.sort((a, b) => Number(b?.domainAuthority) - Number(a?.domainAuthority))
            } else {
                if (sort === 1) sorted = backlinks.sort((a, b) => Number(a?.pageAuthority) - Number(b?.pageAuthority))
                if (sort === -1) sorted = backlinks.sort((a, b) => Number(b?.pageAuthority) - Number(a?.pageAuthority))
            }
            setSortedBacklinks(sorted)
        }
    }

    useEffect(() => {
        sortBacklinks(sort?.field, sort?.sort)
    }, [sort])

    useEffect(() => {
        setSortedBacklinks(backlinks)
    }, [backlinks])

    const renderBacklinks = () => {
        if (loading) return <Spinner size="sm" className="m-auto text-center" color="success" />
        if (sortedBacklinks && sortedBacklinks?.length > 0) {
            return sortedBacklinks?.map(bk => {
                return <tr>
                    <td>
                        <div><b>{bk?.domain}</b></div>
                        <div><small className='text-muted text-sm'><a href={bk?.url} className="text-dark" target="_blank">{bk?.url}</a></small></div>
                    </td>
                    <td>{bk?.domainAuthority}</td>
                    <td>{bk?.pageAuthority}</td>
                    <td style={{ fontSize: "12px" }}><a className='text-dark' href={bk?.targetUrl}>{bk?.targetUrl?.length > 30 ? String(bk?.targetUrl).substring(0, 30) + '...' : bk?.targetUrl}</a></td>
                </tr>
            })
        } else {
            return <tr><td colSpan={4}>{t('no-backlinks-found')}</td></tr>
        }
    }

    return (
        <div className='mt-5'>
            <h6>{details?.total_items || 0} {t('backlinks')}</h6>
            <div className='row justify-content-between align-items-end'>
                <div className='col-md-4'>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className='bg-transparent'><i class="fas fa-search"></i></InputGroup.Text>
                        <FormControl
                            placeholder={t('search')}
                            aria-label={t('search')}
                            aria-describedby="basic-addon1"
                        />
                    </InputGroup>
                </div>
                <div className='col-md-6'>

                </div>
            </div>
            <table className='table'>
                <thead>
                    <tr style={{ fontSize: "14px" }}>
                        <th>{t('who-is-linking-to-you')}</th>
                        <th>
                            <span className='mr-2'>{t('domain-authority')}</span>
                            <CustomTooltip header="This is tooltip for" item='domain_authority' />
                            <i onClick={() => setSort({
                                field: 'domain',
                                sort: sort.sort === 1 ? -1 : 1
                            })} className='fas fa-sort ml-1 cursor-pointer'></i>
                        </th>
                        <th>
                            <span className='mr-2'>{t('page-authority')}</span>
                            <CustomTooltip header="This is tooltip for" item='page_authority' />
                            <i onClick={() => setSort({
                                field: 'page',
                                sort: sort.sort === 1 ? -1 : 1
                            })} className='fas fa-sort ml-1 cursor-pointer'></i>
                        </th>
                        <th width="20%">{t('target-url')}</th>
                    </tr>
                </thead>
                <tbody style={{ fontSize: "14px" }}>
                    {renderBacklinks()}
                </tbody>
            </table>
            <TablePagination count={details?.total_items} page={filters?.page} setPage={setPage} limit={filters?.limit} loading={loading} />
        </div>
    )
}

export default BacklinksTable
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import { getWorkspace } from '../../../store/actions'
import { checkIfLoading } from '../../../store/functions'
import { GET_WORKSPACE } from '../../../store/workspace/actionTypes'
import AccountTab from './components/accountTab'
import BillingTab from './components/billingTab'
import ConnectionsTab from './components/conectionsTab'
import SettingsNav from './components/nav'
import WorkspaceTab from './components/workspaceTab'

function Settings() {
    const dispatch = useDispatch()
    const history = useNavigate()
    const [tab, setTab] = useState('workspace')
    const [selectedContent, setSelectedContent] = useState(<WorkspaceTab />)

    const { workspaces, workspace, workspaceLoading } = useSelector(state => ({
        workspaces: state?.workspace.workspaces,
        workspace: state?.workspace?.workspace,
        workspaceLoading: checkIfLoading(state, GET_WORKSPACE)
    }))

    const handleTabChange = (tab) => {
        switch (tab) {
            case "workspace":
                setSelectedContent(<WorkspaceTab />)
                break;
            case "billing":
                setSelectedContent(<BillingTab tab={tab} />)
                break;
            case "connections":
                setSelectedContent(<ConnectionsTab />)
                break;
            case "account":
                setSelectedContent(<AccountTab />)
                break;
        }
    }

    useEffect(() => {
        dispatch(getWorkspace())
    }, [])

    useEffect(() => {
        handleTabChange(tab)
    }, [tab])

    const renderWorkspacesLogo = () => {
        if (workspaces && workspaces?.length > 0) {
            return workspaces?.map(w => {
                return <li onClick={() => dispatch(getWorkspace(w?._id))} className={`${w?._id === workspace?._id ? "active" : ""}`}>
                    {
                        w.logo ? <img src={w?.logo} /> : <span>{String(w.domain).charAt(0).toUpperCase()}</span>
                    }
                </li>
            })
        }
    }

    return (
        <div className='container-fluid settings page'>
            <div className=''>
                <ul className='list-group m-auto text-center list-group-horizontal-lg workspaces-logo'>
                    {renderWorkspacesLogo()}
                    <li onClick={() => history('/add-workspace')}><i className='fas fa-plus'></i></li>
                </ul>
            </div>
            <hr />
            <SettingsNav tab={tab} setTab={setTab} />
            <div className='mt-5'>
                {workspaceLoading ? <Spinner size="sm" className="m-auto text-center" color="success" /> : selectedContent}
            </div>
        </div>
    )
}

export default Settings
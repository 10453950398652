export const START_ACTION = 'START_ACTION'
export const STOP_ACTION = 'STOP_ACTION'

export const SET_ERROR = 'SET_ERROR'
export const SET_SUCCESS = 'SET_SUCCESS'
export const CLEAR_ERRROR = 'CLEAR_ERRROR'

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'

export const REFRESH_ACTION_START = 'REFRESH_ACTION_START'
export const REFRESH_ACTION_STOP = 'REFRESH_ACTION_STOP'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import IntegrateZapier from './Components/IntegrateZapier'
import EmailBuilderTab from './Components/EmailBuilderTab'
import MarketingNav from './Components/MarketingNav'
import NewAutomationTab from './Components/NewAutomationTab'

function MarketingAutomation() {
    const dispatch = useDispatch()
    const [tab, setTab] = useState('integrate-zapier')
    const [selectedContent, setSelectedContent] = useState()

    const handleTabChange = (tab) => {
        switch (tab) {
            case "email-builder":
                setSelectedContent(<EmailBuilderTab />)
                break;
            case "integrate-zapier":
                setSelectedContent(<IntegrateZapier />)
                break;
        }
    }

    useEffect(() => {
        handleTabChange(tab)
    }, [tab])

    return (
        <div className='container-fluid marketing page'>
            <div className='mt-2'>
                <MarketingNav tab={tab} setTab={setTab} />
            </div>
            <hr />
            {selectedContent}
        </div>
    )
}

export default MarketingAutomation
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap'
import TablePagination from '../../../../components/common/Pagination'
import { addCompetitor, getCompetitorKeywordsNotMe, listBacklinks, listCompetitors, removeCompetitor } from '../../../../store/actions'
import { checkIfLoading } from '../../../../store/functions'
import { ADD_COMPETITOR, GET_COMPETITOR_KEYWORDS_NOT_ME, LIST_BACKLINKS, LIST_COMPETITORS, REMOVE_COMPETITOR } from '../../../../store/webreputation/actionTypes'
import { useTranslation } from 'react-i18next'
import DataTableComponent from '../../../../components/tables/DataTable'
import WebReputationNav from '../../WebReputation/components/Nav'

function KeywordsGap() {
    const dispatch = useDispatch()
    const [selectedCompetitor, setSelectedCompetitor] = useState(null)
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [addCompBox, setAddCompBox] = useState(false)
    const [compUrl, setCompUrl] = useState(null)
    const [tableData, setTableData] = useState([])
    const [t] = useTranslation()

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    let { keywords, details, competitors, keyLoading, compLoading, addCompLoading, removeCompLoading, workspace } = useSelector(state => ({
        keywords: state?.webreputation?.keywordsNotMe?.data,
        details: state?.webreputation?.competitor?.page_details,
        competitors: state?.webreputation?.competitors?.list,
        keyLoading: checkIfLoading(state, GET_COMPETITOR_KEYWORDS_NOT_ME),
        compLoading: checkIfLoading(state, LIST_COMPETITORS),
        addCompLoading: checkIfLoading(state, ADD_COMPETITOR),
        removeCompLoading: checkIfLoading(state, REMOVE_COMPETITOR),
        workspace: state?.workspace?.workspace
    }))

    useEffect(() => {
        if (keywords && keywords?.length > 0) {
            const mappedData = keywords?.map(e => {
                return {
                    id: e._id,
                    keyword: e.keyword,
                    searchVolume: e.searchVolume,
                    rank: e.rank,
                    cpc: e?.cpc
                }
            })
            setTableData(mappedData)
        } else {
            setTableData([])
        }
    }, [keywords])

    const [filters, setFilters] = useState({
        page: 1,
        limit: 10
    })

    const setPage = (page) => {
        setFilters({
            ...filters,
            page
        })
    }

    useEffect(() => {
        if (workspace) dispatch(listCompetitors())
    }, [workspace])

    useEffect(() => {
        if (competitors && competitors?.length > 0) {
            setSelectedCompetitor(competitors[0])
        }
    }, [competitors])

    useEffect(() => {
        console.log({
            selectedCompetitor
        })
        if (selectedCompetitor) {
            const id = selectedCompetitor?._id
            dispatch(getCompetitorKeywordsNotMe(id))
        }
    }, [selectedCompetitor, filters.page])

    const renderKeywords = () => {
        if (keyLoading) return <Spinner size="sm" className="m-auto text-center" color="success" />
        if (keywords && keywords?.length > 0) {
            return keywords?.map(bk => {
                return <tr>
                    <td>{bk?.keyword}</td>
                    <td>{bk?.cpc}</td>
                    <td>{bk?.rank}</td>
                </tr>
            })
        } else {
            return <tr><td colSpan={4}>{t('no-backlinks-found')}</td></tr>
        }
    }

    const renderCompetitors = () => {
        if (compLoading) return <Spinner size="sm" className="m-auto text-center" color="success" />
        if (competitors?.length > 0) {
            return competitors?.map(comp => {
                return <DropdownItem onClick={() => setSelectedCompetitor(comp)}>
                    <button className="btn">{comp?.url}</button>
                </DropdownItem>
            })
        } else {
            return <option>No Competitor found</option>
        }
    }

    const submitAddCompetitor = () => {
        if (!compUrl) return
        dispatch(addCompetitor(compUrl, () => setAddCompBox(false)))
    }

    const columns = [
        {
            name: t('Keyword'),
            selector: "keyword",
            sortable: true,
            cell: e => {
                return <p style={{ fontWeight: 600, fontSize: "13px", color: "#347362", marginBottom: 0 }}>{e.keyword}</p>
            },
        },
        {
            name: t('Search volume'),
            selector: "searchVolume",
            sortable: true,
            cell: e => {
                return <p style={{ fontWeight: 800, fontSize: "14px", color: "#347362", marginBottom: 0 }}>{e.searchVolume}</p>
            },
        },
        {
            name: t('Rank'),
            selector: "rank",
            sortable: true,
            cell: e => {
                return <p style={{ fontWeight: 500, fontSize: "18px", color: "#347362", marginBottom: 0 }}>{e.rank}</p>
            },
        },
        {
            name: t('Cpc'),
            selector: "cpc",
            sortable: true,
            cell: e => {
                return <p style={{ fontWeight: 500, fontSize: "18px", color: "#347362", marginBottom: 0 }}>{e.cpc}</p>
            },
        },
    ];

    return (
        <div className='container-fluid backlinks page'>
            <WebReputationNav />
            <div className='mt-5'>
                <h4>{t('Choose the competitor to view keywords related to him')} <h4 className='text-danger d-inline-block'>{t('but not to you')}</h4></h4>
                <Dropdown className='custom-dropdown select-box' isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle caret>
                        {selectedCompetitor?.url}
                    </DropdownToggle>
                    <DropdownMenu right={true}>
                        {renderCompetitors()}
                    </DropdownMenu>
                </Dropdown>
                <div className='d-flex my-3'>
                    <button onClick={() => setAddCompBox(!addCompBox)} className='btn click-btn green-btn mr-2'>{t('add-new-competitor')}</button>
                    <button className='btn click-btn orange-btn-outline' onClick={() => dispatch(removeCompetitor(selectedCompetitor?._id))}>
                        {
                            removeCompLoading ? <Spinner size="sm" className="m-auto text-center" color="orange" /> : t('remove-competitor')
                        }
                    </button>
                </div>
                <div className='add-competitor' style={{ display: addCompBox ? 'block' : 'none' }}>
                    <div className='row align-items-end'>
                        <div className='col-md-8'>
                            <input className='form-control' onChange={(e) => setCompUrl(e.target.value)} placeholder={t('enter-competitor-url')} />
                        </div>
                        <div className='col-md-4'>
                            <button className='btn click-btn orange-btn btn-block py-1 px-1' onClick={submitAddCompetitor}>
                                {
                                    addCompLoading ? <Spinner size="sm" className="m-auto text-center" color="light" /> : t('add-competitor')
                                }
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col-md-12'>
                    { keyLoading ? <Spinner size={'sm'} color="success" /> : <DataTableComponent columns={columns} data={tableData} /> }
                </div>
            </div>
        </div>
    )
}

export default KeywordsGap
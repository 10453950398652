import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";

function MarketingChannel({ competitor }) {
    const [chartOptions, setChartOptions] = useState(null)
    const [t] = useTranslation()

    useEffect(() => {
        const sources = competitor?.data?.traffic?.sources
        const data = [
            {
                x: 'Direct',
                y: sources?.direct?.percent || 0
            },
            {
                x: 'Referrals',
                y: sources?.referring?.percent || 0
            },
            {
                x: "Search",
                y: sources?.search_organic?.percent || 0
            },
            {
                x: "Social",
                y: sources?.social?.percent || 0
            },
            {
                x: "Mail",
                y: sources?.mail?.percent || 0
            },
            {
                x: "Display",
                y: sources?.display_ad?.percent
            }
        ]
        if (data?.length > 0) {
            setChartOptions({
                series: [{
                    data
                }],
                options: {
                    plotOptions: {
                        bar: {
                            borderRadius: 4,
                            dataLabels: {
                                position: 'top',
                            },
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                            return val + '%';
                        },
                        offsetY: -20,
                        style: {
                            fontSize: '12px',
                            colors: ["#304758"]
                        }
                    },
                    tooltip: {
                        enabled: false,
                      },
                    // xaxis: {
                    //     categories: ['-48 h', '', '', '', '', '', '', '', '', 'Now'],
                    //     labels: {
                    //         rotate: 0
                    //     }
                    // },
                    yaxis: {
                        show: false
                    },
                    colors: ['#EF8451'],
                },
            })
        }
    }, [competitor])

    return (
        <div className="card mt-3 competitors-marketing">
            <div className="d-flex align-items-center justify-content-between">
                <div>
                    <h4>{t('Marketing Channels Overview')}</h4>
                    <span className="text-muted">
                        Discover the digital marketing channels driving traffic to your website. {competitor?.data?.site_url} receives traffic from search (74.24%)
                        and referrals (13.04%). An underutilized channel is "ads."
                        (On Desktop, Worldwide)
                    </span>
                </div>
                {/* <div className="bg-gray rounded-10 p-3"><i class="fas fa-share-alt"></i></div> */}
            </div>
            <div className="bg-gray rounded-10 p-3 mt-3">
                <h5>{t('Marketing Channels Distribution')}</h5>
                {
                    chartOptions ? <Chart height={280} options={chartOptions.options} series={chartOptions.series} type="bar" /> : ""
                }
            </div>
        </div>
    );
}

export default MarketingChannel;
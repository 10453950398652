export const LIST_CAMPAIGNS = 'LIST_CAMPAIGNS'
export const LIST_CAMPAIGNS_SUCCESS = 'LIST_CAMPAIGNS_SUCCESS'

export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN'
export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS'

export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN'
export const UPDATE_CAMPAIGN_SUCCESS = 'UPDATE_CAMPAIGN_SUCCESS'

export const STOP_CAMPAIGN = 'STOP_CAMPAIGN'
export const STOP_CAMPAIGN_SUCCESS = 'STOP_CAMPAIGN_SUCCESS'

export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN'
export const DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS'

export const VIEW_CAMPAIGN = 'VIEW_CAMPAIGN'
export const VIEW_CAMPAIGN_SUCCESS = 'VIEW_CAMPAIGN_SUCCESS'
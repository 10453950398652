import React, { useState } from 'react'
import Sidemenu from './Sidemenu'
import SidemenuToggleBtn from './SidemenuToggleBtn'

function SidemenuIndex() { 
  const [open, setOpen] = useState(false)
  return (
    <>
      <Sidemenu open={open} setOpen={setOpen} />
      <SidemenuToggleBtn setOpen={setOpen} />
    </>
  )
}

export default SidemenuIndex
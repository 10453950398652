import React, { useState } from 'react'

function SidemenuToggleBtn({setOpen}) {

    // ** function to handle side menu open
    const openSideMenu = () => {
        setOpen(true) 
    }

    return (
        <div className='toggleBtnCon'>
            <button type='button' className='btn' onClick={openSideMenu}>
                <i className="fas fa-bars"></i>
            </button>
        </div>
    )
}

export default SidemenuToggleBtn
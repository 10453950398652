import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Modal, Spinner } from 'reactstrap'
import DeletePrompt from '../../../../components/common/DeletePrompt'
import ImageCropModal from '../../../../components/common/ImageCropModal'
import { deleteUser, setLanguage, updateUserData } from '../../../../store/actions'
import { DELETE_USER } from '../../../../store/auth/actionTypes'
import { checkIfLoading } from '../../../../store/functions'
import { START_UPLOAD } from '../../../../store/upload/actionTypes'
import ChangePasswordModal from './changePasswordModal'
import ChangeUserName from './changeUserName'
import UpdateEmailModal from './updateEmailModal'

const AccountTab = () => {
    const [cropModal, setCropModal] = useState(false)
    const [changeNameModal, setChangeNameModal] = useState(false)
    const [img, setImg] = useState(undefined)
    const [userImage, setUserImage] = useState(null)
    const [deleteModal, setDeleteModal] = useState(null)
    const dispatch = useDispatch()
    const history = useNavigate()
    const [t] = useTranslation()

    const [passwordModal, setPasswordModal] = useState(false);
    const [emailModal, setEmailModal] = useState(false);

    const togglePasswordModal = () => setPasswordModal(!passwordModal);
    const toggleEmailModal = () => setEmailModal(!emailModal);
    const toggleChangeNameModal = () => setChangeNameModal(!changeNameModal);

    const { uploadLoading, user, deleteLoading } = useSelector(state => ({
        uploadLoading: checkIfLoading(state, START_UPLOAD),
        user: state.auth?.user,
        deleteLoading: checkIfLoading(state, DELETE_USER)
    }))

    const handleCropSubmit = file => {
        setCropModal(false)
        setUserImage(file)
    }

    useEffect(() => {
        if (userImage) dispatch(updateUserData({ image: userImage }))
    }, [userImage])

    const hiddenFileInput = React.useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        setImg(URL.createObjectURL(fileUploaded))
        setTimeout(() => {
            setCropModal(true)
        }, 300)
    };

    return (
        <div className='col-md-8'>
            <span>{t('Here you can see settings that are common amongst all the workspaces.')}</span>
            <div className='mt-4'>
                <h4>{t('personal-info')}</h4>
                <div className='card'>
                    <div className='d-flex align-items-center'>
                        {
                            uploadLoading ? <Spinner className="ml-2" size="sm" color="success" /> : <>
                                <img className='img-thumbnail rounded-circle avatar cursor-pointer' onClick={() => handleClick()} src={user?.profilePicture || `${process.env.PUBLIC_URL}/assets/images/avatar.png`} alt="workspace logo" />
                                <input type="file" accept="image/*" style={{ display: "none" }} ref={hiddenFileInput} onChange={(e) => handleChange(e)} /></>
                        }
                        <div className='d-inline-block ml-3'>
                            <h5 className='m-0 mb-1'>{user?.fullName}</h5>
                            <span className='btn btn-link m-0 p-0' onClick={() => setChangeNameModal(true)} style={{ fontSize: "14px" }}>{t('change-name')}</span>
                        </div>
                    </div>
                    <div className='row justify-content-between align-items-center my-3'>
                        <div className='col-md-6'>
                            <label className='text-muted'>{t('email-address')}</label>
                            <div><b>{user?.email}</b></div>
                        </div>
                        <button className='btn btn-outline-secondary col-md-4 py-2' onClick={() => setEmailModal(true)}>{t('change-email')}</button>
                    </div>
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-md-6'>
                            <label className='text-muted'>{t('password')}</label>
                            <div><b>****************</b></div>
                        </div>
                        <button className='btn btn-outline-secondary col-md-4 py-2' onClick={() => setPasswordModal(true)}>{t('change-password')}</button>
                    </div>
                    <span className='text-muted'><small>{t('Last changed on')} {moment(user.updatedAt).format('MMM DD, YYYY')}</small></span>
                </div>
            </div>
            <div className='mt-4'>
                <h4>{t('language')}</h4>
                <span className='text-muted'>{t('Choose language of your SEO Suite')}</span>
                <div className='card mt-2'>
                    <label>{t('Account language')}</label>
                    <select defaultValue={user?.language} onChange={(e) => {
                        dispatch(setLanguage(e.target.value))
                        dispatch(updateUserData({ language: e.target.value }))
                    }} className='form-control custom-border'>
                        <option value={'en'}>English</option>
                        <option value={'it'}>Italian</option>
                    </select>
                </div>
            </div>
            <div className='mt-4'>
                <h4>{t('delete-account')}</h4>
                <span><small>{t('Completely remove your current workspace from SEO Suite')}</small></span>
                <div className='card d-flex mt-2'>
                    <h5 className='d-inline-block'>{t('delete-account')}</h5>
                    <span>{t('If you want to Delete your account, you can do so from the page.')} <Link to={'#'} onClick={() => {
                        setDeleteModal({
                            show: true,
                            func: () => {
                                dispatch(deleteUser(history, () => setDeleteModal(null)))
                            }
                        })
                    }} className='text-orange'>{t('Delete Account')}</Link> {t('page')}.</span>
                </div>
            </div>
            <Modal centered={true} size='lg' isOpen={cropModal} toggle={() => { setCropModal(!cropModal) }}>
                <ImageCropModal handleCropSubmit={handleCropSubmit} img={img} toggle={() => { setCropModal(!cropModal) }} />
            </Modal>
            <ChangePasswordModal modal={passwordModal} toggle={togglePasswordModal} />
            <UpdateEmailModal modal={emailModal} toggle={toggleEmailModal} />
            <ChangeUserName user={user} modal={changeNameModal} toggle={toggleChangeNameModal} />
            <Modal centered={true} size='md' isOpen={deleteModal?.show} toggle={() => { setDeleteModal(null) }}>
                <DeletePrompt title={'Delete Account'} message={t("Are you sure you want to delete this account?")} func={deleteModal?.func} loading={deleteLoading} toggle={() => setDeleteModal(null)} />
            </Modal>
        </div>
    )
}

export default AccountTab
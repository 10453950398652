import { put, select, takeEvery } from "redux-saga/effects"
import { GetContentAnalysisAPI, GetContentAnalysisPageKeywordsAPI, UpdateContentAnalysisAPI } from "./calls"
import { ContentAnalysisPageKeywordsSuccess, getContentAnalysisError, getContentAnalysisSuccess, updateContentAnalysisSuccess } from './actions'
import { CONTENT_ANALYSIS_PAGE_KEYWORDS, GET_CONTENT_ANALYSIS, UPDATE_CONTENT_ANALYSIS } from "./actionTypes"
import { setError, startAction, stopAction } from "../actions"

function* getContentAnalysis({ type, payload: { id, update } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    let query = {
      workspace: state?.workspace?.workspace?._id,
    }
    if (update) query['update'] = update
    query = new URLSearchParams(query).toString()
    const result = yield GetContentAnalysisAPI(query, state)
    console.log(result)
    yield put(stopAction(type))
    yield put(getContentAnalysisSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(getContentAnalysisError(err))
    yield put(stopAction(type))
    // yield put(setError(err))
  } finally {
    
  }
}

function* updateContentAnalysis({ type, payload: { } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield UpdateContentAnalysisAPi(state)
    console.log(result)
    yield put(updateContentAnalysisSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* contentAnalysisPageKeywords({ type, payload: { page, update } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const workspace = state?.workspace?.workspace?._id
    const body = {
      page,
      workspace
    }
    let query = {}
    if (update) query['update'] = update
    query = new URLSearchParams(query).toString()
    console.log(query)
    const result = yield GetContentAnalysisPageKeywordsAPI(body, query, state)
    console.log(result)
    yield put(ContentAnalysisPageKeywordsSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* ContentAnalysisSaga() {
  yield takeEvery(GET_CONTENT_ANALYSIS, getContentAnalysis)
  yield takeEvery(UPDATE_CONTENT_ANALYSIS, updateContentAnalysis)
  yield takeEvery(CONTENT_ANALYSIS_PAGE_KEYWORDS, contentAnalysisPageKeywords)
}

export default ContentAnalysisSaga
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import FeedbackModal from '../common/FeedbackModal';

const Feedback = () => {
  // ** states
  const [modal, setModal] = useState(false);

  // ** function to handle toggle modal
  const toggle = () => setModal(!modal);

  return (
    <>
      <Button className='feedback-btn' onClick={toggle}>
        <img src={`${process.env.PUBLIC_URL}/assets/images/feedback.svg`} alt="feedback-btn" />
      </Button>
      <FeedbackModal modal={modal} toggle={toggle} />
    </>
  );
}

export default Feedback;
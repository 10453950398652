import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import StripePayment from '../../../../components/common/PaymentBox';
import { updatePassword } from '../../../../store/actions';
import { CHANGE_PAYMENT_METHOD, UPDATE_PASSWORD } from '../../../../store/auth/actionTypes';
import { checkIfLoading } from '../../../../store/functions';

const ChangeCard = ({ modal, toggle }) => {
    const paymentSubmitBtn = React.useRef(null);
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [t] = useTranslation()

    const handleClick = () => {
        paymentSubmitBtn.current.click();
    };

    const { changeLoading } = useSelector(state => ({
        changeLoading: checkIfLoading(state, CHANGE_PAYMENT_METHOD)
    }))

    return (
        <>
            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader className='px-4' toggle={toggle}></ModalHeader>
                <ModalBody className='pt-1 pb-3'>
                    <h4 className='mb-3 text-center'>{t('Change Card')}</h4>
                    <div style={{ width: "80%" }} className="m-auto">
                        <StripePayment setLoading={setLoading} page="change-card" toggle={toggle} paymentSubmitBtn={paymentSubmitBtn} />
                    </div>
                    <div className='mt-3 text-right'>
                        <button className='btn click-btn green-btn' onClick={handleClick}>
                            {changeLoading || loading ? <Spinner size={'sm'} color="light" /> : t("Change")}
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default ChangeCard
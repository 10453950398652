import {
  LIST_BUSINESSES,
  LIST_BUSINESSES_SUCCESS,
  GET_BUSINESS,
  GET_BUSINESS_SUCCESS,
  DISCONNECT_BUSINESS,
  DISCONNECT_BUSINESS_SUCCESS,
  SELECT_BUSINESS,
  SELECT_BUSINESS_SUCCESS
} from "./actionTypes"

export const listBusinesses = (name, toggle1, toggle2) => {
  return {
    type: LIST_BUSINESSES,
    payload: { name, toggle1, toggle2 },
  }
}

export const listBusinessesSuccess = (data) => {
  return {
    type: LIST_BUSINESSES_SUCCESS,
    payload: data
  }
}

export const getBusiness = (id, workspace, history) => {
  return {
    type: GET_BUSINESS,
    payload: { id, workspace, history },
  }
}

export const getBusinessSuccess = (data) => {
  return {
    type: GET_BUSINESS_SUCCESS,
    payload: data
  }
}

export const selectBusiness = (id) => {
  return {
    type: SELECT_BUSINESS,
    payload: { id },
  }
}

export const selectBusinessSuccess = (data) => {
  return {
    type: SELECT_BUSINESS_SUCCESS,
    payload: data
  }
}

export const disconnectBusiness = (history) => {
  return {
    type: DISCONNECT_BUSINESS,
    payload: { history },
  }
}

export const disconnectBusinessSuccess = () => {
  return {
    type: DISCONNECT_BUSINESS_SUCCESS,
    payload: null
  }
}
import React from "react";
import { useTranslation } from "react-i18next";

function Posts({ data }) {
  const [t] = useTranslation()
  const renderData = () => {
    if (data.length > 0) {
      return data.map(ele =>
        <div className="post" key={ele._id}>
          <div className="img-con">
            <img src={ele?.image} width="50px" height={'50px'} onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = `${process.env.PUBLIC_URL}/assets/images/post-img.jpg`;
            }} alt="" />
          </div>
          <div className="description">
            <h6>{ele.author}</h6>
            <p className="text">{ele.text}</p>
            <p className="time">{t('posted-at')} {new Date(ele.date).toLocaleDateString()}</p>
          </div>
        </div>
      )
    } else {
      return <div>{t('no-posts-found')}</div>
    }
  }

  return (
    <div className="posts">
      <h4>{t('posts')} ({data?.length})</h4>
      {renderData()}
    </div>
  )
}

export default Posts;

export const LIST_REMINDERS = 'LIST_REMINDERS'
export const LIST_REMINDERS_SUCCESS = 'LIST_REMINDERS_SUCCESS'

export const UPDATE_REMINDER = 'UPDATE_REMINDER'
export const UPDATE_REMINDER_SUCCESS = 'UPDATE_REMINDER_SUCCESS'

export const DELETE_REMINDER = 'DELETE_REMINDER'
export const DELETE_REMINDER_SUCCESS = 'DELETE_REMINDER_SUCCESS'

export const ADD_REMINDER = 'ADD_REMINDER'
export const ADD_REMINDER_SUCCESS = 'ADD_REMINDER_SUCCESS'
import React, { useState, useEffect } from 'react'
import Tabs from './Tabs'
import Cards from './Cards'
import { contentGeneratorTabs } from '../../../store/data'
import { FormControl, InputGroup } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

function index() {
    // ** states
    const [tab, setTab] = useState(1)
    const [filterData, setFilterData] = useState()
    const data = contentGeneratorTabs
    const [t] = useTranslation()

    const { user } = useSelector(state => ({
        user: state.auth.user
    }))

    // ** use effect to set all data in default
    useEffect(() => {
        setFilterData(data)
    }, [])

    // ** function to handle filter data
    const handleFilterData = (e) => {
        switch (e.target.value) {
            case "all":
                setTab(1)
                setFilterData(data)
                break;
            case "blog-content":
                setTab(2)
                setFilterData(data.filter(ele => ele.category === "blog-content"))
                break;
            case "website-copy":
                setTab(3)
                setFilterData(data.filter(ele => ele.category === "website-copy"))
                break;
            case "social-media":
                setTab(4)
                setFilterData(data.filter(ele => ele.category === "social-media"))
                break;
            case "marketing":
                setTab(5)
                setFilterData(data.filter(ele => ele.category === "marketing"))
                break;
            case "more-tools":
                setTab(6)
                setFilterData(data.filter(ele => ele.category === "more-tools"))
                break;
        }
    }

    const handleSearch = (value) => {
        if(value && value?.length > 0) {
            const condition = new RegExp(value, 'i')
            setFilterData(data.filter(ele => condition.test(ele.title)))
        } else {
            setFilterData(data)
        }
    }

    return (
        <div className='contentGenerator'>
            <div className='container-fluid'>
                <div className='row justify-content-center'>
                    <div className='col-12 text-center title'>
                        <h2>{t('Hello')} {user.firstName}! {t('What are you creating today?')}</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.</p>
                    </div>
                    <div className='col-6 text-center'>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1" className='bg-transparent'><i class="fas fa-search"></i></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                onInput={e => handleSearch(e.target.value)}
                            />
                        </InputGroup>
                        {/* <Tabs handleFilterData={handleFilterData} tab={tab} /> */}
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row justify-content-center cards'>
                    <Cards filterData={filterData} />
                </div>
            </div>
        </div>
    )
}

export default index
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'reactstrap'
import BarChartPositiveNegative from '../../../../components/charts/BarChartPN'

function NewLostBacklinks({ loading, data }) {
    const [t] = useTranslation()
    return (
        <div className='page-views card'>
            <h4>{t('New & Lost backlinks')}</h4>
            {
                loading ? <div className='text-center mt-3'><Spinner color='success' size={'md'} /></div> : <BarChartPositiveNegative data={data} />
            }
        </div>
    )
}

export default NewLostBacklinks
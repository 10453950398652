import { put, select, takeEvery } from "redux-saga/effects"
import { GetTechnicalAnalysisAPI, UpdateTechnicalAnalysisAPI } from "./calls"
import { getTechnicalAnalysisError, getTechnicalAnalysisSuccess, updateTechnicalAnalysisSuccess } from './actions'
import { GET_TECHNICAL_ANALYSIS, UPDATE_TECHNICAL_ANALYSIS } from "./actionTypes"
import { setError, startAction, stopAction } from "../actions"

function* getTechnicalAnalysis({ type, payload: { id, update } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    let query = {
      workspace: state?.workspace?.workspace?._id,
    }
    if (update) query['update'] = update
    query = new URLSearchParams(query).toString()
    const result = yield GetTechnicalAnalysisAPI(query, state)
    yield put(stopAction(type))
    console.log(result)
    yield put(getTechnicalAnalysisSuccess(result?.data?.data))
  } catch (err) {
    yield put(getTechnicalAnalysisError(err))
    yield put(stopAction(type))
    // yield put(setError())
  } finally {
    // yield put(stopAction(type))
  }
}

function* updateTechnicalAnalysis({ type, payload: { } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield UpdateTechnicalAnalysisAPI(state)
    console.log(result)
    yield put(updateTechnicalAnalysisSuccess(result?.data?.data))
  } catch (err) {
    yield put(setError())
  } finally {
    yield put(stopAction(type))
  }
}

function* technicalAnalysisSaga() {
  yield takeEvery(GET_TECHNICAL_ANALYSIS, getTechnicalAnalysis)
  yield takeEvery(UPDATE_TECHNICAL_ANALYSIS, updateTechnicalAnalysis)
}

export default technicalAnalysisSaga
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'reactstrap'
import AreaChart from '../../../../components/charts/AreaChart'

function BacklinksOverTime({ loading, data }) {
    // ** fake backend database 

    const [trafficData, setTrafficData] = useState([])
    const [t] = useTranslation()

    useEffect(() => {
        if(data) {
            setTrafficData(data?.map(e => {
                return {
                    label: moment(e?.date).format('MMM'),
                    value: e?.backlinks
                }
            }))
        }
    }, [data])

    return (
        <div className='traffic-history card'>
            <h4>{t('Backlinks over time')}</h4>
            {
                loading ? <div className='text-center mt-3'><Spinner color='success' size={'md'} /></div> : <AreaChart name="Traffic Data" data={trafficData} curveType="smooth" showYaxis={true} />
            }
        </div>
    )
}

export default BacklinksOverTime
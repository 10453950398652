export const LIST_PROJECTS = 'LIST_PROJECTS'
export const LIST_PROJECTS_SUCCESS = 'LIST_PROJECTS_SUCCESS'

export const ADD_PROJECT = 'ADD_PROJECT'
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS'

export const ADD_USER_TO_PROJECT = 'ADD_USER_TO_PROJECT'
export const ADD_USER_TO_PROJECT_SUCCESS = 'ADD_USER_TO_PROJECT_SUCCESS'

export const REMOVE_USER_FROM_PROJECT = 'REMOVE_USER_FROM_PROJECT'
export const REMOVE_USER_FROM_PROJECT_SUCCESS = 'REMOVE_USER_FROM_PROJECT_SUCCESS'

export const DELETE_PROJECT = 'DELETE_PROJECT'
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS'

export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS'

export const GET_PROJECT = 'GET_PROJECT'
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS'

export const LIST_TASKS = 'LIST_TASKS'
export const LIST_TASKS_SUCCESS = 'LIST_TASKS_SUCCESS'

export const ADD_TASK = 'ADD_TASK'
export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS'

export const EDIT_TASK = 'EDIT_TASK'
export const EDIT_TASK_SUCCESS = 'EDIT_TASK_SUCCESS'

export const DELETE_TASK = 'DELETE_TASK'
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS'

export const SET_TASK_COMPLETE = 'SET_TASK_COMPLETE'
export const SET_TASK_COMPLETE_SUCCESS = 'SET_TASK_COMPLETE_SUCCESS'

export const ADD_SUBTASK = 'ADD_SUBTASK'
export const ADD_SUBTASK_SUCCESS = 'ADD_SUBTASK_SUCCESS'

export const UPDATE_SUBTASK = 'UPDATE_SUBTASK'
export const UPDATE_SUBTASK_SUCCESS = 'UPDATE_SUBTASK_SUCCESS'

export const DELETE_SUBTASK = 'DELETE_SUBTASK'
export const DELETE_SUBTASK_SUCCESS = 'DELETE_SUBTASK_SUCCESS'

export const SET_SUBTASK_DONE = 'SET_SUBTASK_DONE'
export const SET_SUBTASK_DONE_SUCCESS = 'SET_SUBTASK_DONE_SUCCESS'
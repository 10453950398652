import React, { useEffect, useState } from 'react'
import { Col, FormControl, InputGroup, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap'
import TablePagination from '../../../components/common/Pagination'
import { addCompetitor, competitorsBacklinks, getCompetitorBacklinks, getCompetitorBacklinksNotMe, listBacklinks, listCompetitors, removeCompetitor } from '../../../store/actions'
import { checkIfLoading } from '../../../store/functions'
import { ADD_COMPETITOR, COMPETITORS_BACKLINKS, GET_COMPETITOR_BACKLINKS, GET_COMPETITOR_BACKLINKS_NOT_ME, LIST_BACKLINKS, LIST_COMPETITORS, REMOVE_COMPETITOR } from '../../../store/webreputation/actionTypes'
import WebReputationNav from './components/Nav'
import CompetitorsBacklinksTable from './components/CompetitorsBacklinksTable'
import CustomTooltip from '../../../components/common/CustomTooltip'
import { useTranslation } from 'react-i18next'

function BacklinksGap() {
    const dispatch = useDispatch()
    const [selectedCompetitor, setSelectedCompetitor] = useState(null)
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [addCompBox, setAddCompBox] = useState(false)
    const [compUrl, setCompUrl] = useState(null)
    const [t] = useTranslation()
    const [sortedBacklinks, setSortedBacklinks] = useState([])
    const [sort, setSort] = useState({
        field: 'domain',
        sort: 1
    })

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    let { backlinks, details, competitors, bkLoading, compLoading, addCompLoading, removeCompLoading, workspace } = useSelector(state => ({
        backlinks: state?.webreputation?.backlinksNotMe,
        details: state?.webreputation?.competitor?.page_details,
        competitors: state?.webreputation?.competitors?.list,
        bkLoading: checkIfLoading(state, GET_COMPETITOR_BACKLINKS_NOT_ME),
        compLoading: checkIfLoading(state, LIST_COMPETITORS),
        addCompLoading: checkIfLoading(state, ADD_COMPETITOR),
        removeCompLoading: checkIfLoading(state, REMOVE_COMPETITOR),
        workspace: state?.workspace?.workspace
    }))

    const [filters, setFilters] = useState({
        page: 1,
        limit: 10
    })

    const sortBacklinks = (field, sort) => {
        if (backlinks && backlinks?.length > 0) {
            let sorted
            if (field === 'domain') {
                if (sort === 1) sorted = backlinks.sort((a, b) => Number(a?.domainAuthority) - Number(b?.domainAuthority))
                if (sort === -1) sorted = backlinks.sort((a, b) => Number(b?.domainAuthority) - Number(a?.domainAuthority))
            } else {
                if (sort === 1) sorted = backlinks.sort((a, b) => Number(a?.pageAuthority) - Number(b?.pageAuthority))
                if (sort === -1) sorted = backlinks.sort((a, b) => Number(b?.pageAuthority) - Number(a?.pageAuthority))
            }
            setSortedBacklinks(sorted)
        }
    }

    useEffect(() => {
        sortBacklinks(sort?.field, sort?.sort)
    }, [sort])

    useEffect(() => {
        setSortedBacklinks(backlinks)
    }, [backlinks])

    const setPage = (page) => {
        setFilters({
            ...filters,
            page
        })
    }

    useEffect(() => {
        if (workspace) dispatch(listCompetitors())
    }, [workspace])

    useEffect(() => {
        if (competitors && competitors?.length > 0) {
            setSelectedCompetitor(competitors[0])
        }
    }, [competitors])

    useEffect(() => {
        console.log({
            selectedCompetitor
        })
        if (selectedCompetitor) {
            const id = selectedCompetitor?._id
            dispatch(getCompetitorBacklinksNotMe(id))
        }
    }, [selectedCompetitor, filters.page])

    console.log(sortedBacklinks)

    const renderBacklinks = () => {
        if (bkLoading) return <Spinner size="sm" className="m-auto text-center" color="success" />
        if (sortedBacklinks && sortedBacklinks?.length > 0) {
            return sortedBacklinks?.map(bk => {
                return <tr>
                    <td>
                        <div><b>{bk?.domain}</b></div>
                        <div><small className='text-muted text-sm'><a href={bk?.url} className="text-dark">{bk?.url}</a></small></div>
                    </td>
                    <td>{bk?.domainAuthority}</td>
                    <td>{bk?.pageAuthority}</td>
                    <td width={'30%'}><a className='text-dark' href={bk?.targetUrl}>{bk?.targetUrl}</a></td>
                </tr>
            })
        } else {
            return <tr><td colSpan={4}>{t('no-backlinks-found')}</td></tr>
        }
    }

    const renderCompetitors = () => {
        if (compLoading) return <Spinner size="sm" className="m-auto text-center" color="success" />
        if (competitors?.length > 0) {
            return competitors?.map(comp => {
                return <DropdownItem onClick={() => setSelectedCompetitor(comp)}>
                    <button className="btn">{comp?.url}</button>
                </DropdownItem>
            })
        } else {
            return <option>No Competitor found</option>
        }
    }

    const submitAddCompetitor = () => {
        if (!compUrl) return
        dispatch(addCompetitor(compUrl, () => setAddCompBox(false)))
    }

    return (
        <div className='container-fluid backlinks page'>
            <WebReputationNav />
            <div className='mt-5'>
                <h4>{t('Choose the competitor to view who is linking to him')} <h4 className='text-danger d-inline-block'>{t('but not to you')}</h4></h4>
                <Dropdown className='custom-dropdown select-box' isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle caret>
                        {selectedCompetitor?.url}
                    </DropdownToggle>
                    <DropdownMenu right={true}>
                        {renderCompetitors()}
                    </DropdownMenu>
                </Dropdown>
                <div className='d-flex my-3'>
                    <button onClick={() => setAddCompBox(!addCompBox)} className='btn click-btn green-btn mr-2'>{t('add-new-competitor')}</button>
                    <button className='btn click-btn orange-btn-outline' onClick={() => dispatch(removeCompetitor(selectedCompetitor?._id))}>
                        {
                            removeCompLoading ? <Spinner size="sm" className="m-auto text-center" color="orange" /> : t('remove-competitor')
                        }
                    </button>
                </div>
                <div className='add-competitor' style={{ display: addCompBox ? 'block' : 'none' }}>
                    <div className='row align-items-end'>
                        <div className='col-md-8'>
                            <input className='form-control' onChange={(e) => setCompUrl(e.target.value)} placeholder={t('enter-competitor-url')} />
                        </div>
                        <div className='col-md-4'>
                            <button className='btn click-btn orange-btn btn-block py-1 px-1' onClick={submitAddCompetitor}>
                                {
                                    addCompLoading ? <Spinner size="sm" className="m-auto text-center" color="light" /> : t('add-competitor')
                                }
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col-md-12'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>{t('backlinker’s website')}</th>
                                <th>{t('domain-authority')}
                                    <i onClick={() => setSort({
                                        field: 'domain',
                                        sort: sort.sort === 1 ? -1 : 1
                                    })} className='fas fa-sort ml-1 cursor-pointer'></i></th>
                                <th>
                                    {t('page-authority')}
                                    <i onClick={() => setSort({
                                        field: 'page',
                                        sort: sort.sort === 1 ? -1 : 1
                                    })} className='fas fa-sort ml-1 cursor-pointer'></i>
                                </th>
                                <th width="30%">{t('Linking to your competitor Here')}</th>
                                {/* <th>{t('Is the Backlinker linking to you?')}</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {renderBacklinks()}
                        </tbody>
                    </table>
                </div>
                <div className='col-md-12 mt-3'>
                    <TablePagination count={details?.total_items} page={filters?.page} setPage={setPage} limit={filters?.limit} loading={bkLoading} />
                </div>
            </div>
        </div>
    )
}

export default BacklinksGap
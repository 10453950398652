import React from "react";
import { useTranslation } from "react-i18next";

function Tabs({ handleSelectContent, tab, business, descovering }) {
  const [t] = useTranslation()

  return (
    <div className='tabs'>
      <button type='button' className={tab === 1 ? 'btn active' : 'btn'} value="all" onClick={e => handleSelectContent(e.target.value)}>
        {t('all-keywords')} ({business?.keywords?.length})
      </button>
      <button type='button' className={tab === 2 ? 'btn active' : 'btn'} value="common" onClick={e => handleSelectContent(e.target.value)}>
        {t('keywords-in-common')} ({business?.keywordsCommon?.length})
      </button>
      <button type='button' className={tab === 3 ? 'btn active' : 'btn'} value="posts" onClick={e => handleSelectContent(e.target.value)}>
        {t('posts')} ({business?.postsGenerated ? business?.posts?.length : "Still processing"})
      </button>
      <button type='button' className={tab === 4 ? 'btn active' : 'btn'} value="reviews" onClick={e => handleSelectContent(e.target.value)}>
        {t('reviews')} ({business?.reviewsGenerated ? business?.reviews?.length : "Still processing"})
      </button>
      {!descovering ? <button type='button' className={tab === 5 ? 'btn active' : 'btn'} value="discovering" onClick={e => handleSelectContent(e.target.value)}>
        {t('discover-growth')}
      </button> : ""}
    </div>
  )
}

export default Tabs;
import React from 'react'
import DataTable from 'react-data-table-component';

function DataTableComponent({ columns, data, limit = 50, paginationPerPage = [50, 100, 200] }) {
    return (
        <DataTable
            columns={columns}
            data={data}
            pagination={true}
            paginationPerPage={limit}
            paginationRowsPerPageOptions={paginationPerPage}
        />
    )
}

export default DataTableComponent
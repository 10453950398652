import { put, select, takeEvery } from "redux-saga/effects"
import { AddProjectAPI, AddSubtaskAPI, AddTaskAPI, AddUserToProjectAPI, DeleteTaskAPI, EditTaskAPI, ListProjectsAPI, RemoveUserFromProjectAPI } from "./calls"
import { addProjectSuccess, addSubtaskSuccess, addTaskSuccess, addUserToProjectSuccess, deleteTaskSuccess, editTaskSuccess, listProjectsSuccess, removeUserFromProjectSuccess } from './actions'
import { ADD_PROJECT, ADD_SUBTASK, ADD_TASK, ADD_USER_TO_PROJECT, DELETE_TASK, EDIT_TASK, LIST_PROJECTS, REMOVE_USER_FROM_PROJECT } from "./actionTypes"
import { startAction, setError, stopAction, setSuccess } from "../actions"

function* listProjects({ type, payload: { search } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const workspace = state?.workspace?.workspace?._id
    let query = {}
    if(search) query['search'] = search
    query = new URLSearchParams({ ...query, workspace }).toString()
    const result = yield ListProjectsAPI(query, state)
    console.log(result)
    yield put(listProjectsSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* addProject({ type, payload: { data, func } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const workspace = state?.workspace?.workspace?._id
    const body = { ...data, workspace }
    const result = yield AddProjectAPI(body, state)
    console.log(result)
    yield put(addProjectSuccess(result?.data?.data))
    if (func) func()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* addUserToProject({ type, payload: { data, func } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield AddUserToProjectAPI(data, state)
    console.log(result)
    yield put(addUserToProjectSuccess(result?.data?.data))
    if (func) func()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* removeUserFromProject({ type, payload: { data, func } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    console.log({ data, state })
    const result = yield RemoveUserFromProjectAPI(data, state)
    console.log(result)
    yield put(removeUserFromProjectSuccess(result?.data?.data))
    if (func) func()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* addTask({ type, payload: { data, project, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield AddTaskAPI(project, data, state)
    console.log(result)
    yield put(addTaskSuccess(result?.data?.data))
    if (toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* editTask({ type, payload: { data, id, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield EditTaskAPI(id, data, state)
    console.log(result)
    yield put(editTaskSuccess(result?.data?.data))
    if (toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* deleteTask({ type, payload: { id, func } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield DeleteTaskAPI(id, state)
    console.log(result)
    yield put(deleteTaskSuccess(result?.data?.data))
    if (func) func()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* addSubTask({ type, payload: { data, taskId, func } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield AddSubtaskAPI(taskId, data, state)
    console.log(result)
    yield put(addSubtaskSuccess(result?.data?.data))
    if (func) func()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* ProjectManagementSaga() {
  yield takeEvery(LIST_PROJECTS, listProjects)
  yield takeEvery(ADD_PROJECT, addProject)
  yield takeEvery(ADD_TASK, addTask)
  yield takeEvery(EDIT_TASK, editTask)
  yield takeEvery(DELETE_TASK, deleteTask)
  yield takeEvery(ADD_SUBTASK, addSubTask)
  yield takeEvery(ADD_USER_TO_PROJECT, addUserToProject)
  yield takeEvery(REMOVE_USER_FROM_PROJECT, removeUserFromProject)
}

export default ProjectManagementSaga
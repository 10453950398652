import {
  GENERATE_IDEA,
  GENERATE_IDEA_SUCCESS
} from "./actionTypes"

export const generateIdea = (data) => {
  return {
    type: GENERATE_IDEA,
    payload: data,
  }
}

export const generateIdeaSuccess = (data) => {
  return {
    type: GENERATE_IDEA_SUCCESS,
    payload: data
  }
}
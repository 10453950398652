import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import ErrorsChart from '../../../components/analysisComponents.js/ErrorsChart'
import Issues from '../../../components/analysisComponents.js/Issues'
import SideDropdown from '../../../components/analysisComponents.js/SideDropdown'
import FeedbackModal from '../../../components/common/FeedbackModal'
import { checkIfLoading } from '../../../store/functions'
import { getTechnicalAnalysis } from '../../../store/technicalAnalysis/actions'
import { GET_TECHNICAL_ANALYSIS } from '../../../store/technicalAnalysis/actionTypes'

function index() {
    // ** states
    const [modal, setModal] = useState(false);
    const dispatch = useDispatch()
    const [page, setPage] = useState(null)
    const [t] = useTranslation()
    const [crawl, setCrawl] = useState(false)

    // ** function to handle modal toggle
    const toggle = () => setModal(!modal);

    const { loading, workspace, technicalAnalysis, message, lang } = useSelector(state => ({
        loading: checkIfLoading(state, GET_TECHNICAL_ANALYSIS),
        workspace: state?.workspace?.workspace,
        technicalAnalysis: state?.technicalAnalysis?.data,
        message: state?.technicalAnalysis?.message,
        lang: state.ui.lang
    }))

    useEffect(() => {
        dispatch(getTechnicalAnalysis(workspace?._id))
        setTimeout(() => {setCrawl(true)}, 2000)
    }, [workspace])

    const handleSetPage = (url) => {
        const page = technicalAnalysis?.pages?.find(p => p.url === url)
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setPage(page)
    }

    return (
        <div className='analysis'>
            <div className='container-fluid'>
                <div className='row justify-content-center'>
                    {
                        loading ? <>
                            <div className='text-center col-md-12'>
                                <Spinner className="m-auto big-spinner text-center" color="success" />
                            </div>
                            { crawl ? <div className='col-md-6 text-center lead mt-3'>{t('we are crawling your website, the time needed for this action might change based on our queue and your website size. Please hold on or return here later')}</div> : ""}
                        </> :
                            technicalAnalysis ? <>
                                <div className='col-md-11 text-center'>
                                    <SideDropdown setPage={setPage} page={page} pages={technicalAnalysis?.pages} />
                                    <p className='description'>{t('Every good SEO strategy needs to be done page by page. For This reason we indicate deeper analysis in the specific pages')}</p>
                                </div>
                                <div className='col-xl-11'>
                                    <div className='row'>
                                        <div className='col-xl-6 col-sm-8'>
                                            <Issues data={page || technicalAnalysis?.data} setPage={handleSetPage} />
                                        </div>
                                        <div className='col-xl-6 col-sm-4'>
                                            <div className='errors-quotation-chart'>
                                                {/* <div className='card'>
                                                    <ErrorsChart data={technicalAnalysis} loading={loading} />
                                                </div> */}
                                                <p className='fix-hint'>{t('fixed-analyze-again')}</p>
                                                <div className='buttons'>
                                                    <button type='button' className='btn click-btn orange-btn' onClick={() => dispatch(getTechnicalAnalysis(workspace?._id, true))}>{t('analyze-website')}</button>
                                                    <button type='button' className='btn click-btn green-btn' onClick={toggle}>{t('contact-us-if-any-help')}</button>
                                                </div>
                                                <FeedbackModal text="tell-us-how-we-can-help" modal={modal} toggle={toggle} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </> :
                                message ?
                                    <div className='col-md-11'>
                                        <div className='alert alert-warning'>{message[lang]}</div>
                                    </div> :
                                    <div className='col-md-11'>
                                        <div className='alert alert-warning'>{t('Task in progress')}</div>
                                    </div>
                    }
                </div>
            </div>
        </div >
    )
}

export default index
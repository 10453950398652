import {
  ADD_CUSTOMER,
  ADD_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_COMMENT,
  ADD_CUSTOMER_COMMENT_SUCCESS,
  ADD_CUSTOMER_TASK,
  ADD_CUSTOMER_TASK_SUCCESS,
  GET_ANALYTICS,
  GET_ANALYTICS_SUCCESS,
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_EMAILS,
  GET_EMAILS_SUCCESS,
  GET_TIMELINE,
  GET_TIMELINE_SUCCESS,
  LIST_TOP_5_TAGS,
  LIST_TOP_5_TAGS_SUCCESS,
  LIST_CUSTOMERS,
  LIST_CUSTOMERS_SUCCESS,
  LIST_TAGS,
  LIST_TAGS_SUCCESS,
  LIST_TAG_CUSTOMERS,
  LIST_TAG_CUSTOMERS_SUCCESS,
  LIST_EMAIL_TEMPLATES,
  LIST_EMAIL_TEMPLATES_SUCCESS,
  ADD_EMAIL_TEMPLATE,
  ADD_EMAIL_TEMPLATE_SUCCESS,
  GET_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATE_SUCCESS,
  UPDATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
  DELETE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE_SUCCESS,
  REMOVE_CUSTOMER_COMMENT,
  REMOVE_CUSTOMER_COMMENT_SUCCESS,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  ADD_TAG,
  ADD_TAG_SUCCESS,
  ADD_MEDIA,
  ADD_MEDIA_SUCCESS,
  LIST_MEDIA,
  LIST_MEDIA_SUCCESS,
  REMOVE_MEDIA,
  REMOVE_MEDIA_SUCCESS,
  UPLOAD_CUSTOMERS,
  UPLOAD_CUSTOMERS_SUCCESS
} from "./actionTypes"

export const addCustomer = (data, toggle) => {
  return {
    type: ADD_CUSTOMER,
    payload: { data, toggle },
  }
}

export const addCustomerSuccess = (data) => {
  return {
    type: ADD_CUSTOMER_SUCCESS,
    payload: data
  }
}

export const deleteCustomer = (id, history) => {
  return {
    type: DELETE_CUSTOMER,
    payload: { id, history },
  }
}

export const deleteCustomerSuccess = (data) => {
  return {
    type: DELETE_CUSTOMER_SUCCESS,
    payload: data
  }
}

export const updateCustomer = (id, data, toggle, pipeline) => {
  return {
    type: UPDATE_CUSTOMER,
    payload: { id, data, toggle, pipeline },
  }
}

export const updateCustomerSuccess = (data) => {
  return {
    type: UPDATE_CUSTOMER_SUCCESS,
    payload: data
  }
}

export const addComment = (comment, toggle) => {
  return {
    type: ADD_CUSTOMER_COMMENT,
    payload: { comment, toggle },
  }
}

export const addCommentSuccess = (data) => {
  return {
    type: ADD_CUSTOMER_COMMENT_SUCCESS,
    payload: data
  }
}

export const uploadCustomers = (file, toggle) => {
  return {
    type: UPLOAD_CUSTOMERS,
    payload: { file, toggle },
  }
}

export const uploadCustomersSuccess = (data) => {
  return {
    type: UPLOAD_CUSTOMERS_SUCCESS,
    payload: data
  }
}

export const removeComment = (id) => {
  return {
    type: REMOVE_CUSTOMER_COMMENT,
    payload: { id },
  }
}

export const removeCommentSuccess = (id) => {
  return {
    type: REMOVE_CUSTOMER_COMMENT_SUCCESS,
    payload: id
  }
}

export const addCustomerTask = (id, data, toggle) => {
  return {
    type: ADD_CUSTOMER_TASK,
    payload: { id, data, toggle },
  }
}

export const addCustomerTaskSuccess = (data) => {
  return {
    type: ADD_CUSTOMER_TASK_SUCCESS,
    payload: data
  }
}

export const getCrmAnalytics = (history) => {
  return {
    type: GET_ANALYTICS,
    payload: { history },
  }
}

export const getCrmAnalyticsSuccess = (data) => {
  return {
    type: GET_ANALYTICS_SUCCESS,
    payload: data
  }
}

export const getCustomer = (id, history) => {
  return {
    type: GET_CUSTOMER,
    payload: { id, history },
  }
}

export const getCustomerSuccess = (data) => {
  return {
    type: GET_CUSTOMER_SUCCESS,
    payload: data
  }
}

export const addTag = (name, toggle, customer) => {
  return {
    type: ADD_TAG,
    payload: { name, toggle, customer },
  }
}

export const addTagSuccess = (data) => {
  return {
    type: ADD_TAG_SUCCESS,
    payload: data
  }
}

export const getEmails = (id, query) => {
  return {
    type: GET_EMAILS,
    payload: { id, query },
  }
}

export const getEmailsSuccess = (data) => {
  return {
    type: GET_EMAILS_SUCCESS,
    payload: data
  }
}

export const listEmailTemplates = () => {
  return {
    type: LIST_EMAIL_TEMPLATES,
    payload: {},
  }
}

export const listEmailTemplatesSuccess = (data) => {
  return {
    type: LIST_EMAIL_TEMPLATES_SUCCESS,
    payload: data
  }
}

export const addEmailTemplate = (body, toggle) => {
  return {
    type: ADD_EMAIL_TEMPLATE,
    payload: { body, toggle },
  }
}

export const addEmailTemplateSuccess = (data) => {
  return {
    type: ADD_EMAIL_TEMPLATE_SUCCESS,
    payload: data
  }
}

export const getEmailTemplate = (id) => {
  return {
    type: GET_EMAIL_TEMPLATE,
    payload: { id },
  }
}

export const getEmailTemplateSuccess = (data) => {
  return {
    type: GET_EMAIL_TEMPLATE_SUCCESS,
    payload: data
  }
}

export const updateEmailTemplate = (id, data, toggle) => {
  return {
    type: UPDATE_EMAIL_TEMPLATE,
    payload: { id, data, toggle },
  }
}

export const updateEmailTemplateSuccess = (data) => {
  return {
    type: UPDATE_EMAIL_TEMPLATE_SUCCESS,
    payload: data
  }
}

export const deleteEmailTemplate = (id, toggle) => {
  return {
    type: DELETE_EMAIL_TEMPLATE,
    payload: { id, toggle },
  }
}

export const deleteEmailTemplateSuccess = (data) => {
  return {
    type: DELETE_EMAIL_TEMPLATE_SUCCESS,
    payload: data
  }
}

export const getTimeline = (user, history) => {
  return {
    type: GET_TIMELINE,
    payload: { user, history },
  }
}

export const getTimelineSuccess = (data) => {
  return {
    type: GET_TIMELINE_SUCCESS,
    payload: data
  }
}

export const listTop5Tags = (query) => {
  return {
    type: LIST_TOP_5_TAGS,
    payload: { query },
  }
}

export const listTop5TagsSuccess = (data) => {
  return {
    type: LIST_TOP_5_TAGS_SUCCESS,
    payload: data
  }
}

export const listCustomers = (query, history) => {
  return {
    type: LIST_CUSTOMERS,
    payload: { query, history },
  }
}

export const listCustomersSuccess = (data) => {
  return {
    type: LIST_CUSTOMERS_SUCCESS,
    payload: data
  }
}

export const listTags = (query) => {
  return {
    type: LIST_TAGS,
    payload: { query },
  }
}

export const listTagsSuccess = (data) => {
  return {
    type: LIST_TAGS_SUCCESS,
    payload: data
  }
}

export const listTagCustomers = (user, history) => {
  return {
    type: LIST_TAG_CUSTOMERS,
    payload: { user, history },
  }
}

export const listTagCustomersSuccess = (data) => {
  return {
    type: LIST_TAG_CUSTOMERS_SUCCESS,
    payload: data
  }
}

export const addMedia = (file, query) => {
  return {
    type: ADD_MEDIA,
    payload: { file, query },
  }
}

export const addMediaSuccess = (data) => {
  return {
    type: ADD_MEDIA_SUCCESS,
    payload: data
  }
}

export const listMedia = (id, query) => {
  return {
    type: LIST_MEDIA,
    payload: { id, query },
  }
}

export const listMediaSuccess = (data) => {
  return {
    type: LIST_MEDIA_SUCCESS,
    payload: data
  }
}

export const removeMedia = (id) => {
  return {
    type: REMOVE_MEDIA,
    payload: { id },
  }
}

export const removeMediaSuccess = (data) => {
  return {
    type: REMOVE_MEDIA_SUCCESS,
    payload: data
  }
}
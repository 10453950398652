import React, { useEffect, useState } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import TablePagination from '../../../../components/common/Pagination'
import { addCompetitor, competitorsBacklinks, listCompetitors, removeCompetitor } from '../../../../store/actions'
import { checkIfLoading } from '../../../../store/functions'
import { ADD_COMPETITOR } from '../../../../store/webreputation/actionTypes'

const CompetitorsTable = ({ loading, competitors, details }) => {
    const dispatch = useDispatch()
    const [filters, setFilters] = useState({
        page: 1,
        limit: 5
    })
    const [compUrl, setCompUrl] = useState(null)
    const [t] = useTranslation()

    const setPage = (page) => {
        setFilters({
            ...filters,
            page
        })
    }

    const { addCompLoading, workspace } = useSelector(state => ({
        addCompLoading: checkIfLoading(state, ADD_COMPETITOR),
        workspace: state?.workspace?.workspace
    }))

    useEffect(() => {
        const query = new URLSearchParams(filters).toString()
        console.log(query)
        dispatch(listCompetitors(query))
    }, [filters.page, workspace])

    const renderCompetitors = () => {
        if (loading) return <Spinner size="sm" className="m-auto text-center" color="success" />
        if (competitors?.length > 0) {
            return competitors?.map(comp => {
                return <li>
                    <span>{comp?.url}</span>
                    <button onClick={() => dispatch(removeCompetitor(comp?._id))} className='btn btn-link m-0 p-0 green float-right'>Remove</button>
                </li>
            })
        } else {
            return <div>{t('No competitor found')}</div>
        }
    }

    const submitAddCompetitor = () => {
        if (!compUrl || compUrl?.length < 5) return
        dispatch(addCompetitor(compUrl, () => {
            dispatch(competitorsBacklinks())
            setCompUrl('')
        }))
    }

    return (
        <div>
            <div className='row justify-content-between align-items-end'>
                <div className='col-md-4'>
                    <InputGroup>
                        <InputGroup.Text id="basic-addon1" className='bg-transparent'><i class="fas fa-search"></i></InputGroup.Text>
                        <FormControl
                            placeholder={t('search')}
                            aria-label={t('search')}
                            aria-describedby="basic-addon1"
                        />
                    </InputGroup>
                </div>
                <div className='col-md-6'>
                    <div className='add-competitor'>
                        <div className='row align-items-end'>
                            <div className='col-md-6'>
                                <input className='form-control' value={compUrl} onChange={(e) => setCompUrl(e.target.value)} placeholder='Enter name' />
                            </div>
                            <div className='col-md-6 text-right'>
                                <button className='btn click-btn orange-btn btn-sm py-1 px-3' onClick={submitAddCompetitor}>
                                    {addCompLoading ? <Spinner color='light' size={'sm'} /> : t('add-competitor')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul className='list-unstyled competitors-list mt-3'>
                {renderCompetitors()}
            </ul>
            <div className='mt-3'>
                <TablePagination count={details?.total_items} page={filters?.page} setPage={setPage} limit={filters?.limit} loading={loading} />
            </div>
        </div>
    )
}

export default CompetitorsTable
import {
  LIST_BUSINESSES,
  LIST_BUSINESSES_SUCCESS,
  GET_BUSINESS,
  GET_BUSINESS_SUCCESS,
  DISCONNECT_BUSINESS,
  DISCONNECT_BUSINESS_SUCCESS,
  SELECT_BUSINESS,
  SELECT_BUSINESS_SUCCESS
} from "./actionTypes"

const initialState = {
  businesses: [],
  business: null,
  selectedBusiness: null,
  keyword: null
}

const localseo = (state = initialState, action) => {
  switch (action.type) {
    case LIST_BUSINESSES:
      return { ...state, businesses: [] }
    case LIST_BUSINESSES_SUCCESS:
      return { ...state, businesses: action.payload?.data, keyword: action?.payload?.keyword }
    case GET_BUSINESS:
      return { ...state, business: null }
    case GET_BUSINESS_SUCCESS:
      return { ...state, business: action.payload }
    case SELECT_BUSINESS:
      return { ...state, }
    case SELECT_BUSINESS_SUCCESS:
      return { ...state, selectedBusiness: action?.payload }
    case DISCONNECT_BUSINESS:
      return { ...state }
    case DISCONNECT_BUSINESS_SUCCESS:
      return { ...state, business: null }
    default:
      return state
  }
}

export default localseo

import React, { useEffect, useState } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import { listEmailTemplates } from '../../../../store/actions'
import { LIST_EMAIL_TEMPLATES } from '../../../../store/crm/actionTypes'
import { checkIfLoading } from '../../../../store/functions'
import CreateNewEmail from './CreateNewEmail'

const EmailBuilderTab = () => {
    const [newEmail, setNewEmail] = useState(false)
    const [template, setTemplate] = useState(null)
    const dispatch = useDispatch()
    const [t] = useTranslation()

    const { templates, loading } = useSelector(state => ({
        templates: state.crm.templates,
        loading: checkIfLoading(state, LIST_EMAIL_TEMPLATES)
    }))

    useEffect(() => {
        if (!newEmail) dispatch(listEmailTemplates())
    }, [newEmail])

    useEffect(() => {
        setNewEmail(false)
    }, [])

    const editTemplate = (temp) => {
        setTemplate(temp)
        setNewEmail(true)
    }

    const renderTemplates = () => {
        if (loading) return <Spinner size="sm" className="m-auto text-center" color="success" />
        if (templates && templates?.length > 0) {
            return templates?.map(temp => (
                <div className='col-md-3 cursor-pointer' onClick={() => editTemplate(temp)}>
                    <div className='image-box mb-2'>
                        <img className='img-responsive' height={400} src={`${process.env.PUBLIC_URL}/assets/images/image 28.svg`} />
                    </div>
                    <h6>{temp.name}</h6>
                </div>
            ))
        } else {
            return <div className='col-md-6'>{t('No templates found')}</div>
        }
    }

    return (
        newEmail ? <CreateNewEmail template={template} toggle={() => {
            setNewEmail(false)
            setTemplate(null)
        }} /> : <>
            <div className='d-flex align-items-center justify-content-between'>
                <h4>{t('Your templates')}</h4>
                <InputGroup className="custom col-md-3 mb-3">
                    <InputGroup.Text id="basic-addon1" className='bg-transparent'><i class="fas fa-search"></i></InputGroup.Text>
                    <FormControl placeholder="Search" aria-label="Search" aria-describedby="basic-addon1" />
                </InputGroup>
            </div>
            <div className='my-3'>
                <button className='btn click-btn green-btn' onClick={() => setNewEmail(true)}>{t('Create new email')}</button>
            </div>
            <div>
                <div className='lead'>{t('Recently created')}</div>
                <div className='row mt-3'>
                    {renderTemplates()}
                </div>
            </div>
        </>
    )
}

export default EmailBuilderTab
import React from 'react'
import { useNavigate } from 'react-router-dom'
import ChoosePlan from '../../../Auth/ChoosePlan'

const ChangePlan = () => {
    const history = useNavigate()

    return (
        <div className='container-fluid page'>
            <div>
                <i class="fas fa-arrow-left fa-2x text-green" style={{ cursor: "pointer" }} onClick={() => history('/settings')}></i>
            </div>
            <ChoosePlan />
        </div>
    )
}

export default ChangePlan
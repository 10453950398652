import { all, fork } from 'redux-saga/effects';
import authSaga from './auth/saga'
import workspaceSaga from './workspace/saga'
import contentAnalysisSaga from './contentAnalysis/saga'
import technicalAnalysisSaga from './technicalAnalysis/saga'
import dashboardSaga from './dashboard/saga'
import localseoSaga from './localseo/saga'
import salesSaga from './salesManagement/saga'
import webReputationSaga from './webreputation/saga'
import crmSaga from './crm/saga'
import uploadSaga from './upload/saga'
import reminderSaga from './reminders/saga'
import subscriptionSaga from './subscription/saga'
import contentGeneratorSaga from './contentGenerator/saga'
import ProjectManagementSaga from './projectManagement/saga';
import CampaignsSaga from './campaigns/saga';
import CompetitorAnalysis from './competitorAnalysis/saga';

const sagas = [authSaga, workspaceSaga, contentAnalysisSaga, technicalAnalysisSaga, dashboardSaga,
  localseoSaga, crmSaga, salesSaga, webReputationSaga, reminderSaga, uploadSaga,
  subscriptionSaga, contentGeneratorSaga, ProjectManagementSaga, CampaignsSaga, CompetitorAnalysis];

export default function* rootSaga() {
  yield all(sagas.map(saga => fork(saga)));
}

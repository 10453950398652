import React from 'react'

function Notification() {
  return (
    <div className='notification-con'>
        <img src={`${process.env.PUBLIC_URL}/assets/images/icons/notification.svg`} alt='notification' />
    </div>
  )
}

export default Notification
import React from "react";
import { useTranslation } from "react-i18next";
import { numberWithCommas } from "../../../../store/functions";

function CompetitorBoxes({ competitor }) {
    const [t] = useTranslation()

    return (
        <div className="row competitor-boxes mt-3">
            <div className="col-md-3 text-center">
                <div className="card pl-0 pr-0">
                    <div className="box border-left-orange">
                        <div className="text-muted">{t('Total visits')}</div>
                        <div className="h4">{numberWithCommas(competitor?.data?.audience?.visits)}</div>
                    </div>
                </div>
            </div>
            <div className="col-md-3 text-center">
                <div className="card pl-0 pr-0">
                    <div className="box border-left-orange">
                        <div className="text-muted">{t('Bounce Rate')}</div>
                        <div className="h4">{competitor?.data?.audience?.bounce_rate}</div>
                    </div>
                </div>
            </div>
            <div className="col-md-3 text-center">
                <div className="card pl-0 pr-0">
                    <div className="box border-left-orange">
                        <div className="text-muted">{t('Pages per visit')}</div>
                        <div className="h4">{Number(competitor?.data?.audience?.page_views_avg).toFixed(2)}</div>
                    </div>
                </div>
            </div>
            <div className="col-md-3 text-center">
                <div className="card pl-0 pr-0">
                    <div className="box border-left-orange">
                        <div className="text-muted">{t('Average Length of visit')}</div>
                        <div className="h4">{competitor?.data?.audience?.time_on_site_avg}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompetitorBoxes;
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import { deleteCampaign, listCampaigns, updateCampaign } from '../../../store/actions'
import { DELETE_CAMPAIGN, LIST_CAMPAIGNS, UPDATE_CAMPAIGN } from '../../../store/campaigns/actionTypes'
import { checkIfLoading } from '../../../store/functions'
import WebReputationNav from './components/Nav'

const Campaigns = () => {
    const dispatch = useDispatch()
    const { listLoading, campaigns, deleteLoading, updateLoading } = useSelector(state => ({
        listLoading: checkIfLoading(state, LIST_CAMPAIGNS),
        campaigns: state?.campaign?.campaigns,
        deleteLoading: checkIfLoading(state, DELETE_CAMPAIGN),
        updateLoading: checkIfLoading(state, UPDATE_CAMPAIGN)
    }))
    const history = useNavigate()
    const [t] = useTranslation()

    useEffect(() => {
        dispatch(listCampaigns())
    }, [])

    const renderCampaigns = () => {
        if (campaigns && campaigns?.length > 0) {
            return campaigns?.map(camp => (
                <div className='col-md-4 mb-3'>
                    <div className='card campaign-card'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <h4 className='text-green'>{camp?.name}</h4>
                            <span className='text-danger cursor-pointer' onClick={() => dispatch(deleteCampaign(camp?._id))}>
                                {
                                    deleteLoading ? <Spinner size={'sm'} color="danger" /> : <i className='fas fa-times'></i>
                                }
                            </span>
                        </div>
                        <div className=''>
                            <i class="far fa-envelope"></i> x {camp?.campaignEmails?.length}
                        </div>
                        <div className='row mt-4'>
                            <div className='col-md-4'>
                                <div className='d-flex flex-column'>
                                    <span className='number'>{camp?.sent}</span>
                                    <span>{t('Sent')}</span>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='d-flex flex-column'>
                                    <span className='number'>{camp?.replies}</span>
                                    <span>{t('Replies')}</span>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='d-flex flex-column'>
                                    <span className='number'>{camp?.binned}</span>
                                    <span>{t('Binned')}</span>
                                </div>
                            </div>
                        </div>
                        <div className='mt-3'>
                            <button className='btn btn-block btn-outline-secondary' onClick={() => history(`/web-reputation/campaigns/${camp?._id}`)}>{t('View campaign')}</button>
                            {
                                camp?.status === 'active' ? <button className='btn btn-block btn-link text-danger' onClick={() => dispatch(updateCampaign(camp?._id, { stop: true }))}>
                                    {t('Stop campaign')}
                                </button> : <button className='btn btn-block btn-link text-success' onClick={() => dispatch(updateCampaign(camp?._id, { active: true }))}>
                                    {('Start campaign')}
                                </button>
                            }
                        </div>
                    </div>
                </div>
            ))
        } else {
            return <div className='col-md-12'>{t('no-campaign-found')}</div>
        }
    }

    return (
        <div className='container-fluid page mt-5'>
            <WebReputationNav />
            <div className='row justify-content-end mt-4'>
                <div className='col-md-3'><button className='btn btn-outline-secondary btn-block' onClick={() => history('/web-reputation')}>{t('Create new campaign')}</button></div>
            </div>
            <div className='row mt-3'>
                {
                    listLoading ? <Spinner size={'md'} color="success" /> : <>
                        <div className='col-md-3'>
                            <Link className='d-block mb-1 text-green' onClick={() => dispatch(listCampaigns())} to={'#'}><i class="far fa-paper-plane mr-2"></i> {t('All campaigns')}</Link>
                            <Link className='d-block mb-1 text-green' onClick={() => dispatch(listCampaigns({ status: 'darft' }))} to={'#'}><i class="far fa-sticky-note mr-2"></i> {t('Drafts')}</Link>
                            <Link className='d-block mb-1 text-green' onClick={() => dispatch(listCampaigns({ status: 'in-active' }))} to={'#'}><i class="fas fa-check mr-2"></i> {t('Sent')}</Link>
                        </div>
                        <div className='col-md-9'>
                            <h4 className='mb-3'>{t('all-campaigns')}</h4>
                            <div className='row'>
                                {renderCampaigns()}
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default Campaigns
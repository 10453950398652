import React from 'react'
import { Spinner } from 'reactstrap'
import DonutChart from '../../components/charts/DonutChart'

function ErrorsChart({ data, loading }) {

    return (
        <>
            <h2>Errors Chart</h2>
            <div className='row align-items-center'>
                {
                    loading ? <Spinner size="md" className="m-auto text-center" color="success" /> : <><div className='col-xl-6' style={{ padding: 0 }}>
                    <DonutChart width={300} height={200} data={[data.issuesCount, data?.badUrls]} labels={['issuesCount', 'badUrl']} colors={['rgb(229, 92, 92)', 'rgb(239, 132, 81)']} />
                </div>
                <div className='col-xl-6'>
                    <p className='chart-legend'>
                        <span style={{ background: "#E55C5C" }}>{data?.issuesCount}</span> crawl/index issues
                    </p>
                    <p className='chart-legend'>
                        <span style={{ background: "#EF8451" }}>{data?.badUrls}</span> bad URLs
                    </p>
                    <p className='chart-legend'>
                        <span style={{ background: "#EDCB50" }}>1</span> webmaster site errors
                    </p>
                </div></>
                }
            </div>
        </>
    )
}

export default ErrorsChart
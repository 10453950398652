import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom'
import { checkIfLoading, useQuery } from "../../store/functions";
import { addWorkspace, listWorkspaceLanguages, listWorkspaceRegions, loginUser, verifyUser } from "../../store/actions";
import { Spinner } from "reactstrap";
import { ADD_WORKSPACE, LIST_WORKSPACE_LANGUAGES, LIST_WORKSPACE_REGIONS } from "../../store/workspace/actionTypes";
import CustomSelect from "../../components/common/CustomSelect";
import { useTranslation } from "react-i18next";

const CreateWorkspace = () => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const [domain, setDomain] = useState(null)
    const [region, setRegion] = useState(null)
    const [language, setLanguage] = useState(null)
    const [msg, showMsg] = useState(null)
    const query = useQuery()
    const [t] = useTranslation()

    const { loading, languages, regions, token, workspaces } = useSelector(state => ({
        loading: checkIfLoading(state, ADD_WORKSPACE),
        languages: state?.workspace?.languages,
        regions: state?.workspace?.regions,
        token: state?.auth?.token,
        workspaces: state.workspace.workspaces
    }))

    useEffect(() => {
        if(!token) return history('/login')
        dispatch(listWorkspaceLanguages())
        dispatch(listWorkspaceRegions())
    }, [])

    const addWorkspaceSubmit = () => {
        const data = { language, region, domain }
        if (!language || !region || !domain) return showMsg('Please fill all the fields')
        else {
            showMsg(null)
            dispatch(addWorkspace(data, history))
        }
    }

    return (
        <form className="auth-form">
            <h4>
                {
                    workspaces?.length === 0 ? "Create your first workspace" : "Create your workspace"
                }
            </h4>
            <div className="form-group">
                <label>{t('Your Website’s Domain')}</label>
                <input onChange={(e) => setDomain(e.target.value)} type="text" className="form-control" name="domain" />
            </div>
            <div className="form-group my-4">
                <label className="font-weight-bold">{t('Primary Marketing Region')}</label>
                <CustomSelect options={regions?.map(reg => ({
                    label: reg.location_name,
                    value: reg.location_name
                }))} onChange={(e) => setRegion(e.value)} value={region} />
            </div>
            <div className="form-group">
                <label className="font-weight-bold">{t('Primary Language')}</label>
                <CustomSelect options={languages?.map(reg => ({
                    label: reg.language_name,
                    value: reg.language_code
                }))} onChange={(e) => setLanguage(e.value)} value={region} />
            </div>
            <button type="button" onClick={addWorkspaceSubmit} className="btn click-btn orange-btn btn-block">
                {
                    loading ? (
                        <Spinner
                            size="sm"
                            className="m-auto text-center"
                            color="light"
                        />
                    ) : t("Continue")
                }
            </button>
            {msg ? <div className="alert alert-danger mt-2">
                {msg}
            </div> : ""}
            <div className="ordivider my-3">Or</div>
            <div className="text-center">
                <Link to={"/select-workspace"}><span className="text-dark text-unstyle"><b>{t('Select a workspace')}</b></span></Link>
            </div>
        </form>
    );
}

export default CreateWorkspace;
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { numberWithCommas } from "../../../../store/functions";

function CompetitorTraffic({ competitor }) {
    const [chartOptions, setChartOptions] = useState(null)
    const [t] = useTranslation()

    useEffect(() => {
        if (competitor) {
            const months = competitor.data?.traffic?.estimated?.map(e => {
                return {
                    x: e.date,
                    y: e.value
                }
            })
            if (months?.length > 0) {
                setChartOptions({
                    series: [{
                        data: months
                    }],
                    options: {
                        plotOptions: {
                            bar: {
                                borderRadius: 4,
                                dataLabels: {
                                    position: 'top',
                                },
                            }
                        },
                        dataLabels: {
                            enabled: true,
                            formatter: function (val) {
                                return numberWithCommas(val);
                            },
                            offsetY: -20,
                            style: {
                                fontSize: '12px',
                                colors: ["#304758"]
                            }
                        },
                        tooltip: {
                            enabled: false,
                        },
                        // xaxis: {
                        //     categories: ['-48 h', '', '', '', '', '', '', '', '', 'Now'],
                        //     labels: {
                        //         rotate: 0
                        //     }
                        // },
                        yaxis: {
                            show: false
                        },
                        colors: ['#EF8451'],
                    },
                })
            }
        }
    }, [competitor])

    return (
        <div className="card mt-3 competitors-traffic">
            <div className="d-flex align-items-center justify-content-between">
                <div style={{ width: "60%" }}>
                    <h4>{t('Traffic and Engagement')}</h4>
                    <span className="text-muted">
                        Reveal how well a website meets visitor expectations and captures their interest. {competitor?.data?.site_url}'s traffic has decreased by
                        39.44% compared to last month.
                        (On Desktop & Mobile, Worldwide)
                    </span>
                </div>
                {/* <div className="bg-gray rounded-10 p-3"><i class="fas fa-share-alt"></i></div> */}
            </div>
            <div className="row mt-3">
                <div className="col-md-3">
                    <h6><small>{t('Traffic & Engagement Last Month')}</small></h6>
                    <ul className="list-unstyled">
                        <li className="bg-gray pl-0 pb-1 pt-2 rounded-10 mb-2">
                            <div className="border-left-orange pl-2">
                                <div>{t('Total Visits')}</div>
                                <h6>{competitor.data?.audience?.visits ? numberWithCommas(competitor.data?.audience?.visits) : "0"}</h6>
                            </div>
                        </li>
                        <li className="bg-gray pl-0 pb-1 pt-2 rounded-10 mb-2">
                            <div className="border-left-orange pl-2">
                                <div>{t('Last Month Change')}</div>
                                <h6></h6>
                            </div>
                        </li>
                        <li className="bg-gray pl-0 pb-1 pt-2 rounded-10 mb-2">
                            <div className="border-left-orange pl-2">
                                <div>{t('Avg Visit Duration')}</div>
                                <h6>{competitor.data?.audience?.time_on_site_avg}</h6>
                            </div>
                        </li>
                        <li className="bg-gray pl-0 pb-1 pt-2 rounded-10 mb-2">
                            <div className="border-left-orange pl-2">
                                <div>{t('Bounce Rate')}</div>
                                <h6>{competitor.data?.audience?.bounce_rate}</h6>
                            </div>
                        </li>
                        <li className="bg-gray pl-0 pb-1 pt-2 rounded-10 mb-2">
                            <div className="border-left-orange pl-2">
                                <div>{t('Pages per Visit')}</div>
                                <h6>{Number(competitor.data?.audience?.page_views_avg).toFixed(2)}</h6>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="col-md-6 bg-gray rounded-10 p-3">
                    <h6>{t('Total Visits Last 3 Months')}</h6>
                    {
                        chartOptions ? <Chart height={280} options={chartOptions.options} series={chartOptions.series} type="bar" /> : ""
                    }
                </div>
            </div>
        </div>
    );
}

export default CompetitorTraffic;
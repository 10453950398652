import { put, select, takeEvery } from "redux-saga/effects"
import { GenerateIdeaAPI } from "./calls"
import { generateIdeaSuccess } from './actions'
import { GENERATE_IDEA } from "./actionTypes"
import { startAction, stopAction, setError } from "../actions"

function* generateIdea({ type, payload }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield GenerateIdeaAPI(payload, state)
    console.log(result)
    yield put(generateIdeaSuccess(result?.data?.data))
  } catch (err) {
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* ContentGeneratorSaga() {
  yield takeEvery(GENERATE_IDEA, generateIdea)
}

export default ContentGeneratorSaga
import {
  GENERATE_IDEA,
  GENERATE_IDEA_SUCCESS
} from "./actionTypes"

const initialState = {
  ideas: []
}

const contentGenerator = (state = initialState, action) => {
  switch (action.type) {
    case GENERATE_IDEA:
      return { ...state }
    case GENERATE_IDEA_SUCCESS:
      return { ...state, ideas: action.payload }
    default:
      return state
  }
}

export default contentGenerator
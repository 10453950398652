import React, { useEffect, useState } from 'react'
import AreaChart from '../../../components/charts/AreaChart'
import moment from 'moment'
import { Spinner } from 'reactstrap'
import { useTranslation } from 'react-i18next'

function TrafficHistory({ data, loading }) {
  // ** fake backend database 
  const [trafficData, setTrafficData] = useState([])
  const [t] = useTranslation()

  useEffect(() => {
    if (data && !loading) {
      const traffic = []
      data.forEach(e => {
        traffic.push({
          label: moment(e.label, 'M').format('MMMM'),
          value: e.value
        })
      })
      console.log(traffic)
      setTrafficData(traffic)
    }
  }, [data, loading])

  return (
    <div className='traffic-history card'>
      <h5 className='mb-2'>{t('Traffic History')}</h5>
      {
        loading ? <Spinner size="md" className="m-auto text-center" color="success" /> : trafficData?.length > 0 ? <AreaChart name="Traffic Data" data={trafficData} curveType="smooth" showYaxis={true} /> : "No data shown"
      }
    </div>
  )
}

export default TrafficHistory
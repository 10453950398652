import React from 'react'

function ErrorsQuotation({ data }) {
    const renderData = data?.length > 0 ? data.map(ele => {
        let h1Html = ele?.h1 ? ele?.h1?.map(h => <li>{h}</li>) : ''
        return <div key={ele.id} className='quotation-error'>
            {ele?.h1 ? <ul>
                {h1Html}
            </ul> : ""}
            <div className='links'>
                <h6>Copied from:</h6>
                <ul>
                    <li>{ele?.url}</li>
                    {/* {ele.links.length > 0 && ele.links.map((link, index) => <li key={index}><a href='#top' className='btn'>{link}</a></li>)} */}
                </ul>
            </div>
        </div>
    }) : 'No data found'

    return (
        <>
            {renderData}
        </>
    )
}

export default ErrorsQuotation
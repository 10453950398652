import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, Spinner } from 'reactstrap'
import { addTag, deleteCustomer, getCustomer, listTags, listWorkspaceRegions, updateCustomer, updateTab } from '../../../store/actions'
import { ADD_TAG, DELETE_CUSTOMER, GET_CUSTOMER, LIST_TAGS, UPDATE_CUSTOMER } from '../../../store/crm/actionTypes'
import { checkIfLoading } from '../../../store/functions'
import { UPDATE_TAB } from '../../../store/salesManagement/actionTypes'
import { LIST_WORKSPACE_REGIONS } from '../../../store/workspace/actionTypes'
import DateTimePicker from './Components/DateTimePicker'
import RelatedDocuments from './Components/RelatedDocuments'
import TimeLine from './Components/Timeline'
import CustomSelect from "../../../components/common/CustomSelect";
import DeletePrompt from '../../../components/common/DeletePrompt'
import { useTranslation } from 'react-i18next'

function CustomerProfile() {
  const dispatch = useDispatch()
  const history = useNavigate()
  const location = useLocation()
  const [t] = useTranslation()

  const [updates, setUpdates] = useState({})
  const [editMode, setEditMode] = useState(false)
  const [editDesc, setEditDesc] = useState(false)
  const [editTags, setEditTags] = useState(false)

  const [desc, setDesc] = useState(null)
  const [selectedTags, setSelectedTags] = useState(null)
  const [newTag, setNewTag] = useState(null)
  const [searchTag, setSearchTag] = useState(null)
  const [deleteModal, setDeleteModal] = useState(null)
  const [regDropdown, setRegDropdown] = useState(false)

  const { customer, timeline, getLoading, regLoading, regions,
    updateLoading, workspace, tags, tagsLoading, deleteLoading, emails, addTagLoading } = useSelector(state => ({
      customer: state.crm.customer,
      timeline: state.crm.timeline,
      getLoading: checkIfLoading(state, GET_CUSTOMER),
      regLoading: checkIfLoading(state, LIST_WORKSPACE_REGIONS),
      regions: state?.workspace?.regions,
      updateLoading: checkIfLoading(state, UPDATE_CUSTOMER),
      workspace: state.workspace.workspace,
      tags: state?.crm?.tags?.list,
      tagsLoading: checkIfLoading(state, LIST_TAGS),
      deleteLoading: checkIfLoading(state, DELETE_CUSTOMER),
      emails: state?.crm?.emails,
      addTagLoading: checkIfLoading(state, ADD_TAG)
    }))

  useEffect(() => {
    const id = location.pathname.split('/customer/')[1]
    if (!id || id?.length !== 24) history('/crm/current-customers')
    console.log({ id })
    dispatch(getCustomer(id, history))
    dispatch(listWorkspaceRegions())
    dispatch(listTags({ limit: 200 }))
  }, [workspace, location.pathname])

  const onInputChange = (key, value, address) => {
    if (address) {
      setUpdates({
        ...updates,
        address: {
          ...(updates?.address || {}),
          [key]: value
        }
      })
    } else {
      setUpdates({
        gender: 'male',
        birthdate: new Date(),
        ...updates,
        [key]: value
      })
    }
  }

  useEffect(() => {
    if (customer) {
      setDesc(customer?.tab?.description)
    } 
  }, [customer])

  useEffect(() => {
    if (!getLoading && !customer) {
      // history('/crm/current-customers')
    }
  }, [getLoading])

  const renderRegions = () => {
    if (regLoading) return <option>Loading...</option>
    if (regions?.length > 0) {
      return regions?.map(reg => {
        return <DropdownItem onClick={() => onInputChange('country', reg?.location_name, true)}>
          {reg?.location_name}
        </DropdownItem>
      })
    } else {
      return t('No region found')
    }
  }

  const renderTags = () => {
    const tags = customer?.tags
    if (tags && tags?.length > 0) {
      return tags.map(tag => (
        <div className='cust-tag'>{tag?.name}</div>
      ))
    } else {
      return t('No tag found')
    }
  }

  const onCustomerUpdate = () => {
    dispatch(updateCustomer(customer?._id, updates, () => setEditMode(false)))
  }

  const updateDescription = () => {
    if (customer?.tab) { customer.tab.description = desc }
    dispatch(updateCustomer(customer?._id, { description: desc }, () => setEditDesc(false)))
  }

  const onTagsUpdate = () => {
    dispatch(updateCustomer(customer?._id, { tags: selectedTags?.map(t => t.value) }, () => setEditTags(false)))
  }

  return (
    <div className='container-fluid customer-profile page'>
      <div>
        <i class="fas fa-arrow-left fa-2x text-green" style={{ cursor: "pointer" }} onClick={() => history('/crm/current-customers')}></i>
      </div>
      <div className='row mt-5'>
        <div className='col-md-5'>
          <div className='row mb-3'>
            <div className='col-md-8'>
              <div className='card p-3'>
                {
                  getLoading ? <Spinner size="md" className="m-auto text-center" color="success" /> : <>
                    {
                      editMode ? <div>
                        <div className='mb-2'>
                          <label>{t('Name')}:</label>
                          <input type='text' className='form-control custom-border' onChange={e => onInputChange('name', e.target.value)} placeholder='Enter name' defaultValue={customer?.name} />
                        </div>
                        <div className='mb-2'>
                          <label>{t('Country')}:</label>
                          <Dropdown disabled={regLoading} className='custom-dropdown custom-border select-box mr-2' isOpen={regDropdown} toggle={() => setRegDropdown(!regDropdown)}>
                            <DropdownToggle style={{ width: "200px" }} caret>
                              {updates?.address?.country || customer?.address?.country || "Choose country"}
                            </DropdownToggle>
                            <DropdownMenu style={{ width: "200px" }} right={true}>
                              {renderRegions()}
                            </DropdownMenu>
                          </Dropdown>
                          {/* <select defaultValue={customer?.address?.country} className='form-control custom-border' onChange={e => onInputChange('country', e.target.value, true)}>
                            {renderRegions()}
                          </select> */}
                        </div>
                        <div>
                          <label>{t('Complete street address and city:')}</label>
                          <input type='text' className='form-control custom-border' onChange={e => onInputChange('city', e.target.value, true)} placeholder='Enter city' defaultValue={customer?.address?.city} />
                        </div>
                      </div> : <>
                        <h4>{customer?.name}</h4>
                        <div className='d-flex align-items-center'>
                          <i class="fas fa-map-marker-alt mr-2"></i>
                          <span>{customer?.address?.city}, {customer?.address?.country}</span>
                        </div>
                      </>
                    }
                    <div className='mt-2'>
                      {t('Added')}: <b>{moment(customer?.createdAt).format('MMM D, YYYY')}</b>
                    </div>
                  </>
                }
              </div>
            </div>
            <div className='col-md-4'>
              <div className='card p-3'>
                {
                  editMode ? <button className='btn btn-block btn-sm btn-info' onClick={onCustomerUpdate}>
                    {
                      updateLoading ? <Spinner size="sm" className="m-auto text-center" color="light" /> : t("Save")
                    }
                  </button> :
                    <button className='btn btn-block btn-sm btn-outline-info' onClick={() => setEditMode(true)}>{t('Update')}</button>
                }
                <button onClick={() => setDeleteModal({
                  show: true,
                  func: () => {
                    dispatch(deleteCustomer(customer?._id, history))
                  }
                })} className='btn btn-block btn-sm btn-outline-danger'>{t('Delete')}</button>
              </div>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-md-12'>
              <div className='card'>
                {
                  getLoading ? <Spinner size="md" className="m-auto text-center" color="success" /> : <>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                      <h6>{t('Details')}</h6>
                      {
                        editMode ? <div>
                          <button className='btn click-btn btn-danger mr-2' onClick={() => setEditMode(false)}>{t('Close')}</button>
                          <button className='btn click-btn btn-info' onClick={onCustomerUpdate}>
                            {
                              updateLoading ? <Spinner size="sm" className="m-auto text-center" color="light" /> : "Save"
                            }
                          </button>
                        </div> : <Link onClick={() => setEditMode(true)} to={'#'}>
                          {t('Edit details')}
                        </Link>
                      }
                    </div>
                    <div>
                      <div>
                        <div className='mb-2 d-flex align-items-center'>
                          <label className='m-0' style={{ width: "110px" }}>{t('email')}:</label>
                          {
                            editMode ?
                              <input className='form-control custom-border ml-4' onChange={(e) => onInputChange('email', e.target.value)} placeholder="enter email" defaultValue={customer.email} />
                              : <div>{customer?.email || t("Not found")}</div>
                          }
                        </div>
                        <div className='mb-2 d-flex align-items-center'>
                          <label className='m-0' style={{ width: "110px" }}>{t('Type')}:</label>
                          {
                            editMode ?
                              <select className='form-control custom-border ml-4' onChange={(e) => onInputChange('type', e.target.value)} defaultValue={customer?.type}>
                                <option value={'lead'}>{t('Lead')}</option>
                                <option value={'customer'}>{t('Customer')}</option>
                                <option value={'media'}>{t('Media')}</option>
                              </select>
                              : <div>{customer?.type || t("Not found")}</div>
                          }
                        </div>
                        <div className='mb-2 d-flex align-items-center'>
                          <label className='m-0' style={{ width: "110px" }}>{t('Phone')}:</label>
                          {
                            editMode ?
                              <input className='form-control custom-border ml-4' onChange={(e) => onInputChange('phone', e.target.value)} placeholder="enter phone" defaultValue={customer.phone} />
                              : <div>{customer?.phone || t("Not found")}</div>
                          }
                        </div>
                        {/* <div className='mb-2 d-flex align-items-center'>
                          <label className='m-0' style={{ width: "110px" }}>Address:</label>
                          {
                            editMode ?
                              <input className='form-control custom-border ml-4' onChange={(e) => onInputChange('line1', e.target.value, true)} placeholder="enter address" defaultValue={customer?.address?.line1} />
                              : <div>{customer?.address?.line1 || t("Not found")}</div>
                          }
                        </div>
                        <div className='mb-2 d-flex align-items-center'>
                          <label className='m-0' style={{ width: "110px" }}>Zip code:</label>
                          {
                            editMode ?
                              <input className='form-control custom-border ml-4' onChange={(e) => onInputChange('zipCode', e.target.value, true)} placeholder="enter zip code" defaultValue={customer?.address?.zipCode} />
                              : <div>{customer?.address?.zipCode || t("Not found")}</div>
                          }
                        </div> */}
                        <div className='mb-2 d-flex align-items-center'>
                          <label className='m-0' style={{ width: "110px" }}>{t('Company')}:</label>
                          {
                            editMode ?
                              <input className='form-control custom-border ml-4' onChange={(e) => onInputChange('company', e.target.value)} placeholder={t('Enter company')} defaultValue={customer?.company} />
                              : <div>{customer?.company || t("Not found")}</div>
                          }
                        </div>
                        {/* <div className='mb-2 d-flex align-items-center'>
                          <label className='m-0' style={{ width: "100px" }}>Birthdate:</label>
                          {
                            editMode ?
                              <div className='datetime'>
                                <DateTimePicker date={updates?.birthdate ? new Date(updates?.birthdate) : customer.birthdate ? new Date(customer.birthdate) : new Date()} showTimeSelect={false} className='custom-border' setDate={(date) => onInputChange('birthdate', moment(date).format('YYYY-MM-DD'))} />
                              </div>
                              // <input className='form-control custom-border ml-4' onChange={(e) => onInputChange('birthdate', e.target.value)} placeholder="enter birthdate" defaultValue={customer.birthdate} />
                              : <div>{customer?.birthdate || t("Not found")}</div>
                          }
                        </div>
                        <div className='mb-2 d-flex align-items-center'>
                          <label className='m-0' style={{ width: "100px" }}>Gender:</label>
                          {
                            editMode ?
                              <select className='form-control custom-border ml-4' onChange={(e) => onInputChange('gender', e.target.value)} defaultValue={customer.gender}>
                                <option value='male'>Male</option>
                                <option value='female'>Female</option>
                              </select>
                              : <div>{customer?.gender || t("Not found")}</div>
                          }
                        </div> */}
                      </div>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-md-12'>
              <div className='card'>
                {
                  getLoading ? <Spinner size="md" className="m-auto text-center" color="success" /> : <>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                      <h6>{t('Tags')}</h6>
                      <Link onClick={() => setEditTags(!editTags)} to={'#'}>{t('Edit tags')}</Link>
                    </div>
                    {
                      editTags ? <div>
                        <CustomSelect onInputChange={(e) => {
                          setSearchTag(e)
                        }} options={tags?.map(tag => ({
                          label: tag?.name,
                          value: tag?._id
                        }))} isMulti={true} noOptionsMessage={() => {
                          return <div className='cursor-pointer text-dark' onClick={() => {
                            dispatch(addTag(searchTag, null, customer?._id))
                          }}>Add this tag ({searchTag})</div>
                        }} value={customer?.tags?.map(tag => ({
                          label: tag?.name,
                          value: tag?._id
                        }))} onChange={(tags) => setSelectedTags(tags)} />
                        <div className='text-right'>
                          <button disabled={updateLoading} onClick={() => onTagsUpdate()} className='btn click-btn green-btn p-2 mt-3'>
                            {
                              updateLoading ? <Spinner size='sm' color='light' /> : t("Update")
                            }
                          </button>
                        </div>
                        {/* <div className='mt-2'>
                          <label>Add new tag</label>
                          <input className='form-control custom-border' placeholder='Tag name' onChange={e => setNewTag(e.target.value)} />
                          <div className='text-right mt-2'>
                            <button className='btn click-btn orange-btn-outline' disabled={!newTag || addTagLoading} onClick={() => dispatch(addTag(newTag))}>
                              {addTagLoading ? <Spinner size={'sm'} color="orange" /> : "Add tag"}
                            </button>
                          </div>
                        </div> */}
                      </div> : <div className='d-flex customer-tags'>
                        {renderTags()}
                      </div>
                    }
                  </>
                }
              </div>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-md-12'>
              <div className='card'>
                {
                  getLoading ? <Spinner size="md" className="m-auto text-center" color="success" /> : <>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                      <h6>Description</h6>
                      <Link onClick={() => setEditDesc(!editDesc)} to={'#'}>
                        {
                          editDesc ? <Link to={'#'} onClick={() => setEditDesc(false)}>Cancel</Link> : t("Edit description")
                        }
                      </Link>
                    </div>
                    {
                      editDesc ? <>
                        <input onChange={(e) => setDesc(e.target.value)} className='form-control custom-border' defaultValue={customer?.description} placeholder='enter description' />
                        <button onClick={updateDescription} className='btn btn-sm click-btn mt-2 green-btn px-2 py-1'>
                          {
                            updateLoading ? <Spinner size='sm' color='light' /> : t("Update")
                          }
                        </button>
                      </>
                        : customer?.description ? <p>{customer?.description}</p> : t("No description found")
                    }
                  </>
                }
              </div>
            </div>
          </div>
        </div>
        {
          getLoading ? <div className='text-center m-auto'><Spinner size='md' color='success' /></div> :
            <div className='col-md-7'>
              <RelatedDocuments customer={customer} />
              <TimeLine customer={customer} timeline={timeline} emails={emails} />
            </div>
        }
        <Modal centered={true} size='md' isOpen={deleteModal?.show} toggle={() => { setDeleteModal(null) }}>
          <DeletePrompt title={'Delete Customer'} message={t("Are you sure you want to delete this customer?")} func={deleteModal?.func} loading={deleteLoading} toggle={() => setDeleteModal(null)} />
        </Modal>
      </div>
    </div>
  )
}

export default CustomerProfile
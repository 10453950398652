import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const DateTimePicker = ({ date = new Date(), setDate, showTimeSelect = true, className }) => {
    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    return (
        <DatePicker
            selected={date}
            onChange={(date) => setDate(date)}
            showTimeSelect={showTimeSelect}
            className={`cursor-pointer ${className}`}
            filterTime={filterPassedTime}
            dateFormat={`MMMM d, yyyy${showTimeSelect ? ' h:mm aa' : ""}`}
        />
    );
}

export default DateTimePicker
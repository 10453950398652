import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import { addMedia, listMedia, removeMedia } from '../../../../store/actions'
import { ADD_MEDIA, LIST_MEDIA, REMOVE_MEDIA } from '../../../../store/crm/actionTypes'
import { checkIfLoading } from '../../../../store/functions'

function RelatedDocuments({ customer }) {
    const dispatch = useDispatch()
    const history = useNavigate()
    const inputRef = useRef(null)
    const [t] = useTranslation()

    const { media, deleteLoading, listLoading, addLoading } = useSelector(state => ({
        media: state.crm.media,
        deleteLoading: checkIfLoading(state, REMOVE_MEDIA),
        listLoading: checkIfLoading(state, LIST_MEDIA),
        addLoading: checkIfLoading(state, ADD_MEDIA)
    }))

    const onInputChange = (e) => {
        const fileUploaded = event.target.files[0];
        const formData = new FormData();
        formData.append('file', fileUploaded, fileUploaded?.name);
        dispatch(addMedia(formData, { customer: customer?._id }))
    }

    useEffect(() => {
        if (customer) dispatch(listMedia(customer?._id))
    }, [customer])

    const renderMedia = () => {
        if (listLoading || addLoading) return <Spinner size={'md'} color="success" />
        if (media && media?.length > 0) {
            return media.map(m => (
                <div className='position-relative d-inline-block'>
                    <a href={process.env.REACT_APP_API_URL + '/' + m.url} target="_blank" className='d-inline-block text-center media-cover overflow-hidden'>
                        <img src='https://www.iconpacks.net/icons/2/free-pdf-download-icon-2617-thumb.png' className='img-responsive mb-3' width={50} />
                        <div>{m?.name}</div>
                    </a>
                    <span className='media-delete' onClick={() => dispatch(removeMedia(m?._id))}>
                        {deleteLoading ? <Spinner size={'sm'} color="dark" /> : <i className='fas fa-times'></i>}
                    </span>
                </div>
            ))
        }
    }

    return (
        <div className='related-documents mb-3'>
            <h5>{t('Related Documents')}</h5>
            <hr />
            <div className='text-right'>
                <span onClick={() => inputRef.current.click()} className='text-green cursor-pointer'><b>{t('Upload PDF')}</b></span>
                <input onChange={onInputChange} type='file' accept='application/pdf' hidden ref={inputRef} />
            </div>
            <div>
                {renderMedia()}
            </div>
            <hr />
        </div>
    )
}

export default RelatedDocuments
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

const WebReputationNav = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [t] = useTranslation()

    return (
        <div className='container crm-nav mt-5'>
            <ul className='list-group m-auto text-center list-group-horizontal-md'>
                <li onClick={() => navigate('/web-reputation')} className={`m-auto ${location.pathname === '/web-reputation' ? 'active' : ""}`}><i class="fas fa-network-wired"></i><span className='ml-2'>{t('digital-outreach')}</span></li>
                <li onClick={() => navigate('/web-reputation/backlinks')} className={`m-auto ${location.pathname === '/web-reputation/backlinks' ? 'active' : ""}`}><i class="fas fa-link"></i><span className='ml-2'>{t('your-backlinks')}</span></li>
                <li onClick={() => navigate('/web-reputation/competitor-backlinks')} className={`m-auto ${location.pathname === '/web-reputation/competitor-backlinks' ? 'active' : ""}`}><i class="fas fa-user"></i><span className='ml-2'>{t('competitors-backlinks')}</span></li>
                <li onClick={() => navigate('/web-reputation/competitors')} className={`m-auto ${location.pathname === '/web-reputation/competitors' ? 'active' : ""}`}><i class="fas fa-users"></i><span className='ml-2'>{t('competitors-list')}</span></li>
                <li onClick={() => navigate('/web-reputation/feedback')} className={`m-auto ${location.pathname === '/web-reputation/feedback' ? 'active' : ""}`}><i class="fas fa-star"></i><span className='ml-2'>{t('feedback')}</span></li>
                <li onClick={() => navigate('/web-reputation/campaigns')} className={`m-auto ${location.pathname.includes('/web-reputation/campaigns') ? 'active' : ""}`}><i class="fas fa-bell"></i><span className='ml-2'>{t('campaigns')}</span></li>
                <li onClick={() => navigate('/web-reputation/backlinks-gap')} className={`m-auto ${location.pathname === '/web-reputation/backlinks-gap' ? 'active' : ""}`}><i class="fas fa-link"></i><span className='ml-2'>{t('Backlinks Gap')}</span></li>
                <li onClick={() => navigate('/web-reputation/keywords-gap')} className={`m-auto ${location.pathname === '/web-reputation/keywords-gap' ? 'active' : ""}`}><i class="fas fa-link"></i><span className='ml-2'>{t('Keywords Gap')}</span></li>
            </ul>
        </div>
    )
}

export default WebReputationNav
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import TablePagination from '../../../../components/common/Pagination';
import { cancelSubscription, getPyamentMethod, listWorkspaceBillings } from '../../../../store/actions'
import { checkIfLoading } from '../../../../store/functions';
import { CANCEL_WORKSPACE_SUBSCRIPTION, LIST_WORKSPACE_BILLINGS } from '../../../../store/workspace/actionTypes';
import ChangeCard from './changeCard';

const BillingTab = ({ tab }) => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const [query, setQuery] = useState({
        page: 1,
        limit: 5
    })
    const [changeCardModal, setChangeCardModal] = useState(false)
    const [t] = useTranslation()

    const onPageChange = (page) => {
        setQuery({
            ...query,
            page
        })
    }

    const { billings, loading, count, cancelSubLoading, paymentMethod, plan, workspace } = useSelector(state => ({
        billings: state?.workspace?.billings?.list,
        loading: checkIfLoading(state, LIST_WORKSPACE_BILLINGS),
        count: state?.workspace?.billings?.page_details?.total_items,
        cancelSubLoading: checkIfLoading(state, CANCEL_WORKSPACE_SUBSCRIPTION),
        paymentMethod: state?.auth?.paymentMethod,
        plan: state?.subscription?.plan,
        workspace: state.workspace?.workspace
    }))

    useEffect(() => {
        if (tab === 'billing') {
            dispatch(listWorkspaceBillings(query))
        }
        dispatch(getPyamentMethod())
    }, [tab, query.page])

    const renderBillings = () => {
        if (loading) return <tr><td colSpan={4} className="text-center"><Spinner size="sm" className="m-auto text-center" color="success" /></td></tr>
        if (billings && billings?.length > 0) {
            return billings?.map(b => (
                <tr>
                    <td>{new Date(b?.createdAt).toLocaleDateString()}</td>
                    <td>{b?.plan?.type ? String(b?.plan?.type[0]).toUpperCase() + String(b?.plan?.type).substring(1) : ""}</td>
                    <td>{b?.transactionId}</td>
                    <td>{b?.price} {b?.currency}</td>
                    <td><a href={b?.invoice}><i className='fas fa-download'></i></a></td>
                </tr>
            ))
        }
    }

    return (
        <div className='row'>
            <div className='col-md-8'>
                <h4>{t('plan-and-billing')}</h4>
                <div className='card'>
                    <span className='text-muted'>{t('current-plan')}</span>
                    {
                        workspace?.subscription ? <div className='d-flex justify-content-between'>
                            <div className='subscription-details mt-3'>
                                <h2 className='text-orange'>{String(workspace?.subscription?.type[0]).toUpperCase() + workspace?.subscription?.type?.substring(1)}</h2>
                                <ul>
                                    <li>{t('Unlimited access to all our SEO tools.')}</li>
                                    <li>{t('1.000+ webpages.')}</li>
                                    <li>{t('10.000+ contacts.')}</li>
                                </ul>
                                <div className='text-muted mt-2'>{t('Next payment')}: {workspace?.subscription?.plan?.amount} USD on {moment((workspace?.subscription?.expires)).format('DD-MM-YYYY hh:mm')}</div>
                            </div>
                            <div className='d-flex flex-column'>
                                <button onClick={() => history('/settings/change-plan')} className='btn click-btn green-btn mb-2'>{t('change-plan')}</button>
                                <button onClick={() => dispatch(cancelSubscription(workspace?.subscription?._id))} className='btn btn-outline-secondary'>
                                    {cancelSubLoading ? <Spinner size="sm" className="m-auto text-center" color="light" /> : t('cancel-subscription')}
                                </button>
                            </div>
                        </div> : <div className='d-flex align-items-center justify-content-between'>
                            <span>{t('You are not subscribed')}</span>
                            <button onClick={() => history('/settings/change-plan')} className='btn click-btn green-btn mb-2'>{t('Subscribe plan')}</button>
                        </div>
                    }
                    <hr />
                    {
                        paymentMethod ? <div className='d-flex justify-content-between'>
                            <div>
                                <div className='text-muted'>{t('Payment method')}</div>
                                <h6>{String(paymentMethod?.type).charAt(0).toUpperCase() + String(paymentMethod?.type).substring(1)} **** {paymentMethod?.last4}</h6>
                            </div>
                            <button className='btn btn-outline-secondary py-1' style={{ fontSize: "15px" }} onClick={() => setChangeCardModal(true)}>{t('Change Card')}</button>
                        </div> : ""
                    }
                </div>
                <div className='card mt-3'>
                    <h4>{t('billing-history')}</h4>
                    <table className='table table-responsive'>
                        <thead>
                            <tr>
                                <th>{t('date')}</th>
                                <th>{t('type')}</th>
                                <th>{t('transaction-id')}</th>
                                <th>{t('price')}</th>
                                <th>{t('download')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderBillings()}
                        </tbody>
                    </table>
                    <TablePagination count={count} limit={query.limit} loading={loading} page={query.page} setPage={onPageChange} />
                </div>
                <ChangeCard modal={changeCardModal} toggle={() => setChangeCardModal(!changeCardModal)} />
            </div>
        </div>
    )
}

export default BillingTab
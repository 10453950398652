import {
  ADD_PIPELINE,
  ADD_PIPELINE_SUCCESS,
  ADD_COLUMN,
  ADD_COLUMN_SUCCESS,
  ADD_TAB,
  ADD_TAB_SUCCESS,
  DELETE_COLUMN,
  DELETE_COLUMN_SUCCESS,
  DELETE_PIPELINE_SUCCESS,
  DELETE_TAB,
  DELETE_TAB_SUCCESS,
  GET_PIPELINE,
  GET_PIPELINE_SUCCESS,
  LIST_PIPELINES_SUCCESS,
  UPDATE_COLUMN,
  UPDATE_COLUMN_SUCCESS,
  UPDATE_PIPELINE_SUCCESS,
  UPDATE_TAB,
  UPDATE_TAB_SUCCESS,
  DELETE_PIPELINE,
  LIST_PIPELINES,
  UPDATE_PIPELINE
} from "./actionTypes"
const initialState = {
  pipelines: [],
  pipeline: null
}

const SalesManagement = (state = initialState, action) => {
  switch (action.type) {
    case LIST_PIPELINES:
      return { ...state, pipelines: [] }
    case LIST_PIPELINES_SUCCESS:
      return { ...state, pipelines: action.payload }
    case GET_PIPELINE:
      return { ...state, pipeline: null }
    case GET_PIPELINE_SUCCESS:
      return { ...state, pipeline: action.payload }
    case ADD_PIPELINE:
      return { ...state, }
    case ADD_PIPELINE_SUCCESS:
      return { ...state, pipelines: [...state?.pipelines, action.payload], pipeline: action.payload }
    case ADD_COLUMN:
      return { ...state, }
    case ADD_COLUMN_SUCCESS:
      return { ...state, pipeline: { ...state?.pipeline, columns: [...(state?.pipeline?.columns || []), action.payload] } }
    case UPDATE_COLUMN:
      return { ...state, }
    case UPDATE_COLUMN_SUCCESS:
      return {
        ...state, pipeline: {
          ...state?.pipeline, columns: state.pipeline.columns?.map(c => {
            if (String(c?._id) === String(action.payload._id)) {
              c = {
                tabs: c.tabs,
                ...action.payload
              }
            }
            return c
          })
        }
      }
    case ADD_TAB:
      return { ...state, }
    case ADD_TAB_SUCCESS:
      return {
        ...state, pipeline: {
          ...state?.pipeline, columns: state?.pipeline?.columns?.map(col => {
            if (col?._id === action?.payload?.column) {
              col.tabs = [...col?.tabs || [], action.payload]
            }
            return col
          })
        }
      }
    case UPDATE_TAB:
      return { ...state, }
    case UPDATE_TAB_SUCCESS:
      return {
        ...state, pipeline: {
          ...state?.pipeline, columns: state?.pipeline?.columns?.map(col => {
            if (col?._id === action?.payload?.column) {
              col.tabs = col?.tabs?.map(tab => {
                if(tab?._id === action.payload?._id) {
                  return action.payload
                } else {
                  return tab
                }
              })
            }
            return col
          })
        }
      }
    case DELETE_TAB:
      return { ...state, }
    case DELETE_TAB_SUCCESS:
      return {
        ...state, pipeline: {
          ...state?.pipeline, columns: state?.pipeline?.columns?.map(col => {
            col.tabs = col?.tabs?.filter(tab => tab?._id !== action.payload)
            return col
          })
        }
      }
    case DELETE_COLUMN:
      return { ...state, }
    case DELETE_COLUMN_SUCCESS:
      return {
        ...state, pipeline: {
          ...state?.pipeline, columns: state?.pipeline?.columns?.filter(col => action.payload !== col?._id)
        }
      }
    default:
      return state
  }
}

export default SalesManagement

export const LIST_BUSINESSES = 'LIST_BUSINESSES'
export const LIST_BUSINESSES_SUCCESS = 'LIST_BUSINESSES_SUCCESS'

export const GET_BUSINESS = 'GET_BUSINESS'
export const GET_BUSINESS_SUCCESS = 'GET_BUSINESS_SUCCESS'

export const SELECT_BUSINESS = 'SELECT_BUSINESS'
export const SELECT_BUSINESS_SUCCESS = 'SELECT_BUSINESS_SUCCESS'

export const DISCONNECT_BUSINESS = 'DISCONNECT_BUSINESS'
export const DISCONNECT_BUSINESS_SUCCESS = 'DISCONNECT_BUSINESS_SUCCESS'
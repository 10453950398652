import {
  LIST_CAMPAIGNS,
  LIST_CAMPAIGNS_SUCCESS,
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_SUCCESS,
  STOP_CAMPAIGN,
  STOP_CAMPAIGN_SUCCESS,
  VIEW_CAMPAIGN,
  VIEW_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN,
  DELETE_CAMPAIGN_SUCCESS,
} from "./actionTypes"

const initialState = {
  campaigns: [],
  campaign: null
}

const Campaigns = (state = initialState, action) => {
  switch (action.type) {
    case LIST_CAMPAIGNS:
      return { ...state, campaigns: [] }
    case LIST_CAMPAIGNS_SUCCESS:
      return { ...state, campaigns: action.payload }
    case VIEW_CAMPAIGN:
      return { ...state, campaign: null }
    case VIEW_CAMPAIGN_SUCCESS:
      return { ...state, campaign: action.payload }
    case CREATE_CAMPAIGN:
      return { ...state, }
    case CREATE_CAMPAIGN_SUCCESS:
      return { ...state, campaigns: [...state.campaigns, action.payload] }
    case DELETE_CAMPAIGN:
      return { ...state, }
    case DELETE_CAMPAIGN_SUCCESS:
      return { ...state, campaigns: state?.campaigns?.filter(c => c._id !== action.payload) }
    case UPDATE_CAMPAIGN:
      return { ...state, }
    case UPDATE_CAMPAIGN_SUCCESS:
      return { ...state, campaigns: state?.campaigns?.map(c => c?._id === action?.payload?._id ? action?.payload : c) }
    default:
      return state
  }
}

export default Campaigns
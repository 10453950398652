import React from "react";
import { useTranslation } from "react-i18next";

function CompetitorLists({ competitor }) {
    const [t] = useTranslation()

    const renderKeywords = () => {
        const keywords = competitor?.data?.traffic?.sources?.search_organic?.top_keywords
        let keywordsHtml = []
        let total = 0
        if (keywords && keywords?.length > 0) {
            keywords?.map(keyword => {
                total += keyword?.value
                keywordsHtml.push(<li className="d-flex align-items-center border-bottom mb-1 pb-1 justify-content-between">
                    <span>{keyword?.keyword}</span>
                    <span><b>{keyword?.percent}%</b></span>
                </li>)
            })
        } else {
            Array(5).fill(5).map(k => {
                keywordsHtml.push(<li className="d-flex align-items-center border-bottom mb-1 pb-1 justify-content-between">
                    <span>---------</span>
                    <span>---------</span>
                </li>)
            })
        }
        keywordsHtml.push(<li className="d-flex align-items-center border-bottom mb-1 pb-1 justify-content-between">
            <span>Total Keywords</span>
            <span><b>{total}</b></span>
        </li>)
        return keywordsHtml
    }

    const renderReferrals = () => {
        const keywords = competitor?.data?.traffic?.sources?.referring?.top_referring
        let keywordsHtml = []
        let total = 0
        if (keywords && keywords?.length > 0) {
            keywords?.map(keyword => {
                total += keyword?.value
                keywordsHtml.push(<li className="d-flex align-items-center border-bottom mb-1 pb-1 justify-content-between">
                    <span>{keyword?.site}</span>
                    <span><b>{keyword?.percent}%</b></span>
                </li>)
            })
        } else {
            Array(5).fill(5).map(k => {
                keywordsHtml.push(<li className="d-flex align-items-center border-bottom mb-1 pb-1 justify-content-between">
                    <span>---------</span>
                    <span>---------</span>
                </li>)
            })
        }
        keywordsHtml.push(<li className="d-flex align-items-center border-bottom mb-1 pb-1 justify-content-between">
            <span>Total Referrals</span>
            <span><b>{total}</b></span>
        </li>)
        return keywordsHtml
    }

    const renderPublishers = () => {
        const keywords = competitor?.data?.traffic?.sources?.display_ad?.top_publishers
        let keywordsHtml = []
        let total = 0
        if (keywords && keywords?.length > 0) {
            keywords?.map(keyword => {
                total += keyword?.value
                keywordsHtml.push(<li className="d-flex align-items-center border-bottom mb-1 pb-1 justify-content-between">
                    <span>{keyword?.keyword}</span>
                    <span><b>{keyword?.percent}%</b></span>
                </li>)
            })
        } else {
            Array(5).fill(5).map(k => {
                keywordsHtml.push(<li className="d-flex align-items-center border-bottom mb-1 pb-1 justify-content-between">
                    <span>---------</span>
                    <span>---------</span>
                </li>)
            })
        }
        keywordsHtml.push(<li className="d-flex align-items-center border-bottom mb-1 pb-1 justify-content-between">
            <span>Total Keywords</span>
            <span><b>{total}</b></span>
        </li>)
        return keywordsHtml
    }

    return (
        <div className="row competitors-lists mt-3">
            <div className="col-md-6">
                <div className="card">
                    <h6>{t('Keyword Search Traffic')}</h6>
                    <span className="text-muted">{t('See the list of top organic keywords driving traffic to')}
                        {competitor?.data?.site_url}.
                        ({t('On Desktop, Worldwide')})</span>
                    <hr />
                    <h6 className="border-bottom m-0 mb-1 pb-1">{t('Top Keywords')}</h6>
                    <ul className="list-unstyled">
                        {renderKeywords()}
                    </ul>
                    {/* <div className="d-flex mt-3">
                        <button className="btn click-btn orange-btn mr-2 btn-block">See all</button>
                        <button className="btn click-btn orange-btn-outline">Share</button>
                    </div> */}
                </div>
            </div>
            <div className="col-md-6">
                <div className="card">
                    <h6>{t('Top Referrals')}</h6>
                    <span className="text-muted">{t('The top referral sites sending direct links to')}
                        {competitor?.data?.site_url}.
                        ({t('On Desktop, Worldwide')})</span>
                    <hr />
                    <div>{t('Top Keywords')}</div>
                    <ul className="list-unstyled">
                        {renderReferrals()}
                    </ul>
                    {/* <div className="d-flex mt-3">
                        <button className="btn click-btn orange-btn mr-2 btn-block">See all</button>
                        <button className="btn click-btn orange-btn-outline">Share</button>
                    </div> */}
                </div>
            </div>
            {/* <div className="col-md-4">
                <div className="card">
                    <h6>{t('Display Advertising Traffic')}</h6>
                    <span className="text-muted">{t('Analysis of the top publishers sending traffic to')}
                        {competitor?.data?.site_url}.</span>
                    <hr />
                    <div>{t('Top Publishers')}</div>
                    <ul className="list-unstyled">
                        {renderPublishers()}
                    </ul>
                    {/* <div className="d-flex mt-3">
                        <button className="btn click-btn orange-btn mr-2 btn-block">See all</button>
                        <button className="btn click-btn orange-btn-outline">Share</button>
                    </div> *
                </div>
                </div> */}
            {/* <div className="col-md-4">
                <div className="card">
                    <h6>Outgoing Links</h6>
                    <span className="text-muted">Currently, there are 4 websites
                        receiving traffic from
                        {competitor?.data?.site_url}.
                        (On Desktop, Worldwide)</span>
                    <hr />
                    <div>Top Outgoing links</div>
                    <ul className="list-unstyled">
                        <li className="align-center justify-content-between">
                            <span>Zuma</span>
                            <span>17.45%</span>
                        </li>
                        <li className="align-center justify-content-between">
                            <span>Zuma</span>
                            <span>17.45%</span>
                        </li>
                        <li className="align-center justify-content-between">
                            <span>Zuma</span>
                            <span>17.45%</span>
                        </li>
                        <li className="align-center justify-content-between">
                            <span>Zuma</span>
                            <span>17.45%</span>
                        </li>
                        <li className="align-center justify-content-between">
                            <span>Zuma</span>
                            <span>17.45%</span>
                        </li>
                    </ul>
                    // <div className="d-flex mt-3">
                    //     <button className="btn click-btn orange-btn mr-2 btn-block">See all</button>
                    //     <button className="btn click-btn orange-btn-outline">Share</button>
                    // </div>
                </div>
            </div> */}
        </div>
    );
}

export default CompetitorLists;
import React, { useEffect, useRef, useState } from 'react'
import { Tab, Tabs, ListGroup, ListGroupItem, Table, FormCheck, InputGroup, FormControl } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import TablePagination from '../../../components/common/Pagination'
import { getCrmAnalytics, listCustomers, listTags, listTop5Tags, uploadCustomers } from '../../../store/actions'
import { LIST_CUSTOMERS, LIST_TAGS, LIST_TOP_5_TAGS, UPLOAD_CUSTOMERS } from '../../../store/crm/actionTypes'
import { checkIfLoading } from '../../../store/functions'
import AddContactModal from './Components/AddContact'
import CustomersTable from './Components/CustomersTable'
import CrmNav from './Components/Nav'
import TagCustomers from './Components/TagCustomers'

function Customers() {
  const dispatch = useDispatch()
  const [query, setQuery] = useState({
    page: 1,
    email: null,
    limit: 5,
    type: "customer",
    name: ''
  })
  const [selectedTag, setSelectedTag] = useState(null)
  const [contactModal, setContactModal] = useState(false)
  const history = useNavigate()
  const [t] = useTranslation()
  const inputRef = useRef(null)
  const onInputChange = (e) => {
    const fileUploaded = event.target.files[0];
    const formData = new FormData();
    formData.append('file', fileUploaded, fileUploaded?.name);
    dispatch(uploadCustomers(formData, () => dispatch(listCustomers(query))))
  }

  const { customers, count, tags, tagLoading, workspace, top5tags, top5tagsLoading, uploadLoading } = useSelector(state => ({
    customers: state?.crm?.customers?.list,
    count: state?.crm?.customers?.page_details?.total_items,
    tags: state?.crm?.tags?.list,
    tagLoading: checkIfLoading(state, LIST_TAGS),
    workspace: state?.workspace?.workspace,
    top5tags: state?.crm?.top5tags,
    top5tagsLoading: checkIfLoading(state, LIST_TOP_5_TAGS),
    uploadLoading: checkIfLoading(state, UPLOAD_CUSTOMERS)
  }))

  const renderTags = () => {
    if (tagLoading) return <Spinner size="sm" className="m-auto text-center" color="success" />
    if (tags && tags?.length > 0) {
      return tags?.map(tag => {
        return <ListGroupItem>{tag.name}</ListGroupItem>
      })
    } else {
      return t('No tags found')
    }
  }

  const renderTop5Tags = () => {
    if (top5tagsLoading) return <Spinner size="sm" className="m-auto text-center" color="success" />
    if (top5tags && top5tags?.length > 0) {
      return top5tags?.map((tag) => {
        return <div className='outer-box' onClick={() => setSelectedTag(tag)}>
          <div className={`tag-box ${selectedTag?._id === tag?._id ? 'bg-orange' : "bg-green"}`}>
            <div className='percent' style={{ height: (100 - tag?.percent) + '%' }}><div className='number'>{tag?.customers}</div></div>
          </div>
          <h6 className='mt-3'>{tag?.name}</h6>
        </div>
      })
    } else {
      return t('No tags found')
    }
  }

  useEffect(() => {
    dispatch(listTags({ page: 1, limit: 5 }))
  }, [workspace])

  useEffect(() => {
    dispatch(listTop5Tags({ type: query.type }))
  }, [query.type, workspace])

  useEffect(() => {
    if (top5tags && top5tags?.length > 0) setSelectedTag(top5tags[0])
  }, [top5tags, workspace])

  const onTabSelect = (e) => {
    setQuery({
      ...query,
      type: e
    })
  }

  const onPageChange = (page) => {
    setQuery({
      ...query,
      page
    })
  }

  return (
    <div className='container-fluid customers page'>
      <CrmNav />
      <Tabs defaultActiveKey="customer" onSelect={onTabSelect} id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="customer" title="Current Customers"></Tab>
        <Tab eventKey="lead" title="Leads"></Tab>
        <Tab eventKey="media" title="Media"></Tab>
      </Tabs>
      <div className='row'>
        <div className='col-md-6'>
          <div className='d-flex'>
            <h6 className='mr-2'>{count} {t('Total')} {query.type === 'customer' ? 'Customers' : "Leads"}</h6>
          </div>
          <div className='row align-items-center'>
            <InputGroup className='custom col-md-6 mt-2'>
              <InputGroup.Text id="basic-addon1" className='bg-transparent'><i class="fas fa-search"></i></InputGroup.Text>
              <FormControl
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon1"
                onChange={e => setQuery({ ...query, name: e.target.value })}
              />
            </InputGroup>
          </div>
        </div>
        <div className='col-md-6 text-right'>
          <input hidden ref={inputRef} type='file' accept='csv' onInput={onInputChange} />
          <a className='btn click-btn orange-btn mr-2' href={`${process.env.REACT_APP_API_URL}/uploads/template.csv`} target="_blank">Download Template</a>
          <button className='btn click-btn btn-secondary mr-2' onClick={() => inputRef.current.click()}>
            {uploadLoading ? <Spinner size={'sm'} color='light' /> : ""} Upload file
          </button>
          <button className='btn btn-outline-secondary' onClick={() => setContactModal(true)}><i class="fas fa-sm fa-plus"></i> Add Contact</button>
        </div>
      </div>
      <CustomersTable query={query} onPageChange={onPageChange} />
      <div className='mt-3'>
        <h4>{t('Your Most Recent 5 tags')}</h4>
        <div className='mt-2'>
          <ListGroup className='list-tags' horizontal>
            {renderTags()}
          </ListGroup>
        </div>
      </div>
      <div className='my-5'>
        <h4>{t('Your Top 5 tags')}</h4>
        <div className='top5tags d-flex mt-5'>
          {renderTop5Tags()}
        </div>
      </div>
      <div className='mt-3'>
        <TagCustomers tag={selectedTag} />
      </div>
      <AddContactModal modal={contactModal} toggle={() => setContactModal(!contactModal)} />
    </div>
  )
}

export default Customers
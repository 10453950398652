import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom'
import { Spinner } from "reactstrap";
import { forgetPassword } from "../../store/actions";
import { FORGET_PASSWORD } from "../../store/auth/actionTypes";
import { checkIfLoading } from "../../store/functions";

function ForgetPassword() {
  const history = useNavigate()
  const dispatch = useDispatch()
  const [email, setEmail] = useState(null)
  const [t] = useTranslation()

  const { loading } = useSelector(state => ({
    loading: checkIfLoading(state, FORGET_PASSWORD)
  }))

  const submitForget = () => {
    console.log({ email })
    dispatch(forgetPassword(email, history))
  }

  return (
    <form className="auth-form">
      <h4>{t('Forgot your password')}</h4>
      <div className="form-group">
        <label>{t('email')}</label>
        <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control" name="email" />
      </div>
      <button type="button" onClick={submitForget} className="btn click-btn orange-btn btn-block">
        {
          loading ? (
            <Spinner
              size="sm"
              className="m-auto text-center"
              color="light"
            />
          ) : t("Send me reset link")
        }
      </button>
      <Link to="/login" className="btn btn-outline-secondary text-dark btn-block">{t('Go back to login')}</Link>
    </form>
  );
}

export default ForgetPassword;

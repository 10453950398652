import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const MessagePage = ({ url }) => {
    const history = useNavigate()
    const [t] = useTranslation()

    return (
        <div className='text-center'>
            <i class="far fa-check-circle text-green mb-3" style={{ fontSize: "80px" }}></i>
            <h4 className='mb-4'>{t('User Subscribed Successfully')}</h4>
            <button onClick={() => history(url)} className='btn click-btn green-btn'>{t('Done')}</button>
        </div>
    )
}

export default MessagePage
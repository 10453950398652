import React, { useState, useEffect } from 'react'
import Chart from "react-apexcharts";

function AreaChart({ name, data, curveType, showYaxis, height }) {
  // ** states 
  const [chartOptions, setChartOptions] = useState(null)

  // ** function to get chart data
  const getChartData = () => {
    const seriesList = []
    const categoryList = []
    data.map(ele => seriesList.push(ele.value))
    data.map(ele => categoryList.push(ele.label))
    return { seriesList, categoryList }
  }

  // ** use Effect to set chart option if data exist
  useEffect(() => {
    if (data.length > 0) {
      setChartOptions({
        series: [{
          name: name,
          data: getChartData().seriesList
        }],
        options: {
          chart: {
            type: 'area',
            height: '80%',
            zoom: {
              enabled: false
            }
          },
          height: 'auto',
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: curveType
          },
          labels: ["2007, 2008"],
          xaxis: {
            categories: getChartData().categoryList,
            labels: {
              style: {
                fontSize: '10px'
              }
            }
          },
          legend: {
            horizontalAlign: 'left'
          },
          yaxis: {
            show: showYaxis,
          },
          colors: ['#EF8451'],
          fill: {
            colors: ['#EF8451']
          }
        },
      })
    }
  }, [data])

  return (
    <>
      {chartOptions && <Chart height={height || 350} options={chartOptions.options} series={chartOptions.series} type="area" />}
    </>
  )
}

export default AreaChart
import { put, select, takeEvery } from "redux-saga/effects"
import { stopAction, startAction, setError } from "../actions"
import { createCampaignSuccess, deleteCampaignSuccess, listCampaignsSuccess, updateCampaignSuccess, viewCampaignSuccess } from "./actions"
import { CREATE_CAMPAIGN, DELETE_CAMPAIGN, LIST_CAMPAIGNS, UPDATE_CAMPAIGN, VIEW_CAMPAIGN } from "./actionTypes"
import { CreateCampaignAPI, DeleteCampaignAPI, GetCampaignAPI, ListCampaignsAPI, UpdateCampaignAPI } from "./calls"

function* listCampaigns({ type, payload: { query = {} } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    query = new URLSearchParams({ workspace: state?.workspace?.workspace?._id, ...query }).toString()
    const result = yield ListCampaignsAPI(query, state)
    console.log(result)
    yield put(listCampaignsSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* createCampaign({ type, payload: { body, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    body = { workspace: state?.workspace?.workspace?._id, ...body }
    const result = yield CreateCampaignAPI(body, state)
    console.log(result)
    yield put(createCampaignSuccess(result?.data?.data))
    if (toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* getCampaign({ type, payload: { id, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const query = new URLSearchParams({ workspace: state?.workspace?.workspace?._id }).toString()
    const result = yield GetCampaignAPI(id, query, state)
    console.log(result)
    yield put(viewCampaignSuccess(result?.data?.data))
    if (toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* updateCampaign({ type, payload: { id, body } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    body = { workspace: state?.workspace?.workspace?._id, ...body }
    const result = yield UpdateCampaignAPI(id, body, state)
    console.log(result)
    yield put(updateCampaignSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* deleteCampaign({ type, payload: { id } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield DeleteCampaignAPI(id, state)
    console.log(result)
    yield put(deleteCampaignSuccess(id))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* CampaignsSaga() {
  yield takeEvery(LIST_CAMPAIGNS, listCampaigns)
  yield takeEvery(CREATE_CAMPAIGN, createCampaign)
  yield takeEvery(VIEW_CAMPAIGN, getCampaign)
  yield takeEvery(UPDATE_CAMPAIGN, updateCampaign)
  yield takeEvery(DELETE_CAMPAIGN, deleteCampaign)
}

export default CampaignsSaga
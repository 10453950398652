import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

function ContactUsModal({ modal, toggle }) {
  return (
    <Modal className='px-4' isOpen={modal} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody className='text-center px-3 pt-1 pb-3' >
        <img src={`${process.env.PUBLIC_URL}/assets/images/icons/email.4ecc2cef.svg`} alt='email-icon' />
        <p>Thanks for contacting us, one rapresentative will get in touch via email shortly</p>
      </ModalBody>
    </Modal>
  )
}

export default ContactUsModal
import api from '../apis/api'
import { tokenConfig } from '../config'

export const ListBillingsAPI = (body) => {
  return new Promise((res, rej) => {
    api.post(`/login`, body)
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const GetSubscriptionAPI = (body) => {
  return new Promise((res, rej) => {
    api.post(`/register`, body)
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const ChangeSubscriptionAPI = (body) => {
  return new Promise((res, rej) => {
    api.post(`/activate`, body)
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const ListPlansAPI = () => {
  return new Promise((res, rej) => {
    api.get(`/subscribe/plans`)
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const GetPaymentIntentAPI = (body, state) => {
  return new Promise((res, rej) => {
    api.post(`/subscribe`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const PaySubscriptionAPI = (state) => {
  return new Promise((res, rej) => {
    api.post(`/delete`, {}, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import sagas from './saga';
import reducers from './reducers';

const store = (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers =
    typeof window === 'object' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      }) : compose;

  const enchancer = composeEnhancers(applyMiddleware(sagaMiddleware));
  const store = createStore(reducers, initialState, enchancer);
  sagaMiddleware.run(sagas);

  return store;
}

export default store
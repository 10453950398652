import {
  LIST_CAMPAIGNS,
  LIST_CAMPAIGNS_SUCCESS,
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_SUCCESS,
  STOP_CAMPAIGN,
  STOP_CAMPAIGN_SUCCESS,
  VIEW_CAMPAIGN,
  VIEW_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN,
  DELETE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_SUCCESS,
} from "./actionTypes"

export const listCampaigns = (query) => {
  return {
    type: LIST_CAMPAIGNS,
    payload: { query },
  }
}

export const listCampaignsSuccess = (data) => {
  return {
    type: LIST_CAMPAIGNS_SUCCESS,
    payload: data
  }
}

export const createCampaign = (body, toggle) => {
  return {
    type: CREATE_CAMPAIGN,
    payload: { body, toggle },
  }
}

export const createCampaignSuccess = (data) => {
  return {
    type: CREATE_CAMPAIGN_SUCCESS,
    payload: data
  }
}

export const updateCampaign = (id, body) => {
  return {
    type: UPDATE_CAMPAIGN,
    payload: { id, body },
  }
}

export const updateCampaignSuccess = (data) => {
  return {
    type: UPDATE_CAMPAIGN_SUCCESS,
    payload: data
  }
}

export const viewCampaign = (id) => {
  return {
    type: VIEW_CAMPAIGN,
    payload: { id },
  }
}

export const viewCampaignSuccess = (data) => {
  return {
    type: VIEW_CAMPAIGN_SUCCESS,
    payload: data
  }
}

export const deleteCampaign = (id) => {
  return {
    type: DELETE_CAMPAIGN,
    payload: { id },
  }
}

export const deleteCampaignSuccess = (id) => {
  return {
    type: DELETE_CAMPAIGN_SUCCESS,
    payload: id
  }
}
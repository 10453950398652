import React from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'

const IntegrateZapier = () => {
    return (
        <div className='bg-light p-3 rounded'>
            <p>Here, we'll show you how to build a Zap, set up a bot in an AppSheet app to fire an event to trigger the Zap and send an email. Instead, you could use any of the hundreds of other Zapier actions.</p>

            <p>To integrate with Zapier:</p>

            <ol>
                <li>Create a Zapier account.</li>
                <li>Click <strong>Create Zap</strong> to create a new Zap.</li>
                <li>Replace <strong>Name your zap</strong>&nbsp;with your Zap name in the top left.</li>
                <li>Set the Trigger to <strong>Webhook</strong>.<br />
                    <br />
                    <img src="//storage.googleapis.com/support-kms-prod/LI78XrwyqNXw67LSh1bumbW7QRZx8whD9Qr8" width="500" height="349" alt="Select Trigger page" data-mime-type="image/png" /><br />
                    &nbsp;</li>
                <li>Select <strong>Catch Hook</strong> in the <strong>Trigger Event&nbsp;</strong>drop-down and click <strong>Continue</strong>.<br />
                    <br />
                    <img src="//storage.googleapis.com/support-kms-prod/jCkh07rhU2ss2V2vMvSRrk4qWXSySS9RwuCs" width="500" height="274" alt="" data-mime-type="image/png" /><br />
                    &nbsp;</li>
                <li>Click <strong>Copy next to the&nbsp;Custom Webhook URL</strong>&nbsp;to the copy the URL. Then, click <strong>Continue</strong>.<br />
                    <br />
                    <img src="//storage.googleapis.com/support-kms-prod/H2aFvQriBjUDJJPyloF3BzhZA27dX06eBOHI" width="400" height="309" alt="" data-mime-type="image/png" /><br />
                    &nbsp;</li>
                <li>In the AppSheet app editor in your app.</li>
                <li>Paste the Zapier webhook URL copied in the previous step into the <strong>URL </strong>field of the Call a webhook task.</li>
                <li>Save&nbsp;your app.</li>
                <li>In your AppSheet application, make a data update and save it to trigger the AppSheet webhook.<br />
                    You can do this by deploying and running your app or using the Test feature to execute the Call a webhook task.</li>
                <li>In Zapier click on <strong>Test trigger</strong>. This tells Zapier that you've sent a trial webhook to the Zap to trigger it.<br />
                    <br />
                    <img src="//storage.googleapis.com/support-kms-prod/5vlbei4iEByh0CeTdM9ObxkZQJjqZVrBug3U" width="500" height="371" alt="" data-mime-type="image/png" /><br />
                    &nbsp;</li>
                <li>If Zapier receives&nbsp;the webhook from your AppSheet app successfully, it displays the message <code>We found a&nbsp;request!</code>&nbsp;followed by the JSON webhook body sent by your AppSheet webhook. For example:<br />
                    <br />
                    <img src="//storage.googleapis.com/support-kms-prod/5eNLi9avtBnffgybwuSJ1WL6M57wro8X8IUV" width="500" height="528" alt="" data-mime-type="image/png" /><br />
                    &nbsp;</li>
                <li>Click <strong>Continue</strong>.</li>
                <h3 className='mt-3'>Integrate our app</h3>
                <li>
                    Choose an action for the Zap, search for our app called <strong>Seosuite</strong>.
                </li>
                <li>
                    Choose specific event from our app
                    <br />
                    <img src={`${process.env.PUBLIC_URL}/assets/images/zapier/chooseapp.png`} width="500" />
                </li>
                <li>Click <strong>Continue</strong>.</li>
                <li>Get the APIKEY for your workspace, you can find this in settings &gt; workspace tab
                    <br />
                    <img src={`${process.env.PUBLIC_URL}/assets/images/zapier/apikey.png`} width="500" />
                </li>
                <li>
                    Choose an account, then authenticate using the APIKEY we provide for each workspace.
                    <br />
                    <img src={`${process.env.PUBLIC_URL}/assets/images/zapier/chooseaccount.png`} width="500" />
                </li>
                <li>Click <strong>Continue</strong>.</li>
                <li>Configure the action.<br />
                    <strong>Note</strong>: You can use any of the fields from the updated record in the triggered event as they are all available in the payload&nbsp;sent from the event.<br />
                    <br />
                    <img src={`${process.env.PUBLIC_URL}/assets/images/zapier/setupaction.png`} width="500" height="563" alt="" data-mime-type="image/png" /><br />
                    &nbsp;</li>
                <li>Click <strong>Continue</strong>.</li>
                <li>Click <strong>Test &amp; Continue</strong> to test the Zap.</li>
                <li>Click <strong>Turn on Zap</strong>.</li>
                <li>Now Our action is integrated with the trigger!</li>
            </ol>
        </div>
    )
}

export default IntegrateZapier
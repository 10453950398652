import React, { useEffect, useState } from "react";
import Tabs from "./Tabs";
import AllKeyWords from "./contents/AllKeyWords";
import CommonKeyWords from "./contents/CommonKeyWords";
import Discovering from "./contents/Discovering";
import Posts from "./contents/Posts";
import Reviews from "./contents/Reviews";
import { useDispatch, useSelector } from "react-redux";
import { GET_BUSINESS } from "../../../../store/localseo/actionTypes";
import { checkIfLoading } from "../../../../store/functions";
import { Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { disconnectBusiness } from "../../../../store/actions";
import { useTranslation } from "react-i18next";

function index({ descovering, selectedBusiness, selectLoading }) {
  // ** states
  const [tab, setTab] = useState(1)
  const history = useNavigate()
  const dispatch = useDispatch()
  const [business, setBusiness] = useState(null)
  const [t] = useTranslation()

  const { loading, workspaceBusiness } = useSelector(state => ({
    workspaceBusiness: state?.localseo?.business,
    loading: checkIfLoading(state, GET_BUSINESS)
  }))

  useEffect(() => {
    if (tab !== 5 && !workspaceBusiness) history('/local-seo')
    if (selectedBusiness) {
      setBusiness(selectedBusiness)
      setTimeout(() => { handleSelectContent('all', selectedBusiness) }, 500)
    } else {
      setBusiness(workspaceBusiness)
    }
  }, [workspaceBusiness, selectedBusiness])

  const [selectedContent, setSelectedContent] = useState(<AllKeyWords data={business?.keywords} />)

  useEffect(() => {
    handleSelectContent('all', workspaceBusiness)
  }, [])

  // ** function to select content
  const handleSelectContent = (value, selected) => {
    switch (value) {
      case "all":
        setTab(1)
        setSelectedContent(<AllKeyWords data={selected ? selected?.keywords : business?.keywords} loading={loading} />)
        break;
      case "common":
        setTab(2)
        setSelectedContent(<CommonKeyWords data={selected ? selected?.keywordsCommon : business?.keywordsCommon} loading={loading} />)
        break;
      case "posts":
        setTab(3)
        setSelectedContent(<Posts data={selected ? selected?.posts : business?.posts} loading={loading} />)
        break;
      case "reviews":
        setTab(4)
        setSelectedContent(<Reviews data={selected ? selected?.reviews : business?.reviews} loading={loading} />)
        break;
      case "discovering":
        setTab(5)
        break;
    }
  }

  const address = encodeURI(business?.address?.address + ',' + business?.address?.city + ',' + business?.address?.country_code)

  return (
    <div className="detailed-report">
      {
        tab === 5 ? <Discovering /> : <div className="container">
          {
            !descovering ? <><div className="row">
              <div className="col-12 text-center">
                <h4>{t('detailed-report-about-business')}</h4>
                <span>{t('analyze-keywords-positioned-posts-and-more')}</span>
              </div>
            </div>
              <div className="row justify-content-end mb-2">
                <div className="col-md-3 text-right"><button className="btn btn-link" onClick={() => dispatch(disconnectBusiness(history))}>Disconnect</button></div>
              </div></> : ""
          }
          <div className="row">
            <div className="col-12">
              {
                selectLoading ? <Spinner color="success" size={'xl'} /> : <div className="card">
                  {
                    loading ? <Spinner size="sm" className="m-auto text-center" color="success" /> : <iframe src={`https://maps.google.com/maps?q=${address}&t=&z=13&ie=UTF8&iwloc=&output=embed`} width="100%" height="295" style={{ border: "0", marginBottom: "50px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  }
                  <Tabs descovering={descovering} business={business} handleSelectContent={handleSelectContent} tab={tab} />
                  {
                    loading ? <Spinner size="sm" className="m-auto text-center" color="success" /> : business ? selectedContent : ''
                  }
                </div>
              }
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default index;

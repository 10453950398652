import {
  CLEAR_ERRROR,
  REFRESH_ACTION_START,
  REFRESH_ACTION_STOP,
  SET_ERROR,
  SET_SUCCESS,
  START_ACTION,
  STOP_ACTION,
  CHANGE_LANGUAGE
} from './actionTypes';

const initialState = {
  loader: {
    actions: [],
    refreshing: [],
  },
  error: null,
  success: null,
  lang: localStorage.getItem('lang') || 'en'
};

const uiReducer = (state = initialState, { type, payload }) => {
  const { loader } = state;
  const { actions, refreshing } = loader;
  switch (type) {
    case START_ACTION:
      return {
        ...state,
        loader: {
          ...loader,
          actions: [...actions, payload.action]
        }
      };
    case STOP_ACTION:
      return {
        ...state,
        loader: {
          ...loader,
          actions: actions.filter(action => action.name !== payload.name)
        }
      };
    case SET_SUCCESS:
      return {
        ...state,
        success: payload
      }
    case SET_ERROR:
      return {
        ...state,
        error: payload
      }
    case CLEAR_ERRROR:
      return {
        ...state,
        success: null,
        error: null
      }
    case CHANGE_LANGUAGE:
      localStorage.setItem('lang', payload)
      return {
        ...state,
        lang: payload
      }
    case REFRESH_ACTION_START:
      return {
        ...state,
        loader: {
          ...loader,
          refreshing: [...refreshing, payload.refreshAction]
        }
      };
    case REFRESH_ACTION_STOP:
      return {
        ...state,
        loader: {
          ...loader,
          refreshing: refreshing.filter(refresh => refresh !== payload.refreshAction)
        }
      };
    default:
      return state;
  }
};

export default uiReducer;
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DataTableComponent from "../../../../../components/tables/DataTable";

function CommonKeyWords({ data }) {
  const [tableData, setTableData] = useState([])
  const [t] = useTranslation()

  useEffect(() => {
    if (data && data?.length > 0) {
      const mappedData = data?.map(e => {
        let traffic = 0
        console.log(e)
        e.monthlySearches.forEach(m => traffic += m.search_volume)
        return {
          id: e._id,
          keyword: e.keyword,
          traffic: traffic,
          KeywordVolume: e.searchVolume,
          rank: e.competitionIndex,
          url: e?.matches?.length > 0 ? e?.matches[0] : ""
        }
      })
      setTableData(mappedData)
    }
  }, [data])

  const columns = [
    {
      name: t('keyword'),
      selector: "keyword",
      sortable: true,
      cell: e => {
        return <p style={{ fontWeight: 600, fontSize: "13px", color: "#347362", marginBottom: 0 }}>{e.keyword}</p>
      },
    },
    {
      name: t('url'),
      selector: "URL",
      sortable: true,
      cell: e => {
        return <a style={{ fontWeight: 800, fontSize: "10px", color: "#347362", marginBottom: 0, textDecoration: "underline" }} href={e.url}>{e.url}</a>
      },
    },
    {
      name: t('traffic'),
      selector: "traffic",
      sortable: true,
      cell: e => {
        return <p style={{ fontWeight: 800, fontSize: "14px", color: "#347362", marginBottom: 0 }}>{e.traffic}</p>
      },
    },
    {
      name: t('keyword-volume'),
      selector: "KeywordVolume",
      sortable: true,
      cell: e => {
        return <p style={{ fontWeight: 800, fontSize: "14px", color: "#347362", marginBottom: 0 }}>{e.KeywordVolume}</p>
      },
    },
    {
      name: t('rank'),
      selector: "rank",
      sortable: true,
      cell: e => {
        return <p style={{ fontWeight: 500, fontSize: "18px", color: "#347362", marginBottom: 0 }}>{e.rank}</p>
      },
    },
  ];

  return <DataTableComponent columns={columns} data={tableData} />
}

export default CommonKeyWords;
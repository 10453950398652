import {
  ADD_PROJECT,
  ADD_PROJECT_SUCCESS,
  ADD_SUBTASK,
  ADD_SUBTASK_SUCCESS,
  ADD_TASK,
  ADD_TASK_SUCCESS,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  EDIT_TASK,
  EDIT_TASK_SUCCESS,
  GET_PROJECT,
  GET_PROJECT_SUCCESS,
  LIST_PROJECTS,
  LIST_PROJECTS_SUCCESS,
  LIST_TASKS,
  LIST_TASKS_SUCCESS,
  SET_SUBTASK_DONE,
  SET_SUBTASK_DONE_SUCCESS,
  SET_TASK_COMPLETE,
  SET_TASK_COMPLETE_SUCCESS
} from "./actionTypes"

const initialState = {
  projects: []
}

const projectManagement = (state = initialState, action) => {
  switch (action.type) {
    case LIST_PROJECTS:
      return { ...state }
    case LIST_PROJECTS_SUCCESS:
      return { ...state, projects: action.payload }
    case ADD_PROJECT:
      return { ...state }
    case ADD_PROJECT_SUCCESS:
      return { ...state, projects: [...state.projects, action.payload] }
    default:
      return state
  }
}

export default projectManagement

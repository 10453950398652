import React, { useEffect, useState } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Spinner } from 'reactstrap'
import TablePagination from '../../../../components/common/Pagination'
import { competitorsBacklinks } from '../../../../store/actions'

const CompetitorsBacklinksTable = ({ loading, backlinks, details, competitors, handleSelectedEmails, selectedEmails }) => {
    const dispatch = useDispatch()
    const [filters, setFilters] = useState({
        page: 1,
        limit: 10
    })
    const [showEmails, setShowEmails] = useState(null)
    const [t] = useTranslation()

    const setPage = (page) => {
        setFilters({
            ...filters,
            page
        })
    }

    useEffect(() => {
        if (competitors && competitors?.length > 0) {
            const query = new URLSearchParams(filters).toString()
            dispatch(competitorsBacklinks(query))
        }
    }, [competitors, filters.page])

    const renderEmails = (backlink) => {
        return backlink?.emails?.slice(0, 6)?.map(email => {
            return <tr>
                <td onClick={() => handleSelectedEmails(email?._id)}><span className={`click-square ${selectedEmails.includes(email?._id) ? 'active' : ""}`}></span></td>
                <td>{email.firstName}</td>
                <td>{email.lastName}</td>
                <td>{email.position}</td>
                <td>{email.email}</td>
            </tr>
        })
    }

    const renderBacklinks = () => {
        if (loading) return <Spinner size="sm" className="m-auto text-center" color="success" />
        if (backlinks && backlinks?.length > 0) {
            return backlinks?.map(bk => {
                return <>
                    <tr style={{ fontSize: "14px" }} className={`header ${showEmails === bk?._id ? 'active' : ""}`}>
                        <td onClick={() => setShowEmails(showEmails === bk?._id ? null : bk?._id)}><span className={`click-square ${showEmails === bk?._id ? 'active' : ""}`}></span></td>
                        <td><a className='text-dark' href={'https://' + bk?.domain}>{bk?.domain}</a></td>
                        <td>{bk?.targetDomain}</td>
                        <td>{bk?.domainAuthority}</td>
                    </tr>
                    <tr className={`body ${showEmails === bk?._id ? 'active' : ""}`} style={{ display: showEmails === bk?._id ? "table-row" : "none" }}>
                        <td colSpan={4} className="border-0">
                            <div className='d-flex'>
                                <span>{t('Pick contacts that you want to include into the campaign.')}</span>
                                <table className='table table-sm table-hover' style={{ fontSize: "12px" }}>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>{t('name')}</th>
                                            <th>{t('lastname')}</th>
                                            <th>{t('position')}</th>
                                            <th>{t('email')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderEmails(bk)}
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                </>
            })
        } else {
            return <tr><td colSpan={4}>{t('no-backlinks-found')}</td></tr>
        }
    }

    return (
        <div>
            <div className='row justify-content-between align-items-end'>
                <div className='col-md-4'>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className='bg-transparent'><i class="fas fa-search"></i></InputGroup.Text>
                        <FormControl
                            placeholder={t('search')}
                            aria-label={t('search')}
                            aria-describedby="basic-addon1"
                        />
                    </InputGroup>
                </div>
                <div className='col-md-6'>
                    <div className='add-competitor' style={{ height: 'auto' }}>
                        <div className='row align-items-center m-0'>
                            <div className='col-md-6'>
                                <input className='form-control' placeholder={t('Enter website')} />
                            </div>
                            <div className='col-md-6 text-right'>
                                <button className='btn click-btn orange-btn btn-sm py-1 px-3'>{t('Add Media Target')}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-12'>
                    <table className='table backlinks-table'>
                        <thead>
                            <tr style={{ fontSize: "15px" }}>
                                <th></th>
                                <th>{t('Who is linking to your competitors?')}</th>
                                <th>{t('it is linking to:')}</th>
                                <th>{t('domain-authority')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderBacklinks()}
                        </tbody>
                    </table>
                </div>
                <div className='col-md-12 mt-3'>
                    <TablePagination count={details?.total_items} page={filters?.page} setPage={setPage} limit={filters?.limit} loading={loading} />
                </div>
            </div>
        </div>
    )
}

export default CompetitorsBacklinksTable
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

const MarketingNav = ({ tab, setTab }) => {
    const [t] = useTranslation()

    return (
        <div className='marketing-nav mt-5'>
            <div className='row'>
                <div className='col-md-12 d-flex'>
                    <ul className='list-group text-center list-group-horizontal-lg'>
                        <li onClick={() => setTab('integrate-zapier')} className={`mr-3 ${tab === 'integrate-zapier' ? 'active' : ""}`}><span><i class="fas mr-1 fa-envelope"></i> {t('Integrate Zapier')}</span></li>
                        <li onClick={() => setTab('email-builder')} className={`mr-3 ${tab === 'email-builder' ? 'active' : ""}`}><span><i class="fas mr-1 fa-envelope"></i> {t('Email Builder')}</span></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default MarketingNav
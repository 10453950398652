import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import { sendFeedback } from '../../store/actions'
import { SEND_FEEDBACK } from '../../store/auth/actionTypes'
import { checkIfLoading } from '../../store/functions'
import ContactUsModal from '../modals/ContactUsModal'

const FeedbackModal = ({ modal, text, toggle, button }) => {
    const [msg, setMsg] = useState(null);
    const [img, setImg] = useState(null);
    const dispatch = useDispatch()
    const location = useLocation()
    const { workspace, loading, lang } = useSelector(state => ({
        workspace: state?.workspace?.workspace?._id,
        loading: checkIfLoading(state, SEND_FEEDBACK),
        lang: state.ui.lang
    }))
    const [t] = useTranslation()
    const [contactUsModal, setContactUsModal] = useState(false);
    const toggleContactUsModal = () => setContactUsModal(!contactUsModal);

    const feedbackSubmit = () => {
        if (msg) {
            dispatch(sendFeedback({
                message: msg,
                path: location.pathname,
                image: img,
                workspace
            }, () => {
                toggle()
                setContactUsModal(true)
            }))
        }
    }

    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        const formData = new FormData();
        formData.append('image', fileUploaded, fileUploaded?.name);
        setImg(formData)
    };

    return (
        <>
            <Modal className='feedback-modal' isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader toggle={toggle}></ModalHeader>
                <ModalBody>
                    <h3>
                        {text ? t(text) : t('need-specific-feature')}
                    </h3>
                    <textarea onChange={e => setMsg(e.target.value)} value={msg} class="form-control mb-2" placeholder={text ? "Tell us your issue" : "Please, write your suggestion"}></textarea>
                    <div>
                        <label className='d-block'>{t('upload-picture')}</label>
                        <input type='file' onChange={handleChange} />
                    </div>
                    <button disabled={loading} onClick={feedbackSubmit} class="btn">
                        {
                            loading ? <Spinner color='light' size='sm' /> : button ? t(button) : t('submit-feature-request')
                        }
                    </button>
                </ModalBody>
            </Modal>
            <ContactUsModal modal={contactUsModal} toggle={toggleContactUsModal} />
        </>
    )
}

export default FeedbackModal
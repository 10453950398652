import moment from 'moment';
import React, { useState, useEffect } from 'react'
import Chart from "react-apexcharts";

function BarChartPositiveNegative({ data }) {
  // ** states 
  const [chartOptions, setChartOptions] = useState(null)

  // ** use Effect to set chart option if data exist
  useEffect(() => {
    console.log({
      new: data?.map(e => e.newBacklinks),
      lost: data?.map(e => Number('-' + e.lostBacklinks)),
      date: data?.map(e => e.date)
    })
    if (data) {
      setChartOptions({
        series: [{
          name: 'New',
          data: data?.map(e => e.newBacklinks)
        },
        {
          name: 'Lost',
          data: data?.map(e => Number('-' + e.lostBacklinks))
        }],
        options: {
          chart: {
            type: 'bar',
            height: 440,
            stacked: true
          },
          colors: ['#33B08E', '#FF4560'],
          plotOptions: {
            bar: {
              horizontal: false,
              barHeight: '80%',
              borderRadius: 10
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: 1,
            colors: ["#fff"]
          },
          grid: {
            yaxis: {
              lines: {
                show: false,
              }
            }
          },
          yaxis: {
            // min: -200,
            // max: 200,
            title: {
              // text: 'Age',
              borderRadius: 10
            },
          },
          tooltip: {
            shared: false,
            x: {
              formatter: function (val) {
                return val
              }
            },
            y: {
              formatter: function (val) {
                return val
              }
            }
          },
          xaxis: {
            categories: data?.map(e => moment(e.date).format('MMM YYYY')),
            title: {
              text: 'Percent'
            },
            // labels: {
            //   formatter: function (val) {
            //     return Math.abs(Math.round(val)) + "%"
            //   }
            // }
          }
        }
      })
    }
  }, [data])

  return (
    <>
      {chartOptions && <Chart height={400} options={chartOptions.options} series={chartOptions.series} type="bar" />}
    </>
  )
}

export default BarChartPositiveNegative
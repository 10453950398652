import api from '../apis/api'
import { tokenConfig } from '../config'

export const LoginAPI = (body) => {
  return new Promise((res, rej) => {
    api.post(`/auth/login`, body)
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const RegisterAPI = (body) => {
  return new Promise((res, rej) => {
    api.post(`/auth/register`, body)
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const VerifyAPI = (body) => {
  return new Promise((res, rej) => {
    api.post(`/auth/verify`, body)
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const getUserDataAPI = (state) => {
  return new Promise((res, rej) => {
    api.get(`/auth/profile`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const updateProfileAPI = (data, state) => {
  return new Promise((res, rej) => {
    api.put(`/auth/profile`, data, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const updatePasswordAPI = (data, state) => {
  return new Promise((res, rej) => {
    api.put(`/auth/password`, data, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const forgetPasswordAPI = (data) => {
  return new Promise((res, rej) => {
    api.post(`/auth/forget-password`, data)
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const resetPasswordAPI = (data) => {
  return new Promise((res, rej) => {
    api.post(`/auth/reset-password`, data)
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const updateEmailAPI = (data, state) => {
  return new Promise((res, rej) => {
    api.put(`/auth/email`, data, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const listLanguagesAPI = (data, state) => {
  return new Promise((res, rej) => {
    api.get(`/auth/languages`, data, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const authGoogleAPI = (query, state) => {
  return new Promise((res, rej) => {
    api.get(`/auth/google/callback?${query}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => {
        rej(err?.response?.data?.message || err?.response?.data?.error?.message || 'Error, Please try again later')
      })
  })
}

export const refreshGoogleTokenAPI = (body, state) => {
  return new Promise((res, rej) => {
    api.post(`/auth/google/token`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const listAnalyticsAccountAPI = (state) => {
  return new Promise((res, rej) => {
    api.get(`/auth/google/analytics`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const listSearchConsoleAccountAPI = (state) => {
  return new Promise((res, rej) => {
    api.get(`/auth/google/search`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const deleteUserAPI = (state) => {
  return new Promise((res, rej) => {
    api.delete(`/auth`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const GetPaymentMethodAPI = (state) => {
  return new Promise((res, rej) => {
    api.get(`/payment_method`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const AddPaymentMethodAPI = (body, state) => {
  return new Promise((res, rej) => {
    api.post(`/payment_method`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const ChangePaymentMethodAPI = (body, state) => {
  return new Promise((res, rej) => {
    api.put(`/payment_method`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const DeletePaymentMethodAPI = (id, state) => {
  return new Promise((res, rej) => {
    api.delete(`/payment_method/${id}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const SendFeedbackAPI = (body, state) => {
  return new Promise((res, rej) => {
    api.post(`/contact`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const AcceptInvitationAPI = (query) => {
  return new Promise((res, rej) => {
    api.get(`/invite?${query}`)
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}
import React, { useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../../store/auth/actions';
import { checkIfLoading } from '../../../store/functions';
import { GET_USER_DATA } from '../../../store/auth/actionTypes';
import { useTranslation } from 'react-i18next';

function AvatarDropdown() {
    // ** states
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useDispatch()
    const history = useNavigate()
    const [t] = useTranslation()

    // ** function to handle toggle of dropdown
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const { user, userLoading } = useSelector(state => ({
        user: state.auth.user,
        userLoading: checkIfLoading(state, GET_USER_DATA)
    }))

    return (
        <div className="avatar-con">
            <Dropdown className='custom-dropdown' style={{ width: "auto" }} isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle caret>
                    {userLoading ? <Spinner className="ml-2" size="sm" color="success" /> :
                        user?.profilePicture ? <img onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = `${process.env.PUBLIC_URL}/assets/images/avatar.png`;
                        }} src={user?.profilePicture || `${process.env.PUBLIC_URL}/assets/images/avatar.png`} alt="avatar" /> :
                            <div className='img-thumbnail rounded-circle font-logo text-center'>{String(user?.fullName).charAt(0).toUpperCase()}</div>}
                </DropdownToggle>
                <DropdownMenu right={true}>
                    <DropdownItem onClick={() => history('/settings')}>
                        <div className='py-2'>{t('settings')}</div>
                    </DropdownItem>
                    <DropdownItem onClick={() => dispatch(logoutUser(history))}>
                        <div className='py-2'>{t('logout')}</div>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    )
}

export default AvatarDropdown
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Progress, Spinner } from 'reactstrap'
import DonutChart from '../../../components/charts/DonutChart'

function CountryTraffic({ data, loading, total }) {

  const [chart, setChart] = useState([])
  const colors = ['#EF8451', '#dc3545', '#007bff', '#ffc107', '#dc3545', '#17a2b8', '#6c757d']
  const [t] = useTranslation()

  useEffect(() => {
    let dataArray = []
    console.log({data})
    if (data && data?.length > 0) {
      dataArray = data?.slice(0, 7)?.map((e, i) => {
        return {
          ...e,
          color: colors[i]
        }
      })
      setChart(dataArray)
    }
  }, [data])

  const renderCountryTraffic = (chart) => {
    if (chart?.length > 0) {
      return chart.map(e => {
        return <p className='chart-legend mb-1'>
          <span className={`list-circle`} style={{ backgroundColor: e.color }}></span> {e.country} ({e.value})
        </p>
      })
    }
  }

  return (
    <div className='card p-3'>
      <h5 className='m-0 mb-3'>{t('Traffic by Country')}</h5>
      {
        loading ? <Spinner size="md" className="m-auto text-center" color="success" /> : <div className='row'>
          <div className='col-md-6 border-right'>
            <DonutChart data={chart?.map(e => e.value)} labels={chart?.map(e => {
              return `${e.country} (${Number(Number(e.value) / total * 100).toFixed(1)}%)`
            })} colors={colors} />
          </div>
          <div className='col-md-6 pl-4 pt-4'>
            {renderCountryTraffic(chart)}
          </div>
        </div>
      }
    </div>
  )
}

export default CountryTraffic
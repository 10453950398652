import { put, select, takeEvery } from "redux-saga/effects"
import { AddCommentAPI, AddCustomerAPI, AddCustomerTaskAPI, AddEmailTemplateAPI, AddMediaAPI, AddTagAPI, DeleteCustomerAPI, DeleteEmailTemplateAPI, GetAnalyticsAPI, GetCustomerAPI, GetCustomerEmailsAPI, GetEmailTemplateAPI, ListCustomersAPI, ListEmailTemplatesAPI, ListMediaAPI, ListTagsAPI, ListTop5TagsAPI, RemoveCommentAPI, RemoveMediaAPI, UpdateCustomerAPI, UpdateEmailTemplateAPI, UploadCustomersAPI } from "./calls"
import { addCommentSuccess, addCustomerSuccess, addCustomerTaskSuccess, addEmailTemplateSuccess, addMediaSuccess, addTagSuccess, deleteCustomerSuccess, deleteEmailTemplateSuccess, getCrmAnalyticsSuccess, getCustomerSuccess, getEmailsSuccess, getEmailTemplateSuccess, listCustomersSuccess, listEmailTemplatesSuccess, 
  listMediaSuccess, 
  listTagsSuccess, listTop5TagsSuccess, removeCommentSuccess, removeMediaSuccess, updateCustomerSuccess, updateEmailTemplateSuccess, uploadCustomersSuccess } from './actions'
import { ADD_CUSTOMER, ADD_CUSTOMER_COMMENT, ADD_CUSTOMER_TASK, ADD_EMAIL_TEMPLATE, ADD_MEDIA, ADD_TAG, DELETE_CUSTOMER, DELETE_EMAIL_TEMPLATE, GET_ANALYTICS, GET_CUSTOMER, GET_EMAILS, GET_EMAIL_TEMPLATE, LIST_CUSTOMERS, LIST_EMAIL_TEMPLATES, LIST_MEDIA, LIST_TAGS, LIST_TOP_5_TAGS, REMOVE_CUSTOMER_COMMENT, REMOVE_MEDIA, UPDATE_CUSTOMER, UPDATE_EMAIL_TEMPLATE, UPLOAD_CUSTOMERS } from "./actionTypes"
import { setError, setSuccess, startAction, stopAction, getPipeline } from "../actions"

function* addCustomer({ type, payload: { data, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield AddCustomerAPI(data, state)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(addCustomerSuccess(result?.data?.data))
    toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* updateCustomer({ type, payload: { id, data, toggle, pipeline } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield UpdateCustomerAPI(id, data, state)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(updateCustomerSuccess(result?.data?.data))
    console.log(pipeline)
    if (pipeline) pipeline()
    if (toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* uploadCustomers({ type, payload: { file, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    let query = new URLSearchParams({
      workspace: state.workspace.workspace?._id,
      type: 'csv'
    }).toString()
    const result = yield UploadCustomersAPI(query, file, state)
    console.log(result)
    yield put(setSuccess(result.data.message))
    yield put(uploadCustomersSuccess(result?.data?.data))
    if(toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* deleteCustomer({ type, payload: { id, history } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield DeleteCustomerAPI(id, state)
    console.log(result)
    yield put(deleteCustomerSuccess(result?.data?.data))
    if(history) history('/crm/current-customers')
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* getCustomer({ type, payload: { id, history } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const query = new URLSearchParams({ workspace: state.workspace.workspace._id })
    const result = yield GetCustomerAPI(id, query, state)
    console.log(result)
    yield put(getCustomerSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    history('/crm/current-customers')
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* getCustomerEmails({ type, payload: { id, query } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    query = new URLSearchParams(query).toString()
    const result = yield GetCustomerEmailsAPI(id, query, state)
    console.log(result)
    yield put(getEmailsSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* listCustomers({ type, payload: { query = {} } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    query = new URLSearchParams({ ...query, workspace: state?.workspace?.workspace?._id }).toString()
    const result = yield ListCustomersAPI(query, state)
    console.log(result)
    yield put(listCustomersSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* addComment({ type, payload: { comment, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield AddCommentAPI(comment, state)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(addCommentSuccess(result?.data?.data))
    if (toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* removeComment({ type, payload: { id } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield RemoveCommentAPI(id, state)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(removeCommentSuccess(id))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* addTag({ type, payload: { name, customer, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const workspace = state?.workspace?.workspace?._id
    const body = { name, workspace, customer }
    const result = yield AddTagAPI(body, state)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(addTagSuccess(result.data.data))
    if (toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* getCrmAnalytics({ type }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const workspace = state?.workspace?.workspace?._id
    const query = new URLSearchParams({ workspace: workspace }).toString()
    const result = yield GetAnalyticsAPI(query, state)
    console.log(result)
    yield put(getCrmAnalyticsSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* listTags({ type, payload: { query = {} } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const workspace = state?.workspace?.workspace?._id
    query = new URLSearchParams({ workspace: workspace, ...query }).toString()
    const result = yield ListTagsAPI(query, state)
    console.log(result)
    yield put(listTagsSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* listEmailTemplates({ type, payload: { } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const workspace = state?.workspace?.workspace?._id
    const query = new URLSearchParams({ workspace: workspace }).toString()
    const result = yield ListEmailTemplatesAPI(query, state)
    console.log(result)
    yield put(listEmailTemplatesSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* getEmailTemplate({ type, payload: { id } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield GetEmailTemplateAPI(id, state)
    console.log(result)
    yield put(getEmailTemplateSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* addEmailTemplate({ type, payload: { body, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const workspace = state?.workspace?.workspace?._id
    const result = yield AddEmailTemplateAPI({ ...body, workspace }, state)
    console.log(result)
    yield put(setSuccess(result.data?.message))
    yield put(addEmailTemplateSuccess(result?.data?.data))
    toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* updateEmailTemplate({ type, payload: { id, data, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const workspace = state?.workspace?.workspace?._id
    const result = yield UpdateEmailTemplateAPI(id, { ...data, workspace }, state)
    console.log(result)
    yield put(setSuccess(result.data?.message))
    yield put(updateEmailTemplateSuccess(result?.data?.data))
    if (toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* deleteEmailTemplate({ type, payload: { id, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield DeleteEmailTemplateAPI(id, state)
    console.log(result)
    yield put(setSuccess(result.data?.message))
    yield put(deleteEmailTemplateSuccess(result?.data?.data))
    if (toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* listTop5Tags({ type, payload: { query = {} } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const workspace = state?.workspace?.workspace?._id
    query = new URLSearchParams({ workspace: workspace, ...query }).toString()
    const result = yield ListTop5TagsAPI(query, state)
    console.log(result)
    yield put(listTop5TagsSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* addCustomerTask({ type, payload: { id, data, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield AddCustomerTaskAPI(id, data, state)
    console.log(result)
    yield put(addCustomerTaskSuccess(result?.data?.data))
    if(toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* addMedia({ type, payload: { file, query } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    query = new URLSearchParams({
      ...query,
      workspace: state.workspace.workspace?._id,
      type: 'pdf'
    }).toString()
    const result = yield AddMediaAPI(query, file, state)
    console.log(result)
    yield put(addMediaSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* listMedia({ type, payload: { id } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield ListMediaAPI(id, state)
    console.log(result)
    yield put(listMediaSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* removeMedia({ type, payload: { id } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield RemoveMediaAPI(id, state)
    console.log(result)
    yield put(removeMediaSuccess(id))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* CrmSaga() {
  yield takeEvery(ADD_CUSTOMER, addCustomer)
  yield takeEvery(UPDATE_CUSTOMER, updateCustomer)
  yield takeEvery(GET_CUSTOMER, getCustomer)
  yield takeEvery(GET_EMAILS, getCustomerEmails)
  yield takeEvery(DELETE_CUSTOMER, deleteCustomer)
  yield takeEvery(LIST_CUSTOMERS, listCustomers)
  yield takeEvery(ADD_CUSTOMER_COMMENT, addComment)
  yield takeEvery(UPLOAD_CUSTOMERS, uploadCustomers)
  yield takeEvery(REMOVE_CUSTOMER_COMMENT, removeComment)
  yield takeEvery(ADD_CUSTOMER_TASK, addCustomerTask)
  yield takeEvery(ADD_TAG, addTag)
  yield takeEvery(GET_ANALYTICS, getCrmAnalytics)
  yield takeEvery(LIST_TAGS, listTags)
  yield takeEvery(LIST_TOP_5_TAGS, listTop5Tags)
  yield takeEvery(LIST_EMAIL_TEMPLATES, listEmailTemplates)
  yield takeEvery(ADD_EMAIL_TEMPLATE, addEmailTemplate)
  yield takeEvery(GET_EMAIL_TEMPLATE, getEmailTemplate)
  yield takeEvery(UPDATE_EMAIL_TEMPLATE, updateEmailTemplate)
  yield takeEvery(DELETE_EMAIL_TEMPLATE, deleteEmailTemplate)
  yield takeEvery(ADD_MEDIA, addMedia)
  yield takeEvery(LIST_MEDIA, listMedia)
  yield takeEvery(REMOVE_MEDIA, removeMedia)
}

export default CrmSaga
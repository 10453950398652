import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { createCampaign, setError, updateEmail } from '../../../../store/actions';
import { CREATE_CAMPAIGN } from '../../../../store/campaigns/actionTypes';
import { checkIfLoading } from '../../../../store/functions';

function CreateCampaignModal({ modal, toggle, setName, name, campaignDetails, backlinkEmails }) {
    // ** states
    const dispatch = useDispatch()
    const history = useNavigate()
    const [t] = useTranslation()

    const { createLoading } = useSelector(state => ({
        createLoading: checkIfLoading(state, CREATE_CAMPAIGN)
    }))

    const onSubmit = () => {
        if (!name) dispatch(setError({ en: "Please provide name" }))
        dispatch(createCampaign({
            ...campaignDetails,
            backlinkEmails
        }, toggle))
    }

    return (
        <>
            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader className='px-4' toggle={toggle}></ModalHeader>
                <ModalBody className='pt-1 pb-3'>
                    <h4 className='mb-3 text-center'>{t('launch-your-campaign')}</h4>
                    <div style={{ width: "80%" }} className="m-auto">
                        <div className='mb-3'>
                            <label>{t('campaign-name')}</label>
                            <input type='text' onChange={(e) => setName(e.target.value)} className='form-control custom-border' placeholder='Enter campaign name' />
                        </div>
                        <button type='submit' className='btn click-btn green-btn btn-block mt-2' onClick={onSubmit}>
                            {
                                createLoading ? <Spinner size="sm" className="m-auto text-center" color="light" /> : t('launch-your-campaign')
                            }
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default CreateCampaignModal
import {
  LOGIN_USER, LOGIN_USER_SUCCESS, LOGOUT_USER, LOGOUT_USER_SUCCESS,
  AUTH_GOOGLE, AUTH_GOOGLE_SUCCESS, DELETE_USER, DELETE_USER_SUCCESS,
  FORGET_PASSWORD, FORGET_PASSWORD_SUCCESS, GET_USER_DATA, GET_USER_DATA_SUCCESS,
  LIST_ANALYTICS, LIST_ANALYTICS_SUCCESS, LIST_LANGUAGES, LIST_LANGUAGES_SUCCESS,
  REGISTER_USER, REGISTER_USER_SUCCESS, RESET_PASSWORD, RESET_PASSWORD_SUCCESS,
  UPDATE_EMAIL, UPDATE_EMAIL_SUCCESS, UPDATE_PASSWORD, UPDATE_PASSWORD_SUCCESS,
  UPDATE_USER_DATA,
  UPDATE_USER_DATA_SUCCESS,
  VERIFY_USER,
  VERIFY_USER_SUCCESS,
  ADD_PAYMENT_METHOD,
  ADD_PAYMENT_METHOD_SUCCESS,
  GET_PAYMENT_METHOD,
  GET_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD_SUCCESS,
  CHANGE_PAYMENT_METHOD,
  CHANGE_PAYMENT_METHOD_SUCCESS,
  LIST_SEARCH_CONSOLE_ACCOUNTS,
  LIST_SEARCH_CONSOLE_ACCOUNTS_SUCCESS
} from "./actionTypes"

const initialState = {
  user: null,
  token: localStorage.getItem('token') || null,
  analytics: null,
  paymentMethod: null,
  searchAccounts: null
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state }
    case LOGIN_USER_SUCCESS:
      return { ...state, user: action.payload?.user, token: action.payload?.token }
    case REGISTER_USER:
    case REGISTER_USER_SUCCESS:
    case VERIFY_USER:
      return { ...state }
    case VERIFY_USER_SUCCESS:
      return { ...state, token: action.payload }
    case GET_USER_DATA:
      return { ...state }
    case GET_USER_DATA_SUCCESS:
      return { ...state, user: action.payload }
    case UPDATE_USER_DATA:
      return { ...state }
    case UPDATE_USER_DATA_SUCCESS:
      return { ...state, user: action.payload?.data }
    case UPDATE_PASSWORD:
    case UPDATE_PASSWORD_SUCCESS:
    case FORGET_PASSWORD:
    case FORGET_PASSWORD_SUCCESS:
    case RESET_PASSWORD:
    case RESET_PASSWORD_SUCCESS:
    case UPDATE_EMAIL:
    case LIST_LANGUAGES:
    case LIST_LANGUAGES_SUCCESS:
    case AUTH_GOOGLE:
    case AUTH_GOOGLE_SUCCESS:
    case DELETE_USER:
    case DELETE_USER_SUCCESS:
    case LIST_ANALYTICS:
    case LIST_SEARCH_CONSOLE_ACCOUNTS:
      return { ...state, }
    case UPDATE_EMAIL_SUCCESS:
      return { ...state, user: { ...state?.user, email: action?.payload } }
    case LIST_ANALYTICS_SUCCESS:
      return { ...state, analytics: action.payload }
    case LIST_SEARCH_CONSOLE_ACCOUNTS_SUCCESS:
      return { ...state, searchAccounts: action.payload }
    case LOGOUT_USER:
      return { ...state }
    case LOGOUT_USER_SUCCESS:
      return { ...state, user: null, token: null }
    case GET_PAYMENT_METHOD:
      return { ...state }
    case GET_PAYMENT_METHOD_SUCCESS:
      return { ...state, paymentMethod: action.payload }
    case CHANGE_PAYMENT_METHOD:
      return { ...state }
    case CHANGE_PAYMENT_METHOD_SUCCESS:
      return { ...state, paymentMethod: action.payload }
    case ADD_PAYMENT_METHOD:
      return { ...state }
    case ADD_PAYMENT_METHOD_SUCCESS:
      return { ...state, plan: action.payload }
    case DELETE_PAYMENT_METHOD:
      return { ...state }
    case DELETE_PAYMENT_METHOD_SUCCESS:
      return { ...state, paymentMethod: null }
    default:
      return state
  }
}

export default auth

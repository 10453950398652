import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap'
import { addCompetitor, getCompetitorData, listCompetitors, removeCompetitor } from '../../../store/actions'
import { GET_COMPETITOR_DATA } from '../../../store/competitorAnalysis/actionTypes'
import { checkIfLoading } from '../../../store/functions'
import { ADD_COMPETITOR, LIST_COMPETITORS, REMOVE_COMPETITOR } from '../../../store/webreputation/actionTypes'
import CompetitorAge from './Components/age'
import CompetitorAnalytics from './Components/analytics'
import CompetitorAudience from './Components/audience'
import CompetitorBoxes from './Components/competitorBoxes'
import CompetitorCountry from './Components/country'
import CompetitorDetails from './Components/details'
import CompetitorLists from './Components/lists'
import MarketingChannel from './Components/marketing-channel'
import CompetitorRanking from './Components/ranking'
import CompetitorSimilarSites from './Components/similar-sites'
import SocialMediaTraffic from './Components/social-media'
import CompetitorTraffic from './Components/traffic'

function index() {
    // ** states
    const [modal, setModal] = useState(false);
    const dispatch = useDispatch()
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(!dropdownOpen);
    const [addCompBox, setAddCompBox] = useState(false)
    const [t] = useTranslation()
    const [compUrl, setCompUrl] = useState(null)

    const { loading, competitor, competitors, listLoading, addCompLoading, removeLoading } = useSelector(state => ({
        loading: checkIfLoading(state, GET_COMPETITOR_DATA),
        competitor: state?.competitorAnalysis.competitor,
        competitors: state.webreputation?.competitors?.list,
        listLoading: checkIfLoading(state, LIST_COMPETITORS),
        addCompLoading: checkIfLoading(state, ADD_COMPETITOR),
        removeLoading: checkIfLoading(state, REMOVE_COMPETITOR)
    }))

    useEffect(() => {
        dispatch(listCompetitors())
    }, [])

    useEffect(() => {
        // if (competitors?.length > 0) {
        //     dispatch(getCompetitorData({
        //         competitor: competitors[0]?._id
        //     }))
        // }
    }, [competitors])

    const renderCompetitors = () => {
        if (listLoading) return <Spinner size="sm" className="m-auto text-center" color="success" />
        if (competitors?.length > 0) {
            return competitors?.map(comp => {
                return <DropdownItem onClick={() => dispatch(getCompetitorData({
                    competitor: comp?._id
                }))}>
                    <button className="btn">{comp?.url}</button>
                </DropdownItem>
            })
        } else {
            return <option>No Competitor found</option>
        }
    }

    const submitAddCompetitor = () => {
        if (!compUrl) return
        dispatch(addCompetitor(compUrl, () => setAddCompBox(false)))
    }

    return (
        <div className='competitor-analysis'>
            <div className='container-fluid'>
                <div className='row justify-content-center'>
                    <div className='col-md-12 header d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <div className='mr-2'>
                                {
                                    competitor?.logo ? <img className='workspace-logo' src={competitor?.logo} alt='' /> : <span className='header-workspace-font-logo'>{String(competitor?.competitor?.url).charAt(0).toUpperCase()}</span>
                                }
                            </div>
                            <Dropdown disabled={listLoading} className='custom-dropdown select-box mr-2' isOpen={dropdownOpen} toggle={toggle}>
                                <DropdownToggle style={{ width: "200px" }} caret>
                                    {competitor?.competitor?.url || "Choose competitor"}
                                </DropdownToggle>
                                <DropdownMenu style={{ width: "200px" }} right={true}>
                                    {renderCompetitors()}
                                </DropdownMenu>
                            </Dropdown>
                            <button onClick={() => setAddCompBox(!addCompBox)} className='btn click-btn green-btn mr-2'>{t('add-new-competitor')}</button>
                            {/* <button className='btn click-btn green-btn mr-2'>Add new competitor</button> */}
                            <button className='btn click-btn btn-outline-danger' onClick={() => dispatch(removeCompetitor(competitor?.competitor?._id))}>
                                {
                                    removeLoading ? <Spinner color='danger' size={'sm'} /> : "Remove selected competitor"
                                }
                            </button>
                            <div className='add-competitor' style={{ display: addCompBox ? 'block' : 'none' }}>
                                <div className='row align-items-end'>
                                    <div className='col-md-8'>
                                        <input className='form-control' onChange={(e) => setCompUrl(e.target.value)} placeholder={t('enter-competitor-url')} />
                                    </div>
                                    <div className='col-md-4'>
                                        <button className='btn click-btn orange-btn btn-block py-1 px-1' onClick={submitAddCompetitor}>
                                            {
                                                addCompLoading ? <Spinner size="sm" className="m-auto text-center" color="light" /> : t('add-competitor')
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center'>
                            <div className='update'>
                                <button type='button' className='btn text-muted' onClick={() => dispatch(getCompetitorData({
                                    competitor: competitor?.competitor?._id,
                                    update: true
                                }))}><i className="fas fa-redo mr-2"></i> Update</button>
                            </div>
                            {/* <button className='btn click-btn orange-btn'>Download Now</button> */}
                        </div>
                    </div>
                    {
                        loading ? <Spinner className="m-auto text-center" color="success" /> :
                            competitor?.data ? <>
                                <div className='col-md-12 mt-2'>
                                    <div className='row'>
                                        <CompetitorDetails competitor={competitor} />
                                        <CompetitorAnalytics competitor={competitor} />
                                    </div>
                                    <CompetitorBoxes competitor={competitor} />
                                    {/* <CompetitorRanking competitor={competitor} /> */}
                                    <CompetitorTraffic competitor={competitor} />
                                    <CompetitorCountry competitor={competitor} />
                                    {/* <CompetitorAge competitor={competitor} /> */}
                                    <CompetitorAudience competitor={competitor} />
                                    <CompetitorSimilarSites competitor={competitor} />
                                    <MarketingChannel competitor={competitor} />
                                    <CompetitorLists competitor={competitor} />
                                    <SocialMediaTraffic competitor={competitor} />
                                </div>
                            </> : !competitor ? "Choose one competitor from the list to see his analysis" : "No data found"
                    }
                </div>
            </div>
        </div >
    )
}

export default index
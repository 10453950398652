export const tokenConfig = (getState) => {
  let config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  let token = getState?.auth?.token

  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  }

  return config
}

export const tokenConfigUpload = (getState) => {
  let config = {
    headers: {
      // 'Content-Type': 'multipart/form-data'
    }
  }

  let token = getState.auth.token

  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  }

  return config
}

export const config = () => {
  return {
    headers: {
      'Content-Type': 'application/json'
    }
  }
}
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Progress, Spinner } from 'reactstrap'

function SourceTraffic({ data, loading }) {

  const [chart, setChart] = useState([])
  const colors = ['orange', 'danger', 'primary', 'warning', 'danger', 'info', 'secondary']
  const [t] = useTranslation()

  useEffect(() => {
    console.log({ data })
    let dataArray = []
    if (data) {
      // for (let [key, value] of Object.entries(data)) {
      //   dataArray.push({
      //     source: key,
      //     value: value?.percent
      //   })
      // }
      data = data?.slice(0, 7)?.map((e, i) => {
        return {
          ...e,
          color: colors[i]
        }
      })
      setChart(data)
    }
  }, [data])

  const renderSourceTraffic = (chart) => {
    if (chart?.length > 0) {
      return chart.map(e => {
        return <li className='d-flex justify-content-between mb-1'>
          <span><span className={`list-circle bg-${e.color}`}></span>{e.label}</span>
          <span><b>{e.value}</b></span>
        </li>
      })
    }
  }

  const renderProgress = (chart) => {
    if (chart?.length > 0) {
      return chart.map(e => {
        return <Progress bar color={e.color} value={e.value} />
      })
    }
  }

  return (
    <div className='card p-3'>
      <h5 className='m-0 mb-3'>{t('Traffic by Source')}</h5>
      {
        loading ? <Spinner size="md" className="m-auto text-center" color="success" /> : <>
          <Progress multi>
            {renderProgress(chart)}
          </Progress>
          <ul className='list-unstyled mt-3'>
            {renderSourceTraffic(chart)}
          </ul>
        </>
      }
    </div>
  )
}

export default SourceTraffic
import {
  LIST_WORKSPACES,
  LIST_WORKSPACES_SUCCESS,
  GET_WORKSPACE,
  GET_WORKSPACE_SUCCESS,
  ADD_WORKSPACE,
  ADD_WORKSPACE_SUCCESS,
  UPDATE_WORKSPACE,
  UPDATE_WORKSPACE_SUCCESS,
  DELETE_WORKSPACE,
  DELETE_WORKSPACE_SUCCESS,
  INVITE_COLLABORATOR,
  INVITE_COLLABORATOR_SUCCESS,
  REMOVE_COLLABORATOR,
  REMOVE_COLLABORATOR_SUCCESS,
  REGISTER_DOMAIN,
  REGISTER_DOMAIN_SUCCESS,
  ADD_SENDER_EMAIL,
  ADD_SENDER_EMAIL_SUCCESS,
  LIST_WORKSPACE_LANGUAGES,
  LIST_WORKSPACE_REGIONS,
  LIST_WORKSPACE_REGIONS_SUCCESS,
  LIST_WORKSPACE_LANGUAGES_SUCCESS,
  LIST_WORKSPACE_BILLINGS,
  LIST_WORKSPACE_BILLINGS_SUCCESS,
  CANCEL_WORKSPACE_SUBSCRIPTION,
  CANCEL_WORKSPACE_SUBSCRIPTION_SUCCESS,
  SELECT_WORKSPACE_ANALYTICS,
  SELECT_WORKSPACE_ANALYTICS_SUCCESS,
  DISCONNECT_GOOGLE,
  DISCONNECT_GOOGLE_SUCCESS,
  UPDATE_WORKSPACE_BUSINESS_SUCCESS,
  GET_DOMAIN,
  GET_DOMAIN_SUCCESS,
  GET_WORKSPACE_CONNECTIONS,
  GET_WORKSPACE_CONNECTIONS_SUCCESS,
  SELECT_WORKSPACE_SEARCH_CONSOLE,
  SELECT_WORKSPACE_SEARCH_CONSOLE_SUCCESS
} from "./actionTypes"

const initialState = {
  workspaces: [],
  workspace: JSON.parse(localStorage.getItem('workspace')),
  languages: [],
  regions: [],
  billings: {},
  domain: null
}

const workspace = (state = initialState, action) => {
  switch (action.type) {
    case LIST_WORKSPACES:
      return { ...state }
    case LIST_WORKSPACES_SUCCESS:
      return { ...state, workspaces: action.payload }
    case GET_WORKSPACE:
      return { ...state }
    case GET_WORKSPACE_SUCCESS:
      return { ...state, workspace: action.payload }
    case ADD_WORKSPACE:
      return { ...state }
    case ADD_WORKSPACE_SUCCESS:
      return { ...state, workspaces: [...state?.workspaces, action.payload], workspace: action.payload }
    case UPDATE_WORKSPACE:
      return { ...state }
    case UPDATE_WORKSPACE_SUCCESS:
      return { ...state, workspace: action.payload, workspaces: state.workspaces.map(el => el._id === action.payload._id ? action.payload : el) }
    case UPDATE_WORKSPACE_BUSINESS_SUCCESS:
      return {
        ...state, workspace: {
          ...state?.workspace,
          business: action?.payload
        }
      }
    case REGISTER_DOMAIN:
      return { ...state }
    case REGISTER_DOMAIN_SUCCESS:
      return {
        ...state, workspace: {
          ...state?.workspace,
          status: action?.payload?.status,
        }, domain: action?.payload
      }
    case GET_DOMAIN:
      return { ...state }
    case GET_DOMAIN_SUCCESS:
      return {
        ...state, workspace: {
          ...state?.workspace,
          status: action?.payload?.status,
        }, domain: action?.payload
      }
    case ADD_SENDER_EMAIL:
      return { ...state }
    case ADD_SENDER_EMAIL_SUCCESS:
      return {
        ...state, workspace: {
          ...state?.workspace,
          senders: [...state?.workspace?.senders, action.payload]
        }
      }
    case LIST_WORKSPACE_LANGUAGES:
      return { ...state }
    case LIST_WORKSPACE_LANGUAGES_SUCCESS:
      return { ...state, languages: action.payload }
    case LIST_WORKSPACE_REGIONS:
      return { ...state }
    case LIST_WORKSPACE_REGIONS_SUCCESS:
      return { ...state, regions: action.payload }
    case REMOVE_COLLABORATOR:
      return { ...state }
    case REMOVE_COLLABORATOR_SUCCESS:
      return { ...state, workspace: { ...state.workspace, collaborators: state.workspace?.collaborators?.filter(c => c._id !== action.payload) } }
    case LIST_WORKSPACE_BILLINGS:
      return { ...state }
    case LIST_WORKSPACE_BILLINGS_SUCCESS:
      return { ...state, billings: action.payload }
    case DELETE_WORKSPACE:
      return { ...state }
    case DELETE_WORKSPACE_SUCCESS:
      return { ...state, workspace: null }
    case SELECT_WORKSPACE_ANALYTICS:
      return { ...state }
    case SELECT_WORKSPACE_ANALYTICS_SUCCESS:
      return { ...state, workspace: { ...state.workspace, connections: action.payload } }
    case SELECT_WORKSPACE_SEARCH_CONSOLE:
      return { ...state }
    case SELECT_WORKSPACE_SEARCH_CONSOLE_SUCCESS:
      return { ...state, workspace: { ...state.workspace, connections: action.payload } }
    case DISCONNECT_GOOGLE:
    case GET_WORKSPACE_CONNECTIONS:
      return { ...state }
    case DISCONNECT_GOOGLE_SUCCESS:
    case GET_WORKSPACE_CONNECTIONS_SUCCESS:
      return { ...state, workspace: { ...state.workspace, connections: action.payload } }
    case CANCEL_WORKSPACE_SUBSCRIPTION:
      return { ...state }
    case CANCEL_WORKSPACE_SUBSCRIPTION_SUCCESS:
      return { ...state, workspace: { ...state.workspace, subscription: null } }
    default:
      return state
  }
}

export default workspace
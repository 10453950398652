import {
  GET_DASHBOARD,
  GET_DASHBOARD_ERROR,
  GET_DASHBOARD_SUCCESS,
  UPDATE_DASHBOARD,
  UPDATE_DASHBOARD_SUCCESS
} from "./actionTypes"

const initialState = {
  data: null,
  error: null
}

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD:
      return { ...state, data: null }
    case GET_DASHBOARD_SUCCESS:
      return { ...state, data: action.payload }
    case GET_DASHBOARD_ERROR:
      return { ...state, data: null, error: action.payload }
    default:
      return state
  }
}

export default dashboard

import React, { useEffect } from 'react'
import { FormCheck, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import TablePagination from '../../../../components/common/Pagination'
import { getCustomer, listCustomers } from '../../../../store/actions'
import { LIST_CUSTOMERS } from '../../../../store/crm/actionTypes'
import { checkIfLoading } from '../../../../store/functions'
import moment from 'moment'

const CustomersTable = ({ query, onPageChange }) => {
    const dispatch = useDispatch()
    const [t] = useTranslation()

    const { customers, count, customerLoading, workspace } = useSelector(state => ({
        customers: state?.crm?.customers?.list,
        count: state?.crm?.customers?.page_details?.total_items,
        customerLoading: checkIfLoading(state, LIST_CUSTOMERS),
        workspace: state.workspace?.workspace
    }))

    useEffect(() => {
        dispatch(listCustomers(query))
    }, [query, workspace])

    const renderCustomers = () => {
        if (customerLoading) return <tr><td colSpan={6} className="text-center"><Spinner size="md" className="m-auto text-center" color="success" /></td></tr>
        if (customers && customers?.length > 0) {
            return customers?.map(cust => {
                return <tr>
                    <td><FormCheck /></td>
                    <td>
                        <Link className='text-dark' onClick={() => dispatch(getCustomer(cust?._id))} to={`/crm/customer/${cust?._id}`}>
                        {cust?.name || cust?.email}
                        </Link>
                    </td>
                    <td>{cust?.phone}</td>
                    <td>{cust?.email}</td>
                    <td>{cust?.company}</td>
                    <td>{moment(cust?.date || Date.now()).format('DD/MM/YYYY')}</td>
                </tr>
            })
        } else {
            return <tr><td colSpan={6}>{t('No customers found')}</td></tr>
        }
    }

    return (
        <div className="customers-table">
            <Table striped hover>
                <thead>
                    <tr>
                        <th><FormCheck /></th>
                        <th>{t('Name')}</th>
                        <th>{t('Phone Number')}</th>
                        <th>{t('email')}</th>
                        <th>{t('Company name')}</th>
                        <th>{t('Date Added')}</th>
                    </tr>
                </thead>
                <tbody>
                    {renderCustomers()}
                </tbody>
            </Table>
            <TablePagination count={count} limit={query.limit} loading={customerLoading} page={query.page} setPage={onPageChange} />
        </div>
    )
}

export default CustomersTable
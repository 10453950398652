import React from "react";
import { useTranslation } from "react-i18next";
import { numberWithCommas } from "../../../../store/functions";

function CompetitorAnalytics({ competitor }) {
    const [t] = useTranslation()

    return (
        <div className="col-md-6">
            <div className="card competitor-analytics">
                <h4>{competitor?.data?.site_url}</h4>
                <p>{competitor?.data?.site_description}</p>
                <div className="row">
                    <div className="col-md-4 box">
                        <div>{t('Global ranking')}</div>
                        <div className="d-flex align-items-center"><span className="mr-1">#</span><div className="h3 m-0">{numberWithCommas(competitor?.data?.global_rank?.rank)}</div></div>
                        <div className="text-danger">{competitor?.data?.global_rank?.rank}</div>
                    </div>
                    <div className="col-md-4 box">
                        <div>{t('Ranking by country')}</div>
                        <div className="d-flex align-items-center"><span className="mr-1">#</span><div className="h3 m-0">{numberWithCommas(competitor?.data?.country_rank?.rank)}</div></div>
                        <div className="text-danger">{competitor?.data?.country_rank?.rank}</div>
                        <div>{competitor?.data?.country_rank?.country}</div>
                    </div>
                    <div className="col-md-4 box">
                        <div>{t('Classification by category')}</div>
                        <div className="d-flex align-items-center"><span className="mr-1">#</span><div className="h3 m-0">{numberWithCommas(competitor?.data?.category_rank?.rank)}</div></div>
                        <div className="text-danger">{competitor?.data?.category_rank?.rank}</div>
                        <div>{String(competitor?.data?.category_rank?.category).substring(30)}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompetitorAnalytics;
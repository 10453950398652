import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

const SettingsNav = ({ tab, setTab }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const [t] = useTranslation()

    return (
        <div className='crm-nav mt-5'>
            <ul className='list-group m-auto text-center list-group-horizontal-lg'>
                <li onClick={() => setTab('workspace')} className={`${tab === 'workspace' ? 'active' : ""}`}><h6 className='m-0'>{t('workspace')}</h6></li>
                <li onClick={() => setTab('billing')} className={`${tab === 'billing' ? 'active' : ""}`}><h6 className='m-0'>{t('billing')}</h6></li>
                <li onClick={() => setTab('connections')} className={`${tab === 'connections' ? 'active' : ""}`}><h6 className='m-0'>{t('connections')}</h6></li>
                <li onClick={() => setTab('account')} className={`${tab === 'account' ? 'active' : ""}`}><h6 className='m-0'>{t('account')}</h6></li>
            </ul>
        </div>
    )
}

export default SettingsNav
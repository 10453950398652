import { combineReducers } from 'redux';
import auth from './auth/reducer';
import uiReducer from './ui/reducer';
import workspace from './workspace/reducer';
import contentAnalysis from './contentAnalysis/reducer';
import technicalAnalysis from './technicalAnalysis/reducer';
import dashboard from './dashboard/reducer';
import localseo from './localseo/reducer';
import sales from './salesManagement/reducer';
import subscription from './subscription/reducer';
import webreputation from './webreputation/reducer';
import crm from './crm/reducer';
import upload from './upload/reducer';
import reminder from './reminders/reducer';
import contentGenerator from './contentGenerator/reducer';
import project from './projectManagement/reducer';
import campaign from './campaigns/reducer';
import competitorAnalysis from './competitorAnalysis/reducer'

export default combineReducers({
  auth,
  ui: uiReducer,
  workspace,
  contentAnalysis,
  technicalAnalysis,
  dashboard,
  localseo,
  sales,
  webreputation,
  reminder,
  upload,
  subscription,
  contentGenerator,
  crm,
  project,
  campaign,
  competitorAnalysis
});
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { getBusiness, listBusinesses } from '../../../store/actions';
import { checkIfLoading } from '../../../store/functions';
import { GET_BUSINESS, LIST_BUSINESSES } from '../../../store/localseo/actionTypes';

function SearchResultModal({ modal, toggle }) {
  const dispatch = useDispatch()
  const history = useNavigate()
  const [name, setName] = useState(null)
  const [errMsg, setErrMsg] = useState(false)
  const [t] = useTranslation()

  const { listLoading, businesses, selectLoading, workspace } = useSelector(state => ({
    listLoading: checkIfLoading(state, LIST_BUSINESSES),
    businesses: state?.localseo?.businesses,
    selectLoading: checkIfLoading(state, GET_BUSINESS),
    workspace: state?.workspace?.workspace?._id
  }))

  const renderBusinesses = () => {
    if (listLoading) return <Spinner size="sm" className="m-auto text-center" color="success" />
    if (businesses?.length > 0) {
      return businesses?.map(b => {
        return <a className='result btn' onClick={() => dispatch(getBusiness(b?._id, workspace, history))} href='#'>
          <div className='img-con'>
            <img src={b?.image} onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = `${process.env.PUBLIC_URL}/assets/images/market.jpg`;
            }} alt='result-img' />
          </div>
          <div className='description'>
            <h3 className='title'>
              <span className='name'>{b?.title}</span>
              <span className='review'>
                <i class="fas fa-star"></i>
                <b>{b?.rating}</b>
                ({b?.votes})
              </span>
            </h3>
            <p className='location mt-2'>
              <i class="fas fa-map-marker-alt"></i>
              {b?.address?.address}, {b?.address?.city}, {b?.address?.country_code}
            </p>
          </div>
        </a>
      })
    } else {
      return t('No results found')
    }
  }

  const onSearchSubmit = () => {
    if (!name) return setErrMsg(true)
    setErrMsg(false)
    dispatch(listBusinesses(name))
  }

  return (
    <Modal className='search-result-modal' isOpen={modal} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle}>
        <h4>{t('search-results')}</h4>
      </ModalHeader>
      <ModalBody>
        {
          selectLoading ? <div className='text-center my-3'><Spinner size="lg" className="m-auto text-center" color="success" /></div> : <>
            <div>
              <div className='d-flex justify-content-between'>
                <input type='search mb-0' onChange={e => setName(e.target.value)} className='form-control' placeholder='Zuma Rome' />
                <button type='submit' disabled={listLoading} onClick={onSearchSubmit} className='btn search-btn'>
                  <i className="fas fa-search"></i>
                </button>
              </div>
              {errMsg ? <span className='text-danger'>{t('Please enter name')}</span> : ""}
            </div>
            <div className='business-list'>
              {renderBusinesses()}
            </div>
          </>
        }
      </ModalBody>
    </Modal>
  )
}

export default SearchResultModal
import { put, select, takeEvery } from "redux-saga/effects"
import { AddColumnAPI, AddPipelineAPI, AddTabAPI, DeleteColumnAPI, DeleteTabAPI, GetPipelineAPI, ListPipelinesAPI, UpdateColumnAPI, UpdateTabAPI, UpdateTabOrdersAPI } from "./calls"
import { addColumnSuccess, addPipelineSuccess, addTabSuccess, deleteColumnSuccess, deleteTabSuccess, getPipelineSuccess, listPipelinesSuccess, updateColumnSuccess, updateTabSuccess } from './actions'
import { ADD_COLUMN, ADD_PIPELINE, ADD_TAB, DELETE_COLUMN, DELETE_TAB, GET_PIPELINE, LIST_PIPELINES, UPDATE_COLUMN, UPDATE_TAB, UPDATE_TAB_ORDERS } from "./actionTypes"
import { setError, setSuccess, startAction, stopAction } from "../actions"

function* listPipelines({ type, payload: { } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const query = new URLSearchParams({ workspace: state?.workspace?.workspace?._id }).toString()
    const result = yield ListPipelinesAPI(state, query)
    const pipelines = result?.data?.data
    if (pipelines?.length > 0) {
      yield put(getPipelineSuccess(pipelines[0]))
    }
    console.log(result)
    yield put(listPipelinesSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* addPipeline({ type, payload: { data, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    data = { ...data, workspace: state?.workspace?.workspace?._id }
    const result = yield AddPipelineAPI(data, state)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(addPipelineSuccess(result?.data?.data))
    toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* getPipeline({ type, payload: { id, update, search } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    let query = {}
    if(search) query['search'] = search
    query = new URLSearchParams(query).toString()
    let result
    if(update) {
      result = yield GetPipelineAPI(id, query, state)
      result = result?.data?.data
      console.log(result)
    } else {
      result = state?.sales?.pipelines?.find(pip => pip?._id === id)
    }
    yield put(getPipelineSuccess(result))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* addColumn({ type, payload: { id, data } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield AddColumnAPI(id, data, state)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(addColumnSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* updateColumn({ type, payload: { id, data, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield UpdateColumnAPI(id, data, state)
    console.log(result)
    yield put(updateColumnSuccess(result?.data?.data))
    yield put(setSuccess(result?.data?.message))
    toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* addTab({ type, payload: { id, data, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield AddTabAPI(id, data, state)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(addTabSuccess(result?.data?.data))
    toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* deleteTab({ type, payload: { id } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield DeleteTabAPI(id, state)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(deleteTabSuccess(id))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* deleteColumn({ type, payload: { id } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield DeleteColumnAPI(id, state)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(deleteColumnSuccess(id))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* updateTab({ type, payload: { id, data, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield UpdateTabAPI(id, data, state)
    console.log(result)
    yield put(updateTabSuccess(result?.data?.data))
    if(toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* updateTabOrder({ type, payload: { tabs, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield UpdateTabOrdersAPI(tabs, state)
    if(toggle) toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* SalesManagementSaga() {
  yield takeEvery(LIST_PIPELINES, listPipelines)
  yield takeEvery(ADD_PIPELINE, addPipeline)
  yield takeEvery(GET_PIPELINE, getPipeline)
  yield takeEvery(ADD_COLUMN, addColumn)
  yield takeEvery(ADD_TAB, addTab)
  yield takeEvery(DELETE_TAB, deleteTab)
  yield takeEvery(DELETE_COLUMN, deleteColumn)
  yield takeEvery(UPDATE_COLUMN, updateColumn)
  yield takeEvery(UPDATE_TAB, updateTab)
  yield takeEvery(UPDATE_TAB_ORDERS, updateTabOrder)
}

export default SalesManagementSaga
import React from "react";
import { useTranslation } from "react-i18next";

function Reviews({ data }) {
  const [t] = useTranslation()

  const renderData = () => {
    if (data?.length > 0) {
      return data.map(ele =>
        <div className="review" key={ele._id}>
          <div className="img-con">
            <img src={ele?.image} alt="" onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = `${process.env.PUBLIC_URL}/assets/images/market.jpg`;
            }} width={'50px'} height="50px" />
          </div>
          <div className="description">
            <h6>
              <i class="fas fa-star"></i>
              <span className="review-rate">{ele.rating}</span>
              <span>. by {ele.rateBy}  at {new Date(ele.date).toLocaleDateString()}</span>
            </h6>
            <p className="text">“ {ele.review} “</p>
          </div>
        </div>
      )
    } else {
      return <div>{t('no-reviews-found')}</div>
    }
  }

  return (
    <div className="reviews">
      <h4>{t('reviews')} ({data?.length})</h4>
      {renderData()}
    </div>
  )
}

export default Reviews;